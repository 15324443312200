import {
	REQUESTOR_LOGIN_FAIL,
	REQUESTOR_LOGIN_SUCCESS,
	REQUESTOR_LOGOUT,
	REQUESTOR_FETCH_ACCOUNT_SUCCESS,
	REQUESTOR_AUTO_LOGIN_SUCCESS,
	REQUESTOR_AUTO_LOGIN_FAIL,
	REQUESTOR_CLEAR_AUTH_MSG,
	REQUESTOR_LOGIN_REQ_START,
	CONTINUE_WITHOUT_LOGIN,
	EXIT_WITHOUT_LOGIN,
} from "./action-types";

const initialState = {
	loading: false,
	isAuthenticated: false,
	account: null,
	errorMessage: null as null | string,
	redirectMessage: null as null | string,
	sessionHasBeenFetched: false,
	logout: false,
};

export type AuthenticationState = Readonly<typeof initialState>;

const authentication = (
	state: AuthenticationState = initialState,
	action: any
): AuthenticationState => {
	const { type, payload } = action;
	switch (type) {
		case REQUESTOR_LOGIN_REQ_START:
			return {
				...state,
				loading: true,
			};
		case REQUESTOR_LOGIN_SUCCESS:
			return {
				...state,
				isAuthenticated: true,
				loading: false,
				errorMessage: null,
				account: payload.user,
				logout: false,
			};
		case REQUESTOR_LOGIN_FAIL:
			return {
				...state,
				isAuthenticated: false,
				loading: false,
				errorMessage: payload.errorMessage,
				account: null,
				logout: false,
			};
		case REQUESTOR_LOGOUT:
			return {
				...state,
				isAuthenticated: false,
				loading: false,
				redirectMessage: payload.message,
				account: null,
				logout: true,
			};
		case CONTINUE_WITHOUT_LOGIN:
			return {
				...state,
				isAuthenticated: false,
				account: null,
			};
		case EXIT_WITHOUT_LOGIN:
			return {
				...state,
				isAuthenticated: false,
			};
		case REQUESTOR_FETCH_ACCOUNT_SUCCESS:
			return {
				...state,
				loading: false,
				errorMessage: null,
				account: payload.user,
			};
		case REQUESTOR_AUTO_LOGIN_SUCCESS:
			return {
				...state,
				loading: false,
				errorMessage: null,
				isAuthenticated: true,
				account: payload.user,
				sessionHasBeenFetched: true,
			};
		case REQUESTOR_AUTO_LOGIN_FAIL:
			return {
				...state,
				loading: false,
				isAuthenticated: false,
				account: null,
				sessionHasBeenFetched: true,
			};
		case REQUESTOR_CLEAR_AUTH_MSG:
			return {
				...state,
				errorMessage: null,
			};
		default:
			return state;
	}
};

export default authentication;
