/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Collapse,
  Space,
  Button,
  Empty,
  InputNumber,
} from "antd";
import classes from "./item-selection.module.scss";
import { allowOnlyNumbers } from "../../../common/utils/trimUtil";
import {
  SPECIALTY_HOVER_TITLE,
  SPECIALTY_INPUT_NUMBER_DISABLED_TITLE,
  VIEW_PATIENTS_TEXT,
} from "../../../common/shared-constants";
import PatientDetailsModal from "../../../common/components/PatientDetailsModal/patient-details-modal";
import DelayModal from "../../../common/components/DelayModal/DelayModal";

const ItemSelection = (props: any) => {
  const { Panel } = Collapse;
  const goBack = () => {
    props.goBack("form");
  };
  useEffect(() => {
    props.fetchCategoriesWithItems();
  }, []);

  const allCategories = props.allCategories;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [item, setItem] = useState<any>();
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [showPatients, setShowPatients] = useState<any>(false);
  const showModal = (item: any) => {
    setItem(item);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    props.onItemAdd(item);
  };

  const handleCancel = () => {
    setItem({});
    setIsModalVisible(false);
  };

  const searchHandle = (event: any) => {
    setSearchTerm(event.target.value.trim());
    if (event.target.value !== "") {
      props.fetchSearchList(event.target.value);
    }
  };

  const checkStatus = (id: any) => {
    const found: any = props.cartItems.find(
      (cartItem: any) => cartItem.item.id === id
    );
    return found ? true : false;
  };

  return (
    <div className={classes.selectionContainer}>
      <Row>
        <Col className={classes.selectionFixed}>
          <Row className={classes.searchInputRowResponsive}>
            <Col className={classes.gobackBtn}>
              <button onClick={goBack}></button>
            </Col>
            <Col>
              <Form.Item name="search" rules={[{ required: false }]}>
                <Input
                  className={`${classes.searchInput} ${
                    searchTerm && classes.searchHasValue
                  }`}
                  onChange={searchHandle}
                  placeholder="Search"
                />
              </Form.Item>
            </Col>
          </Row>
          {searchTerm && props.searchItems.length > 0 ? (
            <Row>
              <Col span={24}>
                <div className={classes.accordianContent}>
                  {props.searchItems.map((item: any, index: any) => {
                    const cartItem = props?.cartItems?.find(
                      (itm: any) => item.id === itm.item.id
                    );
                    return (
                      <div
                        key={item.id}
                        className={`fadeIn ${classes.accordianCol}`}
                      >
                        <div className={classes.accordianImg}>
                          <div className={classes.itemImageWrapper}>
                            <img src={item.image} alt="operation-bed" />
                            {!checkStatus(item.id) ? (
                              <div
                                className={`imageOverlay_100 full`}
                                onClick={() => {
                                  item.notify
                                    ? showModal(item)
                                    : props.onItemAdd(item);
                                }}
                              >
                                Add
                              </div>
                            ) : (
                              <>
                                <div
                                  className={`imageOverlay_100 decrement`}
                                  onClick={() => {
                                    cartItem?.quantity === 1
                                      ? props.onItemRemove(item.id)
                                      : props.quantityEdit(item.id, "DECREASE");
                                  }}
                                >
                                  -
                                </div>
                                <div
                                  className={`imageOverlay_100 increment`}
                                  onClick={() => {
                                    props.quantityEdit(item.id, "INCREASE");
                                  }}
                                >
                                  +
                                </div>
                              </>
                            )}
                          </div>
                          <div className={classes.itemName}>{item.name}</div>
                        </div>
                        <Button
                          type="primary"
                          disabled={checkStatus(item.id)}
                          onClick={() => {
                            item.notify
                              ? showModal(item)
                              : props.onItemAdd(item);
                          }}
                        >
                          {"Add to Order"}
                        </Button>
                        {item.delay && (
                          <div className={classes.delay} title="Delay">
                            *
                          </div>
                        )}
                        {item.specialtyItem && (
                          <div
                            className={classes.specialty}
                            title={SPECIALTY_HOVER_TITLE}
                          >
                            *
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          ) : searchTerm && props.searchItems.length === 0 ? (
            <div className={classes.empty}>
              <Empty />
            </div>
          ) : (
            <Space
              direction="vertical"
              className={`selectionAccordian ${classes.selectionAccordian}`}
            >
              {Object.keys(allCategories).length > 0 ? (
                Object.keys(allCategories).map((category: any, index: any) => {
                  return (
                    <Collapse defaultActiveKey={["beds"]} accordion={true}>
                      <Panel
                        className={classes.accordian}
                        header={category}
                        key={category}
                        showArrow={false}
                      >
                        <div className={classes.accordianContent}>
                          {allCategories[category].length === 0 && (
                            <div className={`${classes.noItemToShow} fadeIn`}>
                              Currently No Item is available
                            </div>
                          )}
                          {allCategories[category].map(
                            (item: any, index: any) => {
                              const cartItem = props?.cartItems?.find(
                                (itm: any) => item.id === itm.item.id
                              );
                              return (
                                <div
                                  key={item.id}
                                  className={classes.accordianCol}
                                >
                                  <div className={classes.accordianImg}>
                                    <div className={classes.itemImageWrapper}>
                                      <img
                                        src={item.image}
                                        alt="operation-bed"
                                      />
                                      {!checkStatus(item.id) ? (
                                        <div
                                          className={`imageOverlay_100 full`}
                                          onClick={() => {
                                            item.notify
                                              ? showModal(item)
                                              : props.onItemAdd(item);
                                          }}
                                        >
                                          Add
                                        </div>
                                      ) : (
                                        <>
                                          <div
                                            className={`imageOverlay_100 decrement`}
                                            onClick={() => {
                                              cartItem?.quantity === 1
                                                ? props.onItemRemove(item.id)
                                                : props.quantityEdit(
                                                    item.id,
                                                    "DECREASE"
                                                  );
                                            }}
                                          >
                                            -
                                          </div>
                                          <div
                                            className={`imageOverlay_100 increment`}
                                            onClick={() => {
                                              props.quantityEdit(
                                                item.id,
                                                "INCREASE"
                                              );
                                            }}
                                          >
                                            +
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <div className={classes.itemName}>
                                      {item.name}
                                    </div>
                                  </div>
                                  <Button
                                    type="primary"
                                    disabled={checkStatus(item.id)}
                                    onClick={() => {
                                      item.notify
                                        ? showModal(item)
                                        : props.onItemAdd(item);
                                    }}
                                  >
                                    {"Add to Order"}
                                  </Button>
                                  {item.delay && (
                                    <div
                                      className={classes.delay}
                                      title="Delay"
                                    >
                                      *
                                    </div>
                                  )}
                                  {item.specialtyItem && (
                                    <div
                                      className={classes.specialty}
                                      title={SPECIALTY_HOVER_TITLE}
                                    >
                                      *
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </Panel>
                    </Collapse>
                  );
                })
              ) : (
                <div className={`${classes.noItemToShow} fadeIn`}>
                  Currently No Item is available
                </div>
              )}
            </Space>
          )}
        </Col>
        <Col className={classes.cartFixed}>
          <Row>
            <Col span={24}>
              <h3 className={classes.orderTitle}>My Order</h3>
            </Col>
          </Row>
          <Row justify="center">
            <div className={classes.selectedItemListing}>
              <ul className={classes.cartItemsList}>
                {props.cartItems.map((cart: any, index: any) => {
                  return (
                    <li
                      key={index}
                      id={cart.item.id}
                      className={classes.cartCardList}
                    >
                      <div className={classes.accordianImg}>
                        <div className={classes.itemImageWrapper}>
                          <img src={cart.item.image} alt="operation-bed" />
                          {
                            <>
                              <div
                                className={`imageOverlay_100 decrement`}
                                onClick={() => {
                                  cart?.quantity === 1
                                    ? props.onItemRemove(cart.item.id)
                                    : props.quantityEdit(
                                        cart.item.id,
                                        "DECREASE"
                                      );
                                }}
                              >
                                -
                              </div>
                              <div
                                className={`imageOverlay_100 increment`}
                                onClick={() => {
                                  props.quantityEdit(cart.item.id, "INCREASE");
                                }}
                              >
                                +
                              </div>
                            </>
                          }
                        </div>
                        <div className={classes.itemName}>{cart.item.name}</div>
                        {cart.item.delay && (
                          <div className={classes.delay} title="Delay">
                            *
                          </div>
                        )}
                        {cart.item.specialtyItem && (
                          <div
                            className={classes.specialty}
                            title={SPECIALTY_HOVER_TITLE}
                          >
                            *
                          </div>
                        )}
                      </div>

                      <div className={classes.quantity}>
                        <p>Qty</p>
                        <button
                          onClick={(id) => {
                            cart?.quantity === 1
                              ? props.onItemRemove(cart.item.id)
                              : props.quantityEdit(cart.item.id, "DECREASE");
                          }}
                          className={classes.minus}
                        ></button>
                        <InputNumber
                          value={cart.quantity}
                          onChange={(quant) => {
                            props.quantityEdit(cart.item.id, "EDIT", quant);
                          }}
                          disabled={cart.item.specialtyItem}
                          title={
                            cart.item.specialtyItem
                              ? SPECIALTY_INPUT_NUMBER_DISABLED_TITLE
                              : ""
                          }
                          onKeyDown={allowOnlyNumbers}
                          min={1}
                          max={99}
                        />
                        <button
                          onClick={(id) => {
                            props.quantityEdit(cart.item.id, "INCREASE");
                          }}
                          disabled={cart.quantity >= 99}
                          className={classes.add}
                        ></button>
                        {cart.patients?.length && cart.item?.specialtyItem ? (
                          <span
                            className="linkLikeText"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowPatients({
                                itemId: cart.item.id,
                                mode: "VIEW",
                              });
                            }}
                          >
                            {VIEW_PATIENTS_TEXT}
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                      <button
                        onClick={() => {
                          props.onItemRemove(cart.item.id);
                        }}
                        className={classes.close}
                      >
                        <span>X</span>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Row>
          {props.cartItems.length > 0 && (
            <Row>
              <div className={classes.checkoutBtn}>
                <Button onClick={() => props.clear([])}>Clear</Button>
                <Button onClick={() => props.checkout()}>Checkout</Button>
              </div>
            </Row>
          )}
        </Col>
      </Row>
      <DelayModal
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
        text={item?.delayDescription}
        key="delayModal"
      />
      <PatientDetailsModal
        visible={!!showPatients.itemId}
        toggleModal={() => setShowPatients(false)}
        cart={props.cartItems} //just to remove items
        itemId={showPatients.itemId}
        mode={showPatients.mode}
      />
    </div>
  );
};

export default ItemSelection;
