import openSocket from "socket.io-client";
const socket = openSocket(`${process.env.REACT_APP_WEBSOCKET_URL}`, {
  path: "/ws/v1",
  transports: ["websocket"],
});

socket.on("connect", () => {
  console.log("websocket connected ", socket.id);
});
socket.on("subscribed", (res) => {
  console.log(res);
});

socket.on("unsubscribed", (res) => {
  console.log(res);
});

const getMySocket = () => {
  return socket;
};
const subscribeToRoom = async (roomName: string) => {
  try {
    await socket.emit(roomName);
    const res = await socket.on("subscribed", () => true);
    return res;
  } catch (err) {
    console.error("Error while joining the room");
    return false;
  }
};

const unSubscribeToRoom = async (roomName: string) => {
  try {
    socket.emit(roomName);
  } catch (err) {
    console.error("Error while leaving the room");
  }
};

const disconnectWebSocket = async () => {
  try {
    if (socket.connected) {
      console.log("Disconnecting from socket");
      await socket.disconnect();
    }
  } catch (err) {
    console.error("Error while disconnecting web socket");
  }
};

const connectWebSocket = async () => {
  try {
    if (socket.disconnected) {
      console.log("Connecting to socket");
      await socket.connect();
    }
  } catch (err) {
    console.error("Error while connecting web socket");
  }
};
export {
  getMySocket,
  subscribeToRoom,
  unSubscribeToRoom,
  connectWebSocket,
  disconnectWebSocket,
};
