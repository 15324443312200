import { adminRootInstance } from "../../../common/config/axios-config";
import {
	FETCH_SLAS_SUCCESS,
	FETCH_SLAS_FAIL,
	CREATE_SLA_SUCCESS,
	CREATE_SLA_FAIL,
	UPDATE_SLA_SUCCESS,
	UPDATE_SLA_FAIL,
	DELETE_SLA_FAIL,
	DELETE_SLA_SUCCESS,
	FETCH_SLAS_START,
	FETCH_REQUEST_TYPES,
	FETCH_REQUEST_TYPES_FAIL,
} from "./action-types";

export const fetchSLAs = ({ search, limit, page }: any) => async (
	dispatch: any
) => {
	dispatch({
		type: FETCH_SLAS_START,
	});
	await adminRootInstance
		.get("slas", {
			params: {
				searchKeyword: search,
				pageSize: limit,
				page,
			},
		})
		.then((res: any) => {
			dispatch({
				type: FETCH_SLAS_SUCCESS,
				payload: {
					slas: res.data.data.slas,
					totalCount: res.data.data.pagination.totalCount,
				},
			});
		})
		.catch((err) => {
			dispatch({
				type: FETCH_SLAS_FAIL,
				payload: { fetchErrorMessage: err.response?.data?.message },
			});
		});
};

export const createSLA = (sla: any) => async (dispatch: any) => {
	let { id, ...addData } = sla;
	return await adminRootInstance
		.post("/sla", addData)
		.then((res) => {
			dispatch({
				type: CREATE_SLA_SUCCESS,
				payload: { successMessage: "SLA Created Successfully" },
			});
			return { success: true };
		})
		.catch((err) => {
			dispatch({
				type: CREATE_SLA_FAIL,
				payload: { errorMessage: err.response?.data?.message },
			});
			return { success: false };
		});
};

export const updateSLA = (sla: any) => async (dispatch: any) => {
	return await adminRootInstance
		.put(`/sla/${sla.id}`, sla)
		.then((res) => {
			dispatch({
				type: UPDATE_SLA_SUCCESS,
				payload: { successMessage: "SLA Updated Successfully" },
			});
			return { success: true };
		})
		.catch((err) => {
			dispatch({
				type: UPDATE_SLA_FAIL,
				payload: { errorMessage: err.response?.data?.message },
			});
			return { success: false };
		});
};

export const deleteSLA = ({ id }: any) => async (dispatch: any) => {
	return await adminRootInstance
		.delete(`/sla/${id}`)
		.then((res) => {
			dispatch({
				type: DELETE_SLA_SUCCESS,
				payload: { successMessage: "SLA Deleted Successfully" },
			});
			return { success: true };
		})
		.catch((err) => {
			dispatch({
				type: DELETE_SLA_FAIL,
				payload: { errorMessage: err.response?.data?.message },
			});
			return { success: false };
		});
};

export const fetchRequests = () => async (dispatch: any) => {
	return await adminRootInstance
		.get(`/request-types`)
		.then((res) => {
			dispatch({
				type: FETCH_REQUEST_TYPES,
				payload: { requests: res.data },
			});
		})
		.catch((err) => {
			dispatch({
				type: FETCH_REQUEST_TYPES_FAIL,
				payload: { errorMessage: err.response?.data?.message },
			});
		});
};
