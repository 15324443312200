/* eslint-disable react-hooks/exhaustive-deps*/
import { Row, Card, Col } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import classes from "../common-reports.module.scss";
import { connect } from "react-redux";
import {
  fetchAllOrderTypes,
  fetchAllSpecialityItems,
  fetchAllUnits,
  fetchSpecialityItemsReport,
  resetReportState,
} from "../../actions";
import { IRootState } from "../../../../../common/redux/reducers";
import { BackButton, ReportsBreadCrumbComponent } from "../commons/utils";
import ReportsFilterForm from "../commons/report-filter-form";
import ReportDetailTable from "./speciality-items-table";
import DownloadCSV from "../commons/download-csv";
const ItemsReports = ({
  fetchAllSpecialityItems,
  fetchSpecialityItemsReport,
  fetchAllOrderTypes,
  fetchAllUnits,
  resetReportState,
  filterData,
  reportData,
}: any) => {
  const [reportView, setReportView] = useState("form");

  const onViewChange = (view: any) => {
    setReportView(view);
  };
  useEffect(() => {
    fetchAllSpecialityItems();
    fetchAllOrderTypes();
    fetchAllUnits();
    return () => {
      resetReportState();
    };
  }, []);
  const submitHandler = async (params: any) => {
    const resp = await fetchSpecialityItemsReport({
      limit: 30,
      page: 1,
      ...params,
    });
    if (resp.success) {
      onViewChange("report");
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <title>Reports | Admin Portal</title>
      </Helmet>
      <ReportsBreadCrumbComponent reportType="Speciality Items Report" />
      <Card className={classes.reportsContainer} bodyStyle={{ width: "100%" }}>
        <Row>
          <Col span={20}>
            <BackButton
              reportView={reportView}
              navigateToForm={() => setReportView("form")}
            />
          </Col>
          {reportView !== "form" && reportData?.data?.length ? (
            <Col span={4} className={classes.downloadCSVButton}>
              <Row>
                <DownloadCSV filterData={filterData} reportType="spl-item" />
              </Row>
            </Col>
          ) : (
            <div />
          )}
        </Row>
        {reportView === "form" ? (
          <ReportsFilterForm
            submitHandler={submitHandler}
            reportType="speciality-items"
          />
        ) : (
          <ReportDetailTable />
        )}
      </Card>
    </div>
  );
};
const mapStateToProps = ({ reportsState }: IRootState) => ({
  dataLoading: reportsState.loading,
  allStatuses: reportsState.allStatuses,
  allOrderTypes: reportsState.allOrderTypes,
  successMsg: reportsState.successMessage,
  filterData: reportsState.filterData,
  reportData: reportsState.reportData,
});
const mapDispatchToProps = {
  fetchAllSpecialityItems,
  fetchAllOrderTypes,
  fetchAllUnits,
  fetchSpecialityItemsReport,
  resetReportState,
};
export default connect(mapStateToProps, mapDispatchToProps)(ItemsReports);
