import * as msal from "@azure/msal-browser";

const msalConfig: any = {
  auth: {
    clientId: process.env.REACT_APP_CLIENTID,
    authority: process.env.REACT_APP_AUTHORITY,
    knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITIES],
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    navigateToLoginRequestUrl:
      process.env.REACT_APP_NAVIGATE_TO_LOGIN_REQUEST_URL,
  },
};
const msalInstance = new msal.PublicClientApplication(msalConfig);

export default msalInstance;
