export const trimValues = (values: any) => {
  const Obj: any = {};
  Object.keys(values).map(
    (k) =>
      (Obj[k] = typeof values[k] === "string" ? values[k].trim() : values[k])
  );
  return Obj;
};

export function allowOnlyNumbers(event: any) {
  if (event.key.length === 1 && /\D/.test(event.key)) {
    event.preventDefault();
  }
}
