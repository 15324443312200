import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Modal, Typography, Button, Row, Col } from "antd";
import classes from "./sla-view-modal.module.scss";
const { Text } = Typography;
const labelSpan = 9;
const valueSpan = 15;
const SLAViewModal = ({
  handelClose,
  sla,
  visible,
  requests,
  allCampuses,
}: any) => {
  const requestTexts = requests.filter((req: any) => {
    const found = sla.requests.find((reqId: any) => reqId === req.id);
    return found ? true : false;
  });

  return (
    <Modal
      title={
        <div className="commonModalHeader">
          <div className="title">
            <p> View SLA </p>
          </div>
          <div className="close">
            <Button className="closeBtn" onClick={handelClose}>
              <CloseOutlined className="close-icon" />
            </Button>
          </div>
        </div>
      }
      visible={visible}
      maskClosable={false}
      onCancel={handelClose}
      footer={null}
      closable={false}
      width={450}
    >
      <div style={{ maxHeight: "500px", overflowY: "auto" }}>
        <Row>
          <Col span={labelSpan}>
            <Text className={classes.doubleDot}>Name</Text>
          </Col>
          <Col span={valueSpan}>
            <Text className={classes.popValue}>{sla.name} </Text>
          </Col>
        </Row>
        <Row>
          <Col span={labelSpan}>
            <Text className={classes.doubleDot}>
              Mapped Campus{sla.campuses.length > 1 ? "es" : ""}
            </Text>
          </Col>
          <Col span={valueSpan}>
            <Text className={classes.popValue}>
              {sla.campuses.length
                ? sla.campuses.map((campusId: number) => {
                    return (
                      <div>
                        {
                          allCampuses.find(
                            (campus: any) => campus.id === campusId
                          )?.name
                        }
                      </div>
                    );
                  })
                : "-"}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={labelSpan}>
            <Text className={classes.doubleDot}> Time Limit</Text>
          </Col>
          <Col span={valueSpan}>
            <Text className={classes.popValue}>{sla.timeLimit} Mins</Text>
          </Col>
        </Row>
        <Row>
          <Col span={labelSpan}>
            <Text className={classes.doubleDot}> Threshold</Text>
          </Col>
          <Col span={valueSpan}>
            <Text className={classes.popValue}>{sla.threshold} Mins</Text>
          </Col>
        </Row>
        <Row>
          <Col span={labelSpan}>
            <Text className={classes.doubleDot}> Applicable Requests</Text>
          </Col>
          <Col span={valueSpan}>
            <Text>
              {requestTexts.map(({ name }: any) => (
                <div className={classes.popValue}>
                  {name}
                  <br />
                </div>
              ))}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={labelSpan}>
            <Text className={classes.doubleDot}> Color</Text>
          </Col>
          <Col span={valueSpan}>
            <Text className={classes.popColorValue}>
              <div
                className={classes.slaColor}
                style={{
                  backgroundColor: sla.color,
                }}
              ></div>
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={labelSpan}>
            <Text className={classes.doubleDot}>Disclaimer</Text>
          </Col>
          <Col span={valueSpan}>
            <Text className={classes.popValue}>
              {sla.disclaimerStatus ? "ON" : "OFF"}{" "}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={labelSpan}>
            {sla.disclaimerStatus && (
              <Text className={classes.doubleDot}> Disclaimer Description</Text>
            )}
          </Col>
          <Col span={valueSpan}>
            {sla.disclaimerStatus && (
              <Text className={classes.popValue}>{sla.disclaimerInfo} </Text>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={labelSpan}>
            <Text className={classes.doubleDot}>Color Warning 1</Text>
          </Col>
          <Col span={valueSpan}>
            <Text className={classes.popValue}>
              {sla.colorWarning1 ? "ON" : "OFF"}{" "}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={labelSpan}>
            <Text className={classes.doubleDot}> Time Left 1</Text>
          </Col>
          <Col span={valueSpan}>
            {sla.timeLeft1 ? (
              <Text className={classes.popValue}>{sla.timeLeft1} Mins</Text>
            ) : (
              <Text className={classes.popValue}>-</Text>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={labelSpan}>
            <Text className={classes.doubleDot}> Highlight Color 1</Text>
          </Col>
          <Col span={valueSpan}>
            <Text className={classes.popColorValue}>
              {sla.highlightColor1 ? (
                <div
                  className={classes.slaColor}
                  style={{
                    backgroundColor: sla.highlightColor1,
                  }}
                ></div>
              ) : (
                "-"
              )}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={labelSpan}>
            <Text className={classes.doubleDot}>Color Warning 2</Text>
          </Col>
          <Col span={valueSpan}>
            <Text className={classes.popValue}>
              {sla.colorWarning2 ? "ON" : "OFF"}{" "}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={labelSpan}>
            <Text className={classes.doubleDot}> Time Left 2</Text>
          </Col>
          <Col span={valueSpan}>
            {sla.timeLeft2 ? (
              <Text className={classes.popValue}>{sla.timeLeft2} Mins</Text>
            ) : (
              <Text className={classes.popValue}>-</Text>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={labelSpan}>
            <Text className={classes.doubleDot}> Highlight Color 2</Text>
          </Col>
          <Col span={valueSpan}>
            <Text className={classes.popColorValue}>
              {sla.highlightColor2 ? (
                <div
                  className={classes.slaColor}
                  style={{
                    backgroundColor: sla.highlightColor2,
                  }}
                ></div>
              ) : (
                "-"
              )}
            </Text>
          </Col>
        </Row>
      </div>
      <Row justify={"center"} className={classes.textContainer}>
        <Button
          className={classes.popClose}
          type="primary"
          htmlType="reset"
          onClick={handelClose}
        >
          Close
        </Button>
      </Row>
    </Modal>
  );
};

export default SLAViewModal;
