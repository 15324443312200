/* eslint-disable react-hooks/exhaustive-deps*/
import React from "react";
import { Select, Row, Col, Button, Alert } from "antd";
import classes from "./campus-select-modal.module.scss";
import { IRootState } from "../../../../common/redux/reducers";
import { connect, useDispatch } from "react-redux";
import { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { fetchCampuses } from "../../reducers/actions";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FETCH_CAMPUS_BY_ID_SUCCESS } from "../../../modules/home/action-types";

const CampusSelectorModal = ({
  fetchCampuses,
  allCampuses,
  showModal,
  closeModal,
  currentCampus: currentCampusData,
  errorMessage,
  loading,
  allCampusLoading,
}: any) => {
  const [condition, setCondition] = useState<boolean>();
  const [currentCampus, setCurrentCampus] = useState(currentCampusData.id);
  const history = useHistory();
  const [selectedCampus, setSelectedCampus] = useState<any>({
    ...currentCampusData,
  });

  useEffect(() => {
    setCurrentCampus(currentCampusData.id);
    if (!localStorage.getItem("currentCampusId") || showModal) {
      setCondition(true);
    } else {
      if (!loading && !currentCampusData.id) {
        setCondition(true);
      } else {
        setCondition(false);
      }
    }
  }, [showModal, currentCampusData]);

  useEffect(() => {
    setCurrentCampus(
      allCampuses.find((campus: any) => campus.id === currentCampusData?.id)
        ?.id || false
    );
    setSelectedCampus(
      allCampuses.find((campus: any) => campus.id === currentCampusData?.id) ||
        {}
    );
  }, [allCampuses]);
  useEffect(() => {
    condition && fetchCampuses();
    setSelectedCampus({ ...currentCampusData });
  }, [condition]);

  const dispatch = useDispatch();
  const submitHandler = () => {
    //fetch the campus data with banner message and store it ot redux
    if (selectedCampus?.id) {
      let campusData = allCampuses.find(
        (campus: any) => campus.id === selectedCampus.id
      );
      campusData &&
        dispatch({
          type: FETCH_CAMPUS_BY_ID_SUCCESS,
          payload: {
            campus: campusData,
          },
        });
    }
    //also update that in local storage if it's successfull + move to home page + close the modal
    localStorage.setItem("currentCampusId", selectedCampus?.id);
    //redirect to home only if the campus selected is different
    if (currentCampusData?.id !== selectedCampus?.id) {
      history.push("/request");
    }
    closeModal();
  };
  const cancelHandler = () => {
    currentCampus && closeModal();
  };
  return (
    <Modal
      visible={condition}
      closable={!!currentCampus}
      maskClosable={false}
      footer={null}
      onCancel={cancelHandler}
      className={classes.campusSelectModal}
    >
      <Row style={{ justifyContent: "center" }}>
        {!!allCampuses.length && errorMessage && (
          <Alert message={errorMessage} />
        )}
      </Row>
      <Row style={{ justifyContent: "center" }}>
        {!allCampusLoading && !allCampuses.length && (
          <Alert type="warning" message={"No Campuses Available"} />
        )}
      </Row>
      <Row className={classes.dataRow}>
        <Col className={classes.label}>Campus Name: </Col>
        <Col>
          <Select
            loading={allCampusLoading}
            showSearch
            filterOption={(input: any, option: any) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "200px" }}
            value={allCampusLoading ? "" : selectedCampus.id}
            onChange={(val, e: any) => {
              setSelectedCampus({ name: e.key, id: e.value });
            }}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {allCampuses.map((campus: any) => (
              <Select.Option
                value={campus.id}
                key={campus.name}
                style={{
                  textAlign: "center",
                }}
              >
                {campus.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row className={classes.buttonRow}>
        <Button
          className={classes.cancelButton}
          hidden={!currentCampus}
          title={!currentCampus ? "No Campus selected" : ""}
          onClick={cancelHandler}
        >
          Cancel
        </Button>
        <Button
          className={classes.fwdButton}
          disabled={!selectedCampus?.id}
          title={!selectedCampus.id ? "Select a Campus" : ""}
          onClick={submitHandler}
        >
          Submit
        </Button>
      </Row>
    </Modal>
  );
};

const mapStateToProps = ({
  requestData: { campuses: allCampuses, loading: allCampusLoading },
  homeReducer: { currentCampus, errorMessage, loading },
}: IRootState) => ({
  allCampuses,
  currentCampus,
  errorMessage,
  loading,
  allCampusLoading,
});

export default connect(mapStateToProps, {
  fetchCampuses,
})(CampusSelectorModal);
