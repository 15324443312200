import React, { useState } from "react";
import { Form } from "antd";
import { connect } from "react-redux";
import { selectAllHandler } from "../commons/utils";
import { IRootState } from "../../../../../common/redux/reducers";
import { setReportFilterData } from "../../actions";
import { FormViewComp } from "../commons/common-filter-form";
import { NEW_REQUEST_TYPES } from "../../../../../common/shared-constants";

const ProductivityForm = (props: any) => {
  const {
    submitHandler,
    reportsState: {
      filterData = {},
      allUsers = [],
      allOrderTypes = [],
      allUnits = [],
    } = {},
    setReportFilterData,
    errorMessage,
    dataLoading,
  } = props;
  const [form] = Form.useForm();
  const [radioValue, setRadioValue] = useState(
    filterData?.reportType || "summary"
  );
  const [selectedValues, setSelectedValues] = useState<any>(filterData || {});
  const onRadioChange = (e: any) => {
    form.setFieldsValue({ ...form.getFieldsValue, users: [] });
    setRadioValue(e.target.value);
  };
  const initialValues = filterData;

  const onFinish = (values: any) => {
    setReportFilterData(form.getFieldsValue());
    submitHandler(values);
  };
  const compProps = {
    form,
    onFinish,
    initialValues,
    errorMessage,
    dataLoading,
    config: [
      {
        name: "reportType",
        onChange: onRadioChange,
      },
      {
        name: "fromDate",
        onChange: (val: any) => {
          setSelectedValues({ ...selectedValues, fromDate: val });
        },
        value: selectedValues?.fromDate,
        selectedValues,
      },
      {
        name: "toDate",
        onChange: (val: any) => {
          setSelectedValues({ ...selectedValues, toDate: val });
        },
        value: selectedValues?.toDate,
        selectedValues,
      },

      {
        name: "users",
        dropDownList: allUsers,
        onChange: (value: any) =>
          radioValue === "summary" &&
          selectAllHandler(form, value, "users", "all"),
        radioValue,
      },
      {
        name: "orderType",
        dropDownList: allOrderTypes,
        onChange: (value: any) =>
          selectAllHandler(form, value, "orderType", "all"),
        radioValue,
      },
      {
        name: "units",
        dropDownList: allUnits,
        onChange: (value: any) => selectAllHandler(form, value, "units", "all"),
        radioValue,
      },
    ],
  };
  return <FormViewComp {...compProps} />;
};
const mapStateToProps = ({ reportsState }: IRootState) => ({
  dataLoading: reportsState.loading,
  reportsState,
  allUsers: reportsState.allUsers,
  successMsg: reportsState.successMessage,
  errorMessage: reportsState.errorMessage,
});
const mapDispatchToProps = { setReportFilterData };
export default connect(mapStateToProps, mapDispatchToProps)(ProductivityForm);
