import { adminRootInstance } from "../../../common/config/axios-config";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  AUTO_LOGIN_SUCCESS,
  AUTO_LOGIN_FAIL,
  CLEAR_AUTH_MSG,
  LOGIN_REQ_START,
} from "./action-types";
import { checkTokenValidity } from "../../../common/config/admin-token-check";
import { decode } from "../../../common/utils/jwt-decode";
import ability, { updateAbility } from "../../shared/ability/ability";
export const login: (
  username: string,
  password: string,
  ability: any
) => void = (username, password) => async (dispatch: any) => {
  dispatch({ type: LOGIN_REQ_START });
  await adminRootInstance
    .post("login", {
      username,
      password,
    })
    .then((res) => {
      localStorage.setItem("access_token", res.data.data.auth.token);
      localStorage.setItem("refresh_token", res.data.data.refresh.token);
      const tokenPayload: any = decode(res.data.data.auth.token as string);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: tokenPayload.data },
      });
      updateAbility(ability, tokenPayload.data);
    })
    .catch((err) => {
      dispatch({
        type: LOGIN_FAIL,
        payload: {
          errorMessage: err.response?.data?.message,
        },
      });
    });
};

export const logoutUser = () => (dispatch: any) => {
  [
    "refresh_token",
    "access_token",
    "filterValues",
    "aimDashboardFilter",
    "aimAssetCheckInFilter",
    "aimAssetDeconFilter",
  ].forEach((localKey: string) => localStorage.removeItem(localKey));

  dispatch({
    type: LOGOUT,
    payload: { message: "you are logged out successfully" },
  });
};

export const autoLogin = (ability: any) => async (dispatch: any) => {
  const result: any = await checkTokenValidity();
  if (result) {
    dispatch({
      type: AUTO_LOGIN_SUCCESS,
      payload: { user: result },
    });
    updateAbility(ability, result);
  } else {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    dispatch({ type: AUTO_LOGIN_FAIL });
  }
};

export const clearAuthError = () => (dispatch: any) => {
  dispatch({
    type: CLEAR_AUTH_MSG,
  });
};

export const clearAdminAuthentication = (message: any) => (dispatch: any) => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  dispatch({
    type: LOGOUT,
    payload: { message: message },
  });
};
