/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState } from "react";
import {
  Button,
  List,
  Skeleton,
  Col,
  message,
  Row,
  Select,
  Tag,
  Spin,
} from "antd";
import { connect } from "react-redux";
import { IRootState } from "../../../../common/redux/reducers";
import { onLoadMore } from "./actions";
import {
  readNotification,
  readAllNotifications,
} from "../notification/actions";
import { DEFAULT_NOTIFICATION_LIMIT } from "../../../../common/shared-constants";
import CustomTimeAgo from "./CustomTimeAgo";
import classes from "./notification-list.module.scss";
import { SyncOutlined } from "@ant-design/icons";
const NotificationList = ({
  initLoading,
  loading,
  onLoadMore,
  notifications,
  notificationsWithSkeletons,
  totalCount,
  fetchNotifications,
  onNotificationClick,
  readNotification,
  readAllNotifications,
  readAllLoader,
  notificationUnreadCount,
  campuses,
  selectedCampus,
  setSelectedCampus,
  campusesLoading,
  notificationLoading,
}: any) => {
  const [indvlLoader, setIndvlLoader] = useState<any>({});
  const loadMoreNotifications = () => {
    onLoadMore(DEFAULT_NOTIFICATION_LIMIT);
    fetchNotifications({ campusId: selectedCampus?.id, replace: false });
  };

  const loadMore =
    !initLoading && !loading && notifications.length < totalCount ? (
      <div
        style={{
          textAlign: "center",
          margin: "12px 0",
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button
          id="notification-viewMore"
          className="nextBtn"
          onClick={() => {
            document.getElementById("notification-viewMore")?.blur();
            loadMoreNotifications();
          }}
        >
          View More
        </Button>
      </div>
    ) : null;
  const markAsReadHandler = async (notificationId: any, readStatus: any) => {
    if (readStatus !== true) {
      const resp = await readNotification(notificationId);
      if (!resp.success) {
        resp?.errorMessage && message.error(resp.errorMessage);
      }
      return resp;
    }
  };
  const { Option } = Select;
  const [campusEditMode, setCampusEditMode] = useState<boolean>(false);

  return (
    <>
      <Row className={classes.notificationHeader}>
        <Col className={classes.title}>
          Notifications
          {notificationLoading && (
            <Spin
              size="small"
              indicator={<SyncOutlined spin />}
              className={classes.spinner}
            />
          )}
        </Col>
        <Col className={classes.readAllButton}>
          <Row align="middle" justify="center" style={{ width: "120px" }}>
            {campusEditMode ? (
              <Select
                getPopupContainer={(trigger) =>
                  trigger.parentNode as HTMLElement
                }
                defaultOpen
                showArrow={false}
                onDropdownVisibleChange={(open: boolean) => {
                  if (!open) setCampusEditMode(false);
                }}
                size="small"
                className={classes.select}
                defaultValue={selectedCampus?.id}
                onChange={async (val, obj: any) => {
                  let storableObj: any = { id: val, name: obj.id };
                  const resp = await fetchNotifications({
                    campusId: storableObj.id,
                    replace: true,
                  });
                  if (resp?.success) setSelectedCampus(storableObj);
                  localStorage.setItem(
                    "selectedCampus",
                    JSON.stringify(storableObj)
                  );
                  setCampusEditMode(false);
                }}
              >
                {campuses?.map((campus: any) => (
                  <Option
                    value={campus.id}
                    id={campus.name}
                    className={classes.selectOption}
                  >
                    {campus.name}
                  </Option>
                ))}
              </Select>
            ) : (
              <Row
                align="middle"
                justify="center"
                onClick={() => {
                  !campusesLoading &&
                    !notificationLoading &&
                    setCampusEditMode(true);
                }}
                className={classes.campusSelect}
              >
                <Tag
                  icon={campusesLoading && <SyncOutlined spin />}
                  title={selectedCampus?.name}
                  color="green"
                  className={classes.campusTag}
                  style={{
                    cursor:
                      campusesLoading || notificationLoading
                        ? "wait"
                        : "pointer",
                  }}
                >
                  {selectedCampus?.name || "-"}
                </Tag>
              </Row>
            )}
          </Row>

          {notificationUnreadCount !== 0 && (
            <Row>
              <span className={classes.horizontalDivider}>|</span>
              <Button
                type="link"
                id="readAllNotificationsButton"
                onClick={async (e) => {
                  document.getElementById("readAllNotificationsButton")?.blur();
                  let resp = await readAllNotifications(selectedCampus?.id);
                  if (!resp.success) {
                    resp?.errorMessage && message.error(resp.errorMessage);
                  }
                }}
                size="small"
                hidden={notificationUnreadCount === 0}
                loading={readAllLoader}
              >
                Mark all as Read
              </Button>
            </Row>
          )}
        </Col>
      </Row>
      <List
        className="demo-loadmore-list"
        loading={false}
        itemLayout="horizontal"
        loadMore={loadMore}
        dataSource={notificationsWithSkeletons}
        renderItem={(item: any) => (
          <List.Item
            onClick={() =>
              onNotificationClick(item.id, item.read, item.orderNumber)
            }
            className={!item.read ? "readed" : ""}
          >
            <Skeleton
              paragraph={{ rows: 2 }}
              title={false}
              loading={item.loading}
              active
            >
              <Col className="content">{item.message}</Col>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <CustomTimeAgo className="timeAgo" date={item.created} />
                {!item.read && (
                  <Button
                    style={{
                      fontSize: "12px",
                      padding: "0px",
                    }}
                    onClick={async (e) => {
                      e.stopPropagation();
                      setIndvlLoader({ ...indvlLoader, [item.id]: true });
                      const resp: any = await markAsReadHandler(
                        item.id,
                        item.read
                      );
                      if (resp) {
                        setIndvlLoader({ ...indvlLoader, [item.id]: false });
                      }
                    }}
                    size="small"
                    type="link"
                    title={indvlLoader[item.id] ? "Loading..." : ""}
                    disabled={indvlLoader[item.id]}
                  >
                    Mark as Read
                  </Button>
                )}
              </Col>
            </Skeleton>
          </List.Item>
        )}
      />
    </>
  );
};

const mapStateToProps = ({
  notificationState: {
    notificationUnreadCount,
    notificationsWithSkeletons,
    notifications,
    totalCount,
    readAllLoader,
    loading,
  },
  dashboardState,
}: IRootState) => ({
  notificationUnreadCount,
  notificationsWithSkeletons,
  notifications,
  totalCount,
  readAllLoader,
  campuses: dashboardState.campuses,
  campusesLoading: dashboardState.allCampusLoading,
  notificationLoading: loading,
});

export default connect(mapStateToProps, {
  onLoadMore,
  readNotification,
  readAllNotifications,
})(NotificationList);
