import { combineReducers } from "redux";

import adminAuthentication, {
  AdminAuthenticationState,
} from "../../admin/modules/auth/reducer";
import campusManagement, {
  CampusManagementState,
} from "../../admin/modules/campus/reducer";
import unitManagement, {
  UnitManagementState,
} from "../../admin/modules/unit/reducer";
import slaManagement, {
  SLAManagementState,
} from "../../admin/modules/sla/reducer";
import userManagement, {
  UserManagementState,
} from "../../admin/modules/user/reducer";
import itemManagement, {
  ItemManagementState,
} from "../../admin/modules/items/reducer";
import deliveryManagement, {
  DeliveryManagementState,
} from "../../request/modules/equipment-delivery/reducer";
import authentication, {
  AuthenticationState,
} from "../../request/modules/login/reducer";
import transportMangement, {
  PatientManagementState,
} from "../../request/modules/patient-transport/reducer";
import requestData, {
  RequestDataState,
} from "../../request/shared/reducers/location-reducer";
import pickUpManagement, {
  PickUpManagementState,
} from "../../request/modules/equipment-pickup/reducer";
import homeReducer, {
  HomeReducerState,
} from "../../request/modules/home/reducer";
import sharpManagement, {
  SharpManagementState,
} from "../../request/modules/sharps/reducer";
import dashboardState, {
  DashboardManagementState,
} from "../../admin/modules/dashboard/reducer";
import myRequests, {
  MyRequestsState,
} from "../../request/modules/my-requests/reducer";
import requestState, {
  SelectedRequestDetails,
} from "../../admin/modules/dashboard/request-view/reducer";
import activityDetails, {
  ActivitiesDetails,
} from "../../admin/modules/dashboard/request-view/ActivitiesWidget/reducer";
import notesDetails, {
  NotesDetails,
} from "../../admin/modules/dashboard/request-view/NotesWidget/reducer";
import notificationState, {
  NotificationManagementState,
} from "../../admin/shared/layout/notification/reducer";
import reportsState, {
  ReportsDetails,
} from "../../admin/modules/reports/reducer";
import aimDashboardState, {
  AIMDashboardManagementState,
} from "../../admin/modules/AIM/AIMDashboard/reducer";
import aimAssetCheckInState, {
  AIMAssetCheckInState,
} from "../../admin/modules/AIM/AssetCheckin/reducer";
import aimAssetDeconState, {
  AIMAssetDeconState,
} from "../../admin/modules/AIM/AssetDecon/reducer";
import aimReportState, {
  AIMReportsDetails,
} from "../../admin/modules/AIM/AIMReports/reducer";
import transportManagement, {
  TransportManagementState,
} from "../../admin/modules/transport/reducer";
export interface IRootState {
  readonly adminAuthentication: AdminAuthenticationState;
  readonly userManagement: UserManagementState;
  readonly slaManagement: SLAManagementState;
  readonly campusManagement: CampusManagementState;
  readonly unitManagement: UnitManagementState;
  readonly itemManagement: ItemManagementState;
  readonly dashboardState: DashboardManagementState;
  readonly requestState: SelectedRequestDetails;
  readonly activityDetails: ActivitiesDetails;
  readonly notesDetails: NotesDetails;
  readonly notificationState: NotificationManagementState;
  readonly reportsState: ReportsDetails;
  readonly transportManagement: TransportManagementState;
  // AIM
  readonly aimDashboardState: AIMDashboardManagementState;
  readonly aimAssetCheckInState: AIMAssetCheckInState;
  readonly aimAssetDeconState: AIMAssetDeconState;
  readonly aimReportState: AIMReportsDetails;
  //request portal
  readonly authentication: AuthenticationState;
  readonly requestData: RequestDataState;
  readonly deliveryManagement: DeliveryManagementState;
  readonly pickUpManagement: PickUpManagementState;
  readonly transportMangement: PatientManagementState;
  readonly homeReducer: HomeReducerState;
  readonly sharpManagement: SharpManagementState;
  readonly myRequests: MyRequestsState;
}

const rootReducer = combineReducers<IRootState>({
  adminAuthentication,
  userManagement,
  slaManagement,
  activityDetails,
  campusManagement,
  unitManagement,
  itemManagement,
  dashboardState,
  requestState,
  notesDetails,
  reportsState,
  notificationState,
  transportManagement,
  //AIM
  aimDashboardState,
  aimAssetCheckInState,
  aimAssetDeconState,
  aimReportState,
  //request portal
  authentication,
  requestData,
  deliveryManagement,
  pickUpManagement,
  transportMangement,
  homeReducer,
  sharpManagement,
  myRequests,
});
export default rootReducer;
