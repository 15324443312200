/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Input,
  Alert,
  Spin,
  Select,
  Switch,
} from "antd";
import classes from "./unit-modal.module.scss";
import "../../../shared/scss/shared-modal.scss";
import { trimValues } from "../../../../common/utils/trimUtil";
import { CloseOutlined } from "@ant-design/icons";
import { EDIT } from "../../../../common/shared-constants";
import {
  buildingNameValidator,
  floorNameValidator,
  unitNameValidator,
} from "../../../../common/utils/formValidators";
const { Option } = Select;
const UnitModal = ({
  unit,
  mode,
  visible,
  handleCancel,
  handleSubmit,
  errorMsg,
  clearMessages,
  loading,
  allCampuses,
}: any) => {
  const [form] = Form.useForm();

  const [submitted, setSubmitted] = useState(false);

  const onFormSubmit = async (values: any) => {
    if (submitted) {
      return;
    }
    setSubmitted(true);
    const trimedValues = trimValues(values);
    await handleSubmit(trimedValues);
    setSubmitted(false);
  };

  return (
    <React.Fragment>
      <Modal
        title={
          <div className="commonModalHeader">
            <div className="title">
              <p> {mode === EDIT ? "Edit Unit" : "Create Unit"}</p>
            </div>
            <div className="close">
              <Button className="closeBtn" onClick={handleCancel}>
                <CloseOutlined className="close-icon" />
              </Button>
            </div>
          </div>
        }
        visible={visible}
        width={500}
        maskClosable={false}
        onCancel={handleCancel}
        footer={null}
        closable={false}
      >
        {errorMsg ? (
          <Alert
            message={errorMsg}
            type="error"
            closable
            afterClose={clearMessages}
            className={`fadeIn ${classes.errorContainer}`}
          />
        ) : null}
        <Form
          form={form}
          layout="horizontal"
          initialValues={unit}
          onFinish={onFormSubmit}
        >
          <Row>
            <Form.Item name="id" hidden />
            <Col span={24}>
              <Form.Item
                labelCol={{
                  sm: { span: 24 },
                  md: { span: 8 },
                }}
                labelAlign={"left"}
                name="name"
                label="Name"
                rules={[
                  () => ({
                    validator: unitNameValidator,
                    required: true,
                  }),
                ]}
              >
                <Input
                  autoFocus={mode !== EDIT}
                  placeholder="Enter the unit name"
                  className="inputStyle"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                labelCol={{
                  sm: { span: 24 },
                  md: { span: 8 },
                }}
                labelAlign={"left"}
                name="campusId"
                label="Mapped Campus"
                rules={[
                  { required: true, message: "Please select the Campus!" },
                ]}
              >
                <Select
                  className={classes.campusSelect}
                  placeholder="Select a campus"
                  showSearch
                  optionFilterProp="children"
                  filterSort={(optionA: any, optionB: any) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {allCampuses.map((campus: any) => (
                    <Option value={campus.id}>{campus.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                labelCol={{
                  sm: { span: 24 },
                  md: { span: 8 },
                }}
                labelAlign={"left"}
                name="building"
                label="Building"
                rules={[
                  {
                    validator: buildingNameValidator,
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder="Type building name/number"
                  className="inputStyle"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                labelCol={{
                  sm: { span: 24 },
                  md: { span: 8 },
                }}
                labelAlign={"left"}
                name="floor"
                label="Floor"
                rules={[
                  {
                    validator: floorNameValidator,
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder="Type floor name/number"
                  className="inputStyle"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                labelCol={{
                  sm: { span: 24 },
                  md: { span: 8 },
                }}
                valuePropName="checked"
                labelAlign={"left"}
                name="proceduralUnit"
                label="Procedural Unit"
                initialValue={unit?.proceduralUnit || false}
              >
                <Switch className="defaultSwitch" />
              </Form.Item>
            </Col>
          </Row>
          <div className="modalBtn">
            <Form.Item className={classes.cancelButton}>
              <Button
                className="cancelBtn"
                htmlType="reset"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Spin spinning={loading}>
                <Button className="submitBtn" htmlType="submit">
                  Submit
                </Button>
              </Spin>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
export default UnitModal;
