import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import classes from "./audit.module.scss";
import { Input, Row, Button, Form, Alert, Col, Tooltip, message } from "antd";
import { adminRootInstance } from "../../../../common/config/axios-config";
import {
  REPAIR_ASSET,
  RETIRED_ASSET,
} from "../../../../common/shared-constants";
import SingleLineSkeleton from "../../../../common/utils/SingleLineSkeleton";
import { useForm } from "antd/lib/form/Form";
import ReVerifyAsset from "./audit-modals/Re-verify";
import useScanner from "../hooks/useScanner";
import { Link } from "react-router-dom";
import { getESTFromUTC, getFormattedDate } from "../../../../common/utils/time";

const Audit = () => {
  const [form] = useForm();
  const [assetDetail, setAssetDetail] = useState<any>({});
  const [errorMsg, setErrorMsg] = useState("");
  const [currentControlNumber, setCurrentControlNumber] = useState("");
  const [currentSerialNumber, setCurrentSerialNumber] = useState("");
  const [currentEquipmentType, setCurrentEquipmentType] = useState("");
  const [controlNoEmpty, setControlNoEmpty] = useState(false);
  const [serialNoEmpty, setSerialNoEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const barcodeData = useScanner();

  const controlNumberChange = (e: any) => {
    setCurrentControlNumber(e.target.value);
    e.target.value.trim() ? setControlNoEmpty(false) : setControlNoEmpty(true);
  };
  const serialNumberChange = (e: any) => {
    setCurrentSerialNumber(e.target.value);
    e.target.value.trim() ? setSerialNoEmpty(false) : setSerialNoEmpty(true);
  };

  const initialValues = {
    controlNumber: "",
  };
  useEffect(() => {
    const timeout = errorMsg && setTimeout(() => setErrorMsg(""), 5000);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [errorMsg]);

  useEffect(() => {
    if (barcodeData) {
      form.setFieldValue("controlNumber", "");
      form.setFieldValue("controlNumber", barcodeData);
      getAssetDetails();
    }
  }, [barcodeData]);

  const manualScanInput = (e: any) => {
    if (
      (e.keyCode === 10 || e.keyCode === 13 || e.key === "Enter") &&
      (e.ctrlKey || e.metaKey)
    ) {
      getAssetDetails();
    }
  };

  const itemCategoryString =
    assetDetail?.itemCategory?.length &&
    assetDetail.itemCategory?.reduce(
      (prev: string, curr: string) => `${prev}, ${curr}`
    );

  const config = [
    {
      label: "Status",
      value: assetDetail?.deleted ? RETIRED_ASSET : assetDetail?.status || "-",
      tooltip: assetDetail?.status,
      parentWidth: 8,
    },
    {
      label: "Last location",
      value: assetDetail?.lastLocation || "-",
      tooltip: assetDetail?.lastLocation,
      parentWidth: 8,
    },
    {
      label: "Last Room/BS",
      value: assetDetail?.lastRoomName || "-",
      tooltip: assetDetail?.lastRoomName,
      parentWidth: 8,
    },
    {
      label: "Campus",
      value: assetDetail?.campus || "-",
      tooltip: assetDetail?.campus,
      parentWidth: 8,
    },
    {
      label: "Last cleaned by",
      value: assetDetail?.loading ? (
        <SingleLineSkeleton width={150} />
      ) : (
        assetDetail?.lastCleanedBy || assetDetail?.lastCleanedBy || "-"
      ),
      tooltip:
        assetDetail?.lastCleanedBy || assetDetail?.lastCleanedBy || undefined,
      parentWidth: 8,
    },
    {
      label: "Last order",
      value: assetDetail.loading ? (
        <SingleLineSkeleton width={150} />
      ) : (
        assetDetail?.orderNumber || assetDetail?.orderNumber || "-"
      ),
      tooltip:
        assetDetail?.orderNumber || assetDetail?.orderNumber || undefined,
      parentWidth: 8,
    },
    {
      label: "Speciality",
      value: assetDetail?.specialty ? "Yes" : "No",
      tooltip: assetDetail?.specialty ? "Yes" : "No",
      parentWidth: 8,
    },
    {
      label: "Rental",
      value: assetDetail?.specialty
        ? assetDetail?.rental
          ? "Yes"
          : "No"
        : "-",
      tooltip: assetDetail?.specialty
        ? assetDetail?.rental
          ? "Yes"
          : "No"
        : "Not Applicable",
      parentWidth: 8,
    },
    assetDetail?.deleted && {
      label: "Retired on",
      value: process.env.REACT_APP_SSO_ENABLED
        ? getFormattedDate(assetDetail?.lastUpdated)
        : getESTFromUTC(assetDetail?.lastUpdated) || "-",
      tooltip: process.env.REACT_APP_SSO_ENABLED
        ? getFormattedDate(assetDetail?.lastUpdated)
        : getESTFromUTC(assetDetail?.lastUpdated) || "Not Applicable",
      parentWidth: 8,
    },
    assetDetail?.deleted && {
      label: "Retired by",
      value: assetDetail?.deletedBy || "-",
      tooltip: assetDetail?.deletedBy,
      parentWidth: 8,
    },
    assetDetail?.status === REPAIR_ASSET && {
      label: "Repair Started on",
      value: process.env.REACT_APP_SSO_ENABLED
        ? getFormattedDate(assetDetail?.repairedAt)
        : getESTFromUTC(assetDetail?.repairedAt) || "-",
      tooltip: process.env.REACT_APP_SSO_ENABLED
        ? getFormattedDate(assetDetail?.repairedAt)
        : getESTFromUTC(assetDetail?.repairedAt) || "Not Applicable",
      parentWidth: 8,
    },
    {
      label: "Last modified at",
      value: process.env.REACT_APP_SSO_ENABLED
        ? getFormattedDate(assetDetail?.lastUpdated)
        : getESTFromUTC(assetDetail?.lastUpdated) || "-",
      tooltip: process.env.REACT_APP_SSO_ENABLED
        ? getFormattedDate(assetDetail?.lastUpdated)
        : getESTFromUTC(assetDetail?.lastUpdated) || "Not Applicable",
      parentWidth: 8,
    },
    {
      label: "Last modified by",
      value: assetDetail?.updatedBy || "-",
      tooltip: assetDetail?.updatedBy || "Not Applicable",
      parentWidth: 8,
    },
    {
      label: "Item category",
      value: assetDetail?.loading ? (
        <SingleLineSkeleton width={350} />
      ) : (
        itemCategoryString || "-"
      ),
      tooltip: itemCategoryString || undefined,
      parentWidth: 8,
    },
    assetDetail?.deleted &&
    assetDetail?.notes &&
    assetDetail?.status === REPAIR_ASSET
      ? {
          label: "Repair Description",
          value: assetDetail?.notes || "-",
          tooltip: "Repair Notes" || undefined,
          parentWidth: 24,
        }
      : null,
  ];

  const getAssetDetails = async () => {
    setControlNoEmpty(false);
    setSerialNoEmpty(false);
    const assetIdentifier = form.getFieldValue("controlNumber");
    await adminRootInstance
      .get(`aim/audit/${assetIdentifier}`)
      .then((res: any) => {
        setAssetDetail({ ...res?.data?.data });
        setCurrentControlNumber(res?.data?.data?.controlNumber);
        setCurrentSerialNumber(res?.data?.data?.serialnumber);
        setCurrentEquipmentType(res?.data?.data?.equipmentName);
      })
      .catch((error) => {
        setErrorMsg(error.response?.data?.message);
      });
  };

  const verifyAsset = async () => {
    setIsLoading(true);
    setErrorMsg("");
    if (!currentControlNumber.trim() && !currentSerialNumber.trim()) {
      setControlNoEmpty(true);
      setSerialNoEmpty(true);
      return;
    } else if (!currentControlNumber.trim()) {
      setControlNoEmpty(true);
      return;
    } else if (!currentSerialNumber.trim()) {
      setSerialNoEmpty(true);
      return;
    } else {
      setControlNoEmpty(false);
      setSerialNoEmpty(false);
    }
    await adminRootInstance
      .put(`aim/audit/${assetDetail?.id}`, {
        controlNumber: currentControlNumber,
        serialNumber: currentSerialNumber,
      })
      .then((res: any) => {
        setIsLoading(false);
        if (res.data.status === "success") {
          setOpenModal(false);
          message.success("Asset updated successfully!");
          form.resetFields();
          setAssetDetail({});
          setControlNoEmpty(false);
          setSerialNoEmpty(false);
          setCurrentControlNumber("");
          setCurrentSerialNumber("");
          setCurrentEquipmentType("");
        } else {
          setErrorMsg(res?.data?.message);
          setOpenModal(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpenModal(false);
        setErrorMsg(error.response?.data?.message);
      });
  };

  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <title>Audit | AIM</title>
      </Helmet>
      <Title style={{ paddingTop: "15px", textAlign: "left" }} level={5}>
        Audit
      </Title>
      {errorMsg ? (
        <Alert
          className={`fadeIn ${classes.alertContainer}`}
          message={errorMsg}
          type="error"
          closable
          afterClose={() => setErrorMsg("")}
        />
      ) : null}
      <div
        className={classes.auditContainer}
        style={{ paddingTop: assetDetail.controlNumber ? "24px" : "74px" }}
      >
        {assetDetail?.controlNumber && (
          <Row
            className={classes.gobackBtnIndvl}
            style={{ marginLeft: "24px", padding: 0, marginTop: 0 }}
          >
            <button
              onClick={() => {
                setOpenModal(false);
                form.resetFields();
                setAssetDetail({});
                setControlNoEmpty(false);
                setSerialNoEmpty(false);
                setCurrentControlNumber("");
                setCurrentSerialNumber("");
                setCurrentEquipmentType("");
              }}
            ></button>
          </Row>
        )}
        <div className={classes.auditForm}>
          {!assetDetail?.controlNumber && (
            <Form
              style={{
                display: `${assetDetail?.controlNumber ? "flex" : ""}`,
                gap: "10px",
              }}
              initialValues={initialValues}
              onFinish={getAssetDetails}
              form={form}
            >
              <Form.Item
                style={{ width: "100%" }}
                label="Control number / Serial number"
                name={"controlNumber"}
                rules={[
                  {
                    required: true,
                    message: "Please enter a Control number or Serial number",
                  },
                ]}
              >
                <Input
                  placeholder="Control number / Serial number"
                  onKeyDown={manualScanInput}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="nextBtn" htmlType="submit">
                  View
                </Button>
              </Form.Item>
            </Form>
          )}
          {assetDetail?.controlNumber && (
            <>
              <label className={classes.title}>Asset Details</label>
              <Row align="middle" className={classes.contentBody}>
                <Col span={8} className={`${classes.dataField} `}>
                  <Row justify="start" align="bottom">
                    <Col span={24}>
                      <Row align="bottom" style={{ width: "100%" }}>
                        <label style={{ color: "#000000D9" }}>
                          Equipment Type
                        </label>
                        <Input
                          className={classes.ellipsis}
                          value={currentEquipmentType}
                          onChange={(e: any) =>
                            setCurrentEquipmentType(e.target.value)
                          }
                          disabled
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={8} className={`${classes.dataField} `}>
                  <Row justify="start" align="bottom">
                    <Col span={24}>
                      <Row align="bottom" style={{ width: "100%" }}>
                        <label style={{ color: "#000000D9" }}>
                          Control number
                        </label>
                        <Input
                          className={classes.ellipsis}
                          value={currentControlNumber}
                          placeholder="Control number"
                          onChange={controlNumberChange}
                          style={
                            controlNoEmpty
                              ? { borderColor: "#ff4d4f", boxShadow: "none" }
                              : {}
                          }
                          disabled={assetDetail?.deleted}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={8} className={`${classes.dataField} `}>
                  <Row justify="start" align="bottom">
                    <Col span={24}>
                      <Row align="bottom" style={{ width: "100%" }}>
                        <label style={{ color: "#000000D9" }}>
                          Serial number
                        </label>
                        <Input
                          className={classes.ellipsis}
                          value={currentSerialNumber}
                          placeholder="Serial number"
                          onChange={serialNumberChange}
                          style={
                            serialNoEmpty
                              ? { borderColor: "#ff4d4f", boxShadow: "none" }
                              : {}
                          }
                          disabled={assetDetail?.deleted}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={8}></Col>

                <Col span={8}>
                  {controlNoEmpty && (
                    <span style={{ color: "#ff4d4f" }}>
                      Control number cannot be empty
                    </span>
                  )}
                </Col>

                <Col span={8}>
                  {serialNoEmpty && (
                    <span style={{ color: "#ff4d4f" }}>
                      Serial number cannot be empty
                    </span>
                  )}
                </Col>

                {config.map((configObj: any) =>
                  configObj ? (
                    <Col
                      span={configObj.parentWidth}
                      className={`${classes.dataField} `}
                    >
                      <Row justify="start" align="bottom">
                        <Col span={24}>
                          <Row
                            align="bottom"
                            style={{ width: "100%", color: "#000000D9" }}
                          >
                            <label>{configObj.label}:</label>
                            <Tooltip
                              title={configObj.tooltip}
                              className={classes.ellipsis}
                              overlayInnerStyle={{ width: "max-content" }}
                              arrowPointAtCenter
                              destroyTooltipOnHide
                            >
                              <span
                                style={{
                                  paddingLeft: "5px",
                                  maxWidth: `${
                                    (configObj.parentWidth / 8) * 200
                                  }px`,
                                }}
                              >
                                {configObj.value}
                              </span>
                            </Tooltip>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  ) : (
                    <React.Fragment />
                  )
                )}
              </Row>
            </>
          )}
          {assetDetail?.controlNumber &&
            assetDetail?.verified &&
            !assetDetail?.deleted && (
              <Button
                type="primary"
                className="nextBtn"
                style={{ marginBottom: "40px" }}
                onClick={() =>
                  !controlNoEmpty && !serialNoEmpty && setOpenModal(true)
                }
              >
                Save & Re-verify
              </Button>
            )}
          {assetDetail?.controlNumber &&
            !assetDetail?.verified &&
            !assetDetail?.deleted && (
              <Button
                type="primary"
                className="nextBtn"
                style={{ marginBottom: "40px" }}
                onClick={verifyAsset}
                loading={isLoading}
              >
                Save & verify
              </Button>
            )}
        </div>
      </div>
      <ReVerifyAsset
        setOpenModal={setOpenModal}
        openModal={openModal}
        verifyAsset={verifyAsset}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Audit;
