import React, { Children } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { mergeDeep, capitalizeFirstLetter } from "../../utils";
import { connect } from "react-redux";
import { IRootState } from "../../../../../common/redux/reducers";
import { fetchPatientTransportReport } from "../../actions";
import moment from "moment";
import classes from "../common-reports.module.scss";
import ReportsTable from "../commons/report-table";
import { title } from "process";

const reformatData = (rawData: any) => {
  let labelData: any = [];
  let dateArr: any = rawData;
  if (!rawData) return { processedData: [], labelData: {} };
  let processedData = dateArr.reduce((acc: any, curr: any, index: any) => {
    let date = curr?.date;
    let dateObj: any = curr;
    let finalObj = {
      date: date,
      totalOrders: dateObj?.total || "-",
      "Procedure Request": dateObj["Procedure Request"],
      "Discharge Request": dateObj["Discharge Request"],
      "Transfer Request": dateObj["Transfer Request"],
      "Return Request": dateObj["Return Request"],
    };
    return acc.concat(finalObj);
  }, []);
  //combine all labels to single object
  return { processedData, labelData: mergeDeep(labelData) };
};

const ReportSummaryTable = ({
  reportData,
  fetchPatientTransportReport,
  filterData,
  reportLoading,
}: any) => {
  const [processedData, setProcessedData] = useState<any>({});

  useEffect(() => {
    reportData.data && setProcessedData(reformatData(reportData.data));
  }, [reportData]);

  const columnFormatter = (): any => {
    const finalObj = [
      {
        title: "Date",
        dataIndex: "date",
        width: 100,
        render: (record: any) => moment(record).format("MM/DD/YYYY"),
        fixed: "left",
        align: "center",
      },
      {
        title: "Total Orders",
        dataIndex: "totalOrders",
        key: "totalOrders",
        width: 100,
        align: "center",
      },
      {
        title: "Discharge",
        dataIndex: "Discharge Request",
        key: "Discharge Request",
        width: 100,
        align: "center",
      },
      {
        title: "Procedure",
        dataIndex: "Procedure Request",
        key: "Procedure Request",
        width: 100,
        align: "center",
      },
      {
        title: "Return",
        dataIndex: "Return Request",
        key: "Return Request",
        width: 100,
        align: "center",
      },
      {
        title: "Transfer",
        dataIndex: "Transfer Request",
        key: "Transfer Request",
        width: 100,
        align: "center",
      },
    ];
    const labelsObj = processedData?.labelData;
    if (labelsObj) {
      const firstLvlLabel = Object.keys(labelsObj);
      const remainingDat: any = firstLvlLabel.map((sla: any) => {
        const slaObj = labelsObj[sla];
        let secondLvlLabel: any = Object.keys(slaObj.data);
        secondLvlLabel = secondLvlLabel
          .filter((val: any) => val !== "total")
          .concat("total");
        return {
          title: slaObj.name,
          className: classes.borderLeft,
          children: secondLvlLabel.reduce((acc: any, curr: any, ind: any) => {
            const borderClass = ind === 0 ? classes.borderLeft : null;
            return [
              ...acc,
              {
                title: capitalizeFirstLetter(curr),
                dataIndex: sla + "-" + curr,
                key: sla + "-" + curr,
                ...(borderClass && { className: borderClass }),
                align: "center",
                width: 100,
                render: (text: any) => (text ? text : "-"),
              },
            ];
          }, []),
        };
      });
      return finalObj.concat(remainingDat);
    }
    return [];
  };
  const data = processedData.processedData;
  let labelData = processedData.labelData;

  return (
    <ReportsTable
      loading={reportLoading}
      rowData={data}
      columnData={labelData ? columnFormatter() : []}
      totalCount={reportData?.totalCount}
      fetchData={(pagination: any) => {
        fetchPatientTransportReport({
          ...pagination,
          ...filterData,
        });
      }}
    />
  );
};
const mapStateToProps = ({ reportsState }: IRootState) => ({
  dataLoading: reportsState.loading,
  allSla: reportsState.allSlas,
  allOrderTypes: reportsState.allOrderTypes,
  successMsg: reportsState.successMessage,
  reportData: reportsState.reportData,
  filterData: reportsState.filterData,
  reportLoading: reportsState.loading,
});
const mapDispatchToProps = {
  fetchPatientTransportReport,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportSummaryTable);
