import Title from "antd/lib/typography/Title";
import React from "react";
import { Helmet } from "react-helmet";
import UnauthorizedAccess from "../../../common/components/unauthorized-page";
import { Admin } from "../../../common/shared-constants";
import { useSelector } from "react-redux";
import { IRootState } from "../../../common/redux/reducers";
import classes from "./data-import.module.scss";
import DataImport from "./data-import";

export default function DataImportComponent() {
  const loggedInUser: any = useSelector(
    (s: IRootState) => s.adminAuthentication.account
  );

  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <title>Items | Data Import</title>
      </Helmet>
      {loggedInUser?.role !== Admin ? (
        <div className={classes.unauthorized}>
          <UnauthorizedAccess />
        </div>
      ) : (
        <>
          <Title className={classes.titleText} level={5}>
            Data Import
          </Title>
          <div className={classes.importContainer}>
            <DataImport
              apiUrl="mass-upload"
              templateFile={{
                data: [
                  [
                    "Campus Name",
                    "Campus Location",
                    "Building",
                    "Unit/Program",
                    "Floor",
                  ],
                ],
                fileName: "data-import-template.csv",
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}
