/* eslint-disable react-hooks/exhaustive-deps*/
import { useEffect } from "react";
import { connect } from "react-redux";
import { IRootState } from "../../../../../common/redux/reducers";
import RequestViewNotes from "../notesComponent/RequestViewNotes";
import { clearNotes, fetchNotesDetails } from "./actions";

const Notes = ({
  dataLoading,
  notesDetails,
  errorMsg,
  selectedRequest,
  fetchNotesDetails,
  reqId,
  clearNotes,
}: any) => {
  //to clear the redux state while unmounting
  useEffect(() => {
    return () => {
      clearNotes();
    };
  }, []);
  useEffect(() => {
    fetchNotesDetails({ reqId });
  }, [reqId]);
  return (
    <RequestViewNotes type="Notes" loading={dataLoading} data={notesDetails} />
  );
};

const mapStateToProps = ({ notesDetails, requestState }: IRootState) => ({
  dataLoading: notesDetails.loading,
  errorMsg: notesDetails.errorMessage,
  notesDetails: notesDetails.notesDetails,
  selectedRequest: requestState.selectedRequest,
});
const mapDispatchToProps = {
  fetchNotesDetails,
  clearNotes,
};
export default connect(mapStateToProps, mapDispatchToProps)(Notes);
