import React, { useEffect } from "react";
import Countdown, { zeroPad } from "react-countdown";

const updateColorScheme = () => {
  const customSpanChildren = Array.from(
    document.querySelectorAll(".timeLeft span[class^='custom-']")
  );
  customSpanChildren.map((child: any) => {
    let classNames: any = [];
    if (child) {
      classNames = child.classList;
    }
    let row = child?.closest("tr");
    let color = classNames[0]?.split("-")[1];
    row.classList.remove("timeExpired", "timeWarning", "noAlert", "alert");
    let priorityText = row.querySelector("div.priorityText");
    if (child && row) {
      row.style.backgroundColor = color;
      row.style.color = "#fff";
      priorityText.style.color = "#000";
      var tdElements = row.querySelectorAll("td");
      tdElements.forEach(function (td: any) {
        td.style.backgroundColor = color;
        td.addEventListener("mouseover", function () {
          td.style.backgroundColor = color;
        });
        td.addEventListener("mouseout", function () {
          td.style.backgroundColor = color;
        });
      });
    }
    return child;
  });
  const children = Array.from(document.querySelectorAll(".timeLeft .alert"));
  children.forEach((childElement) => {
    let classNames = childElement?.classList;
    classNames.forEach((name) => {
      if (name.startsWith("custom")) {
        childElement.classList.remove(name);
      }
    });
  });
  children.map((child: any) => {
    child?.closest("tr").classList.add("timeExpired");
    child?.closest("tr").classList.remove("timeWarning");
    return child;
  });
  const warningchildren = Array.from(
    document.querySelectorAll(".timeLeft .warning")
  );
  warningchildren.forEach((childElement) => {
    let classNames = childElement?.classList;
    classNames.forEach((name) => {
      if (name.startsWith("custom")) {
        childElement.classList.remove(name);
      }
    });
  });
  warningchildren.map((child: any) => {
    child?.closest("tr").classList.add("timeWarning");
    return child;
  });
  const removeWarnAlert = Array.from(
    document.querySelectorAll(".timeLeft .noAlert")
  );
  removeWarnAlert.forEach((childElement) => {
    let classNames = childElement?.classList;
    classNames.forEach((name) => {
      if (name.startsWith("custom")) {
        childElement.classList.remove(name);
      }
    });
  });
  removeWarnAlert.map((child: any) => {
    child?.closest("tr").classList.remove("timeExpired", "timeWarning");
    return child;
  });
};

const Timer = (props: any) => {
  useEffect(() => {
    const interval = setInterval(() => {
      updateColorScheme();
    }, 1000);
    return () => {
      clearInterval(interval);
      updateColorScheme();
    };
  }, []);

  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (days > 0) {
      hours = days * 24 + hours;
    }

    const getCustomClassNames = (
      hours: any,
      minutes: any,
      seconds: any,
      completed: any
    ) => {
      let customClassName = "";
      if (
        props?.record?.priority?.timeLeft1 &&
        props?.record?.priority?.timeLeft2
      ) {
        let totalCurrentMinutes = hours * 60 + minutes + seconds / 60;
        let greater = props?.record?.priority?.timeLeft2;
        let lesser = props?.record?.priority?.timeLeft1;
        let greaterColor = props?.record?.priority?.highlightColor2;
        let lesserColor = props?.record?.priority?.highlightColor1;

        if (
          props?.record?.priority?.timeLeft1 >
          props?.record?.priority?.timeLeft2
        ) {
          greater = props?.record?.priority?.timeLeft1;
          lesser = props?.record?.priority?.timeLeft2;
          greaterColor = props?.record?.priority?.highlightColor1;
          lesserColor = props?.record?.priority?.highlightColor2;
        }

        if (totalCurrentMinutes <= greater && totalCurrentMinutes >= lesser) {
          let timeLeftHours1 = Math.floor(greater / 60);
          let timeLeftMinutes1 = greater % 60;
          let timeLeftSeconds1 = timeLeftMinutes1 * 60;
          if (
            hours <= timeLeftHours1 &&
            minutes <= timeLeftMinutes1 &&
            seconds <= timeLeftSeconds1 &&
            completed !== true
          )
            customClassName = `custom-${greaterColor}`;
        } else {
          let timeLeftHours2 = Math.floor(lesser / 60);
          let timeLeftMinutes2 = lesser % 60;
          let timeLeftSeconds2 = timeLeftMinutes2 * 60;
          if (
            hours <= timeLeftHours2 &&
            minutes <= timeLeftMinutes2 &&
            seconds <= timeLeftSeconds2 &&
            completed !== true
          )
            customClassName = `custom-${lesserColor}`;
        }
      } else if (props?.record?.priority?.timeLeft1) {
        let timeLeftHours = Math.floor(props?.record?.priority?.timeLeft1 / 60);
        let timeLeftMinutes = props?.record?.priority?.timeLeft1 % 60;
        let timeLeftSeconds = 0;
        if (
          hours <= timeLeftHours &&
          minutes <= timeLeftMinutes &&
          seconds <= timeLeftSeconds &&
          completed !== true
        )
          customClassName = `custom-${props?.record?.priority?.highlightColor1}`;
      } else {
        let timeLeftHours = Math.floor(props?.record?.priority?.timeLeft2 / 60);
        let timeLeftMinutes = props?.record?.priority?.timeLeft2 % 60;
        let timeLeftSeconds = 0;
        if (
          hours <= timeLeftHours &&
          minutes <= timeLeftMinutes &&
          seconds <= timeLeftSeconds &&
          completed !== true
        )
          customClassName = `custom-${props?.record?.priority?.highlightColor2}`;
      }
      return customClassName;
    };

    return (
      <>
        <span
          className={
            completed === true
              ? "alert"
              : hours === 0 &&
                minutes <= 4 &&
                seconds <= 59 &&
                completed !== true
              ? "warning"
              : props?.record?.priority?.timeLeft1 ||
                props?.record?.priority?.timeLeft2
              ? getCustomClassNames(hours, minutes, seconds, completed)
              : "noAlert"
          }
          style={{ fontSize: "12px" }}
        >
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      </>
    );
  };

  return (
    <Countdown
      daysInHours={true}
      date={props.timeLeft}
      overtime={true}
      renderer={renderer}
    />
  );
};

export default Timer;
