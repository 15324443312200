import { Tooltip } from "antd";
import classes from "../request-view/request-view.module.scss";

interface charCounter {
  charLen: number;
  showRemaining?: boolean;
}

const CharCounter = ({ charLen, showRemaining = true }: charCounter) => {
  return (
    <Tooltip
      title={showRemaining ? "Characters left" : "Characters entered"}
      className={classes.charCount}
    >
      <p>{showRemaining ? 200 - charLen : charLen}</p>
    </Tooltip>
  );
};

const getRemainingTime = (created: string | Date, timeLimit: number) => {
  let timeExceeded = false;
  let timeRemaining =
    new Date(created).getTime() + timeLimit * 60000 - new Date().getTime();
  if (timeRemaining < 0) {
    timeRemaining = -1 * timeRemaining;
    timeExceeded = true;
  }
  let hrs: any = Math.floor(timeRemaining / (1000 * 60 * 60));
  if (hrs < 10) hrs = "0" + hrs ? hrs : "0";
  let min: any = Math.floor((timeRemaining / 60000) % 60);
  if (min < 10) min = "0" + min ? min : "0";
  let sec: any = Math.floor((timeRemaining / 1000) % 60);
  if (sec < 10) sec = "0" + sec ? sec : "0";
  return { timeLeft: `${hrs}:${min}:${sec}`, timeExceeded };
};
export { CharCounter, getRemainingTime };
