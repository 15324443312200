import { Steps, Form, Alert, Spin, Button, Input, Col, Row, Modal } from "antd";
import classes from "./patient-transport.module.scss";
import { useEffect, useState } from "react";
import Step1 from "../../shared/components/patient-selection";
import Step3 from "../../shared/components/patient-details";
import Step2 from "../../shared/components/transport-information";
import AnonymousModal from "../../../common/utils/anonymous-popup";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { IRootState } from "../../../common/redux/reducers";
import msalInstance from "../../../common/utils/sso-config";
import { sendEmail } from "../../../common/utils/send-email";
import { connect } from "react-redux";
import { patientTransportOrder, saveAlert } from "./actions";
import { login } from "../login/actions";
import {
  contactNameValidator,
  phoneNumberValidator,
} from "../../../common/utils/formValidators";
import {
  DISCHARGE_REQUEST_TYPE_ID,
  PROCEDURE_REQUEST_TYPE_ID,
  RETURN_REQUEST_TYPE_ID,
  TRANSFER_REQUEST_TYPE_ID,
} from "../../../common/shared-constants";
import { fetchUnits } from "../../shared/reducers/actions";
import moment from "moment";
import { requestorRootInstance } from "../../../common/config/axios-config";
import { useHistory } from "react-router-dom";

const { Step } = Steps;

const PatientTransport = (props: any) => {
  const {
    isAuthenticated,
    authLoading,
    login,
    externalDetails,
    allUnits,
    fetchUnits,
    campus,
    patientTransportOrder,
  } = props;
  const history = useHistory();
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [from, setFrom] = useState(0);
  const [selectedRequestId, setSelectedRequestId] = useState(0);
  const [selectedRequest, setSelectedRequest] = useState("");
  const [anonymousMoodal, setAnonymousModal] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [dischargeLocation, setDischargeLocation] = useState<any>([]);
  const [transportDetails, setTransportDetails] = useState<any>(null);
  const [formValues, setFormValues] = useState({
    additionalInformation: "",
    dob: "",
    fromUnit: "",
    nurseName: "",
    patientName: "",
    phoneNumber: "",
    room: "",
    toRoom: "",
    contactPersonName: "",
    time: "",
    toUnit: "",
    selectedTransportItems: [],
  });
  const [timeLapsed, setTimeLapsed] = useState(false);

  interface requestBody {
    cartItem: any[] | null;
    requestTypeId: number;
    unitId: string;
    patientName: string;
    room: string;
    destinationRoom?: string;
    contactPersonName?: string;
    externalUserId: number;
    timeLimit: number;
    expiry: string;
    patientDob: string;
    destinationUnitId?: string;
    additionalInformation?: string;
    dischargeLocationId?: string;
    pickUpOrderId?: string;
    campusId: string;
    orderPriority: number
  }

  useEffect(() => {
    const timeout = loginError && setTimeout(() => setLoginError(""), 5000);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [loginError]);

  useEffect(() => {
    if (current === 0) {
      form.resetFields();
    }
  }, [current]);

  useEffect(() => {
    setFormValues({
      ...formValues,
      nurseName: externalDetails?.name,
      phoneNumber: externalDetails?.phone,
    });
  }, [externalDetails]);

  useEffect(() => {
    if (campus?.id) {
      fetchUnits(campus?.id);
    }
  }, [campus?.id]);

  useEffect(() => {
    const allowedRequestTypesIds = campus?.requestTypes?.map(
      (requestType: any) => requestType.id
    );
    if (
      !allowedRequestTypesIds?.includes(PROCEDURE_REQUEST_TYPE_ID) &&
      !allowedRequestTypesIds?.includes(RETURN_REQUEST_TYPE_ID) &&
      !allowedRequestTypesIds?.includes(DISCHARGE_REQUEST_TYPE_ID) &&
      !allowedRequestTypesIds?.includes(TRANSFER_REQUEST_TYPE_ID)
    ) {
      history.push("/");
    }
  }, [campus]);

  useEffect(() => {
    if (selectedRequestId === DISCHARGE_REQUEST_TYPE_ID && campus?.id) {
      const getDischargeLocations = async () => {
        const res = await requestorRootInstance.get(
          `discharge-location/${campus?.id}`
        );
        setDischargeLocation([...res?.data?.data]);
      };
      getDischargeLocations();
    }
  }, [selectedRequestId, campus?.id]);

  useEffect(() => {
    if (campus?.id) {
      const getTransportDetails = async () => {
        const res = await requestorRootInstance.get(`transport/${campus?.id}`);
        setTransportDetails(res?.data?.data);
      };
      getTransportDetails();
    }
  }, [campus?.id]);

  const handleSubmit = async () => {
    const items = [] as Array<any>;
    formValues?.selectedTransportItems?.forEach((item: any) => {
      items.push({ id: item?.item?.id, quantity: item?.quantity });
    });
    const requestBody: requestBody = {
      cartItem: items,
      requestTypeId: selectedRequestId,
      unitId: formValues?.fromUnit,
      patientName:
        formValues?.patientName?.trim() ||
        form?.getFieldValue("patientName")?.trim(),
      room: formValues?.room?.trim(),
      destinationRoom: formValues?.toRoom?.trim(),
      contactPersonName: formValues?.contactPersonName?.trim(),
      externalUserId: externalDetails?.id,
      timeLimit: moment(form?.getFieldValue("time"))
        .seconds(0)
        .diff(moment().seconds(0), "minutes"),
      expiry: moment(form?.getFieldValue("time")).seconds(0).toISOString(),
      patientDob: formValues?.dob
        ? moment(formValues?.dob).format("YYYY-MM-DD")
        : moment(form?.getFieldValue("dob")).format("YYYY-MM-DD"),
      destinationUnitId:
        selectedRequestId === RETURN_REQUEST_TYPE_ID
          ? formValues?.toUnit?.split("|")[1]
          : selectedRequestId === DISCHARGE_REQUEST_TYPE_ID
          ? undefined
          : formValues?.toUnit,
      additionalInformation: formValues?.additionalInformation?.trim() || "",
      dischargeLocationId:
        selectedRequestId === DISCHARGE_REQUEST_TYPE_ID
          ? formValues?.toUnit
          : undefined,
      pickUpOrderId:
        selectedRequestId === RETURN_REQUEST_TYPE_ID
          ? formValues?.toUnit?.split("|")[0]
          : undefined,
      campusId: campus?.id,
      orderPriority:  selectedRequestId === DISCHARGE_REQUEST_TYPE_ID ?
                      transportDetails.dischargePriority  :
                      selectedRequestId === TRANSFER_REQUEST_TYPE_ID  ?
                      transportDetails.transferPriority : transportDetails.procedurePriority

    };

    const res = await patientTransportOrder(requestBody);
    if (res.status === "success") {
      history.push("/request");
    }
  };

  const handleBack = () => {
    setFrom(current);
    setCurrent((prev) => prev - 1);
  };

  const handleNext = () => {
    setFrom(current);
    if (current === 1) {
      form
        .validateFields()
        .then(() => {
          setCurrent((prev) => prev + 1);
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      setCurrent((prev) => prev + 1);
    }
  };

  const loginRequest = async () => {
    await msalInstance
      .loginPopup()
      .then(async (ssoResponse: any) => {
        if (ssoResponse.idTokenClaims) {
          localStorage.setItem(
            "logoutHint",
            ssoResponse.idTokenClaims.login_hint
          );
          let loginPayload = {
            name: ssoResponse.idTokenClaims.name.split(",")[1]
              ? `${ssoResponse.idTokenClaims.name.split(",")[1]} ${
                  ssoResponse.idTokenClaims.name.split(",")[0]
                }`
              : ssoResponse.idTokenClaims.name.split(",")[0],
            email: ssoResponse.idTokenClaims.preferred_username,
            employeeId: ssoResponse.idTokenClaims.employeeid,
          };
          const loginResponse = await login(loginPayload);
          if (loginResponse.status === "success") {
            setLoginError("");
          } else {
            setLoginError(loginResponse.errorMessage);
          }
        } else {
          setLoginError("SSO login failed");
        }
      })
      .catch((err: any) => {
        sendEmail(err);
        setAnonymousModal(true);
      });
  };

  const loginUser = () => {
    if (process.env.REACT_APP_SSO_ENABLED === "true") {
      loginRequest();
    } else {
      setShowLoginModal(true);
    }
  };

  const loginSubmit = async (val: any) => {
    const response = await login(
      {
        name: val?.name,
        phone: val?.phoneNo,
      },
      true
    );
    if (response.status === "success") {
      setShowLoginModal(false);
    } else {
      setLoginError(response?.errorMessage);
    }
  };

  return (
    <div className="fullWidth">
      {transportDetails?.showHeaderText &&
        transportDetails?.headerText &&
        current === 0 && (
          <div className={classes.bannerText}>
            <div className={classes.titleText}>Advisory Notice:</div>
            {transportDetails?.headerText}
          </div>
        )}
      <div>
        {loginError && process.env.REACT_APP_SSO_ENABLED === "true" ? (
          <Alert
            className={`fadeIn ${classes.alertContainer}`}
            message={loginError}
            type="error"
            closable
            afterClose={() => setLoginError("")}
          />
        ) : null}
        <div className={classes.stepsWrapper}>
          <div
            className={
              current === 0
                ? classes.stepsContainerWithBanner
                : classes.stepsContainer
            }
          >
            <Steps
              current={current}
              className={"greenSteps"}
              direction="horizontal"
            >
              <Step key={0} />
              <Step key={1} />
              <Step key={2} />
            </Steps>
          </div>
        </div>
        <div>
          <div className={classes.radioButtonContainer}>
            <Form form={form} labelAlign="left">
              {current === 0 && (
                <Step1
                  selectedRequestId={selectedRequestId}
                  setSelectedRequestId={setSelectedRequestId}
                  setSelectedRequest={setSelectedRequest}
                  transportDetails={transportDetails}
                />
              )}
              {current === 1 && (
                <Step2
                  form={form}
                  selectedRequestId={selectedRequestId}
                  selectedRequest={selectedRequest}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  externalDetails={externalDetails}
                  from={from}
                  allUnits={allUnits}
                  dischargeLocation={dischargeLocation}
                  setDischargeLocation={setDischargeLocation}
                  transportDetails={transportDetails}
                />
              )}
              {current === 2 && (
                <Step3
                  form={form}
                  selectedRequestId={selectedRequestId}
                  selectedRequest={selectedRequest}
                  allUnits={allUnits}
                  formValues={formValues}
                  dischargeLocation={dischargeLocation}
                  setTimeLapsed={setTimeLapsed}
                />
              )}
            </Form>
          </div>
          <div className={classes.btn}>
            {current === 0 && (
              <Spin spinning={false}>
                <Button
                  onClick={() => history.push("/request")}
                  className={classes.nextBtn}
                >
                  Back
                </Button>
              </Spin>
            )}
            {current === 0 &&
              (!isAuthenticated ? (
                <Spin spinning={authLoading}>
                  <Button onClick={loginUser} className={classes.nextBtn}>
                    Login
                  </Button>
                </Spin>
              ) : (
                <Spin spinning={false}>
                  <Button
                    onClick={handleNext}
                    className={classes.nextBtn}
                    disabled={selectedRequestId === 0}
                  >
                    Next
                  </Button>
                </Spin>
              ))}
            {current === 2 && (
              <div>
                <Button onClick={handleBack} className={classes.nextBtn}>
                  Back
                </Button>
                <Button
                  onClick={() => handleSubmit()}
                  className={classes.nextBtn}
                  disabled={timeLapsed}
                >
                  Submit
                </Button>
              </div>
            )}
            <AnonymousModal
              key="info-modal"
              handleCancel={() => {
                setAnonymousModal(false);
              }}
              handleOk={() => {
                setAnonymousModal(false);
              }}
              isModalVisible={anonymousMoodal}
              icon={<ExclamationCircleTwoTone className={classes.icon} />}
              text={
                "The sso server is currently down. Please try after some time"
              }
              okayText="Ok"
            />
          </div>
        </div>
      </div>
      <Modal
        title="Login"
        open={showLoginModal}
        onCancel={() => {
          setShowLoginModal(false);
          setLoginError("");
        }}
        maskClosable={false}
        destroyOnClose
        centered={true}
        footer={false}
        className={`sharpItemModal ${classes.patientDetailsModal}`}
      >
        <Form onFinish={loginSubmit} labelAlign="left">
          <Row>
            <Col span={24}>
              <Form.Item
                name="name"
                rules={[
                  () => ({
                    validator: contactNameValidator,
                    required: true,
                  }),
                ]}
                wrapperCol={{ xs: 24, sm: 24, md: 16, lg: 16, xl: 17, xxl: 17 }}
                labelCol={{ xs: 24, sm: 24, md: 8, lg: 7, xl: 6, xxl: 6 }}
                label="Name"
              >
                <Input className={classes.searchInput} autoFocus />
              </Form.Item>
              <Form.Item
                name="phoneNo"
                rules={[
                  () => ({
                    validator: phoneNumberValidator,
                    required: true,
                  }),
                ]}
                wrapperCol={{ xs: 24, sm: 24, md: 16, lg: 16, xl: 17, xxl: 17 }}
                labelCol={{ xs: 24, sm: 24, md: 8, lg: 7, xl: 6, xxl: 6 }}
                label="Phone No"
              >
                <Input className={classes.searchInput} />
              </Form.Item>
            </Col>
          </Row>
          {loginError ? (
            <Alert
              className={`fadeIn ${classes.alertContainer} ${classes.loginAlertContainer}`}
              message={loginError}
              type="error"
              closable
              afterClose={() => setLoginError("")}
            />
          ) : null}
          <Col span={24} className={classes.btn}>
            <Spin spinning={authLoading}>
              <Button type="primary" htmlType="submit" className="nextBtn">
                Submit
              </Button>
            </Spin>
          </Col>
        </Form>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({
  authentication,
  requestData,
  homeReducer,
}: IRootState) => ({
  externalDetails: authentication.account,
  loading: homeReducer.loading,
  campus: homeReducer.currentCampus,
  allUnits: requestData.units,
  isAuthenticated: authentication.isAuthenticated,
  authLoading: authentication.loading,
});

const mapDispatchToProps = {
  login,
  patientTransportOrder,
  saveAlert,
  fetchUnits,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientTransport);
