/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Modal,
  Input,
  Select,
  Alert,
  message,
  InputNumber,
  Tooltip,
  DatePicker,
  DatePickerProps,
} from "antd";
import classes from "./request-view-modal.module.scss";
import {
  ASSIGNED,
  CANCELLED,
  COMPLETED,
  DELIVERY_REQUEST_TYPE_ID,
  OVERRIDE_CLOSE,
  PATIENTMODAL_SINGLE_NEW,
  PATIENTMODAL_SINGLE_REMOVE,
  PATIENTMODAL_VIEW,
  PICKUP_REQUEST_TYPE_ID,
  reqTypeAddItemMap,
  REQ_EDIT,
  SHARPS_REQUEST_TYPE_ID,
  SPECIALTY_HOVER_TITLE,
  SPECIALTY_INPUT_NUMBER_DISABLED_TITLE,
  UNASSIGNED,
  VIEW_PATIENTS_TEXT,
  PROCEDURE_REQUEST_TYPE_ID,
  RETURN_REQUEST_TYPE_ID,
  DISCHARGE_REQUEST_TYPE_ID,
  TRANSFER_REQUEST_TYPE_ID,
  BUTTON_GREEN_HEX,
} from "../../../../common/shared-constants";
import {
  ExclamationCircleOutlined,
  ExclamationCircleTwoTone,
  FormOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { getCatalogueIdByRequestType } from "../../../../common/utils/catalogue";
import AddItemModal from "../../../../common/components/AddItemModal/add-item-modal";
import {
  locationRoomNameValidator,
  shortDescriptionValidator,
  patientNameValidator,
  contactNameValidator,
  phoneNumberValidator,
  emailValidator,
  fromRoomNameValidator,
} from "../../../../common/utils/formValidators";
import { CLEAR_MESSAGES } from "../action-types";
import { useDispatch } from "react-redux";
import { getESTFromUTC, getFormattedDate } from "../../../../common/utils/time";
import {
  allowOnlyNumbers,
  trimValues,
} from "../../../../common/utils/trimUtil";
import PatientDetailsModal from "../../../../common/components/PatientDetailsModal/patient-details-modal";
import CancelReqModal from "../../../../common/components/CancelReqModal/CancelReqModal";
import DelayModal from "../../../../common/components/DelayModal/DelayModal";
import moment from "moment";
import {
  adminRootInstance,
  requestorRootInstance,
} from "../../../../common/config/axios-config";

const RequestDetailsView = ({
  request,
  allCampuses,
  units,
  slas,
  allItems,
  fetchItems,
  fetchUnits,
  fetchReqSlas,
  editRequest,
  setSelectedRequest,
  successMsg,
  errorMsg,
  clearMessages,
  cancelRequest,
  reorderRequest,
  closeModal: closeParentModal,
  editPatients,
  patients,
  loading,
}: any) => {
  const { scanned } = request;
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(false);
  const [editTime, setEditTime] = useState(false);
  let disableEditSection = editMode && scanned;
  let disabledSectionTitle =
    (editMode && (scanned ? "Some assets have already been scanned" : "")) ||
    "";
  const [cartItems, setCartItems] = useState<any>([]);
  const [tempCart, setTempCart] = useState<any>([]);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [addItemModalVisible, setAddItemModalVisible] = useState(false);
  const [selectedUnitId, setSelectedUnitId] = useState<any>();
  const [reorderLoading, setReorderLoading] = useState<boolean>(false);
  const [showPatientModal, setShowPatientModal] = useState<any>(false);
  const [dischargeLocations, setDischargeLocations] = useState<any>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [itemsToBeRemoved, setItemsToBeRemoved] = useState<any>([]);
  const [isRemoveItem, setIsRemoveItem] = useState(false);
  const patientFromUnit = Form.useWatch("patientFromUnit", form);
  const patientToUnit = Form.useWatch("patientToUnit", form);
  const [requestTimeLeft, setRequestTimeLeft] = useState<any>("");
  const [transportDetails, setTransportDetails] = useState<any>(null);

  const { Option } = Select;
  const noSpecialityItem = cartItems.every((items: any) => {
    return !items.isSpecialtyItem;
  });
  const dispatch = useDispatch();
  useEffect(() => {
    setCartItems([...request.items]);
    setTempCart([...request.items.map((a: any) => ({ ...a }))]);
    setSelectedUnitId(request.unitId);
  }, [request]);

  useEffect(() => {
    let timer: any;
    if (successMsg) {
      timer = setTimeout(() => {
        clearMessages();
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [successMsg]);

  useEffect(() => {
    (async () => {
      if (request.requestType.id === RETURN_REQUEST_TYPE_ID) {
        editPatients(request?.unitId, request?.id);
      }
    })();
  }, [request.requestType.id]);

  useEffect(() => {
    if (
      request.requestType.id === DISCHARGE_REQUEST_TYPE_ID &&
      request.campusId
    ) {
      const getDischargeLocations = async () => {
        const res = await adminRootInstance.get(
          `discharge-location/${request.campusId}`
        );
        setDischargeLocations([...res?.data?.data]);
      };
      getDischargeLocations();
    }
  }, [request.requestType.id, request.campusId]);

  useEffect(() => {
    if (request.campusId) {
      getTransportDetails(request.campusId);
    }
  }, [request.campusId]);

  const getTransportDetails = async (newCampusId: any) => {
    const res = await adminRootInstance.get(`transport/${newCampusId}`);
    const response = res?.data?.data;
    const calculatedRequestTimeLeft =
      request.requestType.id === RETURN_REQUEST_TYPE_ID ||
      request.requestType.id === PROCEDURE_REQUEST_TYPE_ID
        ? response?.procedureTimeLeft
        : request.requestType.id === TRANSFER_REQUEST_TYPE_ID
        ? response?.transferTimeLeft
        : response?.dischargeTimeLeft;
    setRequestTimeLeft(calculatedRequestTimeLeft);
    setTransportDetails(res?.data?.data);
  };

  const scroll = () => {
    const element: any = document.getElementById("alert");
    element?.scrollIntoView({
      block: "end",
      inline: "nearest",
    });
  };

  const onCancelClick = () => {
    setCancelModalVisible(true);
  };

  const onCancelSubmit = async (cancelReason: string) => {
    const res = await cancelRequest(request.requestId, cancelReason);
    if (res.success) {
      setSelectedRequest({ ...request, status: CANCELLED });
      setCancelModalVisible(false);
    }
  };
  const handleCancel = () => {
    setCancelModalVisible(false);
  };

  const onEditMode = () => {
    setTempCart([...request.items.map((a: any) => ({ ...a }))]);
    setEditMode(true);
    scroll();
  };

  const onReOrder = async (id: any) => {
    await Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      className: "unitSpecificModal",
      content: "Are you sure you want to reorder?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        setReorderLoading(true);
        let resp = await reorderRequest(id);
        if (resp.success) {
          setReorderLoading(false);
          message.success(resp.message);
          closeParentModal();
        } else {
          setReorderLoading(false);
          message.error(resp.message);
        }
      },
      onCancel: () => {},
    });
  };

  const onItemRemove = (removedId: any) => {
    let itemRemoving = [...tempCart];
    itemRemoving = itemRemoving.filter(
      (cartItem) => cartItem.item.id !== removedId
    );
    setTempCart(itemRemoving);
  };

  const removeItem = () => {
    const filteredItems = tempCart.filter(
      (item: any) => !itemsToBeRemoved.includes(item?.item?.id)
    );
    setTempCart([...filteredItems]);
    setIsRemoveItem(false);
  };

  const onUpdateReq = async (values: any) => {
    const trimedValues = trimValues(values);
    const body = { ...trimedValues, items: [...tempCart] };
    if (isPatientTransportRequest) {
      let isInvalidItem = false;
      if (
        form.getFieldValue("patientCampus") &&
        form.getFieldValue("patientFromUnit") &&
        form.getFieldValue("patientToUnit")
      ) {
        if (tempCart.length > 0) {
          await requestorRootInstance
            .get(
              `transport-item/${form.getFieldValue(
                "patientCampus"
              )}/${form.getFieldValue("patientFromUnit")}/${
                request.requestType.id === DISCHARGE_REQUEST_TYPE_ID
                  ? 1
                  : request.requestType.id === RETURN_REQUEST_TYPE_ID
                  ? form.getFieldValue("patientToUnit").split("|")[1]
                  : form.getFieldValue("patientToUnit")
              }`
            )
            .then((res: any) => {
              if (res?.data?.data) {
                const allowedItemIds = res.data.data?.map(
                  (item: any) => item.id
                );
                const selectedItemIds = tempCart.map(
                  (item: any) => item?.item?.id
                );
                const notAllowedItemIds: any = [];
                selectedItemIds.forEach((itemId: any) => {
                  if (!allowedItemIds.includes(itemId)) {
                    notAllowedItemIds.push(itemId);
                  }
                });
                if (notAllowedItemIds.length > 0) {
                  setItemsToBeRemoved([...notAllowedItemIds]);
                  setIsRemoveItem(true);
                  isInvalidItem = true;
                }
              }
            })
            .catch((err: any) => {
              message.error(err?.message);
            });
        }
      }

      if (isInvalidItem) return;
      const patientTransportPayload = {
        isPatientTransportRequest: true,
        cartItem: body.items,
        unitId: body.patientFromUnit,
        patientName: body.patientName?.trim(),
        room: body.patientRoom,
        externalUserId: request?.user?.id,
        timeLimit: moment(body.time)
          .seconds(0)
          .diff(moment().seconds(0), "minutes"),
        expiry: moment(body.time).seconds(0).toISOString(),
        patientDob: moment(body.patientDob).format("YYYY-MM-DD"),
        destinationUnitId:
          request.requestType.id === DISCHARGE_REQUEST_TYPE_ID
            ? ""
            : request.requestType.id === RETURN_REQUEST_TYPE_ID
            ? body.patientToUnit.split("|")[1]
            : body.patientToUnit,
        dischargeLocationId:
          request.requestType.id === DISCHARGE_REQUEST_TYPE_ID
            ? body.patientToUnit
            : "",
        additionalInstruction: body.additionalInstruction?.trim(),
        pickUpOrderId:
          request.requestType.id === RETURN_REQUEST_TYPE_ID
            ? body.patientToUnit.split("|")[0]
            : "",
        nurseName: body.patientUserName?.trim(),
        nursePhoneNumber: body.patientUserPhone,
        nurseEmail: body.patientUserEmail?.trim(),
        orderPriority:  request.requestType.id === DISCHARGE_REQUEST_TYPE_ID ?
        transportDetails.dischargePriority  :
        request.requestType.id === TRANSFER_REQUEST_TYPE_ID  ?
        transportDetails.transferPriority : transportDetails.procedurePriority
      };
      const res = await editRequest(request.requestId, patientTransportPayload);
      if (res.success) {
        setSelectedRequest(res.updatedRequest);
        setEditMode(false);
        setEditTime(false);
      }
      scroll();
    } else {
      const res = await editRequest(request.requestId, body);
      if (res.success) {
        setSelectedRequest(res.updatedRequest);
        setEditMode(false);
      }
      scroll();
    }
  };

  const quantityEdit = (
    itemId: any,
    action: "DECREASE" | "INCREASE" | "EDIT",
    quantity?: number
  ) => {
    const items = tempCart.map((cartItem: any) => {
      const getPatientsCond =
        request.requestType.id === DELIVERY_REQUEST_TYPE_ID &&
        (cartItem.item.specialtyItem ||
          cartItem.item.specialityItem ||
          cartItem.isSpecialtyItem);
      if (cartItem.item.id === itemId) {
        if (action === "INCREASE") {
          if (getPatientsCond) {
            setShowPatientModal({ id: itemId, mode: PATIENTMODAL_SINGLE_NEW });
          } else cartItem.quantity += 1;
        } else if (action === "EDIT") {
          cartItem.quantity = quantity;
        } else {
          if (getPatientsCond) {
            cartItem?.patients?.length &&
              setShowPatientModal({
                id: itemId,
                mode: PATIENTMODAL_SINGLE_REMOVE,
              });
          } else cartItem.quantity -= 1;
        }
      }
      return cartItem;
    });
    setTempCart(items);
  };

  const onItemsSelected = (items: any) => {
    setTempCart(items);
    setAddItemModalVisible(false);
  };

  const onCampusChange = (campusId: any) => {
    form.setFieldsValue({ unitId: "" });
    setSelectedUnitId("");
    fetchUnits(campusId);
    form.setFieldsValue({ slaId: "" });
    fetchReqSlas(request.requestType.id, campusId);
    determineIsDisabled();
  };

  const onEditCancel = () => {
    fetchUnits(request.campusId);
    fetchReqSlas(request.requestType.id, request.campusId);
    form.resetFields();
    dispatch({ type: CLEAR_MESSAGES });
    setEditMode(false);
  };

  const onUnitChange = (unitId: any) => {
    const campusId = form.getFieldsValue()?.campusId;
    if (tempCart.length !== 0) {
      const unitSpecificItem = tempCart.filter(({ item }: any) => {
        const allUnitsMapped = item?.unitIds?.includes(1);
        const allCampusesMapped = item?.campusIds?.includes(1);
        if (allUnitsMapped && allCampusesMapped) {
          return false;
        } else if (
          item?.campusIds?.includes(campusId) &&
          (item?.unitIds?.includes(unitId) || allUnitsMapped)
        ) {
          return false;
        } else if (allCampusesMapped && item?.unitIds?.includes(unitId)) {
          return false;
        } else return true;
      });
      if (unitSpecificItem.length > 0) {
        Modal.confirm({
          title: "Confirm",
          icon: <ExclamationCircleOutlined />,
          className: "unitSpecificModal",
          content:
            "Your Cart has some items that are specific to previous unit. It will be removed by the current selection of unit. Do you want to continue?",
          okText: "Yes",
          cancelText: "No",
          onOk: () => {
            form.setFieldsValue({ unitId });
            setSelectedUnitId(unitId);
            const removeIds = unitSpecificItem.map(
              (cartItem: any) => cartItem.item.id
            );
            let itemRemoving = [...tempCart];
            itemRemoving = itemRemoving.filter(
              (cartItem) => !removeIds.includes(cartItem.item.id)
            );
            setTempCart(itemRemoving);
          },
          onCancel: () => {
            form.setFieldsValue({ unitId: selectedUnitId });
          },
        });
      } else {
        setSelectedUnitId(unitId);
        form.setFieldsValue({ unitId });
      }
    } else {
      setSelectedUnitId(unitId);
      form.setFieldsValue({ unitId });
    }
    determineIsDisabled();
  };

  const onAddNewClick = async () => {
    const catalogueId = getCatalogueIdByRequestType(request.requestType.id);
    await fetchItems(
      form.getFieldValue("unitId"),
      catalogueId,
      isPatientTransportRequest
        ? form.getFieldValue("patientCampus")
        : form.getFieldValue("campusId"),
      form.getFieldValue("patientFromUnit"),
      request.requestType.id === DISCHARGE_REQUEST_TYPE_ID
        ? 1
        : request.requestType.id === RETURN_REQUEST_TYPE_ID
        ? form.getFieldValue("patientToUnit").split("|")[1]
        : form.getFieldValue("patientToUnit"),
      isPatientTransportRequest
    );
    setAddItemModalVisible(true);
  };

  const closeModal = () => {
    setAddItemModalVisible(false);
  };
  let patientDetailsPresent =
    tempCart.reduce(
      (acc: any, item: any) => (item.patients ? acc + 1 : acc),
      0
    ) > 0;
  //Show SLA Disclaimer on SLA change - if it has any
  const onPriorityChange = (slaId: any) => {
    const sla = slas.find((ele: any) => ele.id === slaId);
    if (sla.disclaimerStatus) {
      info(sla.disclaimerInfo);
    }
  };
  const [showInfo, setShowInfo] = useState("");
  const info = (data: any) => {
    setShowInfo(data);
  };

  //on campus value change, fetch data for units dropdown
  const onPatientCampusChange = (campusId: any) => {
    getTransportDetails(campusId);
    form.setFieldsValue({ patientFromUnit: "" });
    form.setFieldsValue({ patientToUnit: "" });
    form.setFieldsValue({ time: "" });
    fetchUnits(campusId);
    determineIsDisabled();
  };

  const onPatientFromUnitChange = async (unitId: any) => {
    if (request.requestType.id === RETURN_REQUEST_TYPE_ID) {
      form.setFieldValue("patientToUnit", "");
      await editPatients(unitId, request?.id);
    }
    determineIsDisabled();
  };

  const onPatientToUnitChange = async (unitId: any) => {
    if (request.requestType.id === RETURN_REQUEST_TYPE_ID) {
      form.setFieldValue("patientName", unitId.split("|")[2]);
      form.setFieldValue("patientDob", moment(unitId.split("|")[3]));
    }
    determineIsDisabled();
  };

  const onDateChange: DatePickerProps["onChange"] = (dateString) => {
    form.setFieldsValue({ patientDob: dateString });
  };

  const onDisabledDate = (current: any) => {
    return current && current > moment().seconds(0).endOf("day");
  };

  const isPatientTransportRequest =
    request.requestType.id === PROCEDURE_REQUEST_TYPE_ID ||
    request.requestType.id === RETURN_REQUEST_TYPE_ID ||
    request.requestType.id === DISCHARGE_REQUEST_TYPE_ID ||
    request.requestType.id === TRANSFER_REQUEST_TYPE_ID;

  const determineIsDisabled = () => {
    if (isPatientTransportRequest) {
      const patientCampus = form.getFieldValue("patientCampus");
      const patientFromUnit = form.getFieldValue("patientFromUnit");
      const patientToUnit = form.getFieldValue("patientToUnit");
      setIsDisabled(!(patientCampus && patientFromUnit && patientToUnit));
    } else {
      const unitId = form.getFieldValue("unitId");
      setIsDisabled(!(unitId && unitId !== ""));
    }
  };

  // Disable dates before today
  const disabledDate = (current: any) => {
    return (
      current &&
      (current < moment().seconds(0).startOf("day") ||
        current > moment().seconds(0).add(23, "hours").endOf("day"))
    );
  };

  // Disable times before now + transportDetails?.timeLeft for the current date and times after 23 hours for the next day
  const disabledTime = (current: any) => {
    if (!current) {
      current = moment().seconds(0);
    }

    const isToday = current.isSame(moment().seconds(0), "day");
    const isTomorrow = current.isSame(
      moment().seconds(0).add(23, "hours"),
      "day"
    );

    if (isToday) {
      const currentPlusTimeLeft = moment()
        .seconds(0)
        .add(Number(requestTimeLeft), "minutes");
      return {
        disabledHours: () =>
          range(0, moment(currentPlusTimeLeft).seconds(0).hour()),
        disabledMinutes: () =>
          current.hour() === moment(currentPlusTimeLeft).seconds(0).hour()
            ? range(0, moment(currentPlusTimeLeft).seconds(0).minute() + 1)
            : [],
      };
    }

    if (isTomorrow) {
      return {
        disabledHours: () =>
          range(moment().seconds(0).add(23, "hours").hour() + 1, 24),
        disabledMinutes: () =>
          current.hour() === moment().seconds(0).add(23, "hours").hour()
            ? range(moment().seconds(0).add(23, "hours").minute() + 1, 60)
            : [],
      };
    }

    return {};
  };

  // Helper function to create a range of numbers
  const range = (start: any, end: any) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  return (
    <>
      <div
        id="alert"
        style={{ width: "100%", minHeight: "1px", marginBottom: "10px" }}
      >
        {successMsg ? (
          <Alert
            className={`fadeIn ${classes.alertContainer}`}
            message={successMsg}
            type="success"
            closable
            afterClose={clearMessages}
          />
        ) : null}
        {errorMsg ? (
          <Alert
            className={`fadeIn ${classes.alertContainer}`}
            message={errorMsg}
            type="error"
            closable
            afterClose={clearMessages}
          />
        ) : null}
      </div>
      <Form
        form={form}
        onFinish={onUpdateReq}
        initialValues={{ ...request, patientDob: moment(request.patientDob) }}
      >
        <Row className={classes.requestContainer}>
          <Row className={classes.nonEditableRow}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={7}
              xl={7}
              xxl={7}
              className={classes.reqId}
            >
              <span>Request ID : {request.requestId}</span>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={7}
              xl={7}
              xxl={7}
              className={classes.dateAndTime}
            >
              <span>
                Date:{" "}
                {process.env.REACT_APP_SSO_ENABLED
                  ? getFormattedDate(request.date)
                  : getESTFromUTC(request.date)}
              </span>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={7}
              xl={7}
              xxl={7}
              className={classes.status}
            >
              <span>
                Status: {request.orderExpired ? "EXPIRED" : request.status}
              </span>
            </Col>
            {request.cancelReason && (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={24}
                xl={24}
                xxl={24}
                className={classes.reasonParent}
              >
                Cancellation Reason:{" "}
                <span className={classes.reason}>{request.cancelReason}</span>
              </Col>
            )}
          </Row>
          <Row className={classes.requestDetailCardRow}>
            <Col
              span={24}
              style={{
                cursor: disableEditSection ? "not-allowed" : "default",
              }}
              title={disabledSectionTitle}
            >
              {isPatientTransportRequest ? (
                <Card
                  title="Location Info"
                  className={`${classes.requestDetailCard} ${
                    disableEditSection && classes.disableEvents
                  }`}
                >
                  <Row className={""}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        name="patientRequestType"
                        label={
                          <span
                            style={{
                              whiteSpace: "normal",
                            }}
                          >
                            Request type
                          </span>
                        }
                        wrapperCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 15,
                          xl: 14,
                          xxl: 13,
                        }}
                        labelCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 9,
                          xl: 10,
                          xxl: 11,
                        }}
                        labelAlign="left"
                      >
                        <span className={classes.formValue}>
                          {request?.requestType?.name}
                        </span>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        name="patientCampus"
                        label={
                          <span
                            style={{
                              whiteSpace: "normal",
                            }}
                          >
                            Campus name
                          </span>
                        }
                        initialValue={request.campus?.id}
                        rules={[
                          () => ({
                            required: true,
                            message: "Please select a campus.",
                          }),
                        ]}
                        wrapperCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 15,
                          xl: 14,
                          xxl: 13,
                        }}
                        labelCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 9,
                          xl: 10,
                          xxl: 11,
                        }}
                        labelAlign="left"
                      >
                        {editMode ? (
                          <Select
                            onChange={onPatientCampusChange}
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {allCampuses.map((campus: any) => {
                              return (
                                <Option value={campus.id}>{campus.name}</Option>
                              );
                            })}
                          </Select>
                        ) : (
                          <span className={classes.formValue}>
                            {request?.campus?.name}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className={""}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        name="patientFromUnit"
                        label="From unit"
                        initialValue={request.unitId}
                        rules={[
                          () => ({
                            required: true,
                            message: "Please select a unit.",
                          }),
                        ]}
                        wrapperCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 15,
                          xl: 14,
                          xxl: 13,
                        }}
                        labelCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 9,
                          xl: 10,
                          xxl: 11,
                        }}
                        labelAlign="left"
                      >
                        {editMode ? (
                          <Select
                            placeholder="Enter unit name"
                            loading={loading}
                            onChange={(val, e: any) => {
                              onPatientFromUnitChange(val);
                            }}
                            showSearch
                            filterOption={(input: any, option: any) =>
                              option?.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {loading ? (
                              <Select.Option value={request.unitId}>
                                {request.unit?.name}
                              </Select.Option>
                            ) : request.requestType.id ===
                              RETURN_REQUEST_TYPE_ID ? (
                              units
                                ?.filter(
                                  (filterUnit: any) =>
                                    filterUnit?.proceduralUnit &&
                                    filterUnit?.id !== patientToUnit
                                )
                                ?.map((unit: any, index: Number) => (
                                  <Select.Option
                                    value={unit.id}
                                    key={"patientFromUnit" + index}
                                  >
                                    {unit.name}
                                  </Select.Option>
                                ))
                            ) : request.requestType.id ===
                                TRANSFER_REQUEST_TYPE_ID ||
                              request.requestType.id ===
                                PROCEDURE_REQUEST_TYPE_ID ? (
                              units
                                ?.filter(
                                  (filterUnit: any) =>
                                    filterUnit?.id !== patientToUnit
                                )
                                ?.map((unit: any, index: Number) => (
                                  <Select.Option
                                    value={unit.id}
                                    key={"patientFromUnit" + index}
                                  >
                                    {unit.name}
                                  </Select.Option>
                                ))
                            ) : (
                              units?.map((unit: any, index: Number) => (
                                <Select.Option
                                  value={unit.id}
                                  key={"patientFromUnit" + index}
                                >
                                  {unit.name}
                                </Select.Option>
                              ))
                            )}
                          </Select>
                        ) : (
                          <span className={classes.formValue}>
                            {request?.unit?.name}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        name="patientToUnit"
                        label="To unit"
                        initialValue={
                          request.requestType.id === RETURN_REQUEST_TYPE_ID
                            ? `${request.parentOrderDetails.id}|${request.parentOrderDetails?.unitId}|${request.parentOrderDetails?.patientName}|${request.parentOrderDetails?.patientDob}`
                            : request.requestType.id ===
                              DISCHARGE_REQUEST_TYPE_ID
                            ? request.dischargeLocation?.id
                            : request.destinationUnit?.id
                        }
                        rules={[
                          () => ({
                            required: true,
                            message: "Please select to unit.",
                          }),
                        ]}
                        wrapperCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 15,
                          xl: 14,
                          xxl: 13,
                        }}
                        labelCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 9,
                          xl: 10,
                          xxl: 11,
                        }}
                        labelAlign="left"
                      >
                        {editMode ? (
                          request.requestType.id !==
                          DISCHARGE_REQUEST_TYPE_ID ? (
                            <Select
                              placeholder="Enter unit name"
                              loading={loading}
                              onChange={(val, e: any) => {
                                onPatientToUnitChange(val);
                              }}
                              filterOption={(input: any, option: any) =>
                                option?.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                              showSearch
                              optionFilterProp="children"
                            >
                              {loading ? (
                                <Option value={request.destinationUnit?.id}>
                                  {request.destinationUnit?.name}
                                </Option>
                              ) : request.requestType.id ===
                                PROCEDURE_REQUEST_TYPE_ID ? (
                                units
                                  ?.filter(
                                    (filterUnit: any) =>
                                      filterUnit?.proceduralUnit &&
                                      filterUnit?.id !== patientFromUnit
                                  )
                                  ?.map((unit: any, index: Number) => (
                                    <Option
                                      value={unit.id}
                                      key={"patientToUnit" + index}
                                    >
                                      {unit.name}
                                    </Option>
                                  ))
                              ) : request.requestType.id ===
                                TRANSFER_REQUEST_TYPE_ID ? (
                                units
                                  ?.filter(
                                    (filterUnit: any) =>
                                      filterUnit?.id !== patientFromUnit
                                  )
                                  ?.map((unit: any, index: Number) => (
                                    <Option
                                      value={unit.id}
                                      key={"patientToUnit" + index}
                                    >
                                      {unit.name}
                                    </Option>
                                  ))
                              ) : (
                                patients?.map((unit: any, index: Number) => {
                                  return (
                                    <Option
                                      value={`${unit.id}|${unit?.unitId?.id}|${unit?.patientName}|${unit?.patientDob}`}
                                      key={"patientToUnit" + index}
                                    >
                                      {`${unit?.patientName} | ${unit?.unitId?.name}`}
                                    </Option>
                                  );
                                })
                              )}
                            </Select>
                          ) : (
                            <Select
                              id="dischargeIdSelect"
                              placeholder="Enter unit name"
                              loading={loading}
                              filterOption={(input: any, option: any) =>
                                option?.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                              showSearch
                              optionFilterProp="children"
                            >
                              {loading ? (
                                <Option value={request.dischargeLocation?.id}>
                                  {request.dischargeLocation?.name}
                                </Option>
                              ) : (
                                dischargeLocations?.map(
                                  (unit: any, index: Number) => (
                                    <Option
                                      value={unit.id}
                                      key={"patientToUnit" + index}
                                    >
                                      {unit.name}
                                    </Option>
                                  )
                                )
                              )}
                            </Select>
                          )
                        ) : (
                          <span className={classes.formValue}>
                            {request?.requestType?.id ===
                            DISCHARGE_REQUEST_TYPE_ID
                              ? request?.dischargeLocation?.name
                              : request?.destinationUnit?.name}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className={""}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        name="patientRoom"
                        initialValue={request.room}
                        rules={[
                          () => ({
                            required: true,
                            validator: fromRoomNameValidator,
                          }),
                        ]}
                        label={
                          <span
                            style={{
                              whiteSpace: "normal",
                            }}
                          >
                            From room
                          </span>
                        }
                        wrapperCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 15,
                          xl: 14,
                          xxl: 13,
                        }}
                        labelCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 9,
                          xl: 10,
                          xxl: 11,
                        }}
                        labelAlign="left"
                      >
                        {editMode ? (
                          <Input />
                        ) : (
                          <span className={classes.formValue}>
                            {request.room}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              ) : (
                <Card
                  title="Location Info"
                  className={`${classes.requestDetailCard} ${
                    disableEditSection && classes.disableEvents
                  }`}
                >
                  <Row className={""}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        label={
                          <span
                            style={{
                              whiteSpace: "normal",
                            }}
                          >
                            Campus name
                          </span>
                        }
                        name="campusId"
                        wrapperCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 15,
                          xl: 14,
                          xxl: 13,
                        }}
                        labelCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 9,
                          xl: 10,
                          xxl: 11,
                        }}
                        labelAlign="left"
                        rules={[
                          {
                            required: true,
                            message: "Please select the Campus!",
                          },
                        ]}
                      >
                        {editMode ? (
                          <Select
                            onChange={onCampusChange}
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {allCampuses.map((campus: any) => {
                              return (
                                <Option value={campus.id}>{campus.name}</Option>
                              );
                            })}
                          </Select>
                        ) : (
                          <span className={classes.formValue}>
                            {request?.campus?.name}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        label={
                          <span
                            style={{
                              whiteSpace: "normal",
                            }}
                          >
                            Room No/Bedspace
                          </span>
                        }
                        name="room"
                        wrapperCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 15,
                          xl: 14,
                          xxl: 13,
                        }}
                        labelCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 9,
                          xl: 10,
                          xxl: 11,
                        }}
                        labelAlign="left"
                        rules={[
                          () => ({
                            required: true,
                            validator: locationRoomNameValidator,
                          }),
                        ]}
                      >
                        {editMode ? (
                          <Input />
                        ) : (
                          <span className={classes.formValue}>
                            {request.room}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className={""}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        label="Unit name"
                        name="unitId"
                        className={classes.noMargin}
                        wrapperCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 15,
                          xl: 14,
                          xxl: 13,
                        }}
                        labelCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 9,
                          xl: 10,
                          xxl: 11,
                        }}
                        labelAlign="left"
                        rules={[
                          {
                            required: true,
                            message: "Please select the Unit!",
                          },
                        ]}
                      >
                        {editMode ? (
                          <Select
                            onChange={onUnitChange}
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {units.map((unit: any) => {
                              return (
                                <Option value={unit.id}>{unit.name}</Option>
                              );
                            })}
                          </Select>
                        ) : (
                          <span className={classes.formValue}>
                            {request?.unit?.name}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        label="SLA"
                        name="slaId"
                        className={classes.noMargin}
                        wrapperCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 15,
                          xl: 14,
                          xxl: 13,
                        }}
                        labelCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 9,
                          xl: 10,
                          xxl: 11,
                        }}
                        labelAlign="left"
                        rules={[
                          () => ({
                            required: true,
                            message: "Please Select a SLA!",
                          }),
                        ]}
                      >
                        {editMode ? (
                          <Select
                            onChange={onPriorityChange}
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {slas.map((sla: any) => {
                              return <Option value={sla.id}>{sla.name}</Option>;
                            })}
                          </Select>
                        ) : (
                          <span className={classes.formValue}>
                            {request?.sla?.name}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              )}
            </Col>
            <Col span={24}>
              {isPatientTransportRequest ? (
                <Card
                  title="Contact Info"
                  className={classes.requestDetailCard}
                >
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        label="Name"
                        name="patientUserName"
                        initialValue={request.user.name}
                        rules={[
                          () => ({
                            required: true,
                            validator: contactNameValidator,
                          }),
                        ]}
                        wrapperCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 15,
                          xl: 14,
                          xxl: 13,
                        }}
                        labelCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 9,
                          xl: 10,
                          xxl: 11,
                        }}
                        labelAlign="left"
                      >
                        <span className={classes.formValue}>
                          {request.user.name}
                        </span>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        label="Email"
                        name="patientUserEmail"
                        initialValue={request.user.email}
                        rules={[
                          {
                            required: false,
                            validator: emailValidator,
                            message: "Please enter valid email.",
                            type: "email",
                          },
                        ]}
                        wrapperCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 15,
                          xl: 14,
                          xxl: 13,
                        }}
                        labelCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 9,
                          xl: 10,
                          xxl: 11,
                        }}
                        labelAlign="left"
                      >
                        <span className={classes.formValue}>
                          {request.user.email ? request.user.email : "-"}
                        </span>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        label="Phone"
                        name="patientUserPhone"
                        initialValue={request.user.phone}
                        rules={[
                          {
                            required: true,
                            validator: phoneNumberValidator,
                          },
                        ]}
                        wrapperCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 15,
                          xl: 14,
                          xxl: 13,
                        }}
                        labelCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 9,
                          xl: 10,
                          xxl: 11,
                        }}
                        labelAlign="left"
                        className={classes.noMargin}
                      >
                        <span className={classes.formValue}>
                          {request.user.phone}
                        </span>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        label="Patient name"
                        name="patientName"
                        rules={[
                          {
                            required: true,
                            validator: patientNameValidator,
                          },
                        ]}
                        wrapperCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 15,
                          xl: 14,
                          xxl: 13,
                        }}
                        labelCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 9,
                          xl: 10,
                          xxl: 11,
                        }}
                        labelAlign="left"
                      >
                        {editMode ? (
                          <Input
                            disabled={
                              request.requestType.id === RETURN_REQUEST_TYPE_ID
                            }
                          />
                        ) : (
                          <span className={classes.formValue}>
                            {request.patientName}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        label="Patient dob"
                        name="patientDob"
                        rules={[
                          {
                            required: true,
                            message: "Please select the Date Of Birth!",
                          },
                        ]}
                        wrapperCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 15,
                          xl: 14,
                          xxl: 13,
                        }}
                        labelCol={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 9,
                          xl: 10,
                          xxl: 11,
                        }}
                        labelAlign="left"
                      >
                        {editMode ? (
                          <DatePicker
                            getPopupContainer={(trigger): any =>
                              trigger.parentNode
                            }
                            placeholder="e.g. 12-27-2020"
                            format={"MM-DD-YYYY"}
                            className={classes.PatientDob}
                            onChange={onDateChange}
                            disabledDate={onDisabledDate}
                            disabled={
                              request.requestType.id === RETURN_REQUEST_TYPE_ID
                            }
                          />
                        ) : (
                          <span className={classes.formValue}>
                            {request.patientDob}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              ) : (
                <Card
                  title="Contact Info"
                  className={classes.requestDetailCard}
                >
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        label="Name"
                        wrapperCol={{
                          xs: 14,
                          sm: 14,
                          md: 14,
                          lg: 17,
                          xl: 20,
                          xxl: 15,
                        }}
                        labelCol={{
                          xs: 10,
                          sm: 10,
                          md: 10,
                          lg: 7,
                          xl: 4,
                          xxl: 9,
                        }}
                        labelAlign="left"
                      >
                        <span className={classes.formValue}>
                          {request.user.name}
                        </span>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        label="Email"
                        wrapperCol={{
                          xs: 14,
                          sm: 14,
                          md: 14,
                          lg: 17,
                          xl: 14,
                          xxl: 15,
                        }}
                        labelCol={{
                          xs: 10,
                          sm: 10,
                          md: 10,
                          lg: 7,
                          xl: 10,
                          xxl: 9,
                        }}
                        labelAlign="left"
                      >
                        <span className={classes.formValue}>
                          {request.user.email ? request.user.email : "-"}
                        </span>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        label="Phone"
                        wrapperCol={{
                          xs: 14,
                          sm: 14,
                          md: 14,
                          lg: 17,
                          xl: 20,
                          xxl: 15,
                        }}
                        labelCol={{
                          xs: 10,
                          sm: 10,
                          md: 10,
                          lg: 7,
                          xl: 4,
                          xxl: 9,
                        }}
                        labelAlign="left"
                        className={classes.noMargin}
                      >
                        <span className={classes.formValue}>
                          {request.user.phone}
                        </span>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Form.Item
                        label="Short description"
                        name="additionalNotes"
                        className={`${classes.noMargin} autoScroll`}
                        wrapperCol={{
                          xs: 14,
                          sm: 14,
                          md: 14,
                          lg: 17,
                          xl: 14,
                          xxl: 15,
                        }}
                        labelCol={{
                          xs: 10,
                          sm: 10,
                          md: 10,
                          lg: 7,
                          xl: 10,
                          xxl: 9,
                        }}
                        labelAlign="left"
                        style={{ minHeight: 45 }}
                        rules={[
                          {
                            validator: shortDescriptionValidator,
                            required: false,
                          },
                        ]}
                      >
                        {editMode ? (
                          <Input />
                        ) : (
                          <span className={classes.formValue}>
                            {request.additionalNotes
                              ? request.additionalNotes
                              : "-"}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              )}
            </Col>
          </Row>
          <Row
            style={{
              width: "100%",
              marginBottom: "25px",
            }}
          >
            <Col span={24}>
              {isPatientTransportRequest && (
                <Card
                  title="Request Info"
                  className={classes.requestDetailCard}
                >
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item
                        label="Time patient needs to be at destination"
                        name="time"
                        initialValue={moment(request.expiry)
                          .seconds(0)
                          .format("MM-DD-YYYY HH:mm")}
                        required={true}
                        rules={[
                          () => ({
                            validator(rule, value) {
                              if (!value) {
                                return Promise.reject(`Time is required!`);
                              }
                              if (
                                editTime &&
                                !moment(value)
                                  .seconds(0)
                                  .isBetween(
                                    moment()
                                      .seconds(0)
                                      .add(Number(requestTimeLeft), "minutes"),
                                    moment().seconds(0).add(23, "hours")
                                  )
                              ) {
                                return Promise.reject(
                                  `Time must be greater than ${moment()
                                    .seconds(0)
                                    .add(Number(requestTimeLeft), "minutes")
                                    .format(
                                      "MM-DD-YYYY HH:mm"
                                    )} and less than or equal to ${moment()
                                    .seconds(0)
                                    .add(23, "hours")
                                    .format("MM-DD-YYYY HH:mm")}!`
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                        wrapperCol={{
                          xs: 18,
                          sm: 18,
                          md: 18,
                          lg: 18,
                          xl: 18,
                          xxl: 18,
                        }}
                        labelCol={{
                          xs: 6,
                          sm: 6,
                          md: 6,
                          lg: 6,
                          xl: 6,
                          xxl: 6,
                        }}
                        labelAlign="left"
                      >
                        {editMode ? (
                          <Row
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {!editTime && (
                              <>
                                <Col xs={7} sm={7} md={7} lg={7} xl={7} xxl={7}>
                                  <span className={classes.formValue}>
                                    {moment(request.expiry)
                                      .seconds(0)
                                      .format("MM-DD-YYYY HH:mm")}
                                  </span>
                                </Col>
                                <Col
                                  xs={1}
                                  sm={1}
                                  md={1}
                                  lg={1}
                                  xl={1}
                                  xxl={1}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FormOutlined
                                    style={{
                                      color: BUTTON_GREEN_HEX,
                                      fontSize: "18px",
                                      cursor: "pointer",
                                    }}
                                    className="edit-icon"
                                    onClick={() => {
                                      setEditTime(true);
                                      form.setFieldValue("time", "");
                                    }}
                                  />
                                </Col>
                              </>
                            )}
                            {editTime && (
                              <>
                                <Col
                                  xs={23}
                                  sm={23}
                                  md={23}
                                  lg={23}
                                  xl={23}
                                  xxl={23}
                                >
                                  <DatePicker
                                    getPopupContainer={(trigger): any =>
                                      trigger.parentNode
                                    }
                                    placeholder="Select date time (e.g. 12-27-2020 13:00)"
                                    className="fullWidth"
                                    showTime
                                    format="MM-DD-YYYY HH:mm"
                                    disabledDate={disabledDate}
                                    showNow={false}
                                    disabledTime={disabledTime}
                                    onChange={(time) =>
                                      form.setFieldValue(
                                        "time",
                                        moment(time).seconds(0)
                                      )
                                    }
                                    onBlur={(e) => {
                                      if (
                                        e.target.value &&
                                        moment(e.target.value).isValid()
                                      ) {
                                        form.setFieldValue(
                                          "time",
                                          moment(e.target.value).seconds(0)
                                        );
                                        if (
                                          editTime &&
                                          moment(e.target.value)
                                            .seconds(0)
                                            .isBetween(
                                              moment()
                                                .seconds(0)
                                                .add(
                                                  Number(
                                                    requestTimeLeft
                                                  ),
                                                  "minutes"
                                                ),
                                              moment()
                                                .seconds(0)
                                                .add(23, "hours")
                                            )
                                        ) {
                                          form.setFields([
                                            {
                                              name: "time",
                                              errors: [],
                                            },
                                          ]);
                                        }
                                      }
                                    }}
                                  />
                                </Col>
                                <Col
                                  xs={1}
                                  sm={1}
                                  md={1}
                                  lg={1}
                                  xl={1}
                                  xxl={1}
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <CloseCircleOutlined
                                    style={{
                                      color: BUTTON_GREEN_HEX,
                                      fontSize: "20px",
                                      cursor: "pointer",
                                    }}
                                    className="delete-icon"
                                    onClick={() => {
                                      form.setFields([
                                        {
                                          name: "time",
                                          errors: [],
                                        },
                                      ]);
                                      form.setFieldValue(
                                        "time",
                                        moment(request.expiry)
                                          .seconds(0)
                                          .format("MM-DD-YYYY HH:mm")
                                      );
                                      setEditTime(false);
                                    }}
                                  />
                                </Col>
                              </>
                            )}
                          </Row>
                        ) : (
                          <span className={classes.formValue}>
                            {moment(request.expiry)
                              .seconds(0)
                              .format("MM-DD-YYYY HH:mm")}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item
                        label="Short description"
                        name="additionalInstruction"
                        initialValue={request.additionalNotes}
                        rules={[
                          {
                            validator: shortDescriptionValidator,
                            required: false,
                          },
                        ]}
                        wrapperCol={{
                          xs: 18,
                          sm: 18,
                          md: 18,
                          lg: 18,
                          xl: 18,
                          xxl: 18,
                        }}
                        labelCol={{
                          xs: 6,
                          sm: 6,
                          md: 6,
                          lg: 6,
                          xl: 6,
                          xxl: 6,
                        }}
                        labelAlign="left"
                      >
                        {editMode ? (
                          <Input.TextArea
                            autoSize={{ minRows: 1, maxRows: 20 }}
                          />
                        ) : (
                          <span className={classes.formValue}>
                            {request.additionalNotes
                              ? request.additionalNotes
                              : "-"}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              )}
            </Col>
          </Row>
          <Row
            style={{
              width: "100%",
              cursor: disableEditSection ? "not-allowed" : "default",
            }}
            title={disabledSectionTitle}
          >
            <Col
              span={24}
              style={{
                width: "100%",
              }}
              className={`${disableEditSection && classes.disableEvents}`}
            >
              <Card
                title="Items"
                style={{ width: "100%" }}
                bodyStyle={{ width: "100%" }}
                extra={
                  editMode ? (
                    <Button
                      disabled={isDisabled}
                      onClick={() => onAddNewClick()}
                      className="nextBtn"
                    >
                      Add New Item
                    </Button>
                  ) : null
                }
              >
                {
                  <div
                    className={`${classes.requestList} ${
                      editMode ? "" : classes.view
                    }`}
                  >
                    {editMode
                      ? tempCart.map((cart: any, index: any) => {
                          const hasOrderSplFlag =
                            Object.keys(cart).includes("isSpecialtyItem");
                          const sizeName = cart?.item?.size?.name;
                          return (
                            <div className={classes.requestGrid}>
                              <div className={classes.requestGridImg}>
                                <div className={classes.itemImageWrapper}>
                                  <img
                                    src={cart?.item?.image}
                                    alt="operation-bed"
                                  />
                                  <div
                                    className={`imageOverlay_100 decrement`}
                                    onClick={() => {
                                      cart.quantity === 1
                                        ? onItemRemove(cart.item.id)
                                        : quantityEdit(
                                            cart.item.id,
                                            "DECREASE"
                                          );
                                    }}
                                  >
                                    -
                                  </div>
                                  <div
                                    className={`imageOverlay_100 increment`}
                                    onClick={() => {
                                      quantityEdit(cart.item.id, "INCREASE");
                                    }}
                                  >
                                    +
                                  </div>
                                  {cart?.item?.delay &&
                                    !(
                                      request.requestType.id ===
                                      PICKUP_REQUEST_TYPE_ID
                                    ) && (
                                      <div
                                        className={classes.delay}
                                        title="Delay"
                                      >
                                        *
                                      </div>
                                    )}
                                  {(hasOrderSplFlag
                                    ? cart?.isSpecialtyItem
                                    : cart?.item?.specialtyItem) &&
                                    request.requestType.id ===
                                      DELIVERY_REQUEST_TYPE_ID && (
                                      <div
                                        className={classes.specialty}
                                        title={SPECIALTY_HOVER_TITLE}
                                      >
                                        *
                                      </div>
                                    )}
                                </div>
                                <Tooltip title={cart?.item?.name}>
                                  <div className={classes.itemName}>
                                    {cart?.item?.name}
                                  </div>
                                </Tooltip>
                                {sizeName && (
                                  <Tooltip title={sizeName}>
                                    {request.requestType.id ===
                                      SHARPS_REQUEST_TYPE_ID && (
                                      <div className={classes.itemName}>
                                        <b>Size:</b> {sizeName}
                                      </div>
                                    )}
                                  </Tooltip>
                                )}
                              </div>
                              <div className={classes.requestQty}>
                                <p>Qty</p>
                                <div className={classes.requestCount}>
                                  {editMode && (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        cart.quantity === 1
                                          ? onItemRemove(cart.item.id)
                                          : quantityEdit(
                                              cart.item.id,
                                              "DECREASE"
                                            );
                                      }}
                                      className={classes.minus}
                                    ></button>
                                  )}
                                  {editMode ? (
                                    <InputNumber
                                      value={cart.quantity}
                                      onChange={(quant) => {
                                        quantityEdit(
                                          cart.item.id,
                                          "EDIT",
                                          quant
                                        );
                                      }}
                                      disabled={
                                        !!(
                                          (hasOrderSplFlag
                                            ? cart?.isSpecialtyItem
                                            : cart.item?.specialtyItem ||
                                              cart.item?.specialityItem) &&
                                          request.requestType.id ===
                                            DELIVERY_REQUEST_TYPE_ID
                                        )
                                      }
                                      title={
                                        !!(
                                          (hasOrderSplFlag
                                            ? cart?.isSpecialtyItem
                                            : cart.item?.specialtyItem ||
                                              cart.item?.specialityItem) &&
                                          request.requestType.id ===
                                            DELIVERY_REQUEST_TYPE_ID
                                        )
                                          ? SPECIALTY_INPUT_NUMBER_DISABLED_TITLE
                                          : ""
                                      }
                                      onKeyDown={allowOnlyNumbers}
                                      min={1}
                                      max={99}
                                    />
                                  ) : (
                                    <span
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "12px",
                                        fontWeight: 600,
                                        color: "#000",
                                      }}
                                    >
                                      {cart.quantity}
                                    </span>
                                  )}
                                  {editMode && (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        quantityEdit(cart.item.id, "INCREASE");
                                      }}
                                      disabled={cart.quantity >= 99}
                                      className={classes.add}
                                    ></button>
                                  )}
                                </div>
                              </div>
                              {editMode && (
                                <button
                                  onClick={() => {
                                    onItemRemove(cart.item.id);
                                  }}
                                  type="button"
                                  className={classes.close}
                                >
                                  <span>X</span>
                                </button>
                              )}
                              {cart.patients?.length ? (
                                <span
                                  className="linkLikeText"
                                  style={{ position: "relative", left: "5px" }}
                                  onClick={() =>
                                    setShowPatientModal({
                                      id: cart.item.id,
                                      mode: PATIENTMODAL_VIEW,
                                    })
                                  }
                                >
                                  {VIEW_PATIENTS_TEXT}
                                </span>
                              ) : (
                                <div
                                  className={
                                    patientDetailsPresent
                                      ? classes.emptyShowPatientBlock
                                      : ""
                                  }
                                ></div>
                              )}
                            </div>
                          );
                        })
                      : cartItems.map((cart: any, index: any) => {
                          const sizeName = cart?.item?.size?.name;
                          return (
                            <div className={classes.requestGrid}>
                              <div className={classes.requestGridImg}>
                                <img src={cart.item.image} alt="" />
                                <Tooltip title={cart.item.name}>
                                  <span className={classes.itemName}>
                                    {cart.item.name}
                                  </span>
                                </Tooltip>
                                {sizeName && (
                                  <Tooltip title={sizeName}>
                                    {request.requestType.id ===
                                      SHARPS_REQUEST_TYPE_ID && (
                                      <div className={classes.itemName}>
                                        <b>Size:</b> {sizeName}
                                      </div>
                                    )}
                                  </Tooltip>
                                )}

                                {cart?.item?.delay &&
                                  !(
                                    request.requestType.id ===
                                    PICKUP_REQUEST_TYPE_ID
                                  ) && (
                                    <div
                                      className={classes.delay}
                                      title="Delay"
                                    >
                                      *
                                    </div>
                                  )}
                                {cart?.isSpecialtyItem &&
                                  request.requestType.id ===
                                    DELIVERY_REQUEST_TYPE_ID && (
                                    <div
                                      className={classes.specialty}
                                      title={SPECIALTY_HOVER_TITLE}
                                    >
                                      *
                                    </div>
                                  )}
                              </div>
                              <div className={classes.requestQty}>
                                <p>Qty</p>
                                <div className={classes.requestCount}>
                                  {editMode && (
                                    <button
                                      onClick={(id) => {
                                        quantityEdit(cart.item.id, "DECREASE");
                                      }}
                                      className={classes.minus}
                                      disabled={
                                        cart.quantity === 1 ? true : false
                                      }
                                    ></button>
                                  )}
                                  <span
                                    style={{
                                      marginLeft: "5px",
                                      fontSize: "12px",
                                      fontWeight: 600,
                                      color: "#000",
                                    }}
                                  >
                                    {cart.quantity}
                                  </span>
                                  {editMode && (
                                    <button
                                      onClick={(id) => {
                                        quantityEdit(cart.item.id, "INCREASE");
                                      }}
                                      disabled={cart.quantity >= 99}
                                      className={classes.add}
                                    ></button>
                                  )}
                                </div>
                              </div>
                              {editMode && (
                                <button
                                  onClick={() => {
                                    onItemRemove(cart.item.id);
                                  }}
                                  className={classes.close}
                                >
                                  <span>X</span>
                                </button>
                              )}
                              {cart.patients?.length &&
                              !isPatientTransportRequest ? (
                                <span
                                  className="linkLikeText"
                                  onClick={() =>
                                    setShowPatientModal({
                                      id: cart.item.id,
                                      mode: PATIENTMODAL_VIEW,
                                    })
                                  }
                                >
                                  {VIEW_PATIENTS_TEXT}
                                </span>
                              ) : (
                                <div
                                  className={
                                    patientDetailsPresent
                                      ? classes.emptyShowPatientBlock
                                      : ""
                                  }
                                ></div>
                              )}
                            </div>
                          );
                        })}
                  </div>
                }
              </Card>
            </Col>
          </Row>
          {editMode ? (
            <Row align="middle" className={classes.modalBtns} justify="center">
              <Button onClick={() => onEditCancel()} className="backBtn">
                Cancel
              </Button>
              <Button htmlType="submit" className="nextBtn">
                Update
              </Button>
            </Row>
          ) : (
            <Row align="middle" className={classes.modalBtns} justify="center">
              {request.status !== COMPLETED ? (
                <>
                  {request.status !== CANCELLED &&
                    request.status !== OVERRIDE_CLOSE && (
                      <Button className="backBtn" onClick={onCancelClick}>
                        Cancel Request
                      </Button>
                    )}
                  {request.status === UNASSIGNED ||
                  request.status === ASSIGNED ? (
                    <Button
                      onClick={() => {
                        setEditTime(false);
                        onEditMode();
                      }}
                      className="nextBtn"
                    >
                      Edit Request
                    </Button>
                  ) : null}
                </>
              ) : null}
              {![
                PROCEDURE_REQUEST_TYPE_ID,
                RETURN_REQUEST_TYPE_ID,
                DISCHARGE_REQUEST_TYPE_ID,
                TRANSFER_REQUEST_TYPE_ID,
              ].includes(request.requestType.id) &&
              (request.status === COMPLETED ||
                request.status === OVERRIDE_CLOSE) &&
              (noSpecialityItem ||
                request.requestType.id !== DELIVERY_REQUEST_TYPE_ID) ? (
                <Button
                  onClick={() => {
                    onReOrder(request.id);
                  }}
                  className="nextBtn"
                  loading={reorderLoading}
                >
                  Reorder
                </Button>
              ) : null}
            </Row>
          )}
        </Row>
      </Form>
      {addItemModalVisible ? (
        <AddItemModal
          allItems={allItems}
          visible={addItemModalVisible}
          handleCancel={closeModal}
          onItemsSelected={onItemsSelected}
          editCart={tempCart}
          type={reqTypeAddItemMap[request?.requestType?.id] || REQ_EDIT}
          showDelayFlag={request.requestType.id !== PICKUP_REQUEST_TYPE_ID}
          showSpecialityFlag={
            request.requestType.id === DELIVERY_REQUEST_TYPE_ID
          }
          patientDetails
        />
      ) : null}
      {/* <Modal
        title={
          <div className="commonModalHeader">
            <div className="title">
              <p> Cancel Order</p>
            </div>
            <div className="close">
              <Button className="closeBtn" onClick={handleCancel}>
                <CloseOutlined className="close-icon" />
              </Button>
            </div>
          </div>
        }
        visible={cancelModalVisible}
        maskClosable={false}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        centered={true}
      >
        <Row
          className={classes.flexMiddle}
          style={{ width: "100%", textAlign: "center" }}
        >
          <InfoCircleOutlined className={classes.exclamationIcon} />
          <span style={{ paddingLeft: 10 }}>
            Are you sure you want to cancel this Request?
          </span>
          <Row className={`${classes.modalFooter} ${classes.footerButtons}`}>
            <Button
              type="default"
              className={`${classes.cancelButton}`}
              onClick={handleCancel}
            >
              No
            </Button>
            <Button
              type="default"
              className={`${classes.fwdButton} ${classes.modalFooterButton}`}
              onClick={onCancelSubmit}
            >
              Yes
            </Button>
          </Row>
        </Row>
      </Modal> */}
      <CancelReqModal
        showModal={cancelModalVisible}
        requestId={request.requestId}
        toggleModal={() => handleCancel()}
        onCancelSubmit={onCancelSubmit}
      />
      {request.requestType.id === DELIVERY_REQUEST_TYPE_ID && (
        <PatientDetailsModal
          visible={!!showPatientModal}
          toggleModal={() => setShowPatientModal(false)}
          cart={tempCart}
          setCart={setTempCart}
          itemId={showPatientModal.id}
          mode={showPatientModal.mode}
        />
      )}

      <DelayModal
        key="info-modal"
        handleCancel={() => {
          setShowInfo("");
        }}
        handleOk={() => {
          setShowInfo("");
        }}
        isModalVisible={!!showInfo}
        icon={<ExclamationCircleTwoTone style={{ fontSize: "50px" }} />}
        text={showInfo}
        okayText="Okay"
        hideCancel={true}
      />

      <DelayModal
        key="info-modal"
        handleCancel={() => {
          setIsRemoveItem(false);
        }}
        handleOk={removeItem}
        isModalVisible={isRemoveItem}
        icon={<ExclamationCircleTwoTone style={{ fontSize: "50px" }} />}
        text="Your Cart has some items that are specific to previous unit. It will be removed automatically. Do you want to continue?"
        okayText="Yes"
        cancelText="No"
      />
    </>
  );
};

export default RequestDetailsView;
