import React from "react";

const Blank = (props: any) => {
  return (
    <div>
      <h5>
        The application was unable to load your credentials for logging in to
        the application. We recommend closing the browser and trying from a new
        window.
      </h5>
    </div>
  );
};

export default Blank;
