import { Dispatch } from "redux";
import { adminRootInstance } from "../../../../common/config/axios-config";
import {
  AIM_REPORT_CLEAR_ALL,
  FETCH_AIM_REPORT_SUCCESS,
  FETCH_AIM_REPORT_FAIL,
  SET_AIM_REPORT_FILTER,
  FETCH_AIM_REPORT_START,
} from "./action-types";
const api_dateFormat = "YYYY-MM-DD";
//Common method to fetch the report respone and set the data in redux / set error message if it fails
interface QueryParams {
  pageSize: string | number;
  page: string | number;
  from?: string;
  to?: string;
  status?: string;
  itemId?: string | number;
  speciality?: string | boolean;
  campusId?: string | number;
  campus?: string | number;
}
const fetchReports = async (
  endpoint: string,
  queryParams: QueryParams,
  dispatch: Dispatch
) => {
  return await adminRootInstance
    .get(endpoint, {
      params: queryParams,
    })
    .then((res: any) => {
      if (res?.data)
        dispatch({
          type: FETCH_AIM_REPORT_SUCCESS,
          payload: {
            reportData: res.data?.data?.assets,
            totalCount: res.data?.data?.pagination?.totalCount,
          },
        });
      return { success: true };
    })
    .catch((err) => {
      dispatch({
        type: FETCH_AIM_REPORT_FAIL,
        payload: { errorMessage: err?.response?.data?.message },
      });
      return { success: false };
    });
};
// set Filter Data for Report (form data)
export const setReportFilterData = (data: Object) => async (dispatch: any) => {
  dispatch({
    type: SET_AIM_REPORT_FILTER,
    payload: { filterData: data },
  });
};

export const resetReportState = () => async (dispatch: any) => {
  dispatch({
    type: AIM_REPORT_CLEAR_ALL,
  });
};
//FETCH AIM REPORTS DATA
export const fetchAIMReport =
  ({ limit = 30, page = 1, ...rest }: any) =>
  async (dispatch: any) => {
    const { itemType: itemId, status, speciality, campusId, from, to } = rest;
    dispatch({ type: FETCH_AIM_REPORT_START });
    return await fetchReports(
      "aim/reports/all",
      {
        pageSize: limit,
        page,
        itemId,
        status,
        from: from.format(api_dateFormat),
        to: to.format(api_dateFormat),
        ...(speciality !== "all" ? { speciality } : {}),
        campusId,
      },
      dispatch
    );
  };

export const fetchCheckinReport =
  ({ limit = 30, page = 1, ...rest }: any) =>
  async (dispatch: any) => {
    const { itemType: itemId, from, to, campusId } = rest;
    dispatch({ type: FETCH_AIM_REPORT_START });
    return await fetchReports(
      "aim/reports/checkin",
      {
        pageSize: limit,
        page,
        itemId,
        from: from.format(api_dateFormat), //asset checkin
        to: to.format(api_dateFormat), //checkout
        campusId,
      },
      dispatch
    );
  };

export const fetchRentalReport =
  ({ limit = 30, page = 1, ...rest }: any) =>
  async (dispatch: any) => {
    const { itemType: itemId, status, from, to, campusId } = rest;
    dispatch({ type: FETCH_AIM_REPORT_START });
    return await fetchReports(
      "aim/reports/rental",
      {
        pageSize: limit,
        page,
        itemId,
        status,
        from: from.format(api_dateFormat),
        to: to.format(api_dateFormat),
        campusId,
      },
      dispatch
    );
  };

export const fetchDeconReport =
  ({ limit = 30, page = 1, ...rest }: any) =>
  async (dispatch: any) => {
    const { itemType: itemId, from, to, campusId } = rest;
    dispatch({ type: FETCH_AIM_REPORT_START });
    return await fetchReports(
      "aim/reports/decon",
      {
        pageSize: limit,
        page,
        itemId,
        from: from.format(api_dateFormat),
        to: to.format(api_dateFormat),
        campusId,
      },
      dispatch
    );
  };
export const clearDetails = () => async (dispatch: any) => {
  dispatch({
    type: AIM_REPORT_CLEAR_ALL,
  });
};
