/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  Alert,
  message,
  Select,
  Form,
  Input,
} from "antd";
import classes from "./CancelReqModal.module.scss";
import { useState } from "react";
import { OVERRIDE_CLOSE_STATUS_ID } from "../../shared-constants";
import { CANCEL_STATUS_ID } from "../../shared-constants";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  adminRootInstance,
  requestorRootInstance,
} from "../../config/axios-config";

const cancelReasonLength = 200;

const cancelRequest = (
  reqId: string,
  overrideClose: boolean,
  cancelReason: string,
  requestorPortal: boolean
) => {
  return (requestorPortal ? requestorRootInstance : adminRootInstance)
    .put(
      `order/${reqId}?status=${
        overrideClose ? OVERRIDE_CLOSE_STATUS_ID : CANCEL_STATUS_ID
      }`,
      { ...(!overrideClose && { cancelReason }) }
    )
    .then((res) => {
      return { message: res.data.message, success: true };
    })
    .catch((err) => ({
      success: false,
      message: err?.response?.data?.message || err.message,
    }));
};

interface CancelReqModalInterface {
  showModal: boolean;
  toggleModal: Function;
  requestId: any;
  modalTitle?: string;
  bodyText?: string;
  overrideClose?: boolean;
  requestorPortal?: boolean;
  onCancelSubmit?: Function;
}
const CancelReqModal = (props: CancelReqModalInterface) => {
  const {
    showModal,
    toggleModal,
    requestId = {},
    modalTitle = "Cancel request confirmation",
    bodyText = "Are you sure you want to cancel this Request?",
    overrideClose = false,
    requestorPortal = false,
    onCancelSubmit,
  } = props;
  const [resp, setResp] = useState<any>({});
  const { Option } = Select;
  const [showInputArea, setShowInputArea] = useState<any>(false);
  const submitHandler = async () => {
    const formvalues = form.getFieldsValue();
    document.getElementById("Cancel-SubmitButton")?.blur();
    setResp({ loading: true });

    let cancellationReason = (
      formvalues["reason-detail"]
        ? formvalues["reason-detail"]
        : formvalues.reason
    )?.trim();
    if (onCancelSubmit) {
      return onCancelSubmit(cancellationReason.trim());
    }
    const res: any = await cancelRequest(
      requestId,
      overrideClose,
      cancellationReason,
      requestorPortal
    );
    res.message && setResp(res);
    if (res?.success) {
      message.success(res?.message);
      toggleModal();
    }
  };
  const cancellationReason = [
    "Duplicate Order",
    "No Longer Needed",
    "Wrong Item Ordered",
    "Others",
  ];
  const getReasonIndex = 3;
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
    setShowInputArea(false);
  }, [showModal]);
  return (
    <Modal
      title={<div className={classes.modalHeader}>{modalTitle}</div>}
      centered
      visible={showModal}
      onOk={() => toggleModal()}
      onCancel={() => toggleModal()}
      style={{ minWidth: "300px" }}
      footer={null}
      maskClosable={false}
    >
      <Row
        justify="center"
        align="middle"
        style={{ width: "100%", textAlign: "center" }}
      >
        {!resp?.success
          ? resp.message &&
            !resp?.loading && (
              <Alert
                message={resp?.message}
                style={{ width: "100%", marginBottom: "15px" }}
                closable
                type="error"
              />
            )
          : null}
        <Col className={classes.modalMessage} span={24}>
          <ExclamationCircleOutlined className={classes.exclamationIcon} />
          <span>{bodyText}</span>
        </Col>

        <Form
          layout="horizontal"
          initialValues={{}}
          onFinish={submitHandler}
          form={form}
        >
          {!overrideClose && (
            <Row justify="center">
              <Form.Item
                style={{ width: "280px" }}
                name="reason"
                label="Cancellation Reason"
                wrapperCol={{ xs: 11, sm: 11, md: 11, lg: 11, xl: 11, xxl: 11 }}
                labelCol={{ xs: 13, sm: 13, md: 13, lg: 13, xl: 13, xxl: 13 }}
                labelAlign="left"
                required
                rules={[
                  () => ({
                    validator(rule, value) {
                      if (!value)
                        return Promise.reject("Select a valid reason!");
                      return Promise.resolve();
                    },
                    required: true,
                  }),
                ]}
              >
                <Select
                  placeholder="Select Reason"
                  onChange={(val: any, ind: any) => {
                    setShowInputArea(ind.key === getReasonIndex.toString());
                  }}
                >
                  {cancellationReason.map((reason: any, ind: number) => {
                    return (
                      <Option key={ind} value={reason}>
                        {reason}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Row>
          )}
          {showInputArea && (
            <Row justify="center">
              <Form.Item
                style={{ width: "280px" }}
                name="reason-detail"
                label=""
                rules={[
                  () => ({
                    validator(rule, value) {
                      if (!value || value.trim().length < 1)
                        return Promise.reject("Enter a valid reason!");
                      else if (value.trim().length > cancelReasonLength)
                        return Promise.reject(
                          `Reason should be less than ${cancelReasonLength} character long!`
                        );
                      return Promise.resolve();
                    },
                    required: true,
                  }),
                ]}
              >
                <Input.TextArea
                  autoSize={{
                    minRows: 4,
                    maxRows: 4,
                  }}
                  placeholder="Mention the reason"
                  onKeyDown={(event: any) => {
                    if (
                      (event.keyCode === 10 || event.keyCode === 13) &&
                      event.ctrlKey
                    )
                      form.submit();
                  }}
                />
              </Form.Item>
            </Row>
          )}
          <Col span={24}>
            <Row className={`${classes.modalFooter} `} justify="center">
              <Button
                htmlType="button"
                type="default"
                className={`${classes.cancelButton}`}
                onClick={() => {
                  toggleModal();
                }}
              >
                No
              </Button>
              <Button
                htmlType="submit"
                type="default"
                loading={!!resp?.loading}
                className={`${classes.fwdButton}`}
              >
                Yes
              </Button>
            </Row>
          </Col>
        </Form>
      </Row>
    </Modal>
  );
};

export default CancelReqModal;
