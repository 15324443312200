/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import Header from "./header";
import Footer from "./footer";
import classes from "./admin-layout.module.scss";
import arrow from "../../../images/next-arrow.png";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../../shared/ability/can";
import {
  connectWebSocket,
  disconnectWebSocket,
} from "../../../common/utils/socket";
import SideBar from "./SideBar";
import ErrorBoundaryComp from "../../../common/components/ErrorBoundaryComp/ErrorBoundaryComp";

const { Content } = Layout;

const AdminLayout = (props: any) => {
  const ability = useAbility(AbilityContext);
  const [visible, setVisible] = useState(false);
  const [active, setActive] = useState<string>("");
  useEffect(() => {
    setActive(
      props.location.pathname.endsWith("/")
        ? props.location.pathname.substring(
            0,
            props.location.pathname.length - 1
          )
        : props.location.pathname
    );
  }, [props.location]);

  useEffect(() => {
    connectWebSocket();
    return () => {
      disconnectWebSocket();
    };
  }, []);
  const onClose = () => {
    setVisible(false);
  };
  const onOpen = () => {
    setVisible(true);
  };

  return (
    <React.Fragment>
      <SideBar
        {...{ onClose, visible, active, ability, variant: props.variant }}
      />
      <Layout className={classes.layoutContainer}>
        <Header variant={props.variant} />
        <ErrorBoundaryComp>
          <Content className={classes.contentContainer}>
            {!props.hideSideDrawer && (
              <div onClick={onOpen} className={classes.openContainer}>
                <img alt="" src={arrow} className={classes.openArrow} />
              </div>
            )}
            {props.children}
          </Content>
        </ErrorBoundaryComp>
        <Footer />
      </Layout>
    </React.Fragment>
  );
};

export default AdminLayout;
