import React from "react";
import { connect } from "react-redux";
import { fetchProductivityReport } from "../../actions";
import { IRootState } from "../../../../../common/redux/reducers";
import ReportsTable from "../commons/report-table";
export let productivitySummaryColumns: any;
const ReportSummaryTable = ({
  fetchProductivityReport,
  reportData,
  reportLoading,
  filterData,
}: any) => {
  const headers: any = [];
  reportData?.data?.forEach((data: any) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (!headers.includes(key)) {
        headers.push(key);
      }
    });
  });
  const columns: any = [];
  const headerMap: any = {
    userName: "User Name",
    avgQueueDuration: "Avg Queue Duration",
    avgAcceptDuration: "Avg Accepted Duration",
    avgStartDuration: "Avg Start Duration",
    avgDeliverDuration: "Avg Deliver Duration",
    avgDelayDuration: "Avg Delay Duration",
    delayed: "Delayed",
    total: "Total",
  };
  headers?.forEach((header: any) => {
    columns.push({
      title: headerMap[header]
        ? headerMap[header]
        : header.replaceAll("_", " "),
      dataIndex: header,
      align: "center",
      render: (value: any, record: any) =>
        record[header] ? record[header] : "-",
    });
  });

  productivitySummaryColumns = columns;
  return (
    <ReportsTable
      loading={reportLoading}
      rowData={reportData?.data}
      columnData={columns}
      totalCount={reportData?.totalCount}
      fetchData={(pagination: any) => {
        fetchProductivityReport({
          ...pagination,
          ...filterData,
        });
      }}
    />
  );
};
const mapStateToProps = ({ reportsState }: IRootState) => ({
  allSla: reportsState.allSlas,
  allOrderTypes: reportsState.allOrderTypes,
  successMsg: reportsState.successMessage,
  reportData: reportsState.reportData,
  reportLoading: reportsState.loading,
  filterData: reportsState.filterData,
});
const mapDispatchToProps = {
  fetchProductivityReport,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportSummaryTable);
