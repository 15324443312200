/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import {
  Row,
  Modal,
  Button,
  Form,
  Input,
  Alert,
  Spin,
  Switch,
  Select,
  Col,
  Typography,
  List,
  Skeleton,
} from "antd";
import classes from "./transport-modal.module.scss";
import "../../../shared/scss/shared-modal.scss";
import { trimValues } from "../../../../common/utils/trimUtil";
import { CloseOutlined, FormOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  BUTTON_GREEN_HEX,
  DELETE_TEXT,
  EDIT,
  EDIT_TEXT,
  GREEN_THEME_HEX,
} from "../../../../common/shared-constants";
import { adminRootInstance } from "../../../../common/config/axios-config";

const TransportModal = ({
  transport,
  mode,
  visible,
  handleCancel,
  handleSubmit,
  errorMsg,
  clearMessages,
  loading,
}: any) => {
  const [submitted, setSubmitted] = useState(false);
  const [form] = Form.useForm();
  const [dischargeLocations, setDischargeLocations] = useState<any>([]);
  const [dischargeLocationindex, setDischargeLocationIndex] =
    useState<any>(null);

  const getDischargeLocations = async (campusId: any) => {
    const res = await adminRootInstance.get(`discharge-location/${campusId}`);
    setDischargeLocations([...res?.data?.data, {}]);
  };
  const [addDischargeLocation, setAddDischargeLocation] = useState<any>("");
  const [editDischargeLocation, setEditDischargeLocation] = useState<any>("");
  const [addValidationError, setAddValidationError] = useState<any>("");
  const [editValidationError, setEditValidationError] = useState<any>("");

  useEffect(() => {
    if (transport?.campusId?.id) {
      getDischargeLocations(transport?.campusId?.id);
    }
  }, [transport?.campusId?.id]);

  const onFormSubmit = async (values: any) => {
    if (submitted) {
      return;
    }
    setSubmitted(true);
    const trimedValues = trimValues(values);
    console.log(trimedValues);

    await handleSubmit(trimedValues);
    setSubmitted(false);
  };

  const procedureOnchangeHandler = (e: boolean) => {
    if (e === false) {
      form.setFieldValue("returnRequest", false);
    }
  };

  const returnOnchangeHandler = (e: boolean) => {
    if (e === true) {
      form.setFieldValue("procedureRequest", true);
    }
  };

  return (
    <React.Fragment>
      <Modal
        title={
          <div className="commonModalHeader">
            <div className="title">
              <p> {mode === EDIT ? "Edit Transport" : "Create Transport"} </p>
            </div>
            <div className="close">
              <Button className="closeBtn" onClick={handleCancel}>
                <CloseOutlined className="close-icon" />
              </Button>
            </div>
          </div>
        }
        visible={visible}
        width={1000}
        maskClosable={false}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        bodyStyle={{
          maxHeight: "600px",
          overflowY: "auto",
        }}
        centered
      >
        {errorMsg ? (
          <Alert
            message={errorMsg}
            type="error"
            closable
            afterClose={clearMessages}
            className={`fadeIn ${classes.errorContainer}`}
          />
        ) : null}
        <Form form={form} layout="horizontal" onFinish={onFormSubmit}>
          <Row>
            <Form.Item name="id" initialValue={transport?.id} hidden />
            <Form.Item
              name="campusId"
              initialValue={transport?.campusId?.id}
              hidden
            />
            <Form.Item
              className="fullWidth"
              name="campusName"
              label="Campus Name"
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              initialValue={transport?.campusId?.name}
            >
              <Input disabled placeholder="Enter here" className="inputStyle" />
            </Form.Item>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="braceletVerification"
                label="Bracelet Verification"
                valuePropName="checked"
                initialValue={transport?.braceletVerification}
                wrapperCol={{ span: 4 }}
                labelCol={{ span: 20 }}
                labelAlign="left"
              >
                <Switch className="defaultSwitch" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="showHeaderText"
                label="Show Header Text"
                valuePropName="checked"
                initialValue={transport?.showHeaderText}
                wrapperCol={{ span: 4 }}
                labelCol={{ span: 20 }}
                labelAlign="left"
              >
                <Switch className="defaultSwitch" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item
              className="fullWidth"
              name="headerText"
              label="Header Text"
              initialValue={transport?.headerText ? transport?.headerText : ""}
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              rules={[
                {
                  required: false,
                  max: 250,
                  message: "Header Text should be a maximum of 250 characters.",
                },
              ]}
            >
              <Input.TextArea
                placeholder="Enter here"
                className="inputStyle"
                autoSize={{ minRows: 1, maxRows: 15 }}
              />
            </Form.Item>
          </Row>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "20px",
              }}
            >
              <Typography.Title level={5}>Request Management</Typography.Title>
            </Col>
          </Row>
          <Row
            gutter={[0, 16]}
            style={{
              padding: "25px 10px 0px 10px",
              border: `1px solid ${GREEN_THEME_HEX}`,
              borderRadius: "5px",
            }}
          >
            <Col span={6} className={classes.columnPadding}>
              <Form.Item
                name="procedureRequest"
                label="Procedure"
                valuePropName="checked"
                initialValue={transport?.procedureRequest}
                wrapperCol={{ span: 8 }}
                labelCol={{ span: 16 }}
                labelAlign="left"
              >
                <Switch className="defaultSwitch" onChange={procedureOnchangeHandler} />
              </Form.Item>
            </Col>
            <Col span={6} className={classes.columnPadding}>
              <Form.Item
                name="returnRequest"
                label="Return"
                valuePropName="checked"
                initialValue={transport?.returnRequest}
                wrapperCol={{ span: 8 }}
                labelCol={{ span: 16 }}
                labelAlign="left"
              >
                <Switch className="defaultSwitch" onChange={returnOnchangeHandler} />
              </Form.Item>
            </Col>
            <Col span={12} className={classes.columnPadding}>
              <Form.Item
                className="fullWidth"
                name="procedureTimeLeft"
                label="Start Now Reminder"
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 8 }}
                labelAlign="left"
                initialValue={transport?.procedureTimeLeft}
                dependencies={["procedureThreshold"]}
                rules={[
                  () => ({
                    required: true,
                    validator: (rule: any, val: string) => {
                      let value = parseInt(val);
                      if (value) {
                        if (form.getFieldValue("procedureThreshold") >= value) {
                          return Promise.reject(
                            "Should be greater than Not-Accepted Timer"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.reject("Start Now Reminder is required");
                      }
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Enter here"
                  className="inputStyle"
                  type="number"
                  min="1"
                />
              </Form.Item>
            </Col>
            <Col span={12} className={classes.columnPadding}>
              <Form.Item
                className="fullWidth"
                name="procedureThreshold"
                label="Not-Accepted Timer"
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 8 }}
                labelAlign="left"
                initialValue={transport?.procedureThreshold}
                dependencies={["procedureTimeLeft"]}
                rules={[
                  () => ({
                    required: true,
                    validator: (rule: any, val: string) => {
                      let value = parseInt(val);
                      if (value) {
                        if (form.getFieldValue("procedureTimeLeft") <= value) {
                          return Promise.reject(
                            "Should be lesser than Start Now Reminder"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.reject("Not-Accepted Timer is required");
                      }
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Enter here"
                  className="inputStyle"
                  type="number"
                  min="1"
                />
              </Form.Item>
            </Col>
            <Col span={12} className={classes.columnPadding}>
              <Form.Item
                className="fullWidth"
                name="procedurePriority"
                label="Priority"
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 8 }}
                labelAlign="left"
                initialValue={transport?.procedurePriority}
                rules={[
                  () => ({
                    required: true,
                    message: "Please select a priority",
                  }),
                ]}
              >
                <Select
                  className="inputStyle"
                  placeholder="Choose here"
                  getPopupContainer={(trigger): any => trigger.parentNode}
                  filterOption={(input: any, option: any) =>
                    option?.children.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {[1, 2, 3].map((priority: any) => (
                    <Select.Option key={priority} value={priority}>
                      <div className="roleName">{priority}</div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[0, 16]}
            style={{
              padding: "25px 10px 0px 10px",
              border: `1px solid ${GREEN_THEME_HEX}`,
              borderRadius: "5px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Col span={12} className={classes.columnPadding}>
              <Form.Item
                name="transferRequest"
                label="Transfer"
                valuePropName="checked"
                initialValue={transport?.transferRequest}
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 8 }}
                labelAlign="left"
              >
                <Switch className="defaultSwitch" />
              </Form.Item>
            </Col>
            <Col span={12} className={classes.columnPadding}>
              <Form.Item
                className="fullWidth"
                name="transferTimeLeft"
                label="Start Now Reminder"
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 8 }}
                labelAlign="left"
                initialValue={transport?.transferTimeLeft}
                dependencies={["transferThreshold"]}
                rules={[
                  () => ({
                    required: true,
                    validator: (rule: any, val: string) => {
                      let value = parseInt(val);
                      if (value) {
                        if (form.getFieldValue("transferThreshold") >= value) {
                          return Promise.reject(
                            "Should be greater than Not-Accepted Timer"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.reject("Start Now Reminder is required");
                      }
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Enter here"
                  className="inputStyle"
                  type="number"
                  min="1"
                />
              </Form.Item>
            </Col>
            <Col span={12} className={classes.columnPadding}>
              <Form.Item
                className="fullWidth"
                name="transferThreshold"
                label="Not-Accepted Timer"
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 8 }}
                labelAlign="left"
                initialValue={transport?.transferThreshold}
                dependencies={["transferTimeLeft"]}
                rules={[
                  () => ({
                    required: true,
                    validator: (rule: any, val: string) => {
                      let value = parseInt(val);
                      if (value) {
                        if (form.getFieldValue("transferTimeLeft") <= value) {
                          return Promise.reject(
                            "Should be lesser than Start Now Reminder"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.reject("Not-Accepted Timer is required");
                      }
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Enter here"
                  className="inputStyle"
                  type="number"
                  min="1"
                />
              </Form.Item>
            </Col>
            <Col span={12} className={classes.columnPadding}>
              <Form.Item
                className="fullWidth"
                name="transferPriority"
                label="Priority"
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 8 }}
                labelAlign="left"
                initialValue={transport?.transferPriority}
                rules={[
                  () => ({
                    required: true,
                    message: "Please select a priority",
                  }),
                ]}
              >
                <Select
                  className="inputStyle"
                  placeholder="Choose here"
                  getPopupContainer={(trigger): any => trigger.parentNode}
                  filterOption={(input: any, option: any) =>
                    option?.children.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {[1, 2, 3].map((priority: any) => (
                    <Select.Option key={priority} value={priority}>
                      <div className="roleName">{priority}</div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[0, 16]}
            style={{
              padding: "25px 10px 0px 10px",
              border: `1px solid ${GREEN_THEME_HEX}`,
              borderRadius: "5px",
            }}
          >
            <Col span={12} className={classes.columnPadding}>
              <Form.Item
                name="dischargeRequest"
                label="Discharge"
                valuePropName="checked"
                initialValue={transport?.dischargeRequest}
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 8 }}
                labelAlign="left"
              >
                <Switch className="defaultSwitch" />
              </Form.Item>
            </Col>
            <Col span={12} className={classes.columnPadding}>
              <Form.Item
                className="fullWidth"
                name="dischargeTimeLeft"
                label="Start Now Reminder"
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 8 }}
                labelAlign="left"
                initialValue={transport?.dischargeTimeLeft}
                dependencies={["dischargeThreshold"]}
                rules={[
                  () => ({
                    required: true,
                    validator: (rule: any, val: string) => {
                      let value = parseInt(val);
                      if (value) {
                        if (form.getFieldValue("dischargeThreshold") >= value) {
                          return Promise.reject(
                            "Should be greater than Not-Accepted Timer"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.reject("Start Now Reminder is required");
                      }
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Enter here"
                  className="inputStyle"
                  type="number"
                  min="1"
                />
              </Form.Item>
            </Col>
            <Col span={12} className={classes.columnPadding}>
              <Form.Item
                className="fullWidth"
                name="dischargeThreshold"
                label="Not-Accepted Timer"
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 8 }}
                labelAlign="left"
                initialValue={transport?.dischargeThreshold}
                dependencies={["dischargeTimeLeft"]}
                rules={[
                  () => ({
                    required: true,
                    validator: (rule: any, val: string) => {
                      let value = parseInt(val);
                      if (value) {
                        if (form.getFieldValue("dischargeTimeLeft") <= value) {
                          return Promise.reject(
                            "Should be lesser than Start Now Reminder"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.reject("Not-Accepted Timer is required");
                      }
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Enter here"
                  className="inputStyle"
                  type="number"
                  min="1"
                />
              </Form.Item>
            </Col>
            <Col span={12} className={classes.columnPadding}>
              <Form.Item
                className="fullWidth"
                name="dischargePriority"
                label="Priority"
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 8 }}
                labelAlign="left"
                initialValue={transport?.dischargePriority}
                rules={[
                  () => ({
                    required: true,
                    message: "Please select a priority",
                  }),
                ]}
              >
                <Select
                  className="inputStyle"
                  placeholder="Choose here"
                  getPopupContainer={(trigger): any => trigger.parentNode}
                  filterOption={(input: any, option: any) =>
                    option?.children.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {[1, 2, 3].map((priority: any) => (
                    <Select.Option key={priority} value={priority}>
                      <div className="roleName">{priority}</div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} className={classes.columnPadding}>
              <Form.Item className="fullWidth">
                <Row>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography.Title level={5}>
                      Discharge Location
                    </Typography.Title>
                  </Col>
                </Row>
                <List
                  bordered
                  className="demo-loadmore-list"
                  style={{
                    marginTop: "10px",
                    maxHeight: 200,
                    overflow: "auto",
                    padding: "0 16px",
                  }}
                  loading={false}
                  itemLayout="horizontal"
                  dataSource={[...dischargeLocations]}
                  renderItem={(item, i) => (
                    <List.Item
                      style={{
                        alignItems:
                          addValidationError || editValidationError
                            ? "flex-start"
                            : "center",
                      }}
                      actions={
                        i !== dischargeLocationindex &&
                          i !== dischargeLocations.length - 1
                          ? [
                            <Button
                              size="small"
                              title={EDIT_TEXT}
                              className="icon-btn"
                              onClick={() => {
                                setDischargeLocationIndex(i);
                                setEditDischargeLocation(item?.name);
                              }}
                            >
                              <FormOutlined
                                className={`edit-icon ${classes.iconColor}`}
                              />
                            </Button>,
                            <Button
                              size="small"
                              title={DELETE_TEXT}
                              className="icon-btn"
                              onClick={async () => {
                                const deleteId = dischargeLocations[i]?.id;
                                setDischargeLocationIndex(null);
                                const res = await adminRootInstance.delete(
                                  `discharge-location/${deleteId}`
                                );
                                if (res.data.status === "success") {
                                  setEditDischargeLocation("");
                                  getDischargeLocations(
                                    transport?.campusId?.id
                                  );
                                }
                              }}
                            >
                              <DeleteOutlined
                                className={`delete-icon ${classes.iconColor}`}
                              />
                            </Button>,
                          ]
                          : i === dischargeLocationindex &&
                            i !== dischargeLocations.length - 1
                            ? [
                              <Button
                                size="small"
                                className={`icon-btn ${classes.iconColor}`}
                                disabled={
                                  editValidationError ||
                                  editDischargeLocation === ""
                                }
                                onClick={async () => {
                                  const editId = dischargeLocations[i]?.id;
                                  setDischargeLocationIndex(null);
                                  const res = await adminRootInstance.put(
                                    `discharge-location/${editId}`,
                                    { name: editDischargeLocation }
                                  );
                                  if (res.data.status === "success") {
                                    setEditDischargeLocation("");
                                    setEditValidationError("");
                                    getDischargeLocations(
                                      transport?.campusId?.id
                                    );
                                  }
                                }}
                              >
                                Save
                              </Button>,
                              <Button
                                size="small"
                                className={`icon-btn ${classes.iconColor}`}
                                onClick={() => {
                                  setDischargeLocationIndex(null);
                                  setEditValidationError("");
                                }}
                              >
                                Cancel
                              </Button>,
                            ]
                            : [
                              <Button
                                size="small"
                                className={`icon-btn ${classes.iconColor}`}
                                disabled={
                                  addValidationError ||
                                  addDischargeLocation === ""
                                }
                                onClick={async () => {
                                  setDischargeLocationIndex(null);
                                  const res = await adminRootInstance.post(
                                    "discharge-location",
                                    {
                                      name: addDischargeLocation,
                                      campusId: transport?.campusId?.id,
                                    }
                                  );
                                  if (res.data.status === "success") {
                                    setAddDischargeLocation("");
                                    setAddValidationError("");
                                    getDischargeLocations(
                                      transport?.campusId?.id
                                    );
                                  }
                                }}
                              >
                                + Add
                              </Button>,
                              <Button
                                size="small"
                                className={`icon-btn ${classes.iconColor}`}
                                onClick={() => {
                                  setAddDischargeLocation("");
                                  setAddValidationError("");
                                }}
                              >
                                Cancel
                              </Button>,
                            ]
                      }
                    >
                      <Skeleton avatar title={false} loading={false} active>
                        {i !== dischargeLocationindex &&
                          i !== dischargeLocations.length - 1 && (
                            <div>{item?.name}</div>
                          )}
                        {i === dischargeLocationindex &&
                          i !== dischargeLocations.length - 1 && (
                            <div className="fullWidth">
                              <Input
                                defaultValue={item?.name}
                                value={editDischargeLocation}
                                onChange={(e) => {
                                  setEditDischargeLocation(
                                    e.target.value
                                  );
                                  if (e.target.value.trim() === "") {
                                    setEditValidationError(
                                      "Discharge location is required"
                                    );
                                  } else if (
                                    !e.target.value
                                      .trim()
                                      .match(
                                        /^[a-zA-Z0-9]+([a-zA-Z0-9_ $@/&.,:)(%#-])*$/
                                      )
                                  ) {
                                    setEditValidationError(
                                      "Please enter a valid discharge location"
                                    );
                                    return false;
                                  } else if (e.target.value.trim().length < 1) {
                                    setEditValidationError(
                                      "Discharge location should be minimum of 1 character"
                                    );
                                    return false;
                                  } else if (
                                    e.target.value.trim().length > 20
                                  ) {
                                    setEditValidationError(
                                      "Discharge location should be maximum of 20 characters"
                                    );
                                    return false;
                                  } else {
                                    setEditValidationError("");
                                  }
                                }}
                              ></Input>
                              {editValidationError && (
                                <div
                                  className={`${classes.alertContainer} ant-form-item-explain ant-form-item-explain-error`}
                                >
                                  <div role="alert">{editValidationError}</div>
                                </div>
                              )}
                            </div>
                          )}
                        {i === dischargeLocations.length - 1 && (
                          <div className="fullWidth">
                            <Input
                              placeholder="Enter discharge location"
                              defaultValue={""}
                              value={addDischargeLocation}
                              onChange={(e) => {
                                setAddDischargeLocation(e.target.value);
                                if (e.target.value.trim() === "") {
                                  setAddValidationError(
                                    "Discharge location is required"
                                  );
                                } else if (
                                  !e.target.value
                                    .trim()
                                    .match(
                                      /^[a-zA-Z0-9]+([a-zA-Z0-9_ $@/&.,:)(%#-])*$/
                                    )
                                ) {
                                  setAddValidationError(
                                    "Please enter a valid discharge location"
                                  );
                                  return false;
                                } else if (e.target.value.trim().length < 1) {
                                  setAddValidationError(
                                    "Discharge location should be minimum of 1 character"
                                  );
                                  return false;
                                } else if (e.target.value.trim().length > 20) {
                                  setAddValidationError(
                                    "Discharge location should be maximum of 20 characters"
                                  );
                                  return false;
                                } else {
                                  setAddValidationError("");
                                }
                              }}
                            ></Input>
                            {addValidationError && (
                              <div
                                className={`${classes.alertContainer} ant-form-item-explain ant-form-item-explain-error`}
                              >
                                <div role="alert">{addValidationError}</div>
                              </div>
                            )}
                          </div>
                        )}
                      </Skeleton>
                    </List.Item>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="modalBtn">
            <Form.Item className={classes.cancelbutton}>
              <Button
                className="cancelBtn"
                htmlType="reset"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Spin spinning={loading}>
                <Button className="submitBtn" htmlType="submit">
                  Submit
                </Button>
              </Spin>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
export default TransportModal;
