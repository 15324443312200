import {
  CLEAR_SHARP_ERROR_MSG,
  FETCH_SHARPITEMS_FAIL,
  FETCH_SHARPITEMS_SUCCESS,
  SHARP_ORDER_PLACED_FAILED,
} from "./action-types";

const initialState = {
  sharpItems: [] as any,
  errorMessage: null as null | string,
  successMessage: null as null | string,
};

export type SharpManagementState = Readonly<typeof initialState>;

const sharpManagement = (
  state = initialState,
  action: any
): SharpManagementState => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SHARPITEMS_SUCCESS:
      return {
        ...state,
        sharpItems: payload.sharpItems,
      };
    case FETCH_SHARPITEMS_FAIL:
      return {
        ...state,
        errorMessage: payload.errorMessage,
        sharpItems: [],
      };
    case SHARP_ORDER_PLACED_FAILED:
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    case CLEAR_SHARP_ERROR_MSG:
      return {
        ...state,
        errorMessage: null,
      };
    default:
      return state;
  }
};

export default sharpManagement;
