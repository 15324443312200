import {
  FETCH_TRANSPORTS_SUCCESS,
  FETCH_TRANSPORTS_FAIL,
  UPDATE_TRANSPORT_SUCCESS,
  UPDATE_TRANSPORT_FAIL,
  CLEAR_TRANSPORT_MSG,
  CLEAR_TRANSPORT_FETCH_ERROR_MSG,
  FETCH_TRANSPORTS_START,
} from "./action-types";

const initialState = {
  loading: false,
  transports: [] as any,
  totalCount: 0,
  errorMessage: null as null | string,
  successMessage: null as null | string,
  fetchErrorMessage: null as null | string,
};

export type TransportManagementState = Readonly<typeof initialState>;

const transportManagement = (
  state = initialState,
  action: any
): TransportManagementState => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TRANSPORTS_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TRANSPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchErrorMessage: null,
        transports: payload.transports,
        totalCount: payload.totalCount,
      };
    case FETCH_TRANSPORTS_FAIL:
      return {
        ...state,
        loading: false,
        fetchErrorMessage: payload.fetchErrorMessage,
        transports: null,
      };
    case UPDATE_TRANSPORT_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        loading: false,
        successMessage: payload.successMessage,
      };
    case UPDATE_TRANSPORT_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        successMessage: null,
      };
    case CLEAR_TRANSPORT_MSG:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      };
    case CLEAR_TRANSPORT_FETCH_ERROR_MSG:
      return {
        ...state,
        fetchErrorMessage: null,
      };
    default:
      return state;
  }
};

export default transportManagement;
