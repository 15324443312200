import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, RouteProps } from "react-router-dom";
import AdminLayout from "./layout/admin-layout";
import { IRootState } from "../../common/redux/reducers";
import UnauthorizedAccess from "../../common/components/unauthorized-page";
import {
  ADMIN_DASHBOARD,
  AIM,
  POST_LOGIN_SCREEN,
} from "../../common/shared-constants";

export interface IPrivateRouteProps extends RouteProps, StateProps {
  authorized?: Array<string>;
  hideSideDrawer?: boolean;
  variant?:
    | typeof AIM
    | typeof ADMIN_DASHBOARD
    | typeof POST_LOGIN_SCREEN
    | null;
}

export const AdminPrivateRouteComponent = ({
  component: Component,
  isAuthenticated,
  sessionHasBeenFetched,
  logout,
  authorized,
  account,
  variant = ADMIN_DASHBOARD,
  hideSideDrawer = false,
  ...rest
}: IPrivateRouteProps) => {
  const { role } = account || { role: "" };
  const ComponentToRender = Component as React.ElementType;
  const renderRedirect = (props: any) => {
    if (!sessionHasBeenFetched) {
      return <div></div>;
    } else {
      return isAuthenticated ? (
        <AdminLayout {...{ ...props, hideSideDrawer, variant }}>
          {(authorized ? authorized.includes(role) : true) ? (
            <ComponentToRender {...props} />
          ) : (
            <UnauthorizedAccess />
          )}
        </AdminLayout>
      ) : logout ? (
        <Redirect
          to={{
            pathname: "/admin/login",
          }}
        />
      ) : (
        <Redirect
          to={{
            pathname: "/admin/login",
            search: props.location.search,
            state: { from: props.location },
          }}
        />
      );
    }
  };

  return <Route {...rest} render={renderRedirect} />;
};

const mapStateToProps = ({
  adminAuthentication: {
    isAuthenticated,
    sessionHasBeenFetched,
    logout,
    account,
  },
}: IRootState) => ({
  isAuthenticated,
  sessionHasBeenFetched,
  logout,
  account,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export const PrivateRoute = connect(mapStateToProps, null, null, {
  pure: false,
})(AdminPrivateRouteComponent);

export default PrivateRoute;
