import { Alert, message, Row, Button, Modal, Select, Input } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { adminRootInstance } from "../../../../../common/config/axios-config";
import { IRootState } from "../../../../../common/redux/reducers";
//this component is dependent on styling of it's parent i.e. request-view
import classes from "../request-view.module.scss";

const DelayModal = (props: any) => {
  const { showModal, toggleModal, reqId } = props;
  const [delayReason, setDelayReason] = useState<any>("");
  const [delayRes, setDelayRes] = useState<any>();
  const [other, setOther] = useState<any>("");

  useEffect(() => {
    return () => {
      setDelayRes({});
      !showModal && setDelayReason("");
      !showModal && setOther("");
    };
  }, [showModal]);

  const delaySubmitHandler = async () => {
    setDelayRes({ loading: true });
    await adminRootInstance
      .put(`order/${reqId}?status=4`, {
        delayDetails: {
          reason: other ? other : delayReason,
        },
      })
      .then((resp: any) => {
        message.success(resp.data.message);
        setDelayRes({ success: true, ...resp.data });
        toggleModal();
      })
      .catch((err: any) => {
        setDelayRes({ success: false, ...err });
      });
  };

  const delayReasons = [
    "Equipment Not Available",
    "Soiled Equipment Needs Cleaning",
    "Speciality Items Requires Restock",
    "Other",
  ];

  return (
    <Modal
      title={<div className={classes.modalHeader}>Delay order</div>}
      centered
      visible={showModal}
      onOk={toggleModal}
      onCancel={toggleModal}
      style={{ minWidth: "300px" }}
      footer={null}
      maskClosable={false}
    >
      <Row
        className={classes.flexMiddle}
        style={{ width: "100%", textAlign: "center", paddingBottom: "10px" }}
      >
        {delayRes?.message && !delayRes.success ? (
          <Alert
            message={delayRes?.message}
            closable
            type="error"
            style={{ width: "100%" }}
          />
        ) : null}
      </Row>
      <Row
        className={classes.flexMiddle}
        style={{ width: "100%", textAlign: "center" }}
      >
        <span style={{ marginRight: "10px" }}>Delay reason </span>{" "}
        <Select
          placeholder="Select delay reason"
          style={{ width: "300px" }}
          value={delayReason}
          onChange={(val) => {
            setDelayReason(val);
          }}
        >
          {delayReasons.map((reason) => {
            return (
              <Select.Option value={reason} key={reason}>
                {reason}
              </Select.Option>
            );
          })}
        </Select>
        {delayReason === "Other" && (
          <Input.TextArea
            placeholder="Enter delay reason"
            style={{ marginTop: "20px" }}
            maxLength={200}
            required={delayReason === "Other" ? true : false}
            onChange={(e) => {
              setOther(e.target.value);
            }}
          />
        )}
        <Row className={classes.modalFooter}>
          <Button
            type="primary"
            loading={delayRes?.loading}
            className={classes.fwdButton}
            disabled={(delayReason === "Other" && !other) || !delayReason}
            onClick={() => delaySubmitHandler()}
          >
            Submit
          </Button>
        </Row>
      </Row>
    </Modal>
  );
};
const mapStateToProps = ({ requestState }: IRootState) => ({
  dataLoading: requestState.loading,
  requestDetails: requestState.selectedRequest,
  errorMsg: requestState.errorMessage,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DelayModal);
