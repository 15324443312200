export const REQUESTOR_LOGIN_SUCCESS = "REQUESTOR_LOGIN_SUCCESS";
export const REQUESTOR_LOGIN_FAIL = "REQUESTOR_LOGIN_FAIL";
export const REQUESTOR_CLEAR_ERROR = "REQUESTOR_CLEAR_ERROR";
export const REQUESTOR_LOGOUT = "REQUESTOR_LOGOUT";
export const REQUESTOR_FETCH_ACCOUNT_SUCCESS =
	"REQUESTOR_FETCH_ACCOUNT_SUCCESS";
export const REQUESTOR_AUTO_LOGIN_SUCCESS = "REQUESTOR_AUTO_LOGIN_SUCCESS";
export const REQUESTOR_AUTO_LOGIN_FAIL = "REQUESTOR_AUTO_LOGIN_FAIL";
export const REQUESTOR_CLEAR_AUTH_MSG = "REQUESTOR_CLEAR_AUTH_MSG";
export const REQUESTOR_LOGIN_REQ_START = "REQUESTOR_LOGIN_REQ_START";
export const CONTINUE_WITHOUT_LOGIN = "CONTINUE_WITHOUT_LOGIN";
export const EXIT_WITHOUT_LOGIN = "EXIT_WITHOUT_LOGIN";
export const SET_ANONYMOUS_STATUS = "SET_ANONYMOUS_STATUS";

