/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { Button, Alert, Typography } from "antd";
import { FormOutlined, EyeOutlined } from "@ant-design/icons";
import ListComponent from "../../shared/components/list-component/list-component";
import { IRootState } from "../../../common/redux/reducers";
import { connect } from "react-redux";
import { fetchTransports, updateTransport } from "./actions";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_LIMIT,
  VIEW_TEXT,
  EDIT_TEXT,
  VIEW,
  EDIT,
  BUTTON_GREEN_HEX,
  TRANSPORT_PAGE,
} from "../../../common/shared-constants";
import TransportModal from "./transport-modals/transport-modal";
import TransportViewModal from "./transport-modals/transport-view-modal";
import { AbilityContext } from "../../shared/ability/can";
import { useAbility } from "@casl/react";
import { Helmet } from "react-helmet";
import classes from "./transport.module.scss";
import {
  CLEAR_TRANSPORT_MSG,
  CLEAR_TRANSPORT_FETCH_ERROR_MSG,
} from "./action-types";
import { clearMessage } from "../../../common/actions/common-actions";
const { Title } = Typography;

const TransportPage = ({
  transports,
  transportsLoading,
  totalCount,
  errorMsg,
  fetchTransports,
  successMsg,
  fetchErrorMsg,
  clearMessage,
  updateTransport,
}: any) => {
  const ability = useAbility(AbilityContext);
  const [loading, setLoading] = useState(false);
  const [tableOpt, setOperation] = useState({
    search: "",
    limit: DEFAULT_PAGE_LIMIT,
    page: DEFAULT_PAGE,
    pageSizeOptions: [30, 50, 100, 120],
    showSizeChanger: true,
  });

  const { search, page, limit, showSizeChanger, pageSizeOptions } = tableOpt;

  const [modalOp, setModalOp] = useState({
    visible: false,
    mode: EDIT,
    transport: null,
    title: "Edit Transport",
    label: "transport",
  });

  useEffect(() => {
    modalOp.visible && clearMessage(CLEAR_TRANSPORT_MSG);
  }, [modalOp.mode, modalOp.visible]);

  useEffect(() => {
    fetchTransports({ search: search, limit, page: DEFAULT_PAGE });
    return () => {
      clearMessages();
      clearFetchError();
    };
  }, []);

  useEffect(() => {
    let timer: any;
    if (successMsg) {
      timer = setTimeout(() => {
        clearMessages();
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [successMsg]);

  const clearMessages = () => {
    clearMessage(CLEAR_TRANSPORT_MSG);
  };
  const clearFetchError = () => {
    clearMessage(CLEAR_TRANSPORT_FETCH_ERROR_MSG);
  };

  const handleCancel = () => {
    setModalOp({
      ...modalOp,
      visible: false,
    });
  };

  const onView = (transport: any) => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: VIEW,
      transport,
    });
  };

  const onEdit = (transport: any) => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: EDIT,
      transport,
    });
  };

  const onSearch = ({ target }: any) => {
    setOperation({
      ...tableOpt,
      search: target.value,
      page: DEFAULT_PAGE,
    });
    fetchTransports({ search: target.value, limit, page: DEFAULT_PAGE });
  };

  const handleTableChange = (pagination: any) => {
    setOperation({
      ...tableOpt,
      page: pagination.current,
      limit: pagination.pageSize,
    });
    fetchTransports({
      search,
      limit: pagination.pageSize,
      page: pagination.current,
    });
  };

  const handleSubmit = async (transport: any) => {
    setLoading(true);
    let result: any = await updateTransport(transport);
    if (result.success) {
      fetchTransports({ search, limit, page: page });
      setModalOp({
        ...modalOp,
        visible: false,
      });
    }
    setLoading(false);
  };

  const columns: any = [
    {
      align: "center",
      title: "Campus Name",
      dataIndex: "campusId",
      width: "18%",
      key: "campusName",
      render: (campus: any) => campus?.name,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Procedure",
      dataIndex: "procedureRequest",
      width: "18%",
      key: "procedureRequest",
      render: (procedureRequest: any) => (procedureRequest ? "ON" : "OFF"),
      ellipsis: true,
    },
    {
      align: "center",
      title: "Return",
      dataIndex: "returnRequest",
      width: "18%",
      key: "returnRequest",
      render: (returnRequest: any) => (returnRequest ? "ON" : "OFF"),
      ellipsis: true,
    },
    {
      align: "center",
      title: "Discharge",
      dataIndex: "dischargeRequest",
      width: "18%",
      key: "dischargeRequest",
      render: (dischargeRequest: any) => (dischargeRequest ? "ON" : "OFF"),
      ellipsis: true,
    },
    {
      align: "center",
      title: "Transfer",
      dataIndex: "transferRequest",
      width: "18%",
      key: "transferRequest",
      render: (transferRequest: any) => (transferRequest ? "ON" : "OFF"),
      ellipsis: true,
    },
    {
      align: "center",
      title: "Actions",
      key: "action",
      width: "10%",
      ellipsis: true,
      render: (record: any) => (
        <div className="actionIcons">
          <Button
            size="small"
            className="icon-btn"
            title={VIEW_TEXT}
            onClick={() => {
              onView(record);
            }}
          >
            <EyeOutlined
              style={{ color: BUTTON_GREEN_HEX }}
              className="edit-icon"
            />
          </Button>
          {ability.can("edit", "Transport") && (
            <Button
              size="small"
              className="icon-btn"
              title={EDIT_TEXT}
              onClick={() => {
                onEdit(record);
              }}
            >
              <FormOutlined
                style={{ color: BUTTON_GREEN_HEX }}
                className="edit-icon"
              />
            </Button>
          )}
        </div>
      ),
      responsive: ["lg", "md"],
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Transport | Admin Portal</title>
      </Helmet>
      <Title className={classes.titleText} level={5}>
        Transport
      </Title>
      {successMsg ? (
        <Alert
          className={`fadeIn ${classes.alertContainer}`}
          message={successMsg}
          type="success"
          closable
          afterClose={clearMessages}
        />
      ) : null}
      {fetchErrorMsg ? (
        <Alert
          className={`fadeIn ${classes.alertContainer}`}
          message={fetchErrorMsg}
          type="error"
          closable
          afterClose={clearFetchError}
        />
      ) : null}
      <ListComponent
        type={TRANSPORT_PAGE}
        loading={transportsLoading}
        dataSource={transports}
        columns={columns}
        handleTableChange={handleTableChange}
        onSearch={onSearch}
        pagination={{
          current: page,
          pageSize: limit,
          total: totalCount,
          showSizeChanger,
          pageSizeOptions,
        }}
        searchLabel={"Search by Name"}
      />
      {modalOp.mode === VIEW ? (
        <TransportViewModal
          handelClose={handleCancel}
          visible={modalOp.visible}
          transport={modalOp.transport}
        />
      ) : null}
      {modalOp.mode === EDIT && modalOp.visible ? (
        <TransportModal
          visible={modalOp.visible}
          transport={modalOp.transport}
          mode={modalOp.mode}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          errorMsg={errorMsg}
          clearMessages={clearMessages}
          loading={loading}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = ({ transportManagement }: IRootState) => ({
  errorMsg: transportManagement.errorMessage,
  successMsg: transportManagement.successMessage,
  transportsLoading: transportManagement.loading,
  transports: transportManagement.transports,
  totalCount: transportManagement.totalCount,
  fetchErrorMsg: transportManagement.fetchErrorMessage,
});
const mapDispatchToProps = {
  fetchTransports,
  updateTransport,
  clearMessage,
};
export default connect(mapStateToProps, mapDispatchToProps)(TransportPage);
