/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Input,
  Select,
  Alert,
  Spin,
} from "antd";
import classes from "./user-modal.module.scss";
import "../../../shared/scss/shared-modal.scss";
import { Link } from "react-router-dom";
import { trimValues } from "../../../../common/utils/trimUtil";
import { CloseOutlined } from "@ant-design/icons";
import { CREATE, EDIT } from "../../../../common/shared-constants";
import { adminRootInstance } from "../../../../common/config/axios-config";
import {
  firstNameValidator,
  lastNameValidator,
} from "../../../../common/utils/formValidators";

const UserModal = ({
  user,
  mode,
  visible,
  handleCancel,
  handleSubmit,
  roles,
  errorMsg,
  clearMessages,
  loading,
  loggedInUser,
  requestTypes,
}: any) => {
  const [editPassword, setEditPassword] = useState(false);
  const { Option } = Select;
  const [userNameError, setUserNameError] = useState<any>("");
  const [emailError, setEmailError] = useState("");
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    clearMessages();
  }, []);

  const validateUser = async (username: any) => {
    return await adminRootInstance
      .get("/username/" + username)
      .then((res: any) => {
        return Promise.resolve("");
      })
      .catch((err: any) => {
        return Promise.reject(err.response.data.message);
      });
  };

  const checkUserName = async (username: any) => {
    if (mode !== EDIT) {
      try {
        await validateUser(username);
        setUserNameError("");
      } catch (err) {
        setUserNameError(err);
      }
    }
  };

  const onFormSubmit = async (values: any) => {
    if (submitted) {
      return;
    }
    if (emailError.length === 0 && userNameError.length === 0) {
      setSubmitted(true);

      const trimedValues = trimValues(values);
      await handleSubmit(trimedValues);
      setSubmitted(false);
    }
  };

  const formItemLayout = {
    labelCol: {
      sm: { span: 24 },
      md: { span: 8 },
    },
    wrapperCol: {
      sm: { span: 24 },
      md: { span: 16 },
    },
  };

  const emailFormItemLayout = {
    labelCol: {
      sm: { span: 24 },
      md: { span: 4 },
    },
    wrapperCol: {
      sm: { span: 24 },
      md: { span: 20 },
    },
  };
  let tempUser: any;
  let tempTypeArr = [] as Array<any>;
  if (user) {
    if (user.requestType.length !== 0) {
      user.requestType.forEach((reqt: any) => {
        if (reqt?.requestTypeId?.name) {
          tempTypeArr.push(reqt?.requestTypeId?.id);
        }
      });
      tempUser = { ...user, requestTypeIds: tempTypeArr };
    } else {
      tempUser = { ...user };
    }
  }

  return (
    <React.Fragment>
      <Modal
        title={
          <div className="commonModalHeader">
            <div className="title">
              <p> {mode === EDIT ? "Edit User" : "Create User"}</p>
            </div>
            <div className="close">
              <Button className="closeBtn" onClick={handleCancel}>
                <CloseOutlined className="close-icon" />
              </Button>
            </div>
          </div>
        }
        visible={visible}
        width={1000}
        maskClosable={false}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        centered={true}
        wrapClassName="adminDashBoardPopup"
      >
        {errorMsg ? (
          <Alert
            message={errorMsg}
            type="error"
            closable
            afterClose={clearMessages}
            className={`fadeIn ${classes.errorContainer}`}
          />
        ) : null}
        <Form
          layout="horizontal"
          initialValues={tempUser}
          onFinish={onFormSubmit}
          {...formItemLayout}
          labelAlign="left"
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Form.Item name="id" hidden />
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  () => ({
                    validator: firstNameValidator,
                    required: true,
                  }),
                ]}
              >
                <Input
                  autoFocus={mode !== EDIT}
                  placeholder="Enter here"
                  className="inputStyle"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  () => ({
                    validator: lastNameValidator,
                    required: true,
                  }),
                ]}
              >
                <Input placeholder="Enter here" className="inputStyle" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item hidden>
                <Input type="text" id="prevent_autofill" />
              </Form.Item>
              <Form.Item
                name="username"
                label="Username"
                help={userNameError?.length ? userNameError : null}
                validateStatus={userNameError?.length ? "error" : "success"}
                rules={[
                  () => ({
                    async validator(rule, value) {
                      if (value && value.length) {
                        if (!value.match(/^(?=[a-zA-Z])/)) {
                          setUserNameError(
                            "Please enter a valid Username.It must start with alphabet."
                          );
                        } else if (
                          !value.match(
                            /^[a-zA-Z]+[a-zA-Z0-9._\s]*[a-zA-Z0-9\s]$/
                          )
                        ) {
                          if (!value.match(/^.*[A-Z|a-z|0-9|\s]$/))
                            return setUserNameError(
                              "Last character must be an alphanumeric"
                            );
                          if (
                            !value.match(
                              /^[A-Za-z][A-Za-z0-9._\s]*[A-Z|a-z|0-9]$/
                            )
                          )
                            return setUserNameError(
                              "Special characters other than . and _ are not allowed."
                            );
                        } else if (value.trim().length < 6) {
                          setUserNameError(
                            "Username should be minimum of 6 characters."
                          );
                        } else if (value.trim().length > 30) {
                          setUserNameError(
                            "Username should be maximum of 30 characters."
                          );
                        } else if (value.match(/^.*\s{2,}.*$/)) {
                          setUserNameError("Maximum one space is allowed.");
                        } else {
                          await checkUserName(value);
                        }
                      } else {
                        setUserNameError("Please enter Username!");
                      }
                      return Promise.resolve();
                    },
                    required: true,
                  }),
                ]}
              >
                <Input
                  disabled={mode === EDIT}
                  placeholder="Enter here"
                  className="inputStyle"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="role"
                label="Role"
                rules={[{ required: true, message: "Please select the Role!" }]}
              >
                <Select
                  disabled={user?.id === loggedInUser?.id}
                  className={classes.roleSelect}
                  placeholder="Choose here"
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option?.children?.props?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {roles &&
                    roles.map((role: any) => (
                      <Option key={role} value={role}>
                        <div className="roleName">{role}</div>
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Email"
                help={emailError.length ? emailError : null}
                {...emailFormItemLayout}
                validateStatus={emailError.length ? "error" : "success"}
                rules={[
                  () => ({
                    async validator(rule, value) {
                      if (value && value.length) {
                        if (
                          !value.match(
                            /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/
                          ) ||
                          !value.match(/^[a-z]/)
                        ) {
                          setEmailError("Please enter a valid Email.");
                        } else if (
                          value.split("@")[0].includes("..") ||
                          value.split("@")[0].includes("--")
                        ) {
                          setEmailError("Please enter a valid Email.");
                        } else if (
                          value.split("@")[0].endsWith(".") ||
                          value.split("@")[0].endsWith("-")
                        ) {
                          setEmailError("Please enter a valid Email.");
                        } else if (value.trim().length < 8) {
                          setEmailError(
                            "Email should be minimum of 8 characters."
                          );
                        } else if (value.trim().length > 50) {
                          setEmailError(
                            "Email should be maximum of 50 characters."
                          );
                        } else {
                          setEmailError("");
                        }
                      } else {
                        setEmailError("Please enter Email!");
                      }
                      return Promise.resolve();
                    },
                    required: true,
                  }),
                ]}
              >
                <Input
                  placeholder="Enter here"
                  autoComplete="new-email"
                  className={classes.popupEmailInput}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="requestTypeIds"
                label="Assigned User Type"
                rules={[
                  {
                    required: true,
                    message: "Please select the Assigned User Type!",
                  },
                ]}
                {...emailFormItemLayout}
              >
                <Select
                  className="fullWidth"
                  mode="multiple"
                  placeholder="Choose here"
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option?.children?.props?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {requestTypes?.map((request: any) => {
                    return <Option value={request.id}>{request.name}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {mode === EDIT ? (
            <Link
              to="#"
              onClick={() => setEditPassword(true)}
              className={classes.changePasswordStyle}
            >
              Change Password
            </Link>
          ) : null}
          {mode === CREATE || (mode === EDIT && editPassword) ? (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    () => ({
                      validator(rule, value) {
                        if (value && value.length) {
                          if (
                            value.match(
                              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,30}/
                            )
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "Password must contain at least eight characters, including uppercase, lowercase letters, numbers and special characters."
                          );
                        } else {
                          return Promise.reject("Please enter Password!");
                        }
                      },
                      required: true,
                    }),
                  ]}
                >
                  <Input.Password
                    autoComplete="new-password"
                    placeholder="Enter here"
                    className="passwordStyle"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="confirmation"
                  label={"Confirm Password"}
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please enter Confirm Password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Password does not match!");
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Enter here"
                    className="passwordStyle"
                  />
                </Form.Item>
              </Col>
            </Row>
          ) : null}
          <div className="modalBtn">
            <Button
              className="cancelBtn"
              htmlType="reset"
              onClick={handleCancel}
              style={{ marginRight: "25px" }}
            >
              Cancel
            </Button>
            <Spin spinning={loading}>
              <Button className="submitBtn" htmlType="submit">
                Submit
              </Button>
            </Spin>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
export default UserModal;
