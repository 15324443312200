/* eslint-disable react-hooks/exhaustive-deps*/
import { Card, Row, Col } from "antd";
import { connect } from "react-redux";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import classes from "../common-reports.module.scss";
import SlaForm from "./sla-form";
import ReportSummaryTable from "./sla-summary-report";
import ReportDetailTable from "./sla-detailed-report";
import {
  fetchAllOrderTypes,
  fetchAllSLAs,
  fetchAllUnits,
  fetchSLABasedReport,
  resetReportState,
} from "../../actions";
import { IRootState } from "../../../../../common/redux/reducers";
import { useEffect } from "react";
import { BackButton, ReportsBreadCrumbComponent } from "../commons/utils";
import DownloadCSV from "../commons/download-csv";

const SlaReports = ({
  fetchAllSLAs,
  fetchAllOrderTypes,
  fetchAllUnits,
  fetchSLABasedReport,
  resetReportState,
  reportData,
  filterData,
}: any) => {
  const [reportView, setReportView] = useState("form");
  const submitHandler = async (params: any) => {
    const resp = await fetchSLABasedReport({ limit: 30, page: 1, ...params });
    if (resp.success) {
      onViewChange(params.reportType);
    }
  };
  const onViewChange = (view: any) => {
    setReportView(view);
  };

  useEffect(() => {
    fetchAllSLAs();
    fetchAllOrderTypes();
    fetchAllUnits();
    return () => {
      resetReportState();
    };
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <title>Reports | Admin Portal</title>
      </Helmet>
      <ReportsBreadCrumbComponent
        reportType="SLA Report"
        reportView={reportView}
      />
      <Card className={classes.reportsContainer} bodyStyle={{ width: "100%" }}>
        <Row>
          <Col span={20}>
            <BackButton
              reportView={reportView}
              navigateToForm={() => setReportView("form")}
            />
          </Col>
          {reportView !== "form" && reportData?.data?.length ? (
            <Col span={4} className={classes.downloadCSVButton}>
              <Row>
                <DownloadCSV filterData={filterData} reportType="sla" />
              </Row>
            </Col>
          ) : (
            <div />
          )}
        </Row>
        {reportView === "form" ? (
          <SlaForm submitHandler={submitHandler} />
        ) : reportView === "summary" ? (
          <ReportSummaryTable />
        ) : (
          <ReportDetailTable />
        )}
      </Card>
    </div>
  );
};
const mapStateToProps = ({ reportsState }: IRootState) => ({
  dataLoading: reportsState.loading,
  allSla: reportsState.allSlas,
  allOrderTypes: reportsState.allOrderTypes,
  successMsg: reportsState.successMessage,
  reportData: reportsState.reportData,
  filterData: reportsState.filterData,
});
const mapDispatchToProps = {
  fetchAllSLAs,
  fetchAllOrderTypes,
  fetchAllUnits,
  fetchSLABasedReport,
  resetReportState,
};
export default connect(mapStateToProps, mapDispatchToProps)(SlaReports);
