import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Spin, Alert, Typography, Row, Input } from "antd";
import { InfoCircleOutlined, CloseOutlined } from "@ant-design/icons";

const { Text } = Typography;

const ReVerifyAsset = ({
  setOpenModal,
  openModal,
  verifyAsset,
  isLoading,
}: any) => {
  return (
    <Modal
      title={
        <div className="commonModalHeader">
          <div className="title">
            <p>Re-verify Asset</p>
          </div>
          <div className="close">
            <Button className="closeBtn" onClick={() => setOpenModal(false)}>
              <CloseOutlined className="close-icon" />
            </Button>
          </div>
        </div>
      }
      visible={openModal}
      width={400}
      onCancel={() => setOpenModal(false)}
      maskClosable={false}
      footer={null}
      closable={false}
    >
      <>
        <Row align={"middle"} justify="center">
          <InfoCircleOutlined style={{ fontSize: "25px" }} />
          <Text style={{ paddingLeft: "10px" }}>
            Are you sure you want to re-verify this asset?
          </Text>
        </Row>
        <Form
          layout="vertical"
          {...{
            onFinish: verifyAsset,
          }}
        >
          <Form.Item name="id" hidden />
          <div className="modalBtn">
            <Form.Item style={{ marginRight: "3%" }}>
              <Button
                className="cancelBtn"
                htmlType="reset"
                onClick={() => setOpenModal(false)}
              >
                No
              </Button>
            </Form.Item>
            <Form.Item>
              <Spin spinning={isLoading}>
                <Button className="submitBtn" htmlType="submit">
                  Yes
                </Button>
              </Spin>
            </Form.Item>
          </div>
        </Form>
      </>
    </Modal>
  );
};

export default ReVerifyAsset;
