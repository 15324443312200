/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import login from "../../admin/modules/auth/login";
import dashBoard from "../../admin/modules/dashboard/dashboard";
import User from "../../admin/modules/user/user";
import SLA from "../../admin/modules/sla/sla";
import Campus from "../../admin/modules/campus/campus";
import Unit from "../../admin/modules/unit/unit";
import Item from "../../admin/modules/items/items";
import Reports from "../../admin/modules/reports/reports";
import SlaReports from "../../admin/modules/reports/report-types/sla/sla-reports";
import PatientReport from "../../admin/modules/reports/report-types/patient-transport/patient-transports";
import StatusReports from "../../admin/modules/reports/report-types/status/status-reports";
import ItemsReports from "../../admin/modules/reports/report-types/items/items-reports";
import ProductivityReports from "../../admin/modules/reports/report-types/productivity/productivity-reports";
import SpecialityItemsReport from "../../admin/modules/reports/report-types/speciality-items/speciality-items-report";
import OverallReport from "../../admin/modules/reports/report-types/overall/overall-report";
import AdminPrivateRoute from "../../admin/shared/admin-private-route";
import { AbilityContext } from "../../admin/shared/ability/can";
import ability from "../../admin/shared/ability/ability";
import { autoLogin } from "../../admin/modules/auth/actions";
import { connect } from "react-redux";
import RequestView from "../../admin/modules/dashboard/request-view/request-view";
import NotFound from "../components/not-found";
import moment from "moment";
import {
  Admin,
  AIM,
  Dispatcher,
  Lead,
  POST_LOGIN_SCREEN,
  Supervisor,
} from "../shared-constants";
import AdminPostLogin from "../../admin/modules/AdminPostLogin/AdminPostLogin";
import AIMDashboard from "../../admin/modules/AIM/AIMDashboard/AIMDashboard";
import DataImportComponent from "../../admin/modules/data-import";
import AIMReports from "../../admin/modules/AIM/AIMReports/AIMReports";
import AIMOverallReport from "../../admin/modules/AIM/AIMReports/AIMOverallReport/AIMOverallReport";
import CheckinReport from "../../admin/modules/AIM/AIMReports/CheckinReport/CheckinReport";
import RentalReport from "../../admin/modules/AIM/AIMReports/RentalReport/RentalReport";
import DeconReport from "../../admin/modules/AIM/AIMReports/DeconReport/DeconReport";
import AssetCheckInDecon from "../../admin/modules/AIM/AssetCheckinDecon/AssetCheckinDecon";
import Audit from "../../admin/modules/AIM/audit/audit";
import TransportComponent from "../../admin/modules/transport/transport";

const AdminRoutes = ({ match, autoLogin }: any) => {
  useEffect(() => {
    autoLogin(ability);
    moment.relativeTimeRounding(Math.floor); //for notification - changing the relative time to accurate diff
  }, []);
  return (
    <>
      <AbilityContext.Provider value={ability}>
        <Switch>
          <Route exact path={`${match.url}/login`} component={login} />
          <AdminPrivateRoute
            exact
            path={`${match.url}/`}
            component={AdminPostLogin}
            hideSideDrawer={true}
            variant={POST_LOGIN_SCREEN}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/users`}
            component={User}
            authorized={[Admin, Lead, Supervisor]}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/slas`}
            component={SLA}
            authorized={[Admin, Lead, Supervisor]}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/dashboard`}
            component={dashBoard}
            authorized={[Admin, Lead, Supervisor, Dispatcher]}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/campuses`}
            component={Campus}
            authorized={[Admin, Lead, Supervisor]}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/units`}
            component={Unit}
            authorized={[Admin, Lead, Supervisor]}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/items`}
            component={Item}
            authorized={[Admin, Lead, Supervisor]}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/reports`}
            component={Reports}
            authorized={[Admin, Lead, Supervisor]}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/reports/sla-reports`}
            component={SlaReports}
            authorized={[Admin, Lead, Supervisor]}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/reports/status-reports`}
            component={StatusReports}
            authorized={[Admin, Lead, Supervisor]}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/reports/items-reports`}
            component={ItemsReports}
            authorized={[Admin, Lead, Supervisor]}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/reports/productivity-reports`}
            component={ProductivityReports}
            authorized={[Admin, Lead, Supervisor]}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/reports/speciality-items-reports`}
            component={SpecialityItemsReport}
            authorized={[Admin, Lead, Supervisor]}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/reports/overall-reports`}
            component={OverallReport}
            authorized={[Admin, Lead, Supervisor]}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/data-import`}
            component={DataImportComponent}
            authorized={[Admin]}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/reports/patient-reports`}
            component={PatientReport}
            authorized={[Admin, Lead, Supervisor]}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/transport`}
            component={TransportComponent}
            authorized={[Admin]}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/dashboard/order/:id`}
            component={RequestView}
            authorized={[Admin, Lead, Supervisor, Dispatcher]}
          />
          {/* AIM Routes */}
          <AdminPrivateRoute
            exact
            path={`${match.url}/aim/dashboard`}
            component={AIMDashboard}
            variant={AIM}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/aim/checkin-decon`}
            component={AssetCheckInDecon}
            variant={AIM}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/aim/reports`}
            component={AIMReports}
            variant={AIM}
            authorized={[Admin, Lead, Supervisor]}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/aim/reports/aim-overall`}
            component={AIMOverallReport}
            variant={AIM}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/aim/reports/rental`}
            component={RentalReport}
            variant={AIM}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/aim/reports/checkin`}
            component={CheckinReport}
            variant={AIM}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/aim/audit`}
            component={Audit}
            variant={AIM}
          />
          <AdminPrivateRoute
            exact
            path={`${match.url}/aim/reports/decon`}
            component={DeconReport}
            variant={AIM}
          />
          <Redirect
            exact
            from={`${match.url}/aim`}
            to={`${match.url}/aim/dashboard`}
          />
          {/* Re routes req to admin/ to admin/dashboard -- DEPRECATED */}
          {/* <Redirect exact from={`${match.url}`} to={`${match.url}/dashboard`} /> */}
          <Route path={"*"} component={NotFound} />
        </Switch>
      </AbilityContext.Provider>
    </>
  );
};

const mapDispatchToProps = { autoLogin };

export default connect(null, mapDispatchToProps)(AdminRoutes);
