import { requestorRootInstance } from "../config/axios-config";

export function sendEmail(errorLog: any) {
  requestorRootInstance
    .post("send-email", {
      errorLog,
    })
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err;
    });
}
