import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Modal, Typography, Button, Row, Col } from "antd";
import classes from "./campus-view-modal.module.scss";
const { Text } = Typography;

const CampusViewModal = ({ handelClose, campus, visible }: any) => {
  return (
    <Modal
      title={
        <div className="commonModalHeader">
          <div className="title">
            <p> View Campus </p>
          </div>
          <div className="close">
            <Button className="closeBtn" onClick={handelClose}>
              <CloseOutlined className="close-icon" />
            </Button>
          </div>
        </div>
      }
      visible={visible}
      maskClosable={false}
      onCancel={handelClose}
      footer={null}
      closable={false}
      width={400}
    >
      <Row>
        <Col span={11}>
          <Text className={classes.doubleDot}>Name</Text>
        </Col>
        <Col span={13}>
          <Text className={classes.popValue}>{campus.name} </Text>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <Text className={classes.doubleDot}> Location</Text>
        </Col>
        <Col span={13}>
          <Text className={classes.popValue}>{campus.location}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <Text className={classes.doubleDot}> Banner Text</Text>
        </Col>
        <Col span={13}>
          <Text className={classes.popValue}>
            {campus.bannerMessage || "-"}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <Text className={classes.doubleDot}> Banner Show</Text>
        </Col>
        <Col span={13}>
          <Text className={classes.popValue}>
            {campus.bannerStatus ? "ON" : "OFF"}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <Text className={classes.doubleDot}> Request Type</Text>
        </Col>
        <Col span={13}>
          <Text className={classes.popValue}>
            {campus?.requestTypes.length
              ? campus?.requestTypes.map((reqt: any) => (
                  <p className={classes.requestTypes}>{reqt?.name}</p>
                ))
              : "-"}
          </Text>
        </Col>
      </Row>
      <Row justify={"center"} className={classes.textContainer}>
        <Button
          className={classes.popClose}
          type="primary"
          htmlType="reset"
          onClick={handelClose}
        >
          Close
        </Button>
      </Row>
    </Modal>
  );
};

export default CampusViewModal;
