/* eslint-disable react-hooks/exhaustive-deps*/
import { Button, message } from "antd";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { adminRootInstance } from "../../../../../common/config/axios-config";
import { getCSVFileConfig } from "./utils";

interface DownloadCSVInterface {
  filterData: any;
  reportType:
    | "sla"
    | "status"
    | "item"
    | "productivity"
    | "spl-item"
    | "overall"
    | "transport";
  reportData?: any;
}

export default function DownloadCSV(props: DownloadCSVInterface) {
  const { filterData: reportsFilter, reportType } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const getReportsData = async () => {
    //hit csv api using th filter data, if successful - set the csv file config to it - else clear it
    setLoading(true);
    let rawData: any = await ReportsAPICall(reportType, reportsFilter);
    if (rawData?.success) {
      setLoading(false);
      message.success(rawData.message);
    } else {
      setLoading(false);
      message.error(rawData.errorMessage);
    }
  };
  return (
    <>
      <Button type="primary" onClick={() => getReportsData()} loading={loading}>
        Email CSV
      </Button>
    </>
  );
}

const ReportsAPICall = async (type: string, reportsFilter: any) => {
  const {
    reportType,
    orderType,
    units,
    fromDate,
    toDate,
    sla,
    status,
    items,
    users,
    specialityItem,
    request,
    fromUnit,
    toUnit,
    toDischargeLocation,
  } = reportsFilter;

  return await adminRootInstance
    .get(`reports/${type}`, {
      params: {
        isCsv: true,
        type: reportType,
        from: fromDate.format("YYYY-MM-DD"),
        to: toDate.format("YYYY-MM-DD"),
        ...(orderType?.length ? { request: orderType.join(",") } : {}),
        ...(units?.length ? { unit: reportsFilter.units.join(",") } : {}),
        ...(sla
          ? { sla: reportType === "detailed" ? sla : sla.join(",") }
          : {}),
        ...(status
          ? { status: reportType === "detailed" ? status : status.join(",") }
          : {}),
        ...(items
          ? { items: reportType === "detailed" ? items : items.join(",") }
          : {}),
        ...(users
          ? { users: reportType === "detailed" ? users : users.join(",") }
          : {}),
        ...(specialityItem?.length
          ? { splItems: specialityItem.join(",") }
          : {}),
        ...(request?.length
          ? { request: reportsFilter.request.join(",") }
          : {}),
        ...(fromUnit?.length
          ? { fromUnit: reportsFilter.fromUnit.join(",") }
          : {}),
        ...(toUnit?.length ? { toUnit: reportsFilter.toUnit.join(",") } : {}),
        ...(toDischargeLocation?.length
          ? { toDischargeLocation: reportsFilter.toDischargeLocation.join(",") }
          : { toDischargeLocation: "all" }),
      },
    })
    .then((res: any) => {
      if (res?.data?.status === "success")
        return {
          success: true,
          message: res?.data?.message,
        };
    })
    .catch((err) => {
      return { success: false, errorMessage: err?.response?.data?.message };
    });
};
