import { Button, Modal, Row } from "antd";
import { WarningTwoTone } from "@ant-design/icons";
import classes from "../components/DelayModal/DelayModal.module.scss";
interface DelayModalInterface {
  isModalVisible: boolean;
  handleOk: () => any;
  handleCancel: () => any;
  text: any;
  icon?: any;
  okayText?: string;
  cancelText?: string;
  hideCancel?: boolean;
}

const AnonymousModal = (props: DelayModalInterface) => {
  const {
    isModalVisible,
    handleOk,
    handleCancel,
    text,
    icon,
    okayText = "Confirm",
  } = props;
  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      wrapClassName="confirmPopup"
      maskClosable={false}
      centered={true}
      footer={null}
      width={500}
    >
      <Row align="middle" justify="center">
        {icon ? (
          icon
        ) : (
          <WarningTwoTone
            twoToneColor="red"
            style={{ color: "red", fontSize: "50px" }}
          />
        )}
      </Row>
      <Row align="middle" justify="center" className={classes.delayDescription}>
        {text}
      </Row>
      <Row className={classes.modalBtns} align={"middle"} justify="center">
        <Button className={`nextBtn `} onClick={handleOk}>
          {okayText}
        </Button>
      </Row>
    </Modal>
  );
};
export default AnonymousModal;
