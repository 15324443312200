import {
  Card,
  Tooltip,
  Button,
  Input,
  Upload,
  Image,
  Empty,
  Col,
  message,
  Row,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import classes from "../request-view.module.scss";
import { useState } from "react";
import NotesImageUploadModal from "../notesImageUploadModal/NotesImageUploadModal";
import { cardHeaderStyle, cardsBodyStyle } from "../request-view";
import { useSelector } from "react-redux";
import { startFromBottom } from "../../../../../common/utils/startFromBottom";
import { adminRootInstance } from "../../../../../common/config/axios-config";
import { useEffect } from "react";
import moment from "moment-timezone";
import { CharCounter } from "../../commons/utils";
const RequestViewNotes = (props: any) => {
  const { type = "Notes", data = [], loading = false } = props;
  const isNotes = type === "Notes" ? true : false;
  const [showImageUploadModal, setImageUploadModalFlag] = useState(false);
  const [notesObj, setNotesObj] = useState({ text: "", img: {} });
  const [showCharCount, setShowCharCount] = useState(false);
  const { reqInfo: { reqId = "" } = {} } = useSelector(
    (s: any) => s?.requestState?.selectedRequest || {}
  );
  const [resp, setResp] = useState<any>();
  useEffect(() => {
    startFromBottom("notesView"); //to scroll to bottom
  }, [data, loading]);
  const changeHandler = (text: any) => {
    setNotesObj({ text, img: {} });
  };

  return (
    <Card
      title={type}
      headStyle={cardHeaderStyle}
      bodyStyle={{ ...cardsBodyStyle, marginBottom: "0px" }}
      className={classes.notesSection}
      style={{ marginRight: "0px", height: "90%" }}
    >
      <Row
        id={isNotes ? "notesView" : "activitiesView"}
        className={classes.notesRow}
        style={{
          ...(isNotes ? { maxHeight: "185px" } : { maxHeight: "230px" }),
        }}
      >
        {data?.length ? (
          [
            ...data.map((section: any) => {
              let formattedDate = process.env.REACT_APP_SSO_ENABLED
                ? moment(new Date(section.created)).format("MMM DD, YYYY")
                : moment(new Date(section.created))
                    .utc()
                    .tz("America/New_York")
                    .format("MMM DD, YYYY");
              let formattedTime = process.env.REACT_APP_SSO_ENABLED
                ? moment(new Date(section.created)).format("hh:mm a")
                : moment(new Date(section.created))
                    .utc()
                    .tz("America/New_York")
                    .format("hh:mm a");
              return (
                <NotesHistorySection
                  note={section.message}
                  userAdded={isNotes ? section.addedBy : section.username == null && props.orderExpired ? 'System' : section.username}
                  dateAdded={
                    <div>
                      <span className={classes.noBreak}>
                        {formattedDate}&nbsp;
                      </span>
                      <span className={classes.noBreak}>{formattedTime}</span>
                    </div>
                  }
                  imageUrl={section.attachment}
                />
              );
            }),
            ...(loading ? [<Spin />] : []),
          ]
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Row>
      {isNotes && (
        <>
          <hr style={{ margin: "5px 0px" }} />
          <Row className={classes.flexMiddle}>
            <Col span={18} className={classes.flexMiddle}>
              <Upload
                maxCount={1}
                customRequest={() => {}}
                showUploadList={false}
                accept=".jpg,.png,.jpeg"
                beforeUpload={(file) => {
                  if (
                    file.type !== "image/png" &&
                    file.type !== "image/jpeg" &&
                    file.type !== "image/jpg"
                  ) {
                    message.error(
                      "Unsupported format, Please choose jpeg/jpg/png"
                    );
                    return Upload.LIST_IGNORE;
                  } else if (file.size > 10485760) {
                    message.error(
                      "Unsupported Size, Please choose file below 10MB"
                    );
                    return Upload.LIST_IGNORE;
                  } else {
                    setNotesObj({ ...notesObj, img: file });
                    setImageUploadModalFlag(true);
                    return false;
                  }
                }}
              >
                <Tooltip title="Upload Image">
                  <Button
                    icon={<UploadOutlined />}
                    className={classes.uploadButton}
                  />
                </Tooltip>
              </Upload>
              <Input.TextArea
                placeholder="Type your note here"
                className={classes.notesTextBox}
                autoSize={{ minRows: 1, maxRows: 7 }}
                value={notesObj.text}
                maxLength={200}
                onChange={(e) => changeHandler(e.target.value)}
                onFocus={() => setShowCharCount(true)}
                onBlur={() => setShowCharCount(false)}
                onKeyDown={(event) => {
                  if (event.ctrlKey && event.keyCode === 13) {
                    document.getElementById("NotesSubmit1")?.click();
                  }
                }}
              />
              {showCharCount && (
                <CharCounter charLen={notesObj?.text?.length} />
              )}
            </Col>
            <Col span={6} className={classes.flexMiddle}>
              <Button
                type="primary"
                size="small"
                id="NotesSubmit1"
                loading={!!resp?.loading}
                disabled={notesObj.text?.trim() === ""}
                title={notesObj.text?.trim() === "" ? "Type Something!" : ""}
                className={classes.addNotesButton}
                onClick={() => {
                  setResp({ loading: true });
                  adminRootInstance
                    .post("note/", {
                      orderNumber: reqId,
                      message: notesObj.text.trim(),
                    })
                    .then((res) => {
                      setResp(res.data.data);
                      successToast();
                      setNotesObj({ text: "", img: {} });
                    })
                    .catch((err) => {
                      setResp(err);
                    });
                }}
              >
                Add Notes
              </Button>
            </Col>
          </Row>
        </>
      )}
      {showImageUploadModal && (
        <NotesImageUploadModal
          successToast={successToast}
          reqId={reqId}
          showModal={showImageUploadModal}
          setNotesObj={setNotesObj}
          toggleModal={() => setImageUploadModalFlag(!showImageUploadModal)}
          notesObj={notesObj}
        />
      )}
    </Card>
  );
};
//

// Component to render Rows inside Notes widget (with note, user who added it, his dp & date added)
const NotesHistorySection = ({
  note = "",
  userAdded = "",
  imageUrl = "",
  dateAdded = "",
}: any) => {
  return (
    <Row style={{ margin: "10px 0px" }}>
      {/* Dp - icon */}
      <Col span={4} className={classes.userIconWrapper}>
        <img
          alt=""
          src="/static/media/user-icon.a6143582.png"
          className={classes.userIcon}
        />
      </Col>
      {/* Notes history - Note and added by who */}
      <Col id="Details" span={20} style={{ textAlign: "left" }}>
        <Row>
          <Col span={14}>
            <b>{userAdded}</b>
          </Col>
          <Col id="Date" span={10}>
            <Row style={{ justifyContent: "flex-end" }}>{dateAdded}</Row>
          </Col>
        </Row>
        <Row>
          {imageUrl && (
            <Row style={{ width: "100%" }}>
              <Image src={imageUrl} width={100} height={100} />
            </Row>
          )}
          {note && (
            <Row style={{ wordBreak: "break-word", width: "100%" }}>
              <Col span={24}>{note}</Col>
            </Row>
          )}
        </Row>
      </Col>
      {/* Date in which it was created */}
    </Row>
  );
};

const successToast = () => {
  message.success("Note posted successfully!");
};

export default RequestViewNotes;
