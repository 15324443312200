/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
// import login from "../../request/modules/login/login";
import home from "../../request/modules/home/home";
import PrivateRoute from "../../request/shared/private-route";
import EquipmentDelivery from "../../request/modules/equipment-delivery/equipment-delivery";
import EquipmentPickup from "../../request/modules/equipment-pickup/equipment-pickup";
import Sharps from "../../request/modules/sharps/sharps";
import PatientTransport from "../../request/modules/patient-transport/patient-transport";
import { autoLogin } from "../../request/modules/login/actions";
import { connect } from "react-redux";
import MyRequests from "../../request/modules/my-requests/my-requests";
import NotFound from "../components/not-found";

const RequestRoutes = ({ match, autoLogin }: any) => {
  useEffect(() => {
    autoLogin();
  }, []);

  return (
    <>
      <Switch>
        {/* <Route exact path={`${match.url}/login`} component={login} /> */}
        <PrivateRoute
          exact
          path={`${match.url}/equipment-pickup`}
          component={EquipmentPickup}
        />
        <PrivateRoute
          exact
          path={`${match.url}/equipment-delivery`}
          component={EquipmentDelivery}
        />
        <PrivateRoute exact path={`${match.url}/sharps`} component={Sharps} />
        <PrivateRoute
          exact
          path={`${match.url}/patient-transport`}
          component={PatientTransport}
        />
        <PrivateRoute
          exact
          path={`${match.url}/my-requests`}
          component={MyRequests}
          authenticatedRoute={true}
        />
        <PrivateRoute exact path={match.url} component={home} />
        <Route path={"*"} component={NotFound} />
      </Switch>
    </>
  );
};

const mapDispatchToProps = { autoLogin };

export default connect(null, mapDispatchToProps)(RequestRoutes);
