import {
  PATIENT_TRANSPORT_FAIL,
  CLEAR_PATIENT_TRANSPORT_ERROR_MSG,
  SAVE_ALERT_MESSAGE,
} from "./action-types";
import {
  DONE_PATIENT_TRANSPORT_LOADING,
  SET_PATIENT_TRANSPORT_LOADING,
  SET_SUCCESS_MSG,
} from "../home/action-types";
import { requestorRootInstance } from "../../../common/config/axios-config";
import {
  DISCHARGE_CREATED_SUCCESSFULLY,
  DISCHARGE_REQUEST_TYPE_ID,
  PICKUP_CREATED_SUCCESSFULLY,
  PROCEDURE_REQUEST_TYPE_ID,
  RETURN_CREATED_SUCCESSFULLY,
  RETURN_REQUEST_TYPE_ID,
  TRANSFER_CREATED_SUCCESSFULLY,
} from "../../../common/shared-constants";

export const saveAlert = (data: any) => async (dispatch: any) => {
  dispatch({
    type: SAVE_ALERT_MESSAGE,
    payload: {
      alertMessage: data,
    },
  });
};

export const patientTransportOrder = (data: any) => async (dispatch: any) => {
  dispatch({
    type: SET_PATIENT_TRANSPORT_LOADING,
  });
  return await requestorRootInstance
    .post(`order/patient-transport/create`, data)
    .then((res: any) => {
      dispatch({
        type: DONE_PATIENT_TRANSPORT_LOADING,
      });
      dispatch({
        type: SET_SUCCESS_MSG,
        payload: {
          successMessage:
            data.requestTypeId === PROCEDURE_REQUEST_TYPE_ID
              ? PICKUP_CREATED_SUCCESSFULLY
              : data.requestTypeId === RETURN_REQUEST_TYPE_ID
              ? RETURN_CREATED_SUCCESSFULLY
              : data.requestTypeId === DISCHARGE_REQUEST_TYPE_ID
              ? DISCHARGE_CREATED_SUCCESSFULLY
              : TRANSFER_CREATED_SUCCESSFULLY,
        },
      });
      return { status: "success" };
    })
    .catch((err) => {
      dispatch({
        type: DONE_PATIENT_TRANSPORT_LOADING,
      });
      dispatch({
        type: PATIENT_TRANSPORT_FAIL,
        payload: {
          errorMessage: err.response?.data?.message,
        },
      });
      return { status: "fail" };
    });
};

export const clearErrorMessage = () => async (dispatch: any) => {
  dispatch({
    type: CLEAR_PATIENT_TRANSPORT_ERROR_MSG,
  });
};
