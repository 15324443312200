import { useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Spin,
  Alert,
} from "antd";
import { Helmet } from "react-helmet";
import classes from "./login.module.scss";
import { IRootState } from "../../../common/redux/reducers";
import { connect } from "react-redux";
import { login, clearAuthError } from "./actions";
import { Redirect } from "react-router-dom";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../../shared/ability/can";
import footerIcon from "../../../images/footer-icon.png";
import { trimValues } from "../../../common/utils/trimUtil";
import { baseInstance } from "../../../common/config/axios-config";

const LoginPage = (props: any) => {

  const [appVersion, setAppVersion] = useState('');

  useEffect(() => {
    (async () => {
      await baseInstance
        .get(`version`)
        .then((res: any) => {
          setAppVersion(res.data);
        })
        .catch((error) => {
          setAppVersion("BACKEND-VERSION-NOT-AVAILABLE");
        });
    })();
  }, []);

  const ability = useAbility(AbilityContext);

  const [credentials] = useState({
    username: "",
    password: "",
  });

  const handleLogin = (values: any) => {
    const trimedValues = trimValues(values);
    props.login(trimedValues.username, trimedValues.password, ability);
  };

  const onChange = (val: any) => {
    if (props.loginError) {
      props.clearAuthError();
    }
  };
  const { location, isAuthenticated } = props;
  const { from } = (location.state as any) || {
    from: { pathname: "/admin", search: location.search },
  };
  if (isAuthenticated) {
    return <Redirect to={from} />;
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <div className={classes.loginContainer}>
      <Helmet>
        <title>Login | Admin Portal</title>
      </Helmet>
      <Row justify="center" align="middle" className={classes.height100}>
        <Col lg={14} xxl={16}>
          <div className={classes.formTitle}>
            <Typography.Title
              level={2}
              className={classes.loginPageOwnerDetails}
            >
              {process.env.REACT_APP_CLIENT_NAME}
            </Typography.Title>
            <Typography.Text>ADMIN PORTAL</Typography.Text>
          </div>
        </Col>
        <Col lg={10} xxl={8} className={classes.height100}>
          <Card
            bodyStyle={{ transform: "translateY(-30px)", width: "100%" }}
            className={classes.loginCard}
          >
            <Typography.Title level={3} className={classes.loginFormHeader}>
              We Got It
            </Typography.Title>
            <Row>
              <Col span={24} className={classes.label}>
                <Form
                  {...layout}
                  initialValues={credentials}
                  onFinish={handleLogin}
                  hideRequiredMark
                >
                  <Form.Item
                    label="Username"
                    name="username"
                    className={classes.label}
                    rules={[
                      {
                        validator(rule, value) {
                          if (value && value.trim().length) {
                            if (value.length > 30) {
                              return Promise.reject(
                                "Username should be maximum of 30 characters."
                              );
                            } else {
                              return Promise.resolve();
                            }
                          } else {
                            return Promise.reject("Please enter Username!");
                          }
                        },
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      name="username"
                      className={classes.loginInput}
                      onChange={onChange}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    className={classes.label}
                    rules={[
                      {
                        required: true,
                        message: "Please enter the Password",
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      name="password"
                      className={`${classes.loginInput} passwordStyle`}
                      onChange={onChange}
                    />
                  </Form.Item>

                  {props.loginError ? (
                    <Alert
                      className={`fadeIn ${classes.alertContainer}`}
                      message={props.loginError}
                      type="error"
                      closable
                      afterClose={props.clearAuthError}
                    />
                  ) : null}
                  <Spin spinning={props.loading}>
                    <Button
                      className={classes.submitButton}
                      type="primary"
                      htmlType="submit"
                    >
                      SIGN IN
                    </Button>
                  </Spin>
                </Form>
              </Col>
            </Row>
          </Card>
          <div className={classes.footerImg}>
            <img src={footerIcon} alt="" />
          </div>
          <div className={classes.versionFooter}>
            FE Version: {process.env.REACT_APP_FRONTEND_APP_VERSION}
            <br />
            BE Version: {appVersion}
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ adminAuthentication }: IRootState) => ({
  loading: adminAuthentication.loading,
  isAuthenticated: adminAuthentication.isAuthenticated,
  loginError: adminAuthentication.errorMessage,
});

const mapDispatchToProps = { login, clearAuthError };

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
