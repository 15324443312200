import { requestorRootInstance } from "../../../common/config/axios-config";
import {
  SET_SUCCESS_MSG,
  FETCH_CAMPUS_BY_ID_SUCCESS,
  FETCH_CAMPUS_BY_ID_FAIL,
  CLEAR_CAMPUS_BY_ID,
  SET_CAMPUS_BY_ID_LOADING,
} from "./action-types";

export const setSuccessMessage = (successMessage: any) => (dispatch: any) => {
  dispatch({
    type: SET_SUCCESS_MSG,
    payload: {
      successMessage: successMessage,
    },
  });
};

export const fetchCurrentCampusData =
  ({ id: campusId }: any) =>
  async (dispatch: any) => {
    //IMPLEMENT AFTER THE API IS AVAILABLE
    dispatch({
      type: SET_CAMPUS_BY_ID_LOADING,
    });
    await requestorRootInstance
      .get(`campus/${campusId}`)
      .then((res: any) => {
        dispatch({
          type: FETCH_CAMPUS_BY_ID_SUCCESS,
          payload: {
            campus: res.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_CAMPUS_BY_ID_FAIL,
          payload: { errorMessage: err.response?.data?.message },
        });
        localStorage.removeItem("currentCampusId");
      });
  };

export const clearCampusBannerMessage = () => async (dispatch: any) => {
  dispatch({
    type: CLEAR_CAMPUS_BY_ID,
  });
};
