export const FETCH_UNITS_SUCCESS = "FETCH_UNITS_SUCCESS";
export const FETCH_UNITS_FAIL = "FETCH_UNITS_FAIL";
export const CREATE_UNIT_SUCCESS = "CREATE_UNIT_SUCCESS";
export const CREATE_UNIT_FAIL = "CREATE_UNIT_FAIL";
export const UPDATE_UNIT_SUCCESS = "UPDATE_UNIT_SUCCESS";
export const UPDATE_UNIT_FAIL = "UPDATE_UNIT_FAIL";
export const DELETE_UNIT_FAIL = "DELETE_UNIT_FAIL";
export const DELETE_UNIT_SUCCESS = "DELETE_UNIT_SUCCESS";
export const CLEAR_UNIT_MSG = "CLEAR_UNIT_MSG";
export const CLEAR_UNIT_FETCH_ERROR_MSG = "CLEAR_UNIT_FETCH_ERROR_MSG";
export const FETCH_UNITS_START = "FETCH_UNITS_START";
export const FETCH_ALL_UNITS_SUCCESS = "FETCH_ALL_UNITS_SUCCESS";
export const FETCH_ALL_UNITS_FAIL = "FETCH_ALL_UNITS_FAIL";
