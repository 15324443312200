import React from "react";
import { Input, Table, Button, Row, Col } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import classes from "./list-component.module.scss";
import { Can } from "../../ability/can";
import { TRANSPORT_PAGE } from "../../../../common/shared-constants";

const listComponent = (props: any) => {
  const {
    type,
    pagination,
    onSearch,
    onAdd,
    columns,
    dataSource,
    handleTableChange,
    addButtonLabel,
    searchLabel,
    loading,
  } = props;

  return (
    <div>
      <Row justify="end" align={"bottom"} className={classes.searchContainer}>
        <Col flex={4}>
          <Input
            placeholder={searchLabel || "Search by name"}
            onChange={onSearch}
            className={classes.searchBox}
            prefix={<SearchOutlined />}
          />
        </Col>
        {type !== TRANSPORT_PAGE && (
          <Col flex={1}>
            <Row justify="end">
              <Can I="create" a={type}>
                <Button type="primary" onClick={onAdd}>
                  <PlusOutlined />
                  {addButtonLabel}
                </Button>
              </Can>
            </Row>
          </Col>
        )}
      </Row>
      <Row>
        <Table
          id={"table"}
          loading={loading}
          columns={columns}
          size={"small"}
          dataSource={dataSource}
          rowKey={(record) => record.id}
          pagination={{ ...pagination }}
          onChange={handleTableChange}
        />
      </Row>
    </div>
  );
};

export default listComponent;
