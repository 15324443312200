import {
  CLEAR_AIM_CHECKIN,
  CLEAR_ALL_CHECKIN_MESSAGES,
  FETCH_CHECKIN_DATA_FAIL,
  FETCH_CHECKIN_DATA_START,
  FETCH_CHECKIN_DATA_SUCCESS,
  SET_CHECKIN_FILTER_DATA,
} from "./action-types";

const initialState = {
  assets: [] as any,
  assetsLoading: false,
  errorMessage: "" as string,
  totalCount: 0,
  filterData: {} as any,
};

export type AIMAssetCheckInState = Readonly<typeof initialState>;

const aimAssetCheckInState = (
  state = initialState,
  action: any
): AIMAssetCheckInState => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CHECKIN_DATA_START:
      return { ...state, assetsLoading: true };
    case FETCH_CHECKIN_DATA_SUCCESS:
      return { ...state, assets: payload.data, assetsLoading: false };
    case FETCH_CHECKIN_DATA_FAIL:
      return {
        ...state,
        assetsLoading: false,
        assets: {},
        errorMessage: payload.error,
      };
    case SET_CHECKIN_FILTER_DATA:
      return {
        ...state,
        filterData: payload.data,
      };

    case CLEAR_ALL_CHECKIN_MESSAGES:
      return {
        ...state,
        errorMessage: "",
      };
    case CLEAR_AIM_CHECKIN:
      return initialState;
    default:
      return state;
  }
};

export default aimAssetCheckInState;
