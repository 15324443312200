import React, { useState } from "react";
import { Row, Col, Button, InputNumber, Tooltip } from "antd";
import classes from "./sharp-item.module.scss";
import AddItemModal from "../../../common/components/AddItemModal/add-item-modal";
import { SHARP_REQ } from "../../../common/shared-constants";
import { allowOnlyNumbers } from "../../../common/utils/trimUtil";

const SharpItem = (props: any) => {
  const [tempCart, setTempCart] = useState<any>([...props.cartItems]);
  const goBack = () => {
    props.goBack("form");
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setTempCart([...props.cartItems]);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const selectedItems = (selectedItems: Array<object>) => {
    props.onItemsSelected(selectedItems);
    setIsModalVisible(false);
  };

  return (
    <section className={classes.sharpItemContainer}>
      <Row>
        <Col className={classes.gobackBtn}>
          <button onClick={goBack}></button>
        </Col>
      </Row>
      <Row align="middle" justify="space-between">
        <Col>
          <h4 className={classes.pickuptitle}>Items to Replace</h4>
        </Col>
        <Col>
          <Button type="primary" onClick={showModal}>
            + Add Item to Replace
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className={classes.sharpItemWrapper}>
            <div className={classes.selectedList}>
              {props.cartItems.map((cart: any, index: any) => {
                return (
                  <div className={classes.requestGrid}>
                    <div className={classes.requestGridImg}>
                      <div className={classes.itemImageWrapper}>
                        <img src={cart.item.image} alt="operation-bed" />
                        <div
                          className={`imageOverlay_100 decrement`}
                          onClick={() => {
                            cart.quantity === 1
                              ? props.onItemRemove(cart.item.id)
                              : props.quantityEdit(cart.item.id, "DECREASE");
                          }}
                        >
                          -
                        </div>
                        <div
                          className={`imageOverlay_100 increment`}
                          onClick={() => {
                            props.quantityEdit(cart.item.id, "INCREASE");
                          }}
                        >
                          +
                        </div>
                      </div>
                      <div className={classes.itemName}>
                        <Tooltip
                          title={cart.item.name}
                          className={classes.ellipsisSentence}
                        >
                          {cart.item.name}
                        </Tooltip>
                      </div>
                      <div className={classes.itemSize}>
                        <b>Size:</b> {cart?.item?.size?.name}
                      </div>
                      {/* {cart.item.specialtyItem && (
                        <div
                          className={classes.specialty}
                          title={SPECIALTY_HOVER_TITLE}
                        >
                          *
                        </div>
                      )} */}
                      {cart.item.delay && (
                        <div
                          className={classes.delay}
                          title={cart.item.delayDescription}
                        >
                          *
                        </div>
                      )}
                    </div>
                    <div className={classes.requestQty}>
                      <p>Qty</p>
                      <div className={classes.requestCount}>
                        <button
                          onClick={(id) => {
                            cart.quantity === 1
                              ? props.onItemRemove(cart.item.id)
                              : props.quantityEdit(cart.item.id, "DECREASE");
                          }}
                          className={classes.minus}
                        ></button>
                        <InputNumber
                          value={cart.quantity}
                          onChange={(quant) => {
                            props.quantityEdit(cart.item.id, "EDIT", quant);
                          }}
                          onKeyDown={allowOnlyNumbers}
                          min={1}
                          max={99}
                        />
                        <button
                          onClick={(id) => {
                            props.quantityEdit(cart.item.id, "INCREASE");
                          }}
                          disabled={cart.quantity >= 99}
                          className={classes.add}
                        ></button>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        props.onItemRemove(cart.item.id);
                      }}
                      className={classes.close}
                    >
                      <span>X</span>
                    </button>
                    {cart.delay === "true" && (
                      <div className={classes.delay}>D</div>
                    )}
                  </div>
                );
              })}
            </div>
            {props.cartItems.length < 1 && (
              <div className={`fadeIn ${classes.plsAddItem}`}>
                Please Add Items to Replace
              </div>
            )}
          </div>
        </Col>
      </Row>
      <div className={classes.nextCancelBtn}>
        <Button type="primary" onClick={() => props.clear([])}>
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={props.cartItems < 1 ? true : false}
          onClick={() => props.checkout()}
        >
          Next
        </Button>
      </div>
      {isModalVisible && (
        <AddItemModal
          visible={isModalVisible}
          handleCancel={handleCancel}
          allItems={props.items}
          editCart={tempCart}
          onItemsSelected={selectedItems}
          type={SHARP_REQ}
          showSpecialityFlag={false}
        />
      )}
    </section>
  );
};

export default SharpItem;
