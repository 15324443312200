import React, { useEffect } from "react";
import "./App.scss";
import Routes from "./common/routes";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import initStore from "./common/redux/store";
import { bindActionCreators } from "redux";
import setupAxiosInterceptors from "./common/config/axios-config";
import { clearAdminAuthentication } from "./admin/modules/auth/actions";
import { clearRequestorAuthentication } from "./request/modules/login/actions";
import { modifyRelativeTimeThreshold } from "./common/utils/time";

const store = initStore();
const actions = bindActionCreators(
  { clearAdminAuthentication, clearRequestorAuthentication },
  store.dispatch
);
setupAxiosInterceptors(
  (message: any) => actions.clearAdminAuthentication(message),
  (message: any) => actions.clearRequestorAuthentication(message)
);

function App() {
  useEffect(() => {
    modifyRelativeTimeThreshold();
  }, []);

  if (
    !localStorage.getItem("logoutHint") &&
    process.env.REACT_APP_SSO_ENABLED === "true"
  ) {
    localStorage.removeItem("req_access_token");
    localStorage.removeItem("req_refresh_token");
  }

  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <header className="App-header">
            <Routes />
          </header>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
