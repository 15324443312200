import moment from "moment";
import { Dispatch } from "redux";
import { adminRootInstance } from "../../../../common/config/axios-config";
import {
  CLEAR_DECON_STATE,
  FETCH_DECON_DATA_FAIL,
  FETCH_DECON_DATA_START,
  FETCH_DECON_DATA_SUCCESS,
  SET_DECON_FILTER_DATA,
} from "./action-types";

export const fetchDeconData =
  ({ filter, search, limit, page }: any) =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      campus: filter.campus,
      checkinFrom: `${
        filter.checkin?.length === 2
          ? moment(filter?.checkin[0]).format("YYYY-MM-DD")
          : ""
      }`,
      checkinTo: `${
        filter.checkin?.length === 2
          ? moment(filter?.checkin[1]).format("YYYY-MM-DD")
          : ""
      }`,
      deconFrom: `${
        filter.decon?.length === 2
          ? moment(filter?.decon[0]).format("YYYY-MM-DD")
          : ""
      }`,
      deconTo: `${
        filter.decon?.length === 2
          ? moment(filter?.decon[1]).format("YYYY-MM-DD")
          : ""
      }`,
      searchKeyword: search || "",
      page,
      pageSize: limit,
    };
    dispatch({ type: FETCH_DECON_DATA_START });
    await adminRootInstance
      .get("aim/decons", {
        params: queryParams,
      })
      .then((res: any) => {
        dispatch({
          type: FETCH_DECON_DATA_SUCCESS,
          payload: {
            data: res.data.data,
          },
        });
      })
      .catch((err: any) => {
        dispatch({
          type: FETCH_DECON_DATA_FAIL,
          payload: { error: err.response?.data?.message },
        });
      });
  };
export const setDeconFilterData =
  (filterData: any) => async (dispatch: Dispatch) => {
    const filterValues = filterData;
    if (filterData.checkin?.length) {
      let [from, to] = filterData.checkin;
      filterValues.checkin = [moment(from), moment(to)];
    }
    if (filterData.decon?.length) {
      let [from, to] = filterData.decon;
      filterValues.decon = [moment(from), moment(to)];
    }
    dispatch({ type: SET_DECON_FILTER_DATA, payload: { data: filterValues } });
  };
export const clearAllDecon = () => (dispatch: Dispatch) =>
  dispatch({ type: CLEAR_DECON_STATE });
