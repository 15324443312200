import {
  CLEAR_ACTIVITIES,
  FETCH_ACTIVITIES_FAIL,
  FETCH_ACTIVITIES_START,
  FETCH_ACTIVITIES_SUCCESS,
  PUSH_ACTIVITIES,
} from "../action-types";

const initialState = {
  activitiesList: null as any,
  fetchErrorMessage: null as null | string,
  loading: false,
  errorMessage: null as null | string,
};

export type ActivitiesDetails = Readonly<typeof initialState>;

const activityDetails = (
  state = initialState,
  action: any
): ActivitiesDetails => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ACTIVITIES_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activitiesList: payload.request,
        fetchErrorMessage: null,
        loading: false,
      };
    case FETCH_ACTIVITIES_FAIL:
      return {
        ...state,
        activitiesList: null,
        loading: false,
        fetchErrorMessage: payload.fetchErrorMessage,
      };
    case PUSH_ACTIVITIES:
      return {
        ...state,
        activitiesList: [payload.request].concat(state.activitiesList),
        fetchErrorMessage: null,
        loading: false,
      };
    case CLEAR_ACTIVITIES:
      return initialState;
    default:
      return state;
  }
};

export default activityDetails;
