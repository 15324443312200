/* eslint-disable react-hooks/exhaustive-deps*/
import React from "react";
import classes from "./patient-request-edit-modal.module.scss";
import {
  Card,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Select,
  Input,
  Empty,
  Alert,
  message,
  DatePicker,
  Typography,
  DatePickerProps,
} from "antd";
import { FormOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import ItemCard from "../../commons/ItemCard";
import { useState } from "react";
import { connect } from "react-redux";
import { IRootState } from "../../../../../common/redux/reducers";
import { fetchAllCampuses } from "../../../campus/actions";
import { useEffect } from "react";
import {
  editPatients,
  fetchUnitsOnCampus,
  updateRequestDetails,
} from "../actions";
import { adminRootInstance } from "../../../../../common/config/axios-config";
import { clearMessage } from "../../../../../common/actions/common-actions";
import { CLEAR_REQUEST_MESSAGE } from "../action-types";
import AddItemModal from "../../../../../common/components/AddItemModal/add-item-modal";
import {
  contactNameValidator,
  emailValidator,
  fromRoomNameValidator,
  patientNameValidator,
  phoneNumberValidator,
  shortDescriptionValidator,
} from "../../../../../common/utils/formValidators";
import {
  DELIVERY_REQUEST_TYPE_ID,
  PATIENTMODAL_SINGLE_NEW,
  PATIENTMODAL_SINGLE_REMOVE,
  PATIENTMODAL_VIEW,
  PICKUP_REQUEST_TYPE_ID,
  reqTypeAddItemMap,
  REQ_EDIT,
  PROCEDURE_REQUEST_TYPE_ID,
  RETURN_REQUEST_TYPE_ID,
  DISCHARGE_REQUEST_TYPE_ID,
  TRANSFER_REQUEST_TYPE_ID,
  BUTTON_GREEN_HEX,
} from "../../../../../common/shared-constants";
import {
  getESTFromUTC,
  getFormattedDate,
} from "../../../../../common/utils/time";
import PatientDetailsModal from "../../../../../common/components/PatientDetailsModal/patient-details-modal";
import DelayModal from "../../../../../common/components/DelayModal/DelayModal";
import moment from "moment";
const { Option } = Select;

const fetchItems = (campusId: any, fromUnit: any, toUnit: any) => {
  return adminRootInstance
    .get(`transport-item/${campusId}/${fromUnit}/${toUnit}`)
    .then((res: any) => {
      return { success: true, items: res.data.data };
    })
    .catch((err: any) => {
      return { success: false, errMsg: err?.response?.data?.message };
    });
};

const PatientRequestEditModal = (props: any) => {
  const {
    showModal,
    toggleModal,
    cardHeaderStyle,
    cardsBodyStyle,
    updateRequestDetails,
    requestDetails,
    fetchAllCampuses,
    allCampuses,
    campusesLoading,
    fetchUnitsOnCampus,
    fetchedUnits = [],
    unitsLoading,
    errorMsg,
    clearMessage,
    editReqLoading,
    editPatients,
    patients,
  } = props;
  const {
    reqInfo: {
      id = "",
      reqId = "",
      reqType: { id: reqTypeId = "", name: reqTypeName = "" } = {},
      description: additionalInformation = "",
      status: reqStatus = "",
      items = [],
      created: reqCreatedDate = "",
      timeLimit = 0,
    } = {},
    locationInfo: {
      campus: { id: campusId = 1, name: campusName = "" } = {},
      unit: { id: fromUnit = 2, name: fromUnitName = "" } = {},
      destinationUnit = {},
      dischargeLocation = {},
      roomName = "",
    } = {},
    contactInfo: {
      id: externalId = "",
      name: nurseName = "",
      phoneNumber: nursePhoneNumber = "",
      mailId: nurseEmail = "",
    } = {},
    scanned = false,
    patientName,
    patientDob,
    parentOrderDetails,
    expiry,
  } = requestDetails || {};
  const initialFormValues = {
    campusId: campusId || "",
    fromUnit: fromUnit || "",
    toUnit:
      reqTypeId === DISCHARGE_REQUEST_TYPE_ID
        ? dischargeLocation?.id
        : reqTypeId === RETURN_REQUEST_TYPE_ID
        ? `${parentOrderDetails.id}|${parentOrderDetails?.unitId}|${parentOrderDetails?.patientName}|${parentOrderDetails?.patientDob}`
        : destinationUnit?.id || "",
    room: roomName || "",
    nurseName: nurseName || "",
    nursePhoneNumber: nursePhoneNumber,
    nurseEmail: nurseEmail || "",
    externalId: externalId || "",
    patientName: patientName,
    patientDob: moment(patientDob),
    time: moment(expiry).seconds(0).format("MM-DD-YYYY HH:mm"),
    additionalInformation: additionalInformation || "",
  };
  const [dynamicItems, setDynamicItems] = useState<any>([]);
  const [addItemModal, setAddItemModal] = useState(false);
  const [tempCart, setTempCart] = useState([
    ...items.map((a: any) => ({ ...a })),
  ]);
  const [itemsErr, setItemsErr] = useState("");
  const [form] = Form.useForm();
  const [showPatientModal, setShowPatientModal] = useState<any>(false);
  const [showItemModal, setShowItemModal] = useState<any>();
  const [dischargeLocations, setDischargeLocations] = useState<any>([]);
  const [itemsToBeRemoved, setItemsToBeRemoved] = useState<any>([]);
  const [isRemoveItem, setIsRemoveItem] = useState(false);
  const [editTime, setEditTime] = useState(false);
  const [requestTimeLeft, setRequestTimeLeft] = useState<any>("");
  const [transportDetails, setTransportDetails] = useState<any>(null);
  const [assetCountModal, setAssetCountModal] = useState<any>(false);
  const { Text } = Typography;

  useEffect(() => {
    if (reqTypeId === DISCHARGE_REQUEST_TYPE_ID && campusId) {
      const getDischargeLocations = async () => {
        const res = await adminRootInstance.get(
          `discharge-location/${campusId}`
        );
        setDischargeLocations([...res?.data?.data]);
      };
      getDischargeLocations();
    }
  }, [reqTypeId, campusId]);

  useEffect(() => {
    (async () => {
      if (reqTypeId === RETURN_REQUEST_TYPE_ID) {
        await editPatients(fromUnit, id);
      }
    })();
  }, [reqTypeId]);

  useEffect(() => {
    if (showModal) {
      //get all campuses
      fetchAllCampuses(true);
      //fetch units list based on initial campus
      fetchUnitsOnCampus({ campusId: campusId });
    }
    return () => {
      clearMessages();
      form.setFieldsValue(initialFormValues);
      setTempCart([...items.map((a: any) => ({ ...a }))]);
    };
  }, [showModal]);

  useEffect(() => {
    if (campusId) {
      getTransportDetails(campusId);
    }
  }, [campusId]);

  const getTransportDetails = async (newCampusId: any) => {
    const res = await adminRootInstance.get(`transport/${newCampusId}`);
    const response = res?.data?.data;
    const calculatedRequestTimeLeft =
      reqTypeId === RETURN_REQUEST_TYPE_ID ||
      reqTypeId === PROCEDURE_REQUEST_TYPE_ID
        ? response?.procedureTimeLeft
        : reqTypeId === TRANSFER_REQUEST_TYPE_ID
        ? response?.transferTimeLeft
        : response?.dischargeTimeLeft;
    setRequestTimeLeft(calculatedRequestTimeLeft);
    setTransportDetails(res?.data?.data);
  };

  //on campus value change, fetch data for units dropdown
  const onCampusChangeHandler = (campusId: any) => {
    getTransportDetails(campusId);
    form.setFieldsValue({ fromUnit: "" });
    form.setFieldsValue({ toUnit: "" });
    form.setFieldsValue({ time: "" });
    fetchUnitsOnCampus({ campusId });
    setDynamicItems([]);
  };

  const clearMessages = () => {
    clearMessage(CLEAR_REQUEST_MESSAGE);
  };

  const fetchNewItems = async (open?: boolean) => {
    if (
      form.getFieldValue("campusId") &&
      form.getFieldValue("fromUnit") &&
      form.getFieldValue("toUnit")
    ) {
      const res: any = await fetchItems(
        form.getFieldValue("campusId"),
        form.getFieldValue("fromUnit"),
        reqTypeId === DISCHARGE_REQUEST_TYPE_ID
          ? 1
          : reqTypeId === RETURN_REQUEST_TYPE_ID
          ? form.getFieldValue("toUnit")?.split("|")[1]
          : form.getFieldValue("toUnit")
      );
      if (res?.success) {
        setDynamicItems([...res.items]);
        if (open) setAddItemModal(true);
      } else {
        message.error(res.errMsg);
      }
    }
  };

  useEffect(() => {
    fetchNewItems();
  }, [
    form.getFieldValue("campusId"),
    form.getFieldValue("fromUnit"),
    form.getFieldValue("toUnit"),
  ]);

  const openAddItemModal = async () => {
    fetchNewItems(true);
  };

  const onItemsSelected = (newItems: any) => {
    setTempCart(newItems);
    setAddItemModal(false);
    setItemsErr("");
  };

  const removeItem = () => {
    const filteredItems = tempCart.filter(
      (item: any) => !itemsToBeRemoved.includes(item?.item?.id)
    );
    setTempCart([...filteredItems]);
    setIsRemoveItem(false);
  };

  const onUpdateFinish = async (values: any) => {
    const allowedItemIds = dynamicItems.map((item: any) => item.id);
    const selectedItemIds = tempCart.map((item: any) => item?.item?.id);
    const notAllowedItemIds: any = [];
    selectedItemIds.forEach((itemId: any) => {
      if (!allowedItemIds.includes(itemId)) {
        notAllowedItemIds.push(itemId);
      }
    });

    if (notAllowedItemIds.length > 0) {
      setItemsToBeRemoved([...notAllowedItemIds]);
      setIsRemoveItem(true);
    } else {
      const updatedData = {
        isPatientTransportRequest: true,
        cartItem: tempCart,
        unitId: values.fromUnit,
        patientName: values.patientName.trim(),
        room: values.room.trim(),
        externalUserId: values.externalId,
        timeLimit: moment(values?.time)
          .seconds(0)
          .diff(moment().seconds(0), "minutes"),
        expiry: moment(values?.time).seconds(0).toISOString(),
        patientDob: moment(values.patientDob).format("YYYY-MM-DD"),
        destinationUnitId:
          reqTypeId === DISCHARGE_REQUEST_TYPE_ID
            ? ""
            : reqTypeId === RETURN_REQUEST_TYPE_ID
            ? values.toUnit?.split("|")[1]
            : values.toUnit,
        additionalInstruction: values.additionalInformation.trim(),
        dischargeLocationId:
          reqTypeId === DISCHARGE_REQUEST_TYPE_ID ? values.toUnit : "",
        pickUpOrderId:
          reqTypeId === RETURN_REQUEST_TYPE_ID
            ? values.toUnit?.split("|")[0]
            : "",
        nurseName: values.nurseName.trim(),
        nursePhoneNumber: values.nursePhoneNumber,
        nurseEmail: values.nurseEmail.trim(),
        orderPriority:  reqTypeId === DISCHARGE_REQUEST_TYPE_ID ?
        transportDetails.dischargePriority  :
        reqTypeId === TRANSFER_REQUEST_TYPE_ID  ?
        transportDetails.transferPriority : transportDetails.procedurePriority
      };
      const res = await updateRequestDetails({ reqId, updatedData });
      if (res?.success) {
        toggleModal();
      }
    }
  };

  const [showDelayModal, setShowDelayModal] = useState("");
  const info = (data: any) => {
    setShowDelayModal(data);
  };

  //function to modify the quantity
  const quantityEdit = (itemId: any, action: any, newQuantity?: number) => {
    const items = tempCart.map((cartItem: any) => {
      const getPatientsCond =
        reqTypeId === DELIVERY_REQUEST_TYPE_ID &&
        (cartItem.item.specialtyItem ||
          cartItem.item.specialityItem ||
          cartItem.isSpecialtyItem);
      if (cartItem.item.id === itemId) {
        if (action === "INCREASE") {
          if (getPatientsCond) {
            setShowPatientModal({ id: itemId, mode: PATIENTMODAL_SINGLE_NEW });
          } else cartItem.quantity += 1;
        } else if (action === "EDIT") {
          if (cartItem.item.specialtyItem || cartItem.item.specialityItem) {
            //open the modal to remove patient based on the number of quantity (old - newQuantity)
            //if submitted, decrement
            //else keep the quantity same
          }
          cartItem.quantity = newQuantity;
        } else {
          if (getPatientsCond) {
            cartItem?.patients?.length &&
              setShowPatientModal({
                id: itemId,
                mode: PATIENTMODAL_SINGLE_REMOVE,
              });
          } else cartItem.quantity -= 1;
        }
      }
      return cartItem;
    });
    setTempCart(items);
  };
  //function to remove item form the cart
  const onItemRemove = (removedId: any) => {
    let itemRemoving = [...tempCart];
    itemRemoving = itemRemoving.filter(
      (cartItem) => cartItem.item.id !== removedId
    );
    setTempCart(itemRemoving);
  };
  let patientDetailsPresent =
    tempCart.reduce(
      (acc: any, item: any) => (item.patients ? acc + 1 : acc),
      0
    ) > 0;
  let disableCards = scanned;

  const onDateChange: DatePickerProps["onChange"] = (dateString) => {
    form.setFieldsValue({ dob: dateString });
  };

  const onDisabledDate = (current: any) => {
    return current && current > moment().endOf("day");
  };

  const onFromUnitChange = async (unitId: any) => {
    if (reqTypeId === RETURN_REQUEST_TYPE_ID) {
      form.setFieldValue("toUnit", "");
      await editPatients(unitId, id);
    }
  };

  const onToUnitChange = async (unit: any) => {
    if (reqTypeId === RETURN_REQUEST_TYPE_ID) {
      form.setFieldValue("patientName", unit?.split("|")[2]);
      form.setFieldValue("patientDob", moment(unit?.split("|")[3]));
    }
  };

  // Disable dates before today
  const disabledDate = (current: any) => {
    return (
      current &&
      (current < moment().seconds(0).startOf("day") ||
        current > moment().seconds(0).add(23, "hours").endOf("day"))
    );
  };

  // Disable times before now + transportDetails?.timeLeft for the current date and times after 23 hours for the next day
  const disabledTime = (current: any) => {
    if (!current) {
      current = moment().seconds(0);
    }

    const isToday = current.isSame(moment().seconds(0), "day");
    const isTomorrow = current.isSame(
      moment().seconds(0).add(23, "hours"),
      "day"
    );

    if (isToday) {
      const currentPlusTimeLeft = moment()
        .seconds(0)
        .add(Number(requestTimeLeft), "minutes");
      return {
        disabledHours: () =>
          range(0, moment(currentPlusTimeLeft).seconds(0).hour()),
        disabledMinutes: () =>
          current.hour() === moment(currentPlusTimeLeft).seconds(0).hour()
            ? range(0, moment(currentPlusTimeLeft).seconds(0).minute() + 1)
            : [],
      };
    }

    if (isTomorrow) {
      return {
        disabledHours: () =>
          range(moment().seconds(0).add(23, "hours").hour() + 1, 24),
        disabledMinutes: () =>
          current.hour() === moment().seconds(0).add(23, "hours").hour()
            ? range(moment().seconds(0).add(23, "hours").minute() + 1, 60)
            : [],
      };
    }

    return {};
  };

  // Helper function to create a range of numbers
  const range = (start: any, end: any) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  return (
    <div>
      <Modal
        title={
          <div className={classes.modalHeader}>
            Patient transport request edit
          </div>
        }
        centered
        visible={showModal}
        onCancel={toggleModal}
        style={{ minWidth: "90vw" }}
        footer={null}
        bodyStyle={{
          backgroundColor: "rgb(240 242 245)",
          padding: "10px 24px",
        }}
        maskClosable={false}
      >
        <Row>
          {errorMsg ? (
            <Alert
              className={`fadeIn ${classes.alertContainer}`}
              message={errorMsg}
              style={{ width: "100%" }}
              type="error"
              closable
              afterClose={clearMessages}
            />
          ) : null}
        </Row>
        <Row className={classes.nonEditableRow}>
          <Col span={7} className={classes.reqId}>
            Request ID : {reqId}
          </Col>
          <Col span={10} className={classes.dateAndTime}>
            Date:{" "}
            {process.env.REACT_APP_SSO_ENABLED
              ? getFormattedDate(reqCreatedDate)
              : getESTFromUTC(reqCreatedDate)}
          </Col>
          <Col span={7} className={classes.status}>
            Status: {reqStatus}
          </Col>
        </Row>
        <Form
          {...{
            labelCol: { span: 10 },
            labelAlign: "left",
            colon: false,
            wrapperCol: { span: 14 },
          }}
          initialValues={initialFormValues}
          onFinish={onUpdateFinish}
          form={form}
        >
          <Row>
            <Col
              span={12}
              style={{
                padding: "10px 20px 10px 0px",
                cursor: disableCards ? "not-allowed" : "default",
              }}
            >
              {/* first form start */}
              <Card
                title="Location Info"
                headStyle={cardHeaderStyle}
                bodyStyle={{
                  ...cardsBodyStyle,
                  textAlign: "left",
                  padding: "20px 100px 20px 15px",
                }}
                className={`${classes.cardStyle} ${
                  disableCards && classes.disableEvents
                }`}
              >
                <Form.Item className={classes.formField} label="Request type">
                  <Text>{reqTypeName || "-"}</Text>
                </Form.Item>
                <Form.Item
                  label="Campus name"
                  name="campusId"
                  className={classes.formField}
                  rules={[
                    () => ({
                      required: true,
                      message: "Please select a campus.",
                    }),
                  ]}
                >
                  <Select
                    placeholder="Enter campus name"
                    defaultValue={campusId}
                    loading={campusesLoading}
                    key="campusId"
                    onChange={onCampusChangeHandler}
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {campusesLoading ? (
                      <Option value={campusId}>{campusName}</Option>
                    ) : (
                      allCampuses
                        ?.filter((filterCampus: any) =>
                          filterCampus?.requestTypes
                            ?.map((requestType: any) => requestType.id)
                            ?.includes(reqTypeId)
                        )
                        .map((campus: any, index: Number) => (
                          <Option value={campus.id} key={"campusId" + index}>
                            {campus.name}
                          </Option>
                        ))
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  className={classes.formField}
                  label="From unit"
                  name="fromUnit"
                  rules={[
                    () => ({
                      required: true,
                      message: "Please select a unit.",
                    }),
                  ]}
                >
                  <Select
                    placeholder="Enter unit name"
                    loading={unitsLoading}
                    onChange={(val, e: any) => {
                      onFromUnitChange(val);
                    }}
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {unitsLoading ? (
                      <Option value={fromUnit}>{fromUnitName}</Option>
                    ) : reqTypeId === RETURN_REQUEST_TYPE_ID ? (
                      fetchedUnits
                        ?.filter(
                          (filterUnit: any) =>
                            filterUnit?.proceduralUnit &&
                            filterUnit?.id !== form.getFieldValue("toUnit")
                        )
                        ?.map((unit: any, index: Number) => (
                          <Option value={unit.id} key={"unitId" + index}>
                            {unit.name}
                          </Option>
                        ))
                    ) : reqTypeId === PROCEDURE_REQUEST_TYPE_ID ||
                      reqTypeId === TRANSFER_REQUEST_TYPE_ID ? (
                      fetchedUnits
                        ?.filter(
                          (filterUnit: any) =>
                            filterUnit?.id !== form.getFieldValue("toUnit")
                        )
                        ?.map((unit: any, index: Number) => (
                          <Option value={unit.id} key={"unitId" + index}>
                            {unit.name}
                          </Option>
                        ))
                    ) : (
                      fetchedUnits?.map((unit: any, index: Number) => (
                        <Option value={unit.id} key={"unitId" + index}>
                          {unit.name}
                        </Option>
                      ))
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  className={classes.formField}
                  label="To unit"
                  name="toUnit"
                  rules={[
                    () => ({
                      required: true,
                      message: "Please select to unit.",
                    }),
                  ]}
                >
                  {reqTypeId !== DISCHARGE_REQUEST_TYPE_ID ? (
                    <Select
                      placeholder="Enter unit name"
                      loading={unitsLoading}
                      onChange={(val, e: any) => {
                        onToUnitChange(val);
                      }}
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option?.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {unitsLoading ? (
                        <Option value={destinationUnit?.id}>
                          {destinationUnit?.name}
                        </Option>
                      ) : reqTypeId === PROCEDURE_REQUEST_TYPE_ID ? (
                        fetchedUnits
                          ?.filter(
                            (filterUnit: any) =>
                              filterUnit?.proceduralUnit &&
                              filterUnit?.id !== form.getFieldValue("fromUnit")
                          )
                          ?.map((unit: any, index: Number) => (
                            <Option value={unit.id} key={"toUnit" + index}>
                              {unit.name}
                            </Option>
                          ))
                      ) : reqTypeId === TRANSFER_REQUEST_TYPE_ID ? (
                        fetchedUnits
                          ?.filter(
                            (filterUnit: any) =>
                              filterUnit?.id !== form.getFieldValue("fromUnit")
                          )
                          ?.map((unit: any, index: Number) => (
                            <Option value={unit.id} key={"toUnit" + index}>
                              {unit.name}
                            </Option>
                          ))
                      ) : (
                        patients?.map((unit: any, index: Number) => {
                          return (
                            <Option
                              value={`${unit?.id}|${unit?.unitId?.id}|${unit?.patientName}|${unit?.patientDob}`}
                              key={"toUnit" + index}
                            >
                              {`${unit?.patientName} | ${unit?.unitId?.name}`}
                            </Option>
                          );
                        })
                      )}
                    </Select>
                  ) : (
                    <Select
                      id="dischargeIdSelect"
                      placeholder="Enter unit name"
                      loading={unitsLoading}
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option?.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {unitsLoading ? (
                        <Option value={dischargeLocation?.id}>
                          {dischargeLocation?.name}
                        </Option>
                      ) : (
                        dischargeLocations?.map((unit: any, index: Number) => (
                          <Option value={unit.id} key={"toUnit" + index}>
                            {unit.name}
                          </Option>
                        ))
                      )}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item
                  className={classes.formField}
                  label="From room"
                  name="room"
                  rules={[
                    () => ({
                      required: true,
                      validator: fromRoomNameValidator,
                    }),
                  ]}
                >
                  <Input
                    placeholder="Enter from room"
                    defaultValue={roomName}
                  />
                </Form.Item>
              </Card>
            </Col>{" "}
            <Col span={12} style={{ paddingTop: "10px" }}>
              <Card
                title="Contact Info"
                headStyle={cardHeaderStyle}
                bodyStyle={{
                  ...cardsBodyStyle,
                  textAlign: "left",
                  padding: "20px 100px 20px 15px",
                }}
                className={classes.cardStyle}
              >
                <Form.Item
                  className={classes.formField}
                  name="externalId"
                  hidden
                />
                <Form.Item
                  className={classes.formField}
                  label="Nurse name"
                  name="nurseName"
                  rules={[
                    () => ({
                      required: true,
                      validator: contactNameValidator,
                    }),
                  ]}
                >
                  <Input placeholder="Enter name" defaultValue={nurseName} />
                </Form.Item>
                <Form.Item
                  className={classes.formField}
                  label="Nurse phone number"
                  name="nursePhoneNumber"
                  rules={[
                    {
                      required: true,
                      validator: phoneNumberValidator,
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter phone number"
                    type="tel"
                    defaultValue={nursePhoneNumber}
                  />
                </Form.Item>
                <Form.Item
                  className={classes.formField}
                  label={<span style={{ paddingLeft: "10px" }}>Email ID</span>}
                  name="nurseEmail"
                  rules={[
                    {
                      required: false,
                      validator: emailValidator,
                      message: "Please enter valid email.",
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Email ID"
                    type="email"
                    defaultValue={nurseEmail}
                  />
                </Form.Item>
                <Form.Item
                  className={classes.formField}
                  label="Patient name"
                  name="patientName"
                  rules={[
                    {
                      required: true,
                      validator: patientNameValidator,
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter name"
                    disabled={reqTypeId === RETURN_REQUEST_TYPE_ID}
                  />
                </Form.Item>
                <Form.Item
                  className={classes.formField}
                  label="Patient date of birth"
                  name="patientDob"
                  rules={[
                    {
                      required: true,
                      message: "Please select the Date Of Birth!",
                    },
                  ]}
                >
                  <DatePicker
                    getPopupContainer={(trigger): any => trigger.parentNode}
                    placeholder="e.g. 12-27-2020"
                    format={"MM-DD-YYYY"}
                    className={classes.PatientDob}
                    onChange={onDateChange}
                    disabledDate={onDisabledDate}
                    disabled={reqTypeId === RETURN_REQUEST_TYPE_ID}
                  />
                </Form.Item>
              </Card>
            </Col>
          </Row>
          <Row>
            <Card
              title="Request Info"
              headStyle={cardHeaderStyle}
              bodyStyle={{
                ...cardsBodyStyle,
                display: "flex",
                flexDirection: "row",
                textAlign: "left",
                padding: "20px 20px 20px 15px",
              }}
              className={classes.cardStyle}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Row style={{ display: "flex", flexDirection: "row" }}>
                  <Col span={12} style={{ width: "100%" }}>
                    <Form.Item
                      className={classes.formField}
                      label="Time patient needs to be at destination"
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 11 }}
                      style={{ width: "100%" }}
                      name="time"
                      required={true}
                      rules={[
                        () => ({
                          validator(rule, value) {
                            if (!value) {
                              return Promise.reject(`Time is required!`);
                            }
                            if (
                              editTime &&
                              !moment(value)
                                .seconds(0)
                                .isBetween(
                                  moment()
                                    .seconds(0)
                                    .add(Number(requestTimeLeft), "minutes"),
                                  moment().seconds(0).add(23, "hours")
                                )
                            ) {
                              return Promise.reject(
                                `Time must be greater than ${moment()
                                  .seconds(0)
                                  .add(Number(requestTimeLeft), "minutes")
                                  .format(
                                    "MM-DD-YYYY HH:mm"
                                  )} and less than or equal to ${moment()
                                  .seconds(0)
                                  .add(23, "hours")
                                  .format("MM-DD-YYYY HH:mm")}!`
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Row style={{ display: "flex", alignItems: "center" }}>
                        {!editTime && (
                          <>
                            <Col
                              xs={23}
                              sm={23}
                              md={23}
                              lg={23}
                              xl={23}
                              xxl={23}
                            >
                              <span className={classes.formValue}>
                                {moment(expiry)
                                  .seconds(0)
                                  .format("MM-DD-YYYY HH:mm")}
                              </span>
                            </Col>
                            <Col
                              xs={1}
                              sm={1}
                              md={1}
                              lg={1}
                              xl={1}
                              xxl={1}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <FormOutlined
                                style={{
                                  color: BUTTON_GREEN_HEX,
                                  fontSize: "18px",
                                  cursor: "pointer",
                                }}
                                className="edit-icon"
                                onClick={() => {
                                  setEditTime(true);
                                  form.setFieldValue("time", "");
                                }}
                              />
                            </Col>
                          </>
                        )}
                        {editTime && (
                          <>
                            <Col
                              xs={22}
                              sm={22}
                              md={22}
                              lg={22}
                              xl={22}
                              xxl={22}
                            >
                              <DatePicker
                                getPopupContainer={(trigger): any =>
                                  trigger.parentNode
                                }
                                placeholder="Select date time (e.g. 12-27-2020 13:00)"
                                className="fullWidth"
                                showTime
                                format="MM-DD-YYYY HH:mm"
                                disabledDate={disabledDate}
                                showNow={false}
                                disabledTime={disabledTime}
                                onChange={(time) =>
                                  form.setFieldValue(
                                    "time",
                                    moment(time).seconds(0)
                                  )
                                }
                                onBlur={(e) => {
                                  if (
                                    e.target.value &&
                                    moment(e.target.value).isValid()
                                  ) {
                                    form.setFieldValue(
                                      "time",
                                      moment(e.target.value).seconds(0)
                                    );
                                    if (
                                      editTime &&
                                      moment(e.target.value)
                                        .seconds(0)
                                        .isBetween(
                                          moment()
                                            .seconds(0)
                                            .add(
                                              Number(
                                                requestTimeLeft
                                              ),
                                              "minutes"
                                            ),
                                          moment().seconds(0).add(23, "hours")
                                        )
                                    ) {
                                      form.setFields([
                                        {
                                          name: "time",
                                          errors: [],
                                        },
                                      ]);
                                    }
                                  }
                                }}
                              />
                            </Col>
                            <Col
                              xs={1}
                              sm={1}
                              md={1}
                              lg={1}
                              xl={1}
                              xxl={1}
                            ></Col>
                            <Col
                              xs={1}
                              sm={1}
                              md={1}
                              lg={1}
                              xl={1}
                              xxl={1}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <CloseCircleOutlined
                                style={{
                                  color: BUTTON_GREEN_HEX,
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                                className="delete-icon"
                                onClick={() => {
                                  form.setFields([
                                    {
                                      name: "time",
                                      errors: [],
                                    },
                                  ]);
                                  form.setFieldValue(
                                    "time",
                                    moment(expiry)
                                      .seconds(0)
                                      .format("MM-DD-YYYY HH:mm")
                                  );
                                  setEditTime(false);
                                }}
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ width: "100%" }}>
                    <Form.Item
                      className={classes.formField}
                      label={
                        <span
                          style={{
                            whiteSpace: "normal",
                          }}
                        >
                          Additional information
                        </span>
                      }
                      name="additionalInformation"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 18 }}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          validator: shortDescriptionValidator,
                          required: false,
                        },
                      ]}
                    >
                      <Input.TextArea autoSize={{ minRows: 1, maxRows: 20 }} />
                    </Form.Item>
                  </Col>
                </Row>

                <div
                  style={{
                    width: "100%",
                    cursor: disableCards ? "not-allowed" : "default",
                  }}
                >
                  <div
                    className={`${classes.dataSectionHeader} ${
                      disableCards && classes.disableEvents
                    }`}
                  >
                    <div style={{ width: "50%" }}>
                      <b>Items</b>
                    </div>
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <Button
                        className={classes.button}
                        disabled={
                          form.getFieldValue("campusId") &&
                          form.getFieldValue("fromUnit") &&
                          form.getFieldValue("toUnit")
                            ? false
                            : true
                        }
                        type="primary"
                        onClick={openAddItemModal}
                      >
                        + Add New Item
                      </Button>
                    </div>
                  </div>
                  {itemsErr ? (
                    <Alert
                      className={`fadeIn ${classes.alertContainer}`}
                      message={itemsErr}
                      type="error"
                      closable
                      afterClose={() => setItemsErr("")}
                    />
                  ) : null}
                  <div className={classes.scrollableRow}>
                    {/* Scrollable row */}
                    {tempCart.length ? (
                      tempCart.map((item: any) => {
                        const hasOrderSplFlag =
                          Object.keys(item).includes("isSpecialtyItem");
                        return ItemCard({
                          disabled: disableCards,
                          itemObj: item.item,
                          name: item.item.name,
                          quantity: item.quantity,
                          image: item.item.image,
                          editable: true,
                          itemId: item.item.id,
                          delay: item.item.delay,
                          assetCount : item?.assetCount,
                          assetCountModal: assetCountModal,
                          onclickAssetCount: () => {
                            setAssetCountModal(item.id)
                          },
                          onCloseAssetCount:() => {
                            setAssetCountModal(false)
                          },
                          reqTypeId,
                          specialityItem: !!(hasOrderSplFlag
                            ? item?.isSpecialtyItem
                            : item?.item?.specialtyItem),
                          propsToEdit: {
                            quantityEdit: quantityEdit,
                            onItemRemove: onItemRemove,
                          },
                          setDetails: () => {
                            setShowPatientModal({
                              id: item.item.id,
                              mode: PATIENTMODAL_VIEW,
                            });
                          },
                          viewPatientProps: {
                            patients: item?.patients || [],
                            patientDetailsPresent,
                          },
                        });
                      })
                    ) : (
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <Empty
                          description="Cart is empty"
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Card>
            <div className={classes.footer}>
              <Button
                type="default"
                className={`${classes.cancelButton} `}
                onClick={(e) => {
                  e.preventDefault();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className={classes.button}
                htmlType="submit"
                loading={editReqLoading}
              >
                Update
              </Button>
            </div>
          </Row>
        </Form>
        {addItemModal && (
          <AddItemModal
            allItems={dynamicItems}
            visible={addItemModal}
            handleCancel={() => setAddItemModal(false)}
            onItemsSelected={onItemsSelected}
            editCart={tempCart}
            type={reqTypeAddItemMap[reqTypeId] || REQ_EDIT}
            showDelayFlag={reqTypeId !== PICKUP_REQUEST_TYPE_ID}
            showSpecialityFlag={reqTypeId === DELIVERY_REQUEST_TYPE_ID}
            patientDetails
          />
        )}
      </Modal>
      {reqTypeId === DELIVERY_REQUEST_TYPE_ID && (
        <PatientDetailsModal
          visible={!!showPatientModal}
          toggleModal={() => setShowPatientModal(false)}
          cart={tempCart}
          setCart={setTempCart}
          itemId={showPatientModal.id}
          mode={showPatientModal.mode}
        />
      )}
      <DelayModal
        key="delay-modal"
        handleCancel={() => {
          setShowDelayModal("");
        }}
        handleOk={() => {
          setShowDelayModal("");
        }}
        isModalVisible={!!showDelayModal}
        icon={<ExclamationCircleTwoTone style={{ fontSize: "50px" }} />}
        text={showDelayModal}
        okayText="Okay"
        hideCancel={true}
      />
      <DelayModal
        key="item-clash-modal"
        handleCancel={() => {
          setShowItemModal(undefined);
        }}
        handleOk={() => {
          form.setFieldsValue({ unitId: showItemModal.unitId });
          const removeIds = showItemModal.unitSpecificItem.map(
            (cartItem: any) => cartItem.item.id
          );
          let itemRemoving = [...tempCart];
          itemRemoving = itemRemoving.filter(
            (cartItem) => !removeIds?.includes(cartItem.item.id)
          );
          setTempCart(itemRemoving);
          setShowItemModal(undefined);
        }}
        isModalVisible={!!showItemModal}
        icon={<ExclamationCircleTwoTone style={{ fontSize: "50px" }} />}
        text="Your Cart has some items that are specific to previous unit. It will be removed by the current selection of unit. Do you want to continue?"
        okayText="Yes"
        cancelText="No"
      />
      <DelayModal
        key="info-modal"
        handleCancel={() => {
          setIsRemoveItem(false);
        }}
        handleOk={removeItem}
        isModalVisible={isRemoveItem}
        icon={<ExclamationCircleTwoTone style={{ fontSize: "50px" }} />}
        text="Your Cart has some items that are specific to previous unit. It will be removed automatically. Do you want to continue?"
        okayText="Yes"
        cancelText="No"
      />
    </div>
  );
};

const mapStateToProps = ({ requestState, campusManagement }: IRootState) => ({
  dataLoading: requestState.loading,
  requestDetails: requestState.selectedRequest,
  fetchedUnits: requestState.units,
  unitsLoading: requestState.unitsLoading,
  errorMsg: requestState.errorMessage,
  allCampuses: campusManagement.allCampuses,
  campusesLoading: campusManagement.loading,
  editReqLoading: requestState.editReqLoading,
  patients: requestState.patients,
});
const mapDispatchToProps = {
  fetchAllCampuses,
  fetchUnitsOnCampus,
  updateRequestDetails,
  clearMessage,
  editPatients,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientRequestEditModal);
