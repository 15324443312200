import { Button, Input, Modal, Alert, Col, Row, Form } from "antd";
import { useState } from "react";
import { adminRootInstance } from "../../../../../common/config/axios-config";
import { compressImage } from "../../../../../common/utils/image-compressor";
import { CharCounter } from "../../commons/utils";
import classes from "../request-view.module.scss";

const NotesImageUploadModal = (props: any) => {
  const { showModal, toggleModal, notesObj, setNotesObj, reqId, successToast } =
    props;
  const { text, img: notesImage } = notesObj;
  const [uploadAPIErrorMessage, setUploadAPIErrorMessage] = useState<any>();
  const [noteText, setNoteText] = useState<any>(text);
  const [loading, setLoading] = useState(false);
  const [showCharCount, setShowCharCount] = useState(false);
  let img = notesImage && URL.createObjectURL(notesImage);

  const imageUpload = async (imageFile: any) => {
    let data = new FormData();
    let compressedFile = await compressImage(imageFile);
    data.append("image", compressedFile);
    return adminRootInstance
      .post("image", data, { params: { mode: "note" } })
      .then((res) => {
        return { status: "success", image: res.data.data };
      })
      .catch((err) => {
        setUploadAPIErrorMessage(err.ressponse?.data?.message);
        return { status: "error" };
      });
  };
  const submitHandler = async () => {
    //hit api to send the notes
    setLoading(true);
    const res: any = await imageUpload(notesObj.img);
    if (res.status === "success") {
      adminRootInstance
        .post("note/", {
          orderNumber: reqId,
          message: noteText.trim(),
          attachment: res.image,
        })
        .then((res) => {
          successToast();
        })
        .catch((err) => {
          console.log(err);
        });
    }

    setLoading(false);
    setNotesObj({ text: "" });
    toggleModal();
  };

  const cancelHandler = () => {
    setNotesObj({ text, img: {} });
    toggleModal();
  };
  const [form] = Form.useForm();
  return (
    <Modal
      title={null}
      centered
      visible={showModal}
      onCancel={cancelHandler}
      style={{ minWidth: "300px" }}
      bodyStyle={{ width: "100%" }}
      footer={null}
    >
      <Form
        {...{
          labelCol: { span: 6 },
          labelAlign: "left",
          colon: false,
          wrapperCol: { span: 18 },
        }}
        onFinish={submitHandler}
        form={form}
      >
        <Row
          className={classes.flexMiddle}
          style={{
            width: "100%",
            textAlign: "center",
            height: "auto",
            flexDirection: "column",
          }}
        >
          <img width={200} alt="noteImage" src={`${img}`} />
          <Row className={classes.notesModal}>
            <Col span={20}>
              <Form.Item
                label={<span className={classes.caption}>Caption:</span>}
                className={classes.labelForm}
                name="notes"
                initialValue={text}
                rules={[
                  {
                    required: false,
                    message: "Please Type Some Text!",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Type your note here"
                  className={classes.notesTextBox}
                  autoSize={{ minRows: 1, maxRows: 6 }}
                  maxLength={200}
                  defaultValue={text}
                  onChange={(e) => {
                    form.setFieldsValue({ notes: e.target.value.trim() });
                    setNoteText(e.target.value);
                  }}
                  onFocus={() => setShowCharCount(true)}
                  onBlur={() => setShowCharCount(false)}
                  onKeyDown={(event) => {
                    if (event.ctrlKey && event.keyCode === 13) {
                      document.getElementById("NotesSubmit2")?.click();
                    }
                  }}
                />
                {showCharCount && <CharCounter charLen={noteText?.length} />}
              </Form.Item>
            </Col>
          </Row>
          <Row className={`${classes.modalFooter} ${classes.footerButtons}`}>
            <Button
              type="default"
              htmlType="button"
              className={`${classes.buttons} ${classes.cancelButton}`}
              onClick={() => {
                cancelHandler();
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className={`${classes.buttons} ${classes.modalFooterButton}`}
              id="NotesSubmit2"
              loading={loading}
              htmlType={"submit"}
            >
              Send
            </Button>
          </Row>
        </Row>
      </Form>
      {uploadAPIErrorMessage ? (
        <Alert
          message={uploadAPIErrorMessage}
          type="error"
          closable
          afterClose={() => setUploadAPIErrorMessage("")}
          className={`fadeIn ${classes.errorContainer}`}
        />
      ) : null}
    </Modal>
  );
};

export default NotesImageUploadModal;
