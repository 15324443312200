export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const CLEAR_USER_MSG = "CLEAR_USER_MSG";
export const CLEAR_USER_FETCH_ERROR_MSG = "CLEAR_USER_FETCH_ERROR_MSG";
export const FETCH_USERS_START = "FETCH_USERS_START";
