/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { Button, Alert, Typography } from "antd";
import { FormOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import ListComponent from "../../shared/components/list-component/list-component";
import { IRootState } from "../../../common/redux/reducers";
import { connect } from "react-redux";
import { fetchUsers, createUser, updateUser, deleteUser } from "./actions";
import { fetchRequests } from "../sla/actions";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_LIMIT,
  VIEW_TEXT,
  EDIT_TEXT,
  DELETE_TEXT,
  CREATE,
  VIEW,
  EDIT,
  DELETE,
  ROLES,
  USER_PAGE,
  BUTTON_GREEN_HEX,
} from "../../../common/shared-constants";
import UserViewModal from "./user-modals/user-view-modal";
import UserModal from "./user-modals/user-modal";
import DeleteModal from "../../shared/components/delete-modal/delete-modal";
import { AbilityContext } from "../../shared/ability/can";
import { useAbility } from "@casl/react";
import { Can } from "../../shared/ability/can";
import { Helmet } from "react-helmet";
import classes from "./user.module.scss";
import { CLEAR_USER_FETCH_ERROR_MSG, CLEAR_USER_MSG } from "./action-types";
import { clearMessage } from "../../../common/actions/common-actions";
const { Title } = Typography;

const UserPage = ({
  users,
  fetchRequests,
  requests,
  loggedInUser,
  totalCount,
  errorMsg,
  fetchUsers,
  successMsg,
  fetchErrorMsg,
  clearMessage,
  createUser,
  updateUser,
  deleteUser,
  usersLoading,
}: any) => {
  const ability = useAbility(AbilityContext);
  const [loading, setLoading] = useState(false);
  const [tableOpt, setOperation] = useState({
    search: "",
    limit: DEFAULT_PAGE_LIMIT,
    page: DEFAULT_PAGE,
    pageSizeOptions: [30, 50, 100, 120],
    showSizeChanger: true,
  });

  const { search, page, limit, showSizeChanger, pageSizeOptions } = tableOpt;

  const [modalOp, setModalOp] = useState({
    visible: false,
    mode: CREATE,
    user: null,
    title: "Delete User",
    label: "user",
  });

  useEffect(() => {
    fetchUsers({ search: search, limit, page: DEFAULT_PAGE });
    fetchRequests();
    return () => {
      clearMessages();
      clearFetchError();
    };
  }, []);

  useEffect(() => {
    let timer: any;
    if (successMsg) {
      timer = setTimeout(() => {
        clearMessages();
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [successMsg]);

  const clearMessages = () => {
    clearMessage(CLEAR_USER_MSG);
  };
  const clearFetchError = () => {
    clearMessage(CLEAR_USER_FETCH_ERROR_MSG);
  };

  const handleCancel = () => {
    setModalOp({
      ...modalOp,
      visible: false,
    });
  };

  const onView = (user: any) => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: VIEW,
      user,
    });
  };
  const onDelete = (user: any) => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: DELETE,
      user,
    });
  };
  const onEdit = (user: any) => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: EDIT,
      user,
    });
  };

  const onSearch = ({ target }: any) => {
    setOperation({
      ...tableOpt,
      search: target.value,
      page: DEFAULT_PAGE,
    });
    fetchUsers({ search: target.value, limit, page: DEFAULT_PAGE });
  };

  const handleTableChange = (pagination: any) => {
    setOperation({
      ...tableOpt,
      page: pagination.current,
      limit: pagination.pageSize,
    });
    fetchUsers({ search, limit, page: pagination.current });
  };

  const onAdd = () => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: CREATE,
      user: null,
    });
  };

  const handleSubmit = async (user: any) => {
    setLoading(true);
    let result: any;
    if (modalOp.mode === CREATE) {
      result = await createUser(user);
    } else if (modalOp.mode === EDIT) {
      result = await updateUser(user);
    } else {
      result = await deleteUser(user);
    }
    if (result.success) {
      fetchUsers({ search, limit, page: page });
      setModalOp({
        ...modalOp,
        visible: false,
      });
    }
    setLoading(false);
  };

  const columns: any = [
    {
      align: "center",
      title: "Username",
      dataIndex: "username",
      width: "18%",
      key: "username",
      render: (username: String) => username,
      ellipsis: true,
      className: "table",
    },
    {
      align: "center",
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      width: "18%",
      render: (firstName: String) => firstName,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      width: "18%",
      render: (lastName: String) => lastName,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "18%",
      render: (email: String) => (email ? email : "-"),
      ellipsis: true,
    },
    {
      align: "center",
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: "13%",
      render: (role: String) => (role ? role : "-"),
      ellipsis: true,
    },
    {
      align: "center",
      title: "Assigned User Type",
      dataIndex: "requestType",
      key: "requestType",
      width: "18%",
      render: (requestType: any) =>
        requestType?.length > 0
          ? requestType.map((reqType: any) => (
              <div>
                <p className={classes.requestType}>
                  {reqType.requestTypeId.name}
                </p>
              </div>
            ))
          : "-",
      ellipsis: true,
    },
    {
      align: "center",
      title: "Actions",
      key: "action",
      width: "15%",
      ellipsis: true,
      render: (record: any) => (
        <div className="actionIcons">
          <Button
            size="small"
            title={VIEW_TEXT}
            className="icon-btn"
            onClick={() => {
              onView(record);
            }}
          >
            <EyeOutlined
              style={{ color: BUTTON_GREEN_HEX }}
              className="edit-icon"
            />
          </Button>
          {ability.can("edit", "User") && (
            <Button
              size="small"
              title={EDIT_TEXT}
              className="icon-btn"
              onClick={() => {
                onEdit(record);
              }}
            >
              <FormOutlined
                style={{ color: BUTTON_GREEN_HEX }}
                className="edit-icon"
              />
            </Button>
          )}
          <Can I="delete" a="User">
            <Button
              disabled={record.id === loggedInUser.id}
              title={DELETE_TEXT}
              size="small"
              className="icon-btn"
              onClick={() => {
                onDelete(record);
              }}
            >
              <DeleteOutlined
                style={{ color: BUTTON_GREEN_HEX }}
                className="delete-icon"
              />
            </Button>
          </Can>
        </div>
      ),
      responsive: ["lg", "md"],
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Users | Admin Portal</title>
      </Helmet>
      <Title className={classes.titleText} level={5}>
        Users
      </Title>
      {successMsg ? (
        <Alert
          className={`fadeIn ${classes.alertContainer}`}
          message={successMsg}
          type="success"
          closable
          afterClose={clearMessages}
        />
      ) : null}
      {fetchErrorMsg ? (
        <Alert
          className={`fadeIn ${classes.alertContainer}`}
          message={fetchErrorMsg}
          type="error"
          closable
          afterClose={clearFetchError}
        />
      ) : null}
      <ListComponent
        type={USER_PAGE}
        loading={usersLoading}
        dataSource={users}
        columns={columns}
        handleTableChange={handleTableChange}
        onSearch={onSearch}
        onAdd={onAdd}
        pagination={{
          current: page,
          pageSize: limit,
          total: totalCount,
          showSizeChanger,
          pageSizeOptions,
        }}
        addButtonLabel={"Add New User"}
        searchLabel={"Search by Username, First Name, Last Name"}
      />
      {modalOp.mode === VIEW ? (
        <UserViewModal
          handelClose={handleCancel}
          visible={modalOp.visible}
          user={modalOp.user}
        />
      ) : null}
      {(modalOp.mode === EDIT || modalOp.mode === CREATE) && modalOp.visible ? (
        <UserModal
          visible={modalOp.visible}
          user={modalOp.user}
          mode={modalOp.mode}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          roles={ROLES}
          requestTypes={requests}
          errorMsg={errorMsg}
          clearMessages={clearMessages}
          loading={loading}
          loggedInUser={loggedInUser}
        />
      ) : null}
      {modalOp.mode === DELETE && modalOp.visible ? (
        <DeleteModal
          loading={loading}
          entity={modalOp.user}
          visible={modalOp.visible}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          title={modalOp.title}
          label={modalOp.label}
          errorMsg={errorMsg}
          clearMessages={clearMessages}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = ({
  adminAuthentication,
  userManagement,
  slaManagement,
}: IRootState) => ({
  loggedInUser: adminAuthentication.account,
  errorMsg: userManagement.errorMessage,
  successMsg: userManagement.successMessage,
  usersLoading: userManagement.loading,
  users: userManagement.users,
  totalCount: userManagement.totalCount,
  fetchErrorMsg: userManagement.fetchErrorMessage,
  requests: slaManagement.requestTypes,
});
const mapDispatchToProps = {
  fetchUsers,
  fetchRequests,
  createUser,
  updateUser,
  deleteUser,
  clearMessage,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
