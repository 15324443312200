import Title from "antd/lib/typography/Title";
import classes from "../../common-reports.module.scss";
import moment from "moment";
import { FormInstance, Row } from "antd";
import { Link } from "react-router-dom";

export const BackButton = ({ reportView, navigateToForm }: any) => {
  return (
    <Row className={classes.gobackBtnIndvl}>
      {reportView === "form" && (
        <Link to="/admin/reports" title="Go back to reports">
          <button></button>
        </Link>
      )}
      {reportView !== "form" && (
        <button onClick={() => navigateToForm()}></button>
      )}
    </Row>
  );
};
export const ReportsBreadCrumbComponent = ({ reportType, reportView }: any) => {
  return (
    <Title
      style={{ paddingTop: "15px", textAlign: "left" }}
      className={classes.titleText}
      level={5}
    >
      {reportType}{" "}
      {reportView && reportView !== "form"
        ? `/ ${reportView[0].toUpperCase() + reportView.slice(1)}`
        : ""}
    </Title>
  );
};
export function disabledDate(current: any, dateType: any, selectedValues: any) {
  if (!selectedValues) return moment().isBefore(current, "day");
  const filterDate =
    dateType === "From"
      ? selectedValues.toDate?.toDate()
      : selectedValues.fromDate?.toDate();
  if (filterDate) {
    if (dateType === "From") {
      return moment(current?.toDate()).isAfter(filterDate, "day");
    } else {
      return (
        moment(current?.toDate()).isBefore(filterDate, "day") ||
        (current && current >= moment().endOf("day"))
      );
    }
  }
  return current && current >= moment().endOf("day");
}

export const selectAllHandler = (
  form: FormInstance,
  value: any,
  fieldName: any,
  allKey: number | string
) => {
  if (value && value.length && value.includes(allKey)) {
    return form.setFieldsValue({
      ...form.getFieldsValue(),
      [fieldName]: [allKey],
    });
  }
};
