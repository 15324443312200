import {
  FETCH_REQUEST_SUCCESS,
  FETCH_REQUEST_FAIL,
  FETCH_REQUEST_START,
  FETCH_UNIT_SUCCESS,
  START_PRIORITY_FETCH,
  FETCH_PRIORITY_SUCCESS,
  CLEAR_REQUEST,
  EDIT_REQUEST_SUCCESS,
  EDIT_REQUEST_FAIL,
  CLEAR_REQUEST_MESSAGE,
  UPDATED_DETAIL,
  EDIT_REQUEST_START,
  START_UNIT_FETCH,
  FETCH_PATIENTS_FAIL,
  FETCH_PATIENTS_SUCCESS,
  FETCH_PATIENTS_START,
} from "./action-types";

const initialState = {
  selectedRequest: null,
  fetchErrorMessage: null as null | string,
  loading: false,
  editReqLoading: false,
  errorMessage: null as null | string,
  successMessage: null as null | string,
  notificationMessage: null as null | string,
  units: null,
  unitsLoading: false,
  slas: null,
  slasLoading: false,
  allItems: null,
  patients: [] as any,
};

export type SelectedRequestDetails = Readonly<typeof initialState>;

const requestState = (
  state = initialState,
  action: any
): SelectedRequestDetails => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        selectedRequest: payload.request,
        fetchErrorMessage: null,
        loading: false,
      };
    case START_UNIT_FETCH:
      return {
        ...state,
        unitsLoading: true,
      };
    case FETCH_UNIT_SUCCESS:
      return {
        ...state,
        unitsLoading: false,
        units: payload.units,
      };
    case START_PRIORITY_FETCH:
      return {
        ...state,
        slasLoading: true,
      };
    case FETCH_PRIORITY_SUCCESS:
      return {
        ...state,
        slasLoading: false,
        slas: payload.slas,
      };
    case FETCH_REQUEST_FAIL:
      return {
        ...state,
        selectedRequest: null,
        loading: false,
        unitsLoading: false,
        slasLoading: false,
        fetchErrorMessage: payload.fetchErrorMessage,
      };
    case CLEAR_REQUEST:
      return initialState;
    case EDIT_REQUEST_START:
      return {
        ...state,
        editReqLoading: true,
      };
    case EDIT_REQUEST_SUCCESS:
      return {
        ...state,
        selectedRequest: payload.updatedRequest,
        errorMessage: null,
        successMessage: payload.successMessage,
        editReqLoading: false,
      };
    case UPDATED_DETAIL:
      return {
        ...state,
        selectedRequest: payload,
        errorMessage: null,
        notificationMessage: `This Order is updated by someone!`,
      };
    case EDIT_REQUEST_FAIL:
      return {
        ...state,
        errorMessage: payload.errorMessage,
        editReqLoading: false,
      };
    case FETCH_PATIENTS_START:
      return {
        ...state,
        unitsLoading: true,
      };
    case FETCH_PATIENTS_SUCCESS:
      return {
        ...state,
        unitsLoading: false,
        errorMessage: null,
        patients: payload.patients,
      };
    case FETCH_PATIENTS_FAIL:
      return {
        ...state,
        unitsLoading: false,
        errorMessage: payload.errorMessage,
        patients: [],
      };
    case CLEAR_REQUEST_MESSAGE:
      return {
        ...state,
        successMessage: null,
        errorMessage: null,
      };
    default:
      return state;
  }
};

export default requestState;
