import React, { useState } from "react";
import { ChromePicker } from "react-color";
import classes from "./colorpicker.module.scss";

const ColorPicker = ({ color, onChange }: any) => {
  const [display, setDisplay] = useState(false);
  const handleClick = (e: any) => {
    e.preventDefault();
    setDisplay(!display);
  };

  const handleClose = (e: any) => {
    e.preventDefault();
    setDisplay(false);
  };

  const onColorChange = (color: any) => {
    onChange(color.hex);
  };

  return (
    <div
      className={color ? classes.pickerBtnWrap : classes.pickerBtnWithoutColor}
    >
      <button
        title={color ? "Selected Color" : "Choose the color"}
        color-attr={color}
        style={{ backgroundColor: color }}
        className={classes.pickerBtn}
        onClick={handleClick}
      >
        {!color && "Choose the color"}
      </button>
      {display ? (
        <div className={`fadeIn ${classes.colorpicker}`}>
          <div className={classes.closeContainer} onClick={handleClose} />
          <ChromePicker
            disableAlpha={true}
            color={color}
            onChange={onColorChange}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
