export const CLEAR_ALL = "CLEAR_ALL";
export const FETCH_SLAs_FAIL = "FETCH_SLAs_FAIL";
export const FETCH_SLAs_SUCCESS = "FETCH_SLAs_SUCCESS";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAIL = "FETCH_ORDERS_FAIL";
export const FETCH_UNITS_SUCCESS = "FETCH_UNITS_SUCCESS";
export const FETCH_UNITS_FAIL = "FETCH_UNITS_FAIL";
export const FETCH_STATUSES_SUCCESS = "FETCH_STATUSES_SUCCESS";
export const FETCH_STATUSES_FAIL = "FETCH_STATUSES_FAIL";
export const FETCH_ITEMS_SUCCESS = "FETCH_ITEMS_SUCCESS";
export const FETCH_ITEMS_FAIL = "FETCH_ITEMS_FAIL";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";
export const FETCH_SPECIALITYITEMS_SUCCESS = "FETCH_SPECIALITYITEMS_SUCCESS";
export const FETCH_SPECIALITYITEMS_FAIL = "FETCH_SPECIALITYITEMS_FAIL";
export const FETCH_REPORT_START = "FETCH_REPORT_START";
export const FETCH_REPORT_SUCCESS = "FETCH_REPORT_SUCCESS";
export const FETCH_REPORT_FAIL = "FETCH_REPORT_FAIL";
export const SET_REPORT_FILTER = "SET_REPORT_FILTER";
export const FETCH_ALL_DISCHARGE_LOCATION_START =
  "FETCH_ALL_DISCHARGE_LOCATION_START";
export const FETCH_ALL_DISCHARGE_LOCATION_SUCCESS =
  "FETCH_ALL_DISCHARGE_LOCATION_SUCCESS";
export const FETCH_ALL_DISCHARGE_LOCATION_FAIL =
  "FETCH_ALL_DISCHARGE_LOCATION_FAIL";