import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NotFound from "../components/not-found";
import AdminRoutes from "./admin-routes";
import RequestRoutes from "./request-routes";
import Blank from "../components/blank";

const Routes = () => (
  <>
    <Switch>
      <Route path="/admin" component={AdminRoutes} />
      <Route path="/request" component={RequestRoutes} />
      <Route path="/request/my-requests" component={RequestRoutes} />
      <Redirect exact from="/" to="/request" />
      <Route path={"/blank"} component={Blank} />
      <Route path="*" component={NotFound} />
    </Switch>
  </>
);

export default Routes;
