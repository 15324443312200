import {
  AIM_REPORT_CLEAR_ALL,
  FETCH_AIM_REPORT_SUCCESS,
  FETCH_AIM_REPORT_FAIL,
  SET_AIM_REPORT_FILTER,
  FETCH_AIM_REPORT_START,
} from "./action-types";

const initialState = {
  loading: false,
  errorMessage: null as null | string,
  successMessage: null as null | string,
  filterData: {} as any,
  reportData: {} as any,
};

export type AIMReportsDetails = Readonly<typeof initialState>;

const AIMreportsState = (
  state = initialState,
  action: any
): AIMReportsDetails => {
  const { type, payload } = action;
  switch (type) {
    case SET_AIM_REPORT_FILTER:
      return { ...state, filterData: payload.filterData };
    case FETCH_AIM_REPORT_START:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case FETCH_AIM_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        reportData: {
          data: payload.reportData,
          totalCount: payload.totalCount,
        },
      };
    case FETCH_AIM_REPORT_FAIL:
      return {
        ...state,
        reportData: {},
        loading: false,
        errorMessage: payload.errorMessage,
      };

    case AIM_REPORT_CLEAR_ALL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default AIMreportsState;
