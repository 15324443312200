/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import { Button, Alert, Typography, message } from "antd";
import { FormOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import ListComponent from "../../shared/components/list-component/list-component";
import { IRootState } from "../../../common/redux/reducers";
import { connect } from "react-redux";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_LIMIT,
  VIEW_TEXT,
  EDIT_TEXT,
  DELETE_TEXT,
  CREATE,
  VIEW,
  EDIT,
  DELETE,
  ITEM_PAGE,
  BUTTON_GREEN_HEX,
} from "../../../common/shared-constants";
import DeleteModal from "../../shared/components/delete-modal/delete-modal";
import { AbilityContext } from "../../shared/ability/can";
import { useAbility } from "@casl/react";
import { Can } from "../../shared/ability/can";
import { Helmet } from "react-helmet";
import classes from "./item.module.scss";
import { clearMessage } from "../../../common/actions/common-actions";
import {
  fetchItems,
  createItem,
  updateItem,
  deleteItem,
  fetchCatalogues,
  fetchCategories,
  fetchItemSizes,
  createItemSize,
} from "./actions";
import { fetchAllCampuses } from "../campus/actions";
import { fetchAllUnits } from "../unit/actions";
import ItemViewModal from "./items-modals/item-view-modal";
import ItemModal from "./items-modals/item-modal";
import { CLEAR_ITEM_MSG, CLEAR_ITEM_FETCH_ERROR_MSG } from "./action-types";
const { Title } = Typography;

const ItemsPage = ({
  items,
  totalCount,
  errorMsg,
  fetchItems,
  successMsg,
  fetchErrorMsg,
  clearMessage,
  createItem,
  updateItem,
  deleteItem,
  allUnits,
  fetchAllUnits,
  categories,
  fetchCategories,
  catalogues,
  fetchCatalogues,
  itemSizes,
  fetchItemSizes,
  itemsLoading,
  fetchAllCampuses,
  allCampuses,
  createItemSize,
}: any) => {
  const ability = useAbility(AbilityContext);
  const [loading, setLoading] = useState(false);
  const [tableOpt, setOperation] = useState({
    search: "",
    limit: DEFAULT_PAGE_LIMIT,
    page: DEFAULT_PAGE,
    pageSizeOptions: [30, 50, 100, 120],
    showSizeChanger: true,
  });

  const { search, page, limit, showSizeChanger, pageSizeOptions } = tableOpt;

  const [modalOp, setModalOp] = useState({
    visible: false,
    mode: CREATE,
    item: null,
    title: "Delete Item",
    label: "Item",
  });

  useEffect(() => {
    fetchItems({ search: search, limit, page: DEFAULT_PAGE });
    fetchItemSizes();
    fetchCategories();
    fetchCatalogues();
    fetchAllUnits();
    fetchAllCampuses();
    return () => {
      clearMessages();
      clearFetchError();
    };
  }, []);

  useEffect(() => {
    let timer: any;
    if (successMsg) {
      timer = setTimeout(() => {
        clearMessages();
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [successMsg]);

  const clearMessages = () => {
    clearMessage(CLEAR_ITEM_MSG);
  };
  const clearFetchError = () => {
    clearMessage(CLEAR_ITEM_FETCH_ERROR_MSG);
  };

  const handleCancel = () => {
    setModalOp({
      ...modalOp,
      item: null,
      visible: false,
    });
  };

  const onView = (item: any) => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: VIEW,
      item,
    });
  };
  const onDelete = (item: any) => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: DELETE,
      item,
    });
  };
  const onEdit = (item: any) => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: EDIT,
      item,
    });
  };

  const onSearch = ({ target }: any) => {
    setOperation({
      ...tableOpt,
      search: target.value,
      page: DEFAULT_PAGE,
    });
    fetchItems({ search: target.value, limit, page: DEFAULT_PAGE });
  };

  const handleTableChange = (pagination: any) => {
    setOperation({
      ...tableOpt,
      page: pagination.current,
      limit: pagination.pageSize,
    });
    fetchItems({ search, limit, page: pagination.current });
  };

  const onAdd = () => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: CREATE,
      item: null,
    });
  };

  const handleSubmit = async (items: any) => {
    setLoading(true);
    let result: any;
    //hit itemSize Create api if sizeId type is string
    let sizeCreate: any = { success: true };
    if (items && typeof items.sizeId === "string") {
      //check if already a size name exsists
      const existingItem = itemSizes.find(
        (itemSize: any) => itemSize.name === items.sizeId
      );
      if (!existingItem) {
        sizeCreate = await createItemSize(items.sizeId);
        if (sizeCreate.success) items.sizeId = sizeCreate.data.id;
        else message.error(sizeCreate.message);
      } else items.sizeId = existingItem.id;
    }
    if (sizeCreate.success)
      if (modalOp.mode === CREATE) {
        result = await createItem(items);
      } else if (modalOp.mode === EDIT || modalOp.mode === VIEW) {
        result = await updateItem(items);
      } else {
        result = await deleteItem(items);
      }
    if (result.success) {
      fetchItems({ search, limit, page: page });
      setModalOp({
        ...modalOp,
        visible: false,
      });
    }
    setLoading(false);
  };

  const columns: any = [
    {
      align: "center",
      title: "Item Name",
      dataIndex: "name",
      width: "20%",
      key: "name",
      render: (name: String) => name,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Category",
      dataIndex: "categoryIds",
      key: "categoryIds",
      width: "20%",
      render: (categoryIds: any) =>
        categoryIds
          ?.map((categoryId: any) =>
            categories.find((category: any) => category.id === categoryId)
          )
          .sort((a: any, b: any) => (a.name < b.name ? -1 : 1))
          .reduce(
            (accumulator: any, currentValue: any, index: any) =>
              index === 0
                ? `${currentValue?.name}`
                : `${accumulator}, ${currentValue?.name}`,
            "-"
          ),
      ellipsis: true,
    },
    {
      align: "center",
      title: "Mapped Campus",
      dataIndex: "campusIds",
      key: "campusIds",
      width: "20%",
      render: (campusIds: any) =>
        campusIds.length
          ? campusIds
              ?.map((campusId: any) =>
                allCampuses.find((unit: any) => unit.id === campusId)
              )
              .reduce(
                (accumulator: any, currentValue: any, index: any) =>
                  index === 0
                    ? `${currentValue?.name ? currentValue?.name : ""}`
                    : `${accumulator} ${currentValue?.name ? "," : ""} ${
                        currentValue?.name ? currentValue?.name : ""
                      }`,
                ""
              )
          : "-",
      ellipsis: true,
    },
    {
      align: "center",
      title: "Mapped Unit Name",
      dataIndex: "unitIds",
      key: "unitIds",
      width: "20%",
      render: (unitIds: any) =>
        unitIds
          ?.map((unitId: any) =>
            allUnits.find((unit: any) => unit.id === unitId)
          )
          .reduce(
            (accumulator: any, currentValue: any, index: any) =>
              index === 0
                ? `${currentValue?.name}`
                : `${accumulator}, ${currentValue?.name}`,
            ""
          ),
      ellipsis: true,
    },
    {
      align: "center",
      title: "Specialty Item",
      dataIndex: "specialtyItem",
      key: "specialtyItem",
      width: "20%",
      render: (specialtyItem: Boolean) => (specialtyItem ? "Yes" : "No"),
      ellipsis: true,
    },
    {
      align: "center",
      title: "Delay",
      dataIndex: "delay",
      key: "delay",
      width: "20%",
      render: (delay: Boolean) => (delay ? "ON" : "OFF"),
    },
    {
      align: "center",
      title: "Actions",
      key: "action",
      width: "20%",
      ellipsis: true,
      render: (record: any) => (
        <div className="actionIcons">
          <Button
            size="small"
            className="icon-btn"
            title={VIEW_TEXT}
            onClick={() => {
              onView(record);
            }}
          >
            <EyeOutlined
              style={{ color: BUTTON_GREEN_HEX }}
              className="edit-icon"
            />
          </Button>
          {ability.can("edit", "Item") && (
            <Button
              size="small"
              className="icon-btn"
              title={EDIT_TEXT}
              onClick={() => {
                onEdit(record);
              }}
            >
              <FormOutlined
                style={{ color: BUTTON_GREEN_HEX }}
                className="edit-icon"
              />
            </Button>
          )}
          <Can I="delete" a="Item">
            <Button
              size="small"
              className="icon-btn"
              title={DELETE_TEXT}
              onClick={() => {
                onDelete(record);
              }}
            >
              <DeleteOutlined
                style={{ color: BUTTON_GREEN_HEX }}
                className="delete-icon"
              />
            </Button>
          </Can>
        </div>
      ),
      responsive: ["lg", "md"],
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Items | Admin Portal</title>
      </Helmet>
      <Title className={classes.titleText} level={5}>
        Items
      </Title>
      {successMsg ? (
        <Alert
          className={`fadeIn ${classes.alertContainer}`}
          message={successMsg}
          type="success"
          closable
          afterClose={clearMessages}
        />
      ) : null}
      {fetchErrorMsg ? (
        <Alert
          className={`fadeIn ${classes.alertContainer}`}
          message={fetchErrorMsg}
          type="error"
          closable
          afterClose={clearFetchError}
        />
      ) : null}
      <ListComponent
        type={ITEM_PAGE}
        loading={itemsLoading}
        dataSource={items}
        columns={columns}
        handleTableChange={handleTableChange}
        onSearch={onSearch}
        onAdd={onAdd}
        pagination={{
          current: page,
          pageSize: limit,
          total: totalCount,
          showSizeChanger,
          pageSizeOptions,
        }}
        addButtonLabel={"Add New Item"}
        searchLabel={"Search by name"}
      />
      {modalOp.mode === VIEW && modalOp.visible ? (
        <ItemViewModal
          handleClose={handleCancel}
          visible={modalOp.visible}
          item={modalOp.item}
          categories={categories}
          allUnits={allUnits}
          catalogues={catalogues}
          loading={loading}
          itemSize={itemSizes}
          handleSubmit={handleSubmit}
          errorMsg={errorMsg}
          clearMessages={clearMessages}
          allCampuses={allCampuses}
        />
      ) : null}
      {(modalOp.mode === EDIT || modalOp.mode === CREATE) && modalOp.visible ? (
        <ItemModal
          visible={modalOp.visible}
          item={modalOp.item}
          categories={categories}
          allUnits={allUnits}
          itemSize={itemSizes}
          catalogues={catalogues}
          mode={modalOp.mode}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          errorMsg={errorMsg}
          clearMessages={clearMessages}
          loading={loading}
          setLoading={setLoading}
          allCampuses={allCampuses}
        />
      ) : null}
      {modalOp.mode === DELETE && modalOp.visible ? (
        <DeleteModal
          loading={loading}
          entity={modalOp.item}
          visible={modalOp.visible}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          title={modalOp.title}
          label={modalOp.label}
          errorMsg={errorMsg}
          clearMessages={clearMessages}
          isPassRequired={true}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = ({
  itemManagement,
  unitManagement,
  campusManagement,
}: IRootState) => ({
  errorMsg: itemManagement.errorMessage,
  successMsg: itemManagement.successMessage,
  itemsLoading: itemManagement.loading,
  items: itemManagement.items,
  totalCount: itemManagement.totalCount,
  fetchErrorMsg: itemManagement.fetchErrorMessage,
  categories: itemManagement.categories,
  catalogues: itemManagement.catalogues,
  itemSizes: itemManagement.itemSizes,
  allUnits: unitManagement.allUnits,
  allCampuses: campusManagement.allCampuses,
});
const mapDispatchToProps = {
  fetchItems,
  createItem,
  createItemSize,
  updateItem,
  deleteItem,
  clearMessage,
  fetchCategories,
  fetchItemSizes,
  fetchCatalogues,
  fetchAllUnits,
  fetchAllCampuses,
};
export default connect(mapStateToProps, mapDispatchToProps)(ItemsPage);
