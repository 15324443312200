import { Dispatch } from "redux";
import { adminRootInstance } from "../../../../common/config/axios-config";
import {
  CLEAR_AIM_DASHBOARD,
  FETCH_ASSETS_FAIL,
  FETCH_ASSETS_START,
  FETCH_ASSETS_SUCCESS,
  FETCH_SUMMARY_FAIL,
  FETCH_SUMMARY_START,
  FETCH_SUMMARY_SUCCESS,
  SET_FILTER_DATA,
  UPDATE_ASSET_FAILED,
  UPDATE_ASSET_START,
  UPDATE_ASSET_SUCCESS,
} from "./action-types";

export const fetchSummaryData = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_SUMMARY_START });
  await adminRootInstance
    .get("aim/count")
    .then((res: any) => {
      dispatch({
        type: FETCH_SUMMARY_SUCCESS,
        payload: {
          data: res.data.data,
        },
      });
    })
    .catch((err: any) => {
      dispatch({
        type: FETCH_SUMMARY_FAIL,
        payload: { error: err.response?.data?.message },
      });
    });
};

export const fetchAssetsData =
  ({ filter, search, limit, page }: any) =>
    async (dispatch: Dispatch) => {
      const queryParams = {
        campus: filter.campusId,
        ...(filter.status !== "ALL" ? { status: filter.status } : {}),
        location: filter.location,
        ...(filter.speciality !== "both"
          ? { speciality: filter.speciality }
          : {}),
        ...(filter.rental !== "both" ? { rental: filter.rental } : {}),
        ...(filter.verified !== "both" ? { verified: filter.verified } : {}),
        ...(filter.ownershipType !== "ALL" ? { ownershipType: filter.ownershipType } : {}),
        searchKeyword: search || "",
        page,
        pageSize: limit,
      };
      dispatch({ type: FETCH_ASSETS_START });
      await adminRootInstance
        .get("aim/assets", {
          params: queryParams,
        })
        .then((res: any) => {
          dispatch({
            type: FETCH_ASSETS_SUCCESS,
            payload: {
              data: res.data.data,
            },
          });
        })
        .catch((err: any) => {
          dispatch({
            type: FETCH_ASSETS_FAIL,
            payload: { error: err.response?.data?.message },
          });
        });
    };
export const updateAssetStatus =
  ({ notes, assetId, status, fetchApiData, selectedStatus, currentControlNumber, currentSerialNumber, campusId, ownershipType, generalNotes }: any) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: UPDATE_ASSET_START });
      const data = await adminRootInstance
        .put(`aim/${assetId}`, {
          note: notes,
          status: selectedStatus,
          controlNumber: currentControlNumber,
          serialNumber: currentSerialNumber,
          campusId, ownershipType, generalNotes
        })
        .then(async (res: any) => {
          dispatch({
            type: UPDATE_ASSET_SUCCESS,
            payload: {
              data: res.data.data,
              // prevStatus: status,
            },
          });
          return { success: true };
        })
        .catch((err: any) => {
          dispatch({
            type: UPDATE_ASSET_FAILED,
            payload: { error: err.response?.data?.message },
          });
          return { success: false, message: err.response?.data?.message };
        });
      if (data?.success) {
        fetchAssetsData(fetchApiData)(dispatch);
        fetchSummaryData()(dispatch);
      }
      return data;
    };
export const setFilterData =
  (filterData: any) => async (dispatch: Dispatch) => {
    dispatch({ type: SET_FILTER_DATA, payload: { data: filterData } });
  };
export const clearState = () => (dispatch: Dispatch) => {
  dispatch({ type: CLEAR_AIM_DASHBOARD });
};
