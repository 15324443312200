import { adminRootInstance } from "../../../common/config/axios-config";
import {
  DELETE_UNIT_SUCCESS,
  DELETE_UNIT_FAIL,
  UPDATE_UNIT_SUCCESS,
  UPDATE_UNIT_FAIL,
  CREATE_UNIT_SUCCESS,
  CREATE_UNIT_FAIL,
  FETCH_UNITS_FAIL,
  FETCH_UNITS_SUCCESS,
  FETCH_UNITS_START,
  FETCH_ALL_UNITS_SUCCESS,
  FETCH_ALL_UNITS_FAIL,
} from "./action-types";

export const fetchUnits =
  ({ search, limit, page }: any) =>
  async (dispatch: any) => {
    dispatch({
      type: FETCH_UNITS_START,
    });
    await adminRootInstance
      .get("units", {
        params: {
          searchKeyword: search,
          pageSize: limit,
          page,
        },
      })
      .then((res: any) => {
        dispatch({
          type: FETCH_UNITS_SUCCESS,
          payload: {
            units: res.data.data.units,
            totalCount: res.data.data.pagination.totalCount,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_UNITS_FAIL,
          payload: { fetchErrorMessage: err.response?.data?.message },
        });
      });
  };

export const createUnit = (unit: any) => async (dispatch: any) => {
  return await adminRootInstance
    .post("/unit", unit)
    .then((res) => {
      dispatch({
        type: CREATE_UNIT_SUCCESS,
        payload: { successMessage: "Unit Created Successfully" },
      });
      return { success: true };
    })
    .catch((err) => {
      dispatch({
        type: CREATE_UNIT_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
      return { success: false };
    });
};

export const updateUnit = (unit: any) => async (dispatch: any) => {
  return await adminRootInstance
    .put(`/unit/${unit.id}`, unit)
    .then((res) => {
      dispatch({
        type: UPDATE_UNIT_SUCCESS,
        payload: { successMessage: "Unit Updated Successfully" },
      });
      return { success: true };
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_UNIT_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
      return { success: false };
    });
};

export const deleteUnit =
  ({ id, password }: any) =>
  async (dispatch: any) => {
    return await adminRootInstance
      .delete(`/unit/${id}`, {
        params: {
          password,
        },
      })
      .then((res) => {
        dispatch({
          type: DELETE_UNIT_SUCCESS,
          payload: { successMessage: "Unit Deleted Successfully" },
        });
        return { success: true };
      })
      .catch((err) => {
        dispatch({
          type: DELETE_UNIT_FAIL,
          payload: { errorMessage: err.response?.data?.message },
        });
        return { success: false };
      });
  };

export const fetchAllUnits = () => async (dispatch: any) => {
  dispatch({
    type: FETCH_UNITS_START,
  });
  return await adminRootInstance
    .get(`/units/all`)
    .then((res) => {
      dispatch({
        type: FETCH_ALL_UNITS_SUCCESS,
        payload: { allUnits: res.data.data },
      });
      return res.data.data;
    })
    .catch((err) => {
      dispatch({
        type: FETCH_ALL_UNITS_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
      return [];
    });
};
