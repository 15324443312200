import React from "react";
import { Table } from "antd";
import classes from "../common-reports.module.scss";
const defaultYScroll = 400;
interface ReportsTableInterface {
  loading: boolean;
  rowData: any;
  columnData: any;
  totalCount: number;
  fetchData: Function;
  scrollX?: number | string;
  scrollY?: number | string;
}
const ReportsTable = ({
  loading,
  rowData,
  columnData,
  totalCount,
  fetchData,
  scrollX,
  scrollY = defaultYScroll,
}: ReportsTableInterface) => {
  return (
    <Table
      loading={loading}
      dataSource={rowData}
      className={classes.reportsTable}
      locale={{ filterEmptyText: true }}
      bordered
      scroll={{
        ...(scrollX && { x: scrollX }),
        ...(scrollY && { y: scrollY }),
      }}
      pagination={{
        total: totalCount,
        pageSizeOptions: ["30", "50", "100", "120"],
        defaultPageSize: 30,
        showSizeChanger: true,
      }}
      size={"small"}
      onChange={(details: any) => {
        fetchData({
          limit: details.pageSize,
          page: details.current,
        });
      }}
      columns={columnData}
    />
  );
};

export default ReportsTable;
