export const FETCH_SUMMARY_START = "FETCH_SUMMARY_START";
export const FETCH_SUMMARY_SUCCESS = "FETCH_SUMMARY_SUCCESS";
export const FETCH_SUMMARY_FAIL = "FETCH_SUMMARY_FAIL";

export const FETCH_ASSETS_START = "FETCH_ASSETS_START";
export const FETCH_ASSETS_SUCCESS = "FETCH_ASSETS_SUCCESS";
export const FETCH_ASSETS_FAIL = "FETCH_ASSETS_FAIL";

export const CLEAR_ALL_MESSAGES = "CLEAR_ALL_MESSAGES";
export const SET_FILTER_DATA = "SET_FILTER_DATA";

export const UPDATE_ASSET_START = "UPDATE_ASSET_START";
export const UPDATE_ASSET_SUCCESS = "UPDATE_ASSET_SUCCESS";
export const UPDATE_ASSET_FAILED = "UPDATE_ASSET_FAILED";

export const CLEAR_AIM_DASHBOARD = "CLEAR_AIM_DASHBOARD";
