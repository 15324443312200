import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Modal, Typography, Button, Row, Col, Skeleton, List } from "antd";
import classes from "./transport-view-modal.module.scss";
import { GREEN_THEME_HEX } from "../../../../common/shared-constants";
import { adminRootInstance } from "../../../../common/config/axios-config";
const { Text } = Typography;

const TransportViewModal = ({ handelClose, transport, visible }: any) => {
  const [dischargeLocations, setDischargeLocations] = useState<any>([]);
  useEffect(() => {
    if (transport?.campusId?.id) {
      const getDischargeLocations = async (campusId: any) => {
        const res = await adminRootInstance.get(
          `discharge-location/${campusId}`
        );
        setDischargeLocations([...res?.data?.data]);
      };
      getDischargeLocations(transport?.campusId?.id);
    }
  }, [transport?.campusId?.id]);
  return (
    <Modal
      title={
        <div className="commonModalHeader">
          <div className="title">
            <p> View Transport </p>
          </div>
          <div className="close">
            <Button className="closeBtn" onClick={handelClose}>
              <CloseOutlined className="close-icon" />
            </Button>
          </div>
        </div>
      }
      visible={visible}
      maskClosable={false}
      onCancel={handelClose}
      footer={null}
      closable={false}
      centered
      bodyStyle={{ maxHeight: 700, overflowY: "auto" }}
      width={800}
    >
      <Row>
        <Col span={5}>
          <Text className={classes.doubleDot}>Campus Name</Text>
        </Col>
        <Col span={7}>
          <Text className={classes.popValue}>{transport?.campusId?.name}</Text>
        </Col>
        <Col span={5}>
          <Text className={classes.doubleDot}>Bracelet Verification</Text>
        </Col>
        <Col span={7}>
          <Text className={classes.popValue}>
            {transport?.braceletVerification ? "ON" : "OFF"}
          </Text>
        </Col>
      </Row>

      <Row>
        <Col span={5}>
          <Text className={classes.doubleDot}>Show Header Text</Text>
        </Col>
        <Col span={7}>
          <Text className={classes.popValue}>
            {transport?.showHeaderText ? "ON" : "OFF"}
          </Text>
        </Col>
      </Row>
      <Row style={{ paddingBottom: "20px" }}>
        <Col span={5}>
          <Text className={classes.doubleDot}>Header Text</Text>
        </Col>
        <Col span={24}>
          <Text>{transport?.headerText || "-"}</Text>
        </Col>
      </Row>

      <Row
        gutter={[0, 16]}
        style={{
          padding: "10px",
          border: `1px solid ${GREEN_THEME_HEX}`,
          borderRadius: "5px",
        }}
      >
        <Col span={7} className={classes.columnPadding}>
          <Text className={classes.doubleDot}>Procedure</Text>
        </Col>
        <Col span={5} className={classes.columnPadding}>
          <Text className={classes.popValue}>
            {transport?.procedureRequest ? "ON" : "OFF"}
          </Text>
        </Col>
        <Col span={7} className={classes.columnPadding}>
          <Text className={classes.doubleDot}>Return</Text>
        </Col>
        <Col span={5} className={classes.columnPadding}>
          <Text className={classes.popValue}>
            {transport?.returnRequest ? "ON" : "OFF"}
          </Text>
        </Col>
        <Col span={7} className={classes.columnPadding}>
          <Text className={classes.doubleDot}>Start Now Reminder</Text>
        </Col>
        <Col span={5} className={classes.columnPadding}>
          <Text className={classes.popValue}>
            {transport?.procedureTimeLeft}
          </Text>
        </Col>
        <Col span={7} className={classes.columnPadding}>
          <Text className={classes.doubleDot}>Not-Accepted Timer</Text>
        </Col>
        <Col span={5} className={classes.columnPadding}>
          <Text className={classes.popValue}>
            {transport?.procedureThreshold}
          </Text>
        </Col>
        <Col span={7} className={classes.columnPadding}>
          <Text className={classes.doubleDot}>Priority</Text>
        </Col>
        <Col span={5}>
          <Text className={classes.popValue}>
            {transport?.procedurePriority}
          </Text>
        </Col>
      </Row>

      <Row
        gutter={[0, 16]}
        style={{
          padding: "10px",
          border: `1px solid ${GREEN_THEME_HEX}`,
          borderRadius: "5px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Col span={7} className={classes.columnPadding}>
          <Text className={classes.doubleDot}>Transfer</Text>
        </Col>
        <Col span={5} className={classes.columnPadding}>
          <Text className={classes.popValue}>
            {transport?.transferRequest ? "ON" : "OFF"}
          </Text>
        </Col>
        <Col span={7} className={classes.columnPadding}>
          <Text className={classes.doubleDot}>Start Now Reminder</Text>
        </Col>
        <Col span={5} className={classes.columnPadding}>
          <Text className={classes.popValue}>
            {transport?.transferTimeLeft}
          </Text>
        </Col>
        <Col span={7} className={classes.columnPadding}>
          <Text className={classes.doubleDot}>Not-Accepted Timer</Text>
        </Col>
        <Col span={5} className={classes.columnPadding}>
          <Text className={classes.popValue}>
            {transport?.transferThreshold}
          </Text>
        </Col>
        <Col span={7} className={classes.columnPadding}>
          <Text className={classes.doubleDot}>Priority</Text>
        </Col>
        <Col span={5} className={classes.columnPadding}>
          <Text className={classes.popValue}>
            {transport?.transferPriority}
          </Text>
        </Col>
      </Row>

      <Row
        gutter={[0, 16]}
        style={{
          padding: "10px",
          border: `1px solid ${GREEN_THEME_HEX}`,
          borderRadius: "5px",
        }}
      >
        <Col span={7} className={classes.columnPadding}>
          <Text className={classes.doubleDot}>Discharge</Text>
        </Col>
        <Col span={5} className={classes.columnPadding}>
          <Text className={classes.popValue}>
            {transport?.dischargeRequest ? "ON" : "OFF"}
          </Text>
        </Col>
        <Col span={7} className={classes.columnPadding}>
          <Text className={classes.doubleDot}>Start Now Reminder</Text>
        </Col>
        <Col span={5} className={classes.columnPadding}>
          <Text className={classes.popValue}>
            {transport?.dischargeTimeLeft}
          </Text>
        </Col>
        <Col span={7} className={classes.columnPadding}>
          <Text className={classes.doubleDot}>Not-Accepted Timer</Text>
        </Col>
        <Col span={5} className={classes.columnPadding}>
          <Text className={classes.popValue}>
            {transport?.dischargeThreshold}
          </Text>
        </Col>
        <Col span={7} className={classes.columnPadding}>
          <Text className={classes.doubleDot}>Priority</Text>
        </Col>
        <Col span={5} className={classes.columnPadding}>
          <Text className={classes.popValue}>
            {transport?.dischargePriority}
          </Text>
        </Col>
        <Col span={24}>
          <Typography.Title level={5} style={{ textAlign: "center" }}>
            Discharge Location
          </Typography.Title>
        </Col>
        <Col span={24} className={classes.columnPadding}>
          <List
            bordered
            className="demo-loadmore-list"
            style={{
              marginTop: "10px",
              maxHeight: 200,
              overflow: "auto",
              padding: "0 16px",
            }}
            loading={false}
            itemLayout="horizontal"
            dataSource={[...dischargeLocations]}
            renderItem={(item, i) => (
              <List.Item>
                <Skeleton avatar title={false} loading={false} active>
                  <div>{item?.name}</div>
                </Skeleton>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row justify={"center"} className={classes.textContainer}>
        <Button
          className={classes.popClose}
          type="primary"
          htmlType="reset"
          onClick={handelClose}
        >
          Close
        </Button>
      </Row>
    </Modal>
  );
};

export default TransportViewModal;
