export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAIL = "FETCH_ORDERS_FAIL";
export const FETCH_ORDERS_START = "FETCH_ORDERS_START";
export const CLEAR_ORDERS_FETCH_ERROR_MSG = "CLEAR_ORDERS_FETCH_ERROR_MSG";
export const FETCH_SUMMARY_SUCCESS = "FETCH_SUMMARY_SUCCESS";
export const FETCH_SUMMARY_FAIL = "FETCH_SUMMARY_FAIL";

export const UPDATE_ORDER = "UPDATE_ORDER";
export const INCREMENT_STATUS_COUNT = "INCREMENT_STATUS_COUNT";

export const FETCH_REQUEST_ALL_CAMPUSES_FAIL =
  "FETCH_REQUEST_ALL_CAMPUSES_FAIL";
export const FETCH_DASHBOARD_ALL_CAMPUSES_SUCCESS =
  "FETCH_DASHBOARD_ALL_CAMPUSES_SUCCESS";
export const FETCH_DASHBOARD_ALL_BUILDINGS_SUCCESS =
  "FETCH_DASHBOARD_ALL_BUILDINGS_SUCCESS";
export const FETCH_DASHBOARD_ALL_BUILDINGS_FAIL =
  "FETCH_DASHBOARD_ALL_BUILDINGS_FAIL";
export const FETCH_DASHBOARD_ALL_CAMPUSES_START =
  "FETCH_DASHBOARD_ALL_CAMPUSES_START";
