import { Typography } from "antd";
import classes from "./patient-selection.module.scss";
import {
  DISCHARGE_REQUEST_TYPE_ID,
  DISCHARGE_TEXT,
  PROCEDURE_REQUEST_TYPE_ID,
  PROCEDURE_TEXT,
  RETURN_REQUEST_TYPE_ID,
  RETURN_TEXT,
  TRANSFER_REQUEST_TYPE_ID,
  TRANSFER_TEXT,
} from "../../../common/shared-constants";

const Step1 = (props: any) => {
  const {
    selectedRequestId,
    setSelectedRequestId,
    setSelectedRequest,
    transportDetails,
  } = props;

  const onClickHandler = (text: String, id: Number) => {
    setSelectedRequest(text);
    setSelectedRequestId(id);
  };

  return (
    <div>
      <div>
        <Typography className={`${classes.title} ${classes.requiredField}`}>
          Where is the Patient Going
        </Typography>
      </div>
      <div className={classes.patient}>
        {transportDetails?.procedureRequest && (
          <li>
            <input
              type="radio"
              id={PROCEDURE_TEXT}
              name={PROCEDURE_TEXT}
              checked={selectedRequestId === PROCEDURE_REQUEST_TYPE_ID}
              onClick={() =>
                onClickHandler(PROCEDURE_TEXT, PROCEDURE_REQUEST_TYPE_ID)
              }
            />
            <label htmlFor={PROCEDURE_TEXT}>{PROCEDURE_TEXT}</label>
          </li>
        )}
        {transportDetails?.returnRequest && (
          <li>
            <input
              type="radio"
              id={RETURN_TEXT}
              name={RETURN_TEXT}
              checked={selectedRequestId === RETURN_REQUEST_TYPE_ID}
              onClick={() =>
                onClickHandler(RETURN_TEXT, RETURN_REQUEST_TYPE_ID)
              }
            />
            <label htmlFor={RETURN_TEXT}>{RETURN_TEXT}</label>
          </li>
        )}
        {transportDetails?.dischargeRequest && (
          <li>
            <input
              type="radio"
              id={DISCHARGE_TEXT}
              name={DISCHARGE_TEXT}
              checked={selectedRequestId === DISCHARGE_REQUEST_TYPE_ID}
              onClick={() =>
                onClickHandler(DISCHARGE_TEXT, DISCHARGE_REQUEST_TYPE_ID)
              }
            />
            <label htmlFor={DISCHARGE_TEXT}>{DISCHARGE_TEXT}</label>
          </li>
        )}
        {transportDetails?.transferRequest && (
          <li>
            <input
              type="radio"
              id={TRANSFER_TEXT}
              name={TRANSFER_TEXT}
              checked={selectedRequestId === TRANSFER_REQUEST_TYPE_ID}
              onClick={() =>
                onClickHandler(TRANSFER_TEXT, TRANSFER_REQUEST_TYPE_ID)
              }
            />
            <label htmlFor={TRANSFER_TEXT}>{TRANSFER_TEXT}</label>
          </li>
        )}
      </div>
    </div>
  );
};

export default Step1;
