import axios from "axios";
import {
	SERVER_DOWN,
	SERVER_DOWN_MESSAGE,
	NETWORK_DOWN_MESSAGE,
	TIMEOUT_ERROR,
} from "../shared-constants";
import { message } from "antd";
import {
	checkRequestorPortalTokenValidity,
	checkTokenValidity,
} from "./admin-token-check";
const TIMEOUT = 30000;

export const adminRootInstance = axios.create({
	baseURL: `${process.env.REACT_APP_API_BASE_URL}admin/`,
	timeout: TIMEOUT,
});

export const requestorRootInstance = axios.create({
	baseURL: `${process.env.REACT_APP_API_BASE_URL}request/`,
	timeout: TIMEOUT,
});

export const baseInstance = axios.create({
	baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
	timeout: TIMEOUT,
});

const setupAxiosInterceptors = (
	onAdminUnauthenticated: any,
	onRequestorUnauthenticated: any
) => {
	const key = SERVER_DOWN;
	const onRequestSuccess = async (config: any) => {
		await checkTokenValidity();
		const token = localStorage.getItem("access_token");
		if (token) {
			config.headers.Authorization = `${token}`;
		}
		return config;
	};
	const onResponseSuccess = (response: any) => response;
	const onResponseError = (err: any) => {
		if (err.code === TIMEOUT_ERROR) {
			message.error({ content: SERVER_DOWN_MESSAGE, key, duration: 10 });
		}
		const status = err.status || (err.response ? err.response.status : 0);
		if (status === 403 || status === 401) {
			onAdminUnauthenticated(err.message);
		}
		if (status === 0) {
			if (!navigator.onLine) {
				message.error({
					content: NETWORK_DOWN_MESSAGE,
					key,
					duration: 10,
				});
			}
		}
		return Promise.reject(err);
	};
	const onRequestorRequestSuccess = async (config: any) => {
		await checkRequestorPortalTokenValidity();
		const token = localStorage.getItem("req_access_token");
		if (token) {
			config.headers.Authorization = `${token}`;
		}
		return config;
	};
	const onRequestorResponseError = (err: any) => {
		if (err.code === TIMEOUT_ERROR) {
			message.error({ content: SERVER_DOWN_MESSAGE, key, duration: 10 });
		}
		const status = err.status || (err.response ? err.response.status : 0);
		if (status === 403 || status === 401) {
			onRequestorUnauthenticated(err.message);
		}
		if (status === 0) {
			if (!navigator.onLine) {
				message.error({
					content: NETWORK_DOWN_MESSAGE,
					key,
					duration: 10,
				});
			}
		}
		return Promise.reject(err);
	};
	adminRootInstance.interceptors.request.use(onRequestSuccess);
	adminRootInstance.interceptors.response.use(
		onResponseSuccess,
		onResponseError
	);
	requestorRootInstance.interceptors.request.use(onRequestorRequestSuccess);
	requestorRootInstance.interceptors.response.use(
		onResponseSuccess,
		onRequestorResponseError
	);
};

export default setupAxiosInterceptors;
