import { Dispatch } from "redux";
import { adminRootInstance } from "../../../../common/config/axios-config";
import { ALL_CAMPUS_ID } from "../../../../common/shared-constants";
import {
  CLEAR_NOTIFICATION,
  FETCH_NOTIFICATION_FAIL,
  FETCH_NOTIFICATION_SUCCESS,
  LOAD_MORE_NOTIFICATIONS,
  READ_ALL_NOTIFICATION_FAIL,
  READ_ALL_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION,
  START_READ_ALL_NOTIFICATION,
  FETCH_NOTIFICATION_START,
} from "./action-types";

export const fetchNotifications =
  ({ skip, limit, replace = false, campus = ALL_CAMPUS_ID }: any) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_NOTIFICATION_START, payload: { replace } });
    return await adminRootInstance
      .get(`notifications`, {
        params: {
          skip,
          limit,
          campus,
        },
      })
      .then((res) => {
        dispatch({
          type: FETCH_NOTIFICATION_SUCCESS,
          payload: {
            replace,
            totalCount: res.data.data.totalRecords,
            notificationUnreadCount: res.data.data.totalUnreadCount,
            notifications: res.data.data.notifications,
          },
        });
        return { success: true };
      })
      .catch((err) => {
        dispatch({
          type: FETCH_NOTIFICATION_FAIL,
          payload: { errorMessage: err.response?.data?.message },
        });
        return { success: false };
      });
  };
export const onLoadMore =
  (limit: any) => async (dispatch: Dispatch, getState: any) => {
    const { notificationState } = getState();
    const notifications = notificationState.notificationsWithSkeletons.concat(
      [...new Array(limit)].map(() => ({ loading: true }))
    );
    dispatch({
      type: LOAD_MORE_NOTIFICATIONS,
      payload: {
        notifications,
      },
    });
  };

export const readNotification =
  (notificationId: any) => async (dispatch: Dispatch) => {
    return await adminRootInstance
      .put(`notification/${notificationId}`)
      .then((res: any) => {
        dispatch({
          type: READ_NOTIFICATION,
          payload: {
            notificationId: notificationId,
          },
        });
        return { success: true };
      })
      .catch((err) => {
        return { success: false, errorMessage: err.response?.data?.message };
      });
  };

export const readAllNotifications =
  (campusId: number) => async (dispatch: Dispatch) => {
    dispatch({ type: START_READ_ALL_NOTIFICATION });
    return await adminRootInstance
      .put(`notifications/all`, { campusId: campusId || ALL_CAMPUS_ID })
      .then((res) => {
        dispatch({
          type: READ_ALL_NOTIFICATION_SUCCESS,
        });
        return { success: true };
      })
      .catch((err) => {
        dispatch({
          type: READ_ALL_NOTIFICATION_FAIL,
          payload: { errorMessage: err.response?.data?.message },
        });
        return { success: false, errorMessage: err.response?.data?.message };
      });
  };
export const clearNotification = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_NOTIFICATION,
  });
};
export const refreshNotification =
  (length: number, campus: any) => (dispatch: Dispatch) => {
    fetchNotifications({ skip: 0, limit: length, replace: true, campus })(
      dispatch
    );
  };
