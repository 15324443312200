import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import classes from "./transport-information.module.scss";
import {
  ExclamationCircleTwoTone,
  RightCircleOutlined,
} from "@ant-design/icons";
import {
  additionalInformationValidator,
  contactPersonNameValidator,
  fromRoomNameValidator,
  nurseNameValidator,
  patientNameValidator,
  phoneNumberValidator,
  toRoomNameValidator,
} from "../../../common/utils/formValidators";
import moment from "moment";
import { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { allowOnlyNumbers } from "../../../common/utils/trimUtil";
import { IRootState } from "../../../common/redux/reducers";
import { connect } from "react-redux";
import { fetchTransportItems } from "../../modules/equipment-pickup/action";
import { fetchPatients } from "../reducers/actions";
import {
  DISCHARGE_REQUEST_TYPE_ID,
  GREEN_THEME_HEX,
  PROCEDURE_REQUEST_TYPE_ID,
  RETURN_REQUEST_TYPE_ID,
  TRANSFER_REQUEST_TYPE_ID,
} from "../../../common/shared-constants";
import DelayModal from "../../../common/components/DelayModal/DelayModal";
const { Title } = Typography;
const { Option } = Select;

const Step2 = (props: any) => {
  const {
    form,
    selectedRequestId,
    selectedRequest,
    handleNext,
    handleBack,
    formValues,
    setFormValues,
    externalDetails,
    from,
    allUnits,
    dischargeLocation,
    transportDetails,
    transportItems,
    currentCampus,
    fetchTransportItems,
    fetchPatients,
    patients,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemArray, setSelectedItemArray] = useState<any>(
    formValues?.selectedTransportItems
  );
  const [initialItemArray, setInitialItemArray] = useState<any>([]);
  const [itemsToBeRemoved, setItemsToBeRemoved] = useState<any>([]);
  const [isRemoveItem, setIsRemoveItem] = useState(false);
  const requestTimeLeft =
    selectedRequestId === RETURN_REQUEST_TYPE_ID ||
      selectedRequestId === PROCEDURE_REQUEST_TYPE_ID
      ? transportDetails?.procedureTimeLeft
      : selectedRequestId === TRANSFER_REQUEST_TYPE_ID
        ? transportDetails?.transferTimeLeft
        : transportDetails?.dischargeTimeLeft;

  useEffect(() => {
    if (
      (currentCampus.id,
        form.getFieldValue("fromUnit"),
        form.getFieldValue("toUnit"))
    ) {
      const initialFetchTransports = async () => {
        await fetchTransportItems(
          currentCampus.id,
          form.getFieldValue("fromUnit"),
          selectedRequestId === RETURN_REQUEST_TYPE_ID
            ? form.getFieldValue("toUnit")?.split("|")[1]
            : selectedRequestId === DISCHARGE_REQUEST_TYPE_ID
              ? 1
              : form.getFieldValue("toUnit")
        );
      };
      initialFetchTransports();
    }
  }, [
    currentCampus.id,
    form.getFieldValue("fromUnit"),
    form.getFieldValue("toUnit"),
  ]);

  useEffect(() => {
    if (transportItems) {
      const selectedItemIds = selectedItemArray.map(
        (item: any) => item?.item?.id
      );
      transportItems.forEach((item: any) => {
        if (selectedItemIds.includes(item?.id)) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
      setInitialItemArray(transportItems);
    }
  }, [transportItems]);

  useEffect(() => {
    const existingTime = getFormValues()?.time;
    if (existingTime && from !== 0) {
      setFormValue("time", existingTime);
    }
  }, []);

  useEffect(() => {
    setFormValues({
      ...formValues,
      selectedTransportItems: [...selectedItemArray],
    });
  }, [selectedItemArray]);

  useEffect(() => {
    if (from === 0) {
      setSelectedItemArray([]);
    }
  }, []);

  const getFormValues = () => {
    return form.getFieldsValue();
  };

  const setFormValue = (fieldName: any | Number, data: any) => {
    form.setFieldValue(fieldName, data);
  };
  const onDateChange: DatePickerProps["onChange"] = (dateString) => {
    setFormValues({ ...formValues, dob: dateString });
  };

  const onDisabledDate = (current: any) => {
    return current && current > moment().endOf("day");
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange = (e: any) => {
    showModal();
  };

  const quantityEdit = (itemId: any, action: any, newQuantity?: number) => {
    let tempArr = [...selectedItemArray];
    const found = selectedItemArray.find(
      (cartItem: any) => cartItem?.item?.id === itemId
    );
    if (found && action === "INCREASE") {
      tempArr = tempArr.filter(function (itm) {
        if (itm?.item?.id === itemId) {
          itm.quantity = itm?.quantity + 1;
          return itm;
        } else {
          return itm;
        }
      });
    }
    if (found && action === "DECREASE") {
      tempArr = tempArr.filter(function (itm) {
        if (itm.item.id === itemId && itm?.quantity > 1) {
          itm.quantity = itm?.quantity - 1;
          return itm;
        } else {
          return itm;
        }
      });
    }
    setSelectedItemArray([...tempArr]);
  };

  const onItemRemove = (removedId: any) => {
    let selectedItems = [...selectedItemArray];
    selectedItems = selectedItems.filter((Item) => {
      if (Item.item.id === removedId) {
        Item.item.selected = false;
      }
      return Item.item.id !== removedId;
    });
    setSelectedItemArray([...selectedItems]);
  };

  const onItemAdd = (added: any) => {
    added.selected = true;
    setSelectedItemArray([...selectedItemArray, { item: added, quantity: 1 }]);
  };

  const handleChange = async (fieldName: String, data: any) => {
    if (
      fieldName === "fromUnit" &&
      selectedRequestId === RETURN_REQUEST_TYPE_ID
    ) {
      form.setFieldValue("toUnit", "");
      setFormValues({ ...formValues, ["toUnit"]: "" });
      await fetchPatients(data);
    }
    if (
      fieldName === "toUnit" &&
      selectedRequestId === RETURN_REQUEST_TYPE_ID
    ) {
      form.setFieldValue("toRoom", data.split("|")[4]);
      form.setFieldValue("patientName", data.split("|")[2]);
      form.setFieldValue("dob", moment(data.split("|")[3]));
    }
    setFormValues({ ...formValues, [`${fieldName}`]: data });
  };

  const onNext = () => {
    const allowedItemIds = transportItems.map((item: any) => item.id);
    const selectedItemIds = selectedItemArray.map(
      (item: any) => item?.item?.id
    );
    const notAllowedItemIds: any = [];
    selectedItemIds.forEach((itemId: any) => {
      if (!allowedItemIds.includes(itemId)) {
        notAllowedItemIds.push(itemId);
      }
    });
    if (notAllowedItemIds.length > 0) {
      setItemsToBeRemoved([...notAllowedItemIds]);
      setIsRemoveItem(true);
    } else {
      handleNext();
    }
  };

  const removeItem = () => {
    const filteredItems = selectedItemArray.filter(
      (item: any) => !itemsToBeRemoved.includes(item?.item?.id)
    );
    setSelectedItemArray([...filteredItems]);
    setIsRemoveItem(false);
  };

  // Disable dates before today
  const disabledDate = (current: any) => {
    return (
      current &&
      (current < moment().seconds(0).startOf("day") ||
        current > moment().seconds(0).add(24, "hours").endOf("day"))
    );
  };

  // Disable times before now + transportDetails?.timeLeft for the current date and times after 24 hours for the next day
  const disabledTime = (current: any) => {
    if (!current) {
      current = moment().seconds(0);
    }

    const isToday = current.isSame(moment().seconds(0), "day");
    const isTomorrow = current.isSame(
      moment().seconds(0).add(24, "hours"),
      "day"
    );

    if (isToday) {
      const currentPlusTimeLeft = moment()
        .seconds(0)
        .add(Number(requestTimeLeft), "minutes");
      return {
        disabledHours: () =>
          range(0, moment(currentPlusTimeLeft).seconds(0).hour()),
        disabledMinutes: () =>
          current.hour() === moment(currentPlusTimeLeft).seconds(0).hour()
            ? range(0, moment(currentPlusTimeLeft).seconds(0).minute() + 1)
            : [],
      };
    }

    if (isTomorrow) {
      return {
        disabledHours: () =>
          range(moment().seconds(0).add(24, "hours").hour() + 1, 24),
        disabledMinutes: () =>
          current.hour() === moment().seconds(0).add(24, "hours").hour()
            ? range(moment().seconds(0).add(24, "hours").minute() + 1, 60)
            : [],
      };
    }

    return {};
  };

  // Helper function to create a range of numbers
  const range = (start: any, end: any) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  return (
    <Row gutter={[24, 48]} className={classes.container}>
      <Col span={24}>
        <Title level={4} className={classes.requestTitle}>
          {selectedRequest}
        </Title>
      </Col>
      <Col span={11} className={classes.cardContainer}>
        <Card
          title="Where is your patient ?"
          bordered
          headStyle={{
            borderColor: GREEN_THEME_HEX,
            borderBottom: `1px solid ${GREEN_THEME_HEX}`,
          }}
          className={classes.card}
        >
          <Title level={5} className={classes.cardTitle}>
            Origin
          </Title>
          <Form.Item
            label="Nurse Name"
            name="nurseName"
            initialValue={externalDetails?.name || formValues?.nurseName || ""}
            rules={[
              {
                validator: nurseNameValidator,
              },
            ]}
            wrapperCol={{
              xs: 24,
              sm: 24,
              md: 16,
              lg: 15,
              xl: 15,
              xxl: 15,
            }}
            labelCol={{ xs: 24, sm: 24, md: 8, lg: 9, xl: 9, xxl: 9 }}
          >
            <Input
              name="nurseName"
              onChange={(e) => {
                handleChange("nurseName", e.target.value);
              }}
              disabled
            />
          </Form.Item>
          <Form.Item
            label="Nurse Phone Number"
            name="phoneNumber"
            initialValue={
              externalDetails?.phone || formValues?.phoneNumber || ""
            }
            rules={[
              {
                validator: phoneNumberValidator,
              },
            ]}
            wrapperCol={{
              xs: 24,
              sm: 24,
              md: 16,
              lg: 15,
              xl: 15,
              xxl: 15,
            }}
            labelCol={{ xs: 24, sm: 24, md: 8, lg: 9, xl: 9, xxl: 9 }}
          >
            <Input
              name="phoneNumber"
              onChange={(e) => {
                handleChange("phoneNumber", e.target.value);
              }}
              disabled={process.env.REACT_APP_SSO_ENABLED === "false"}
            />
          </Form.Item>
          <Form.Item
            label="From Unit"
            name="fromUnit"
            required={true}
            rules={[
              {
                required: true,
                message: "Please select the From Unit!",
              },
            ]}
            wrapperCol={{
              xs: 24,
              sm: 24,
              md: 16,
              lg: 15,
              xl: 15,
              xxl: 15,
            }}
            labelCol={{
              xs: 24,
              sm: 24,
              md: 8,
              lg: 9,
              xl: 9,
              xxl: 9,
            }}
          >
            <Select
              placeholder="Select"
              className={classes.unitTextAlign}
              onChange={(e) => {
                handleChange("fromUnit", e);
              }}
              filterOption={(input: any, option: any) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              getPopupContainer={(trigger) => trigger.parentNode}
              showSearch
              optionFilterProp="children"
            >
              {selectedRequestId === RETURN_REQUEST_TYPE_ID
                ? allUnits
                  .filter(
                    (u: any) =>
                      u?.id !== getFormValues()?.toUnit && u?.proceduralUnit
                  )
                  ?.map((unit: any) => {
                    return (
                      <Option value={unit?.id} id={unit.id}>
                        {unit?.name}
                      </Option>
                    );
                  })
                : selectedRequestId === TRANSFER_REQUEST_TYPE_ID ||
                  selectedRequestId === PROCEDURE_REQUEST_TYPE_ID
                  ? allUnits
                    .filter((u: any) => u?.id !== getFormValues()?.toUnit)
                    ?.map((unit: any) => {
                      return (
                        <Option value={unit?.id} id={unit.id}>
                          {unit?.name}
                        </Option>
                      );
                    })
                  : allUnits?.map((unit: any) => {
                    return (
                      <Option value={unit?.id} id={unit.id}>
                        {unit?.name}
                      </Option>
                    );
                  })}
            </Select>
          </Form.Item>
          <Form.Item
            label="From Room / Bedspace"
            name="room"
            required={true}
            rules={[
              {
                required: true,
                validator: fromRoomNameValidator,
              },
            ]}
            wrapperCol={{
              xs: 24,
              sm: 24,
              md: 16,
              lg: 15,
              xl: 15,
              xxl: 15,
            }}
            labelCol={{
              xs: 24,
              sm: 24,
              md: 8,
              lg: 9,
              xl: 9,
              xxl: 9,
            }}
          >
            <Input
              name="room"
              onChange={(e) => {
                handleChange("room", e.target.value);
              }}
            />
          </Form.Item>
        </Card>
      </Col>
      <Col span={2} className={classes.rightArrowContainer}>
        <RightCircleOutlined className={classes.rightArrow} />
      </Col>
      <Col span={11} className={classes.cardContainer}>
        <Card
          title="Where are they going ?"
          bordered
          headStyle={{
            borderColor: GREEN_THEME_HEX,
            borderBottom: `1px solid ${GREEN_THEME_HEX}`,
          }}
          className={classes.card}
        >
          <Title level={5} className={classes.cardTitle}>
            Destination
          </Title>
          <Form.Item
            label="To Unit"
            name="toUnit"
            required={true}
            rules={[
              {
                required: true,
                message: "Please select the To Unit!",
              },
            ]}
            wrapperCol={{
              xs: 24,
              sm: 24,
              md: 16,
              lg: 15,
              xl: 15,
              xxl: 15,
            }}
            labelCol={{
              xs: 24,
              sm: 24,
              md: 8,
              lg: 9,
              xl: 9,
              xxl: 9,
            }}
          >
            {selectedRequestId !== DISCHARGE_REQUEST_TYPE_ID ? (
              <Select
                placeholder="Select"
                className={classes.unitTextAlign}
                onChange={(e) => {
                  handleChange("toUnit", e);
                }}
                filterOption={(input: any, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                getPopupContainer={(trigger) => trigger.parentNode}
                showSearch
                optionFilterProp="children"
              >
                {selectedRequestId === RETURN_REQUEST_TYPE_ID
                  ? patients?.map((unit: any) => {
                    return (
                      <Option
                        value={`${unit?.id}|${unit?.unitId?.id}|${unit?.patientName}|${unit?.patientDob}|${unit?.roomName}`}
                        id={`${unit?.id}|${unit?.unitId?.id}|${unit?.patientName}|${unit?.patientDob}|${unit?.roomName}`}
                      >
                        {`${unit?.patientName} | ${unit?.unitId?.name}`}
                      </Option>
                    );
                  })
                  : selectedRequestId === PROCEDURE_REQUEST_TYPE_ID
                    ? allUnits
                      .filter(
                        (u: any) =>
                          u?.id !== getFormValues()?.fromUnit &&
                          u?.proceduralUnit
                      )
                      ?.map((unit: any) => {
                        return (
                          <Option value={unit?.id} id={unit.id}>
                            {unit?.name}
                          </Option>
                        );
                      })
                    : allUnits
                      .filter((u: any) => u?.id !== getFormValues()?.fromUnit)
                      ?.map((unit: any) => {
                        return (
                          <Option value={unit?.id} id={unit.id}>
                            {unit?.name}
                          </Option>
                        );
                      })}
              </Select>
            ) : (
              <Select
                id="dischargeIdSelect"
                className={classes.unitTextAlign}
                onChange={(e) => {
                  handleChange("toUnit", e);
                }}
                filterOption={(input: any, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                getPopupContainer={(trigger) => trigger.parentNode}
                showSearch
                optionFilterProp="children"
              >
                {dischargeLocation?.map((unit: any) => {
                  return (
                    <Option
                      value={unit?.id || unit?.name}
                      id={unit.id || unit?.name}
                    >
                      {unit?.name}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="To Room / Bedspace"
            name="toRoom"
            rules={[
              {
                validator: toRoomNameValidator,
              },
            ]}
            wrapperCol={{
              xs: 24,
              sm: 24,
              md: 16,
              lg: 15,
              xl: 15,
              xxl: 15,
            }}
            labelCol={{
              xs: 24,
              sm: 24,
              md: 8,
              lg: 9,
              xl: 9,
              xxl: 9,
            }}
          >
            <Input
              name="toRoom"
              onChange={(e) => {
                handleChange("toRoom", e.target.value);
              }}
              disabled={selectedRequestId === RETURN_REQUEST_TYPE_ID}
            />
          </Form.Item>
          <Form.Item
            label="Name of person to contact"
            name="contactPersonName"
            rules={[
              {
                validator: contactPersonNameValidator,
              },
            ]}
            wrapperCol={{
              xs: 24,
              sm: 24,
              md: 16,
              lg: 15,
              xl: 15,
              xxl: 15,
            }}
            labelCol={{
              xs: 24,
              sm: 24,
              md: 8,
              lg: 9,
              xl: 9,
              xxl: 9,
            }}
          >
            <Input
              name="contactPersonName"
              onChange={(e) => {
                handleChange("contactPersonName", e.target.value);
              }}
            />
          </Form.Item>
        </Card>
      </Col>
      <Col span={24}>
        <Card
          title="Who are they and when do they need to be at their destination ?"
          bordered
          headStyle={{
            borderColor: GREEN_THEME_HEX,
            borderBottom: `1px solid ${GREEN_THEME_HEX}`,
          }}
          className={classes.card}
          bodyStyle={{
            paddingLeft: "100px",
            paddingRight: "100px",
          }}
        >
          <Title level={5} className={classes.cardTitle}>
            Transport Details
          </Title>
          <Form.Item
            label="Patient Name"
            name="patientName"
            required={true}
            rules={[
              {
                required: true,
                validator: patientNameValidator,
              },
            ]}
            wrapperCol={{
              xs: 24,
              sm: 24,
              md: 17,
              lg: 17,
              xl: 17,
              xxl: 17,
            }}
            labelCol={{
              xs: 24,
              sm: 24,
              md: 7,
              lg: 7,
              xl: 7,
              xxl: 7,
            }}
          >
            <Input
              name="patientName"
              onChange={(e) => {
                handleChange("patientName", e.target.value);
              }}
              disabled={selectedRequestId === RETURN_REQUEST_TYPE_ID}
            />
          </Form.Item>
          <Form.Item
            label="Patient Date of Birth (MM-DD-YYYY)"
            name="dob"
            required={true}
            rules={[
              {
                required: true,
                message: "Please select the Date Of Birth!",
              },
            ]}
            wrapperCol={{
              xs: 24,
              sm: 24,
              md: 17,
              lg: 17,
              xl: 17,
              xxl: 17,
            }}
            labelCol={{
              xs: 24,
              sm: 24,
              md: 7,
              lg: 7,
              xl: 7,
              xxl: 7,
            }}
          >
            <DatePicker
              getPopupContainer={(trigger): any => trigger.parentNode}
              placeholder="Select Date (e.g. 12-27-2020)"
              format={"MM-DD-YYYY"}
              className="fullWidth"
              onChange={onDateChange}
              disabledDate={onDisabledDate}
              disabled={selectedRequestId === RETURN_REQUEST_TYPE_ID}
            />
          </Form.Item>
          <Form.Item
            label="What time does patient need to be at destination"
            name="time"
            required
            rules={[
              () => ({
                validator(rule, value) {
                  if (!value) {
                    return Promise.reject(`Time is required!`);
                  }
                  if (
                    !moment(value)
                      .seconds(0)
                      .isBetween(
                        moment()
                          .seconds(0)
                          .add(Number(requestTimeLeft), "minutes"),
                        moment().seconds(0).add(24, "hours")
                      )
                  ) {
                    return Promise.reject(
                      `Time must be greater than ${moment()
                        .seconds(0)
                        .add(Number(requestTimeLeft), "minutes")
                        .format(
                          "MM-DD-YYYY HH:mm"
                        )} and less than or equal to ${moment()
                          .seconds(0)
                          .add(24, "hours")
                          .format("MM-DD-YYYY HH:mm")}!`
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            wrapperCol={{
              xs: 24,
              sm: 24,
              md: 17,
              lg: 17,
              xl: 17,
              xxl: 17,
            }}
            labelCol={{
              xs: 24,
              sm: 24,
              md: 7,
              lg: 7,
              xl: 7,
              xxl: 7,
            }}
          >
            <DatePicker
              getPopupContainer={(trigger): any => trigger.parentNode}
              placeholder="Select date time (e.g. 12-27-2020 13:00)"
              className="fullWidth"
              showTime
              format="MM-DD-YYYY HH:mm"
              disabledDate={disabledDate}
              showNow={false}
              disabledTime={disabledTime}
              onChange={(time) => {
                if (time) {
                  form.setFieldValue("time", moment(time).seconds(0));
                  handleChange("time", moment(time).seconds(0));
                } else {
                  form.setFieldValue("time", "");
                  handleChange("time", "");
                }
              }}
              onBlur={(e) => {
                if (e.target.value && moment(e.target.value).isValid()) {
                  form.setFieldValue("time", moment(e.target.value).seconds(0));
                  handleChange("time", moment(e.target.value).seconds(0));
                  if (
                    moment(e.target.value)
                      .seconds(0)
                      .isBetween(
                        moment()
                          .seconds(0)
                          .add(Number(transportDetails?.timeLeft), "minutes"),
                        moment().seconds(0).add(23, "hours")
                      )
                  ) {
                    form.setFields([
                      {
                        name: "time",
                        errors: [],
                      },
                    ]);
                  }
                }
              }}
            />
          </Form.Item>
          <Form.Item
            name="additionalInformation"
            label="Additional Details"
            rules={[
              {
                validator: additionalInformationValidator,
              },
            ]}
            wrapperCol={{
              xs: 24,
              sm: 24,
              md: 24,
              lg: 24,
              xl: 24,
              xxl: 24,
            }}
            labelCol={{
              xs: 24,
              sm: 24,
              md: 24,
              lg: 24,
              xl: 24,
              xxl: 24,
            }}
          >
            <TextArea
              name="additionalInformation"
              onChange={(e) => {
                handleChange("additionalInformation", e.target.value);
              }}
            />
          </Form.Item>
          <Checkbox
            onChange={onChange}
            checked={selectedItemArray.length !== 0}
            disabled={
              currentCampus?.id &&
                form.getFieldValue("fromUnit") &&
                form.getFieldValue("toUnit")
                ? false
                : true
            }
            className={`${classes.itemCheckbox} customCheckbox`}
          >
            Does the team Need to bring a Transport vessel? (Wheel Chair or Go
            Cart)
          </Checkbox>
          {selectedItemArray.length > 0 && (
            <Col className={classes.itemsResponsive}>
              {selectedItemArray.map((cart: any, index: any) => {
                return (
                  <Col className={classes.selectedItemMargin}>
                    <div
                      className={classes.selectedItemListing}
                      style={{ boxShadow: "5px 5px 5px #bdbdbd" }}
                    >
                      <ul className={classes.cartItemsList}>
                        <li
                          key={index}
                          id={cart?.item?.id}
                          className={classes.cartCardList}
                        >
                          <div
                            className={classes.accordianImg}
                            style={{
                              maxWidth: "120px",
                            }}
                          >
                            <div className={classes.itemImageWrapper}>
                              <img
                                className={classes.widthAuto}
                                src={cart?.item?.image}
                                alt="operation-bed"
                              />
                              <div
                                className={`${classes.itemName} ${classes.rightArrowContainer} ${classes.widthAuto}`}
                              >
                                {cart?.item?.name}
                              </div>
                            </div>
                          </div>
                          <div className={classes.quantity}>
                            <p>Qty</p>
                            <button
                              onClick={(id) => {
                                id.preventDefault();
                                quantityEdit(cart.item.id, "DECREASE");
                              }}
                              className={classes.minus}
                            ></button>
                            <InputNumber
                              value={cart?.quantity}
                              onChange={(quant) => {
                                quantityEdit(cart.item.id, "EDIT", quant);
                              }}
                              disabled={cart?.item?.specialtyItem}
                              onKeyDown={allowOnlyNumbers}
                              min={1}
                              max={99}
                            />
                            <button
                              onClick={(id) => {
                                id.preventDefault();
                                quantityEdit(cart?.item?.id, "INCREASE");
                              }}
                              disabled={cart?.quantity >= 99}
                              className={classes.add}
                            ></button>
                            {cart?.patients?.length &&
                              cart?.item?.specialtyItem ? (
                              <span
                                className="linkLikeText"
                                onClick={(e) => { }}
                              >
                                {"VIEW_PATIENTS_TEXT"}
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                          <button
                            className={classes.close}
                            onClick={(e) => {
                              e.preventDefault();
                              onItemRemove(cart.item.id);
                            }}
                          >
                            <span>X</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </Col>
                );
              })}
            </Col>
          )}
          <Typography>
            *A team member will arrive 15-30 minutes before set time!
          </Typography>
          <Modal
            title="Transportation"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
          >
            <div className={classes.accordianContent}>
              {initialItemArray.map((item: any, index: any) => {
                return (
                  <div
                    key={item.id}
                    className={`${classes.accordianCol} ${classes.selectedItemContainer}`}
                  >
                    <div className={classes.accordianImg}>
                      <div className={classes.itemImageWrapper}>
                        <img src={item?.image} alt="operation-bed" />
                        {!item.selected ? (
                          <div
                            className={`imageOverlay_100 full`}
                            onClick={() => {
                              onItemAdd(item);
                            }}
                          >
                            Add
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className={classes.itemName}>{item?.name}</div>
                    </div>
                    <Button
                      type="primary"
                      disabled={item?.selected}
                      onClick={() => {
                        onItemAdd(item);
                      }}
                    >
                      {"Add to Order"}
                    </Button>
                  </div>
                );
              })}
            </div>
          </Modal>
          <div className={classes.btnContainer}>
            <Button
              onClick={handleBack}
              className={classes.nextBtn}
              style={{ margin: "10px" }}
            >
              Back
            </Button>
            <Button
              onClick={onNext}
              className={classes.nextBtn}
              style={{ margin: "10px" }}
            >
              Next
            </Button>
          </div>
        </Card>
      </Col>
      <DelayModal
        key="info-modal"
        handleCancel={() => {
          setIsRemoveItem(false);
        }}
        handleOk={removeItem}
        isModalVisible={isRemoveItem}
        icon={<ExclamationCircleTwoTone className={classes.icon} />}
        text="Your Cart has some items that are specific to previous unit. It will be removed automatically. Do you want to continue?"
        okayText="Yes"
        cancelText="No"
      />
    </Row>
  );
};

const mapStateToProps = ({
  pickUpManagement,
  homeReducer,
  requestData,
  authentication,
}: IRootState) => ({
  currentCampus: homeReducer.currentCampus,
  transportItems: pickUpManagement.transportItems,
  patients: requestData.patients,
  isAuthenticated: authentication.isAuthenticated,
});

const mapDispatchToProps = {
  fetchTransportItems,
  fetchPatients,
};

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
