import moment from "moment";

export function convertTZ(date: any, tzString: string) {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
}

export function getFormattedDate(date: any) {
  return moment(date).format("MM/DD/yyyy, hh:mm:ss")
 }

export function getESTFromUTC(date: any, asType?: "string" | "object"): any {
  const dateObj = new Date(date);
  const timeZoneInfo = {
    timeZone: "America/New_York",
    hourCycle: "h23",
  } as const;
  switch (asType) {
    case "object":
      return {
        date: dateObj.toLocaleDateString("en-US", timeZoneInfo),
        time: dateObj.toLocaleTimeString("en-US", timeZoneInfo),
      };
    case "string":
    default:
      return dateObj.toLocaleString("en-US", timeZoneInfo);
  }
}

export function modifyRelativeTimeThreshold() {
  moment.relativeTimeThreshold("ss", 1);
  moment.relativeTimeThreshold("s", 60);
  moment.relativeTimeThreshold("m", 60);
  moment.relativeTimeThreshold("h", 24);
  moment.relativeTimeThreshold("d", 7);
  moment.relativeTimeThreshold("w", 4);
  moment.relativeTimeThreshold("M", 12);
}
