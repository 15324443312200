/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Tooltip,
  Button,
  Modal,
  Empty,
  Alert,
  Spin,
  notification,
} from "antd";
import classes from "./request-view.module.scss";
import Timer from "../countdown";
import { connect, useDispatch } from "react-redux";
import { clearRequestDetails, fetchRequestDetails } from "./actions";
import { IRootState } from "../../../../common/redux/reducers";
import { useState } from "react";
import RequestEditModal from "./requestEditModal/request-edit-modal";
import ItemCard from "../commons/ItemCard";
import ReassignUserModal from "./reassignUserModal/ReassignUserModal";
import Notes from "./NotesWidget";
import Activities from "./ActivitiesWidget";
import { clearMessage } from "../../../../common/actions/common-actions";
import {
  CLEAR_REQUEST_MESSAGE,
  PUSH_ACTIVITIES,
  UPDATED_DETAIL,
  UPDATE_NOTES,
} from "./action-types";
import {
  getMySocket,
  subscribeToRoom,
  unSubscribeToRoom,
} from "../../../../common/utils/socket";
import {
  DELIVERY_REQUEST_TYPE_ID,
  DISCHARGE_REQUEST_TYPE_ID,
  OVERRIDE_CLOSE,
  PATIENTMODAL_VIEW,
  PICKUP_REQUEST_TYPE_ID,
  PROCEDURE_REQUEST_TYPE_ID,
  RETURN_REQUEST_TYPE_ID,
  SUB_ROOM3,
  TRANSFER_REQUEST_TYPE_ID,
  UNSUB_ROOM3,
} from "../../../../common/shared-constants";
import {
  ASSIGNED,
  COMPLETED,
  UNASSIGNED,
  CANCELLED,
} from "../../../../common/shared-constants";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getRemainingTime } from "../commons/utils";
import { GenericBackButton } from "../../../../common/components/BackButton/back-button";
import PatientDetailsModal from "../../../../common/components/PatientDetailsModal/patient-details-modal";
import CancelReqModal from "../../../../common/components/CancelReqModal/CancelReqModal";
import DelayModal from "./delayModal/DelayModal";
import PatientRequestEditModal from "./patientRequestEditModal/patient-request-edit-modal";
import moment from "moment";
const cardHeaderStyle: object = {
  textAlign: "left",
  fontSize: "18px",
  color: "#525252",
  borderBottom: "1px solid #525252",
  display: "flex",
  alignItems: "center",
  maxHeight: "15%",
};
const cardsBodyStyle: object = {
  padding: "5px",
  color: "grey",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  fontSize: "13px",
  marginBottom: "10px",
  maxHeight: "85%",
};
const flexRowOverride: object = {
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
};

const RequestView = (props: any) => {
  const {
    match,
    fetchRequestDetails,
    requestDetails = {},
    dataLoading = false,
    clearRequestDetails,
    successMsg,
    clearMessage,
    errorMessage,
    currentUser,
  } = props;
  const {
    params: { id: reqId },
  } = match;
  const {
    reqInfo: {
      reqId: requestId = "-",
      status = "-",
      created = "-",
      timeLimit = "",
      reqType: { id: reqTypeId = 1, name: reqTypeName = "-" } = {},
      description = "-",
      assignedTo = "-",
      cancelReason = "-",
      items = [],
      priority: { color: priorityColor = "", name: priorityName = "-" } = {},
    } = {},
    locationInfo: {
      campus: { name: campusName = "-" } = {},
      unit: { name: unitName = "-" } = {},
      roomName = "",
      destinationUnit = {},
      dischargeLocation = {},
    } = {},
    contactInfo: {
      name: contactName = "-",
      phoneNumber = "-",
      mailId = "-",
    } = {},
    patientName,
    department,
    patientDob,
    expiry,
    orderExpired,
  } = requestDetails || {};

  //-----------------------------------------------------
  const [showPatientModal, setShowPatientModal] = useState<any>(false);
  const [assetCountModal, setAssetCountModal] = useState<any>(false);
  const dispatch = useDispatch();
  const isPatientTransportRequest =
    reqTypeId === PROCEDURE_REQUEST_TYPE_ID ||
    reqTypeId === RETURN_REQUEST_TYPE_ID ||
    reqTypeId === DISCHARGE_REQUEST_TYPE_ID ||
    reqTypeId === TRANSFER_REQUEST_TYPE_ID;

  const orderUpdateListener = (res: any) => {
    dispatch({
      type: UPDATED_DETAIL,
      payload: res,
    });
  };

  const activityCreationListener = (res: any) => {
    dispatch({
      type: PUSH_ACTIVITIES,
      payload: { request: res },
    });
    res.username !== currentUser.username && setReqUpdated(res);
  };
  const noteCreationListener = (res: any) => {
    dispatch({
      type: UPDATE_NOTES,
      payload: res,
    });
  };

  //-----------------------------------------------------
  useEffect(() => {
    let timer: any;
    if (successMsg) {
      timer = setTimeout(() => {
        clearMessages();
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [successMsg]);

  useEffect(() => {
    return () => {
      clearRequestDetails();
    };
  }, []);
  const [remainingTime, setRemainingTime] = useState<any>({});
  useEffect(() => {
    setRemainingTime(getRemainingTime(created, timeLimit));
    const interval = setInterval(() => {
      setRemainingTime(getRemainingTime(created, timeLimit));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timeLimit]);

  useEffect(() => {
    fetchRequestDetails({ reqId });
  }, [match.params.id]);

  const [showEditModal, setEditModalFlag] = useState(false);
  const [showReAssignModal, setReAssignModalFlag] = useState(false);
  const [showCancelReqModal, setCancelReqModalFlag] = useState(false);
  const [showOverrideCloseModal, setOverrideCloseModalFlag] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [reqUpdated, setReqUpdated] = useState<any>();
  const [showDelayModal, setShowDelayModal] = useState(false);
  const toggleEditModal = () => {
    setEditModalFlag(!showEditModal);
  };

  const clearMessages = () => {
    clearMessage(CLEAR_REQUEST_MESSAGE);
  };
  useEffect(() => {
    if (reqUpdated?.message) {
      notification.info({
        message: reqUpdated.message,
        placement: "bottomRight",
      });
      if (
        (showEditModal ||
          showReAssignModal ||
          showCancelReqModal ||
          showOverrideCloseModal) &&
        !warningOpen
      ) {
        setWarningOpen(true);
        Modal.info({
          title: "Info",
          icon: <ExclamationCircleOutlined />,
          className: "unitSpecificModal",
          content:
            "The Current Request details are changed. Kindly verify the changes before modifying the request (Close and re-open the modal to modify the updated details)",
          okText: "Okay",
          onOk: () => {
            setWarningOpen(false);
            setCancelReqModalFlag(false);
            setOverrideCloseModalFlag(false);
            setEditModalFlag(false);
            setReAssignModalFlag(false);
          },
        });
      }
      setReqUpdated({});
    }
  }, [reqUpdated]);
  const joinRoom = async () => {
    const joined = await subscribeToRoom(SUB_ROOM3);
    if (joined) {
      const socket = getMySocket();
      socket.on(`order-${match.params.id}`, orderUpdateListener);
      socket.on(`activity-${match.params.id}`, activityCreationListener);
      socket.on(`note-${match.params.id}`, noteCreationListener);
    }
  };

  useEffect(() => {
    joinRoom();
    return () => {
      clearRequestDetails();
      unSubscribeToRoom(UNSUB_ROOM3);
      const socket = getMySocket();
      socket.off(`order-${match.params.id}`, orderUpdateListener);
      socket.off(`activity-${match.params.id}`, activityCreationListener);
      socket.off(`note-${match.params.id}`, noteCreationListener);
    };
  }, [match.params.id]);
  const header = (
    <Col span={24} className={classes.header}>
      <Row>
        <Col span={2} className={classes.backButton}>
          <Row>
            <GenericBackButton
              title="Go to dashboard"
              link="/admin/dashboard"
            />
          </Row>
        </Col>
        <Col className={classes.breadCrumbText} span={22}>
          <Row>Dashboard &gt; Request Info</Row>
        </Col>
      </Row>
    </Col>
  );
  if (errorMessage || !requestDetails)
    return (
      <Row style={{ maxWidth: "100%" }}>
        {header}
        <Card className={classes.errorCard}>
          {" "}
          <h1>{errorMessage || <Spin />}</h1>
          {errorMessage && (
            <>
              <h6>Click the below link to go to</h6>
              <div>
                <Link to="/admin/dashboard">Dashboard</Link>
              </div>
            </>
          )}
        </Card>
      </Row>
    );
  let patientDetailsPresent =
    items.reduce((acc: any, item: any) => (item.patients ? acc + 1 : acc), 0) >
    0;

  return (
    <React.Fragment>
      <Row style={{ maxWidth: "100%" }}>
        {header}
        {successMsg ? (
          <Col span={24}>
            <Alert
              className={`fadeIn ${classes.alertContainer}`}
              message={successMsg}
              type="success"
              closable
              afterClose={clearMessages}
              style={{ marginTop: 12 }}
            />
          </Col>
        ) : null}
        <Col span={16} style={{ paddingRight: "30px", height: "100%" }}>
          {/* Request Info  */}
          <Row>
            <Card
              title="Request Info"
              headStyle={cardHeaderStyle}
              bodyStyle={cardsBodyStyle}
              className={classes.cardStyle}
            >
              {requestDetails ? (
                <>
                  <Row style={{ maxHeight: "40%" }}>
                    <Col className={classes.dataField}>
                      <b>Request ID :</b> {requestId}
                    </Col>
                    {!isPatientTransportRequest && (
                      <Col className={classes.dataField}>
                        <b>Priority:</b>{" "}
                        <span className={classes.priority}>
                          <div
                            style={{
                              backgroundColor: priorityColor,
                            }}
                            className={classes.priorityColor}
                          ></div>
                          <Tooltip title="">{priorityName}</Tooltip>
                        </span>
                      </Col>
                    )}
                    {!(
                      status === COMPLETED ||
                      status === CANCELLED ||
                      status === OVERRIDE_CLOSE
                    ) && (
                      <Col className={classes.dataField}>
                        <b>
                          Time
                          {remainingTime.timeExceeded
                            ? " exceeded"
                            : " left"}:{" "}
                        </b>
                        <span
                          className={classes.timer}
                          style={
                            remainingTime.timeExceeded ? { color: "red" } : {}
                          }
                        >
                          {
                            <Timer
                              timeLeft={
                                new Date(created).getTime() + timeLimit * 60000
                              }
                              record={requestDetails}
                            />
                          }
                        </span>
                      </Col>
                    )}
                    <Col className={classes.dataField}>
                      <b>Request type:</b> {reqTypeName}
                    </Col>
                    <Col className={classes.dataField}>
                      <b>Status:</b> {orderExpired ? "EXPIRED" : status}
                    </Col>
                    {assignedTo && (
                      <Col className={classes.dataField}>
                        <b>Assigned To:</b> {assignedTo}
                      </Col>
                    )}
                    <Col className={classes.dataField}>
                      <b>Description: </b>
                      {description || "-"}
                    </Col>
                    {isPatientTransportRequest ? (
                      <Col className={classes.dataField}>
                        <b>Patient name: </b>
                        {patientName || "-"}
                      </Col>
                    ) : null}
                    {isPatientTransportRequest ? (
                      <Col className={classes.dataField}>
                        <b>Patient dob: </b>
                        {patientDob || "-"}
                      </Col>
                    ) : null}
                    {isPatientTransportRequest && (
                      <Col className={classes.dataField}>
                        <b>Time patient needs to be at destination:</b>{" "}
                        {moment(expiry).seconds(0).format("MM-DD-YYYY HH:mm")}
                      </Col>
                    )}
                    {status === CANCELLED && (
                      <Col className={classes.dataField}>
                        <b>Cancellation Reason: </b>
                        {cancelReason || "-"}
                      </Col>
                    )}
                  </Row>
                  {/* Second section of Req Info Widget*/}
                  <Row
                    className={classes.padding_left_10}
                    style={{ maxHeight: "60%" }}
                  >
                    <div style={{ maxHeight: "20%" }}>
                      <span className={classes.dataSectionHeader}>
                        <b>Items</b>
                      </span>
                    </div>
                    <div className={classes.scrollableRow}>
                      {/* Scrollable row */}
                      {items?.length > 0 ? (
                        items.map((topLevelItem: any) => {
                          const { item, quantity } = topLevelItem;
                          return ItemCard({
                            itemObj: item,
                            name: item?.name,
                            quantity: quantity,
                            image: item?.image,
                            editable: false,
                            itemId: item?.id,
                            delay: item?.delay,
                            assetCount : item?.assetCount,
                            assetCountModal: assetCountModal,
                            onclickAssetCount: () => {
                              setAssetCountModal(item.id)
                            },
                            onCloseAssetCount:() => {
                              setAssetCountModal(false)
                            },
                            reqTypeId,
                            specialityItem: !!topLevelItem.isSpecialtyItem,
                            setDetails: () => {
                              setShowPatientModal({
                                id: item.id,
                                mode: PATIENTMODAL_VIEW,
                              });
                            },
                            viewPatientProps: {
                              patients: topLevelItem?.patients || [],
                              patientDetailsPresent,
                            },
                          });
                        })
                      ) : (
                        <div className={classes.placeholder}>
                          No items available
                        </div>
                      )}
                    </div>
                  </Row>
                </>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Card>
          </Row>
          <Row>
            <Card
              title="Location Info"
              headStyle={cardHeaderStyle}
              bodyStyle={{
                ...cardsBodyStyle,
                ...(!dataLoading ? flexRowOverride : {}),
              }}
              className={classes.cardStyle}
            >
              {requestDetails ? (
                <>
                  <Col
                    className={`${classes.dataField} ${classes.contactInfoDataField}`}
                  >
                    <b>Campus name :</b> {campusName}
                  </Col>
                  {isPatientTransportRequest ? (
                    <Col
                      className={`${classes.dataField} ${classes.contactInfoDataField}`}
                    >
                      <b>From unit :</b> {unitName}
                    </Col>
                  ) : (
                    <Col
                      className={`${classes.dataField} ${classes.contactInfoDataField}`}
                    >
                      <b>Unit :</b> {unitName}
                    </Col>
                  )}
                  {reqTypeId === DISCHARGE_REQUEST_TYPE_ID && (
                    <Col
                      className={`${classes.dataField} ${classes.contactInfoDataField}`}
                    >
                      <b>Discharge location :</b> {dischargeLocation?.name}
                    </Col>
                  )}
                  {(reqTypeId === PROCEDURE_REQUEST_TYPE_ID ||
                    reqTypeId === RETURN_REQUEST_TYPE_ID ||
                    reqTypeId === TRANSFER_REQUEST_TYPE_ID) && (
                    <Col
                      className={`${classes.dataField} ${classes.contactInfoDataField}`}
                    >
                      <b>To unit :</b> {destinationUnit?.name}
                    </Col>
                  )}
                  <Col
                    className={`${classes.dataField} ${classes.contactInfoDataField}`}
                  >
                    <b>
                      {isPatientTransportRequest
                        ? "From Room "
                        : "Room no/Bedspace "}
                      :
                    </b>{" "}
                    {roomName}
                  </Col>
                  {reqTypeId === RETURN_REQUEST_TYPE_ID && (
                    <Col
                      className={`${classes.dataField} ${classes.contactInfoDataField}`}
                    >
                      <b>To Room :</b>{" "}
                      {requestDetails?.parentOrderDetails?.roomName}
                    </Col>
                  )}
                </>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Card>
          </Row>
          <Row>
            <Card
              title="Contact Info"
              headStyle={cardHeaderStyle}
              bodyStyle={{
                ...cardsBodyStyle,
                ...(!dataLoading ? flexRowOverride : {}),
              }}
              className={classes.cardStyle}
            >
              {requestDetails ? (
                <>
                  <Col
                    className={`${classes.dataField} ${classes.contactInfoDataField}`}
                    style={{ width: "auto" }}
                  >
                    <b>Name :</b> {contactName}
                  </Col>
                  {phoneNumber && (
                    <Col
                      className={`${classes.dataField} ${classes.contactInfoDataField}`}
                    >
                      <b>Phone number :</b> {phoneNumber}
                    </Col>
                  )}
                  <Col
                    className={`${classes.dataField} ${classes.contactInfoDataField}`}
                  >
                    <b> Mail ID : </b> {mailId || "-"}
                  </Col>
                </>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Card>
          </Row>

          <Row className={classes.footerButtons}>
            <Button
              className={classes.fwdButton}
              type="primary"
              onClick={() => setReAssignModalFlag(true)}
              disabled={!!dataLoading}
              hidden={
                status === COMPLETED ||
                status === CANCELLED ||
                status === OVERRIDE_CLOSE
              }
            >
              {requestDetails?.reqInfo?.assignedTo ? "Re-Assign" : "Assign"}
            </Button>
            <Button
              type="default"
              className={`${classes.cancelButton}`}
              onClick={() => setCancelReqModalFlag(true)}
              disabled={!!dataLoading}
              hidden={
                status === COMPLETED ||
                status === CANCELLED ||
                status === OVERRIDE_CLOSE
              }
            >
              Cancel Request
            </Button>
            {status !== COMPLETED &&
              status !== CANCELLED &&
              status !== OVERRIDE_CLOSE &&
              status !== "DELAYED" &&
              (reqTypeId === DELIVERY_REQUEST_TYPE_ID ||
                reqTypeId === PICKUP_REQUEST_TYPE_ID) && (
                <Button
                  type="primary"
                  className={classes.fwdButton}
                  onClick={() => setShowDelayModal(true)}
                  disabled={!!dataLoading}
                >
                  Delay
                </Button>
              )}
            <Button
              type="primary"
              className={classes.cancelButton}
              onClick={() => setOverrideCloseModalFlag(true)}
              disabled={!!dataLoading}
              hidden={
                status === COMPLETED ||
                status === CANCELLED ||
                status === OVERRIDE_CLOSE
              }
            >
              Override Close
            </Button>
            <Button
              type="primary"
              className={classes.fwdButton}
              onClick={toggleEditModal}
              disabled={!!dataLoading}
              hidden={!(status === ASSIGNED || status === UNASSIGNED)}
            >
              Edit Request
            </Button>
          </Row>
        </Col>
        <Col span={8} className={classes.notesActivityHeight}>
          {!isPatientTransportRequest ? (
            <Row className={classes.notesHeight}>
              <Notes type="Notes" loading={dataLoading} reqId={reqId} />
            </Row>
          ) : null}
          <Row className={classes.activityHeight}>
            <Activities type="Activities" loading={dataLoading} reqId={reqId} />
          </Row>
        </Col>
      </Row>

      {showEditModal &&
        (isPatientTransportRequest ? (
          <PatientRequestEditModal
            showModal={showEditModal}
            toggleModal={toggleEditModal}
            cardHeaderStyle={cardHeaderStyle}
            cardsBodyStyle={cardsBodyStyle}
          />
        ) : (
          <RequestEditModal
            showModal={showEditModal}
            toggleModal={toggleEditModal}
            cardHeaderStyle={cardHeaderStyle}
            cardsBodyStyle={cardsBodyStyle}
          />
        ))}
      <ReassignUserModal
        showModal={showReAssignModal}
        toggleModal={() => setReAssignModalFlag(!showReAssignModal)}
        reqId={reqId}
        assignedUsr={assignedTo}
      />
      <CancelReqModal
        showModal={showCancelReqModal}
        requestId={reqId}
        toggleModal={() => setCancelReqModalFlag(!showCancelReqModal)}
      />
      <DelayModal
        showModal={showDelayModal}
        toggleModal={() => setShowDelayModal(!showDelayModal)}
        reqId={reqId}
      />
      <CancelReqModal
        overrideClose={true}
        showModal={showOverrideCloseModal}
        requestId={reqId}
        toggleModal={() => setOverrideCloseModalFlag(!showOverrideCloseModal)}
        modalTitle="Override close confirmation"
        bodyText="Are you sure you want to close this Request?"
      />
      {reqTypeId === DELIVERY_REQUEST_TYPE_ID && (
        <PatientDetailsModal
          visible={!!showPatientModal}
          toggleModal={() => setShowPatientModal(false)}
          cart={items}
          itemId={showPatientModal.id}
          mode={showPatientModal.mode}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({
  requestState,
  adminAuthentication,
}: IRootState) => ({
  dataLoading: requestState.loading,
  requestDetails: requestState.selectedRequest,
  successMsg: requestState.successMessage,
  errorMessage: requestState.fetchErrorMessage,
  currentUser: adminAuthentication?.account,
});
const mapDispatchToProps = {
  fetchRequestDetails,
  clearRequestDetails,
  clearMessage,
};
export default connect(mapStateToProps, mapDispatchToProps)(RequestView);
//Note: These are exported to be used in dependent components like NotesComponent (for card styling)
export { cardHeaderStyle, cardsBodyStyle, flexRowOverride };
