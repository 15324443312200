export const FETCH_CAMPUSES_SUCCESS = "FETCH_CAMPUSES_SUCCESS";
export const FETCH_CAMPUSES_FAIL = "FETCH_CAMPUSES_FAIL";
export const CREATE_CAMPUS_SUCCESS = "CREATE_CAMPUS_SUCCESS";
export const CREATE_CAMPUS_FAIL = "CREATE_CAMPUS_FAIL";
export const UPDATE_CAMPUS_SUCCESS = "UPDATE_CAMPUS_SUCCESS";
export const UPDATE_CAMPUS_FAIL = "UPDATE_CAMPUS_FAIL";
export const DELETE_CAMPUS_FAIL = "DELETE_CAMPUS_FAIL";
export const DELETE_CAMPUS_SUCCESS = "DELETE_CAMPUS_SUCCESS";
export const CLEAR_CAMPUS_MSG = "CLEAR_CAMPUS_MSG";
export const CLEAR_CAMPUS_FETCH_ERROR_MSG = "CLEAR_CAMPUS_FETCH_ERROR_MSG";
export const FETCH_CAMPUSES_START = "FETCH_CAMPUSES_START";
export const FETCH_ALL_CAMPUSES_SUCCESS = "FETCH_ALL_CAMPUSES_SUCCESS";
export const FETCH_ALL_CAMPUSES_FAIL = "FETCH_ALL_CAMPUSES_SUCCESS";
