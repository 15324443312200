import {
  CLEAR_PICKUP_ERROR_MSG,
  FETCH_PICKUPITEMS_FAIL,
  FETCH_PICKUPITEMS_SUCCESS,
  PICKUP_ORDER_PLACED_FAILED,
  FETCH_TRANSPORTITEMS_FAIL,
  FETCH_TRANSPORTITEMS_SUCCESS,
} from "./action-types";
import { SET_SUCCESS_MSG } from "../home/action-types";
import {
  EQUIPMENT_CATALOGUE_ID,
  ORDER_PLACED_SUCCESSFULLY,
} from "../../../common/shared-constants";
import { requestorRootInstance } from "../../../common/config/axios-config";

export const fetchTransportItems =
  (campusId: any, fromUnit: any, toUnit: any) => async (dispatch: any) => {
    await requestorRootInstance
      .get(`transport-item/${campusId}/${fromUnit}/${toUnit}`)
      .then((res: any) => {
        dispatch({
          type: FETCH_TRANSPORTITEMS_SUCCESS,
          payload: {
            transportItems: res.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_TRANSPORTITEMS_FAIL,
          payload: {
            errorMessage: err.response?.data?.message,
          },
        });
      });
  };

export const fetchPickupItems =
  (unitId: any, campusId: any) => async (dispatch: any) => {
    await requestorRootInstance
      .get(
        `item/list?catalogue=${EQUIPMENT_CATALOGUE_ID}&unit=${unitId}&campus=${campusId}`
      )
      .then((res: any) => {
        dispatch({
          type: FETCH_PICKUPITEMS_SUCCESS,
          payload: {
            pickUpItems: res.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_PICKUPITEMS_FAIL,
          payload: {
            errorMessage: err.response?.data?.message,
          },
        });
      });
  };

export const placePickUpOrder = (data: any) => (dispatch: any) => {
  return requestorRootInstance
    .post(`order`, data)
    .then((res: any) => {
      dispatch({
        type: SET_SUCCESS_MSG,
        payload: {
          successMessage: ORDER_PLACED_SUCCESSFULLY,
        },
      });
      return { status: "success" };
    })
    .catch((err) => {
      dispatch({
        type: PICKUP_ORDER_PLACED_FAILED,
        payload: {
          errorMessage: err.response?.data?.message,
        },
      });
      return { status: "fail" };
    });
};

export const clearErrorMessage = () => async (dispatch: any) => {
  dispatch({
    type: CLEAR_PICKUP_ERROR_MSG,
  });
};
