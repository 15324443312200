export const FETCH_MYREQUEST_LIST_SUCCESS = "FETCH_MYREQUEST_LIST_SUCCESS";
export const FETCH_MYREQUEST_LIST_FAIL = "FETCH_MYREQUEST_LIST_FAIL";
export const CLEAR_REQUESTS_FETCH_ERROR_MSG = "CLEAR_REQUESTS_FETCH_ERROR_MSG";
export const FETCH_ALL_ITEMS_FAIL = "FETCH_ALL_ITEMS_FAIL";
export const FETCH_ALL_ITEMS_SUCCESS = "FETCH_ALL_ITEMS_SUCCESS";
export const FETCH_MYREQUEST_LIST_START = "FETCH_MYREQUEST_LIST_START";
export const EDIT_REQ_SUCCESS = "EDIT_REQ_SUCCESS";
export const EDIT_REQ_FAIL = "EDIT_REQ_FAIL";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const CANCEL_REQ_SUCCESS = "CANCEL_REQ_SUCCESS";
export const CANCEL_REQ_FAIL = "CANCEL_REQ_FAIL";
export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const REORDER_REQUEST_SUCCESS = "REORDER_REQUEST_SUCCESS";
export const REORDER_REQUEST_FAIL = "REORDER_REQUEST_FAIL";
export const CLEAR_DATA = "CLEAR_DATA";
