import {
  FETCH_CATEGORIESITEMS_SUCCESS,
  FETCH_CATEGORIESITEMS_FAIL,
  FETCH_SEARCHEDITEMS_SUCCESS,
  FETCH_SEARCHEDITEMS_FAIL,
  CLEAR_DELIVERY_ERROR_MSG,
  DELIVERY_ORDER_PLACED_FAILED,
} from "./action-types";
import { SET_SUCCESS_MSG } from "../home/action-types";
import {
  EQUIPMENT_CATALOGUE_ID,
  ORDER_PLACED_SUCCESSFULLY,
} from "../../../common/shared-constants";
import { requestorRootInstance } from "../../../common/config/axios-config";

export const fetchCategoriesWithItems =
  (values: any) => async (dispatch: any) => {
    await requestorRootInstance
      .get(
        `items/${values?.campusId}/${values?.unitId}/${EQUIPMENT_CATALOGUE_ID}`
      )
      .then((res: any) => {
        dispatch({
          type: FETCH_CATEGORIESITEMS_SUCCESS,
          payload: {
            items: res.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_CATEGORIESITEMS_FAIL,
          payload: { errorMessage: err.response?.data?.message },
        });
      });
  };

export const fetchSearchedItems =
  (keyword: any, unitId: any, selectedCampusId: any) =>
  async (dispatch: any) => {
    await requestorRootInstance
      .get(`item/list`, {
        params: {
          search: keyword,
          catalogue: EQUIPMENT_CATALOGUE_ID,
          unit: unitId,
          campus: selectedCampusId,
        },
      })
      .then((res: any) => {
        dispatch({
          type: FETCH_SEARCHEDITEMS_SUCCESS,
          payload: {
            searchItems: res.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_SEARCHEDITEMS_FAIL,
          payload: {
            errorMessage: err.response?.data?.message,
          },
        });
      });
  };

export const placeDeliveryOrder = (data: any) => (dispatch: any) => {
  return requestorRootInstance
    .post(`order`, data)
    .then((res: any) => {
      dispatch({
        type: SET_SUCCESS_MSG,
        payload: {
          successMessage: ORDER_PLACED_SUCCESSFULLY,
        },
      });
      return { status: "success" };
    })
    .catch((err) => {
      dispatch({
        type: DELIVERY_ORDER_PLACED_FAILED,
        payload: {
          errorMessage: err.response?.data?.message,
        },
      });
      return { status: "fail" };
    });
};

export const clearErrorMessage = () => async (dispatch: any) => {
  dispatch({
    type: CLEAR_DELIVERY_ERROR_MSG,
  });
};
