import { Dispatch } from "redux";
import { requestorRootInstance } from "../../../common/config/axios-config";
import { CANCEL_STATUS_ID } from "../../../common/shared-constants";
import {
  FETCH_MYREQUEST_LIST_FAIL,
  FETCH_MYREQUEST_LIST_SUCCESS,
  FETCH_ALL_ITEMS_SUCCESS,
  FETCH_ALL_ITEMS_FAIL,
  EDIT_REQ_SUCCESS,
  CANCEL_REQ_SUCCESS,
  CANCEL_REQ_FAIL,
  EDIT_REQ_FAIL,
  UPDATE_REQUEST,
  REORDER_REQUEST_SUCCESS,
  REORDER_REQUEST_FAIL,
  CLEAR_DATA,
  FETCH_MYREQUEST_LIST_START,
} from "./action-types";

export const fetchMyRequestsList =
  (userId: any, limit: any, page: any) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_MYREQUEST_LIST_START });
    await requestorRootInstance
      .get(`orders`, {
        params: {
          id: userId,
          pageSize: limit,
          page,
        },
      })
      .then((res: any) => {
        dispatch({
          type: FETCH_MYREQUEST_LIST_SUCCESS,
          payload: {
            orders: res.data.data.orders,
            totalCount: res.data.data.pagination.totalCount,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_MYREQUEST_LIST_FAIL,
          payload: {
            errorMessage: err.response?.data?.message,
          },
        });
      });
  };

export const fetchItems =
  (
    unitId: any,
    catalogueId: any,
    campusId: any,
    patientFromUnit: any,
    patientToUnit: any,
    isPatientTransportRequest: boolean
  ) =>
  async (dispatch: Dispatch) => {
    await requestorRootInstance
      .get(
        isPatientTransportRequest
          ? `transport-item/${campusId}/${patientFromUnit}/${patientToUnit}`
          : `item/list?catalogue=${catalogueId}&unit=${unitId}&campus=${campusId}`
      )
      .then((res: any) => {
        dispatch({
          type: FETCH_ALL_ITEMS_SUCCESS,
          payload: {
            items: res.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_ALL_ITEMS_FAIL,
          payload: {
            errorMessage: err.response?.data?.message,
          },
        });
      });
  };

export const editRequest =
  (requestNumber: any, body: any) => async (dispatch: Dispatch) => {
    return await requestorRootInstance
      .put(
        body.isPatientTransportRequest
          ? "patient-transport/edit/" + requestNumber
          : "order-edit/" + requestNumber,
        body
      )
      .then((res: any) => {
        dispatch({
          type: EDIT_REQ_SUCCESS,
          payload: {
            successMessage: "Updated Successfully",
            updatedRequest: res.data.data,
          },
        });
        return { success: true, updatedRequest: res.data.data };
      })
      .catch((err) => {
        dispatch({
          type: EDIT_REQ_FAIL,
          payload: {
            errorMessage: err.response?.data?.message,
          },
        });
        return { success: false };
      });
  };

export const cancelRequest =
  (orderNumber: any, cancelReason: string) => (dispatch: Dispatch) => {
    return requestorRootInstance
      .put(
        `order/${orderNumber}`,
        { cancelReason },
        {
          params: {
            status: CANCEL_STATUS_ID,
          },
        }
      )
      .then((res: any) => {
        dispatch({
          type: CANCEL_REQ_SUCCESS,
          payload: {
            requestId: orderNumber,
            successMessage: "Cancelled Successfully",
            cancelReason,
          },
        });
        return { success: true };
      })
      .catch((err) => {
        dispatch({
          type: CANCEL_REQ_FAIL,
          payload: {
            errorMessage: err.response?.data?.message,
          },
        });
        return { success: false };
      });
  };

export const updateRequest = (updatedRequest: any) => (dispatch: Dispatch) => {
  dispatch({
    type: UPDATE_REQUEST,
    payload: {
      updatedRequest,
    },
  });
};

export const reorderRequest =
  (reqId: number | string) => (dispatch: Dispatch) => {
    return requestorRootInstance
      .post(`re-order/${reqId}`)
      .then((res: any) => {
        dispatch({
          type: REORDER_REQUEST_SUCCESS,
          payload: { successMessage: res.data.message },
        });
        return { success: true, message: res.data.message };
      })
      .catch((err) => {
        dispatch({
          type: REORDER_REQUEST_FAIL,
          payload: {
            errorMessage: err.response?.data?.message,
          },
        });
        return { success: false, message: err.response.data.message };
      });
  };

export const clearMyRequestsData = () => (dispatch: Dispatch) => {
  dispatch({ type: CLEAR_DATA });
};
