import {
  PATIENT_TRANSPORT_FAIL,
  SAVE_ALERT_MESSAGE,
  CLEAR_PATIENT_TRANSPORT_ERROR_MSG,
} from "./action-types";

const initialState = {
  alertMessage: null as null | boolean,
  pickupRequest: null as null | string,
  patientTransport: [] as any,
  errorMessage: null as null | string,
  successMessage: null as null | string,
};

export type PatientManagementState = Readonly<typeof initialState>;

const transportMangement = (
  state = initialState,
  action: any
): PatientManagementState => {
  const { type, payload } = action;
  switch (type) {
    case PATIENT_TRANSPORT_FAIL:
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    case CLEAR_PATIENT_TRANSPORT_ERROR_MSG:
      return {
        ...state,
        errorMessage: null,
      };
    case SAVE_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: payload.alertMessage,
      };
    default:
      return state;
  }
};

export default transportMangement;
