import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { mergeDeep, capitalizeFirstLetter } from "../../utils";
import { connect } from "react-redux";
import { IRootState } from "../../../../../common/redux/reducers";
import { fetchSLABasedReport } from "../../actions";
import moment from "moment";
import classes from "../common-reports.module.scss";
import ReportsTable from "../commons/report-table";

const reformatData = (rawData: any) => {
  let labelData: any = [];
  let dateArr: any = rawData;
  if (!rawData) return { processedData: [], labelData: {} };
  let processedData = dateArr.reduce((acc: any, curr: any, index: any) => {
    let date = curr?.date;
    let dateObj: any = curr;
    let finalObj = {
      date: date,
      totalOrders: dateObj?.total || "-",
    };
    if (!dateObj?.slaData) return acc; //handle incorrect api response strucuture
    labelData.push(dateObj.slaData);
    let slaData: any = Object.keys(dateObj.slaData);
    const remainingData = slaData.reduce((a: any, c: any, ind: any) => {
      let subText: any = Object.keys(dateObj.slaData[c].data);
      const txtKeyValPair = subText.reduce(
        (acc: any, txt: any) => ({
          ...acc,
          [c + "-" + txt]: dateObj.slaData[c]["data"][txt],
        }),
        {}
      );
      return { ...a, ...txtKeyValPair };
    }, {});
    finalObj = { ...finalObj, ...remainingData };
    return acc.concat(finalObj);
  }, []);
  //combine all labels to single object
  return { processedData, labelData: mergeDeep(labelData) };
};

const ReportSummaryTable = ({
  reportData,
  fetchSLABasedReport,
  filterData,
  reportLoading,
}: any) => {
  const [processedData, setProcessedData] = useState<any>({});

  useEffect(() => {
    reportData.data && setProcessedData(reformatData(reportData.data));
  }, [reportData]);
  const columnFormatter = (): any => {
    const finalObj = [
      {
        title: "Date",
        dataIndex: "date",
        width: 100,
        render: (record: any) => moment(record).format("MM/DD/YYYY"),
        fixed: "left",
        align: "center",
      },
      {
        title: "Total Orders",
        dataIndex: "totalOrders",
        key: "totalOrders",
        width: 100,
        align: "center",
      },
    ];
    const labelsObj = processedData?.labelData;
    if (labelsObj) {
      const firstLvlLabel = Object.keys(labelsObj);
      const remainingDat: any = firstLvlLabel.map((sla: any) => {
        const slaObj = labelsObj[sla];
        let secondLvlLabel: any = Object.keys(slaObj.data);
        secondLvlLabel = secondLvlLabel
          .filter((val: any) => val !== "total")
          .concat("total");
        return {
          title: slaObj.name,
          className: classes.borderLeft,
          children: secondLvlLabel.reduce((acc: any, curr: any, ind: any) => {
            const borderClass = ind === 0 ? classes.borderLeft : null;
            return [
              ...acc,
              {
                title: capitalizeFirstLetter(curr),
                dataIndex: sla + "-" + curr,
                key: sla + "-" + curr,
                ...(borderClass && { className: borderClass }),
                align: "center",
                width: 100,
                render: (text: any) => (text ? text : "-"),
              },
            ];
          }, []),
        };
      });
      return finalObj.concat(remainingDat);
    }
    return [];
  };
  const data = processedData.processedData;
  let labelData = processedData.labelData;

  return (
    <ReportsTable
      loading={reportLoading}
      rowData={data}
      columnData={labelData ? columnFormatter() : []}
      totalCount={reportData?.totalCount}
      fetchData={(pagination: any) => {
        fetchSLABasedReport({
          ...pagination,
          ...filterData,
        });
      }}
    />
  );
};
const mapStateToProps = ({ reportsState }: IRootState) => ({
  dataLoading: reportsState.loading,
  allSla: reportsState.allSlas,
  allOrderTypes: reportsState.allOrderTypes,
  successMsg: reportsState.successMessage,
  reportData: reportsState.reportData,
  filterData: reportsState.filterData,
  reportLoading: reportsState.loading,
});
const mapDispatchToProps = {
  fetchSLABasedReport,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportSummaryTable);
