/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { Button, Alert, Typography } from "antd";
import { FormOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import ListComponent from "../../shared/components/list-component/list-component";
import { IRootState } from "../../../common/redux/reducers";
import { connect } from "react-redux";
import { fetchUnits, createUnit, updateUnit, deleteUnit } from "./actions";
import { fetchAllCampuses } from "../campus/actions";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_LIMIT,
  VIEW_TEXT,
  EDIT_TEXT,
  DELETE_TEXT,
  CREATE,
  VIEW,
  EDIT,
  DELETE,
  UNIT_PAGE,
  BUTTON_GREEN_HEX,
} from "../../../common/shared-constants";
import UnitModal from "./unit-modals/unit-modal";
import UnitViewModal from "./unit-modals/unit-view-modal";
import DeleteModal from "../../shared/components/delete-modal/delete-modal";
import { AbilityContext } from "../../shared/ability/can";
import { useAbility } from "@casl/react";
import { Can } from "../../shared/ability/can";
import { Helmet } from "react-helmet";
import classes from "./unit.module.scss";
import { CLEAR_UNIT_FETCH_ERROR_MSG, CLEAR_UNIT_MSG } from "./action-types";
import { clearMessage } from "../../../common/actions/common-actions";
const { Title } = Typography;

const UnitPage = ({
  units,
  allCampuses,
  unitsLoading,
  totalCount,
  errorMsg,
  fetchUnits,
  successMsg,
  fetchErrorMsg,
  clearMessage,
  createUnit,
  updateUnit,
  deleteUnit,
  fetchAllCampuses,
}: any) => {
  const ability = useAbility(AbilityContext);
  const [loading, setLoading] = useState(false);
  const [tableOpt, setOperation] = useState({
    search: "",
    limit: DEFAULT_PAGE_LIMIT,
    page: DEFAULT_PAGE,
    pageSizeOptions: [30, 50, 100, 120],
    showSizeChanger: true,
  });

  const { search, page, limit, showSizeChanger, pageSizeOptions } = tableOpt;
  const [modalOp, setModalOp] = useState({
    visible: false,
    mode: CREATE,
    unit: null,
    title: "Delete Unit",
    label: "unit",
  });

  useEffect(() => {
    modalOp.visible && clearMessage(CLEAR_UNIT_MSG);
  }, [modalOp.mode, modalOp.visible]);

  useEffect(() => {
    fetchUnits({ search: search, limit, page: DEFAULT_PAGE });
    fetchAllCampuses(true);
    return () => {
      clearMessages();
      clearFetchError();
    };
  }, []);

  useEffect(() => {
    let timer: any;
    if (successMsg) {
      timer = setTimeout(() => {
        clearMessages();
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [successMsg]);

  const clearMessages = () => {
    clearMessage(CLEAR_UNIT_MSG);
  };
  const clearFetchError = () => {
    clearMessage(CLEAR_UNIT_FETCH_ERROR_MSG);
  };

  const handleCancel = () => {
    setModalOp({
      ...modalOp,
      visible: false,
    });
  };

  const onView = (unit: any) => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: VIEW,
      unit,
    });
  };
  const onDelete = (unit: any) => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: DELETE,
      unit,
    });
  };
  const onEdit = (unit: any) => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: EDIT,
      unit,
    });
  };

  const onSearch = ({ target }: any) => {
    setOperation({
      ...tableOpt,
      search: target.value,
      page: DEFAULT_PAGE,
    });
    fetchUnits({ search: target.value, limit, page: DEFAULT_PAGE });
  };

  const handleTableChange = (pagination: any) => {
    setOperation({
      ...tableOpt,
      page: pagination.current,
      limit: pagination.pageSize,
    });
    fetchUnits({
      search,
      limit: pagination.pageSize,
      page: pagination.current,
    });
  };

  const onAdd = () => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: CREATE,
      unit: null,
    });
  };

  const handleSubmit = async (unit: any) => {
    setLoading(true);
    let result: any;
    if (modalOp.mode === CREATE) {
      result = await createUnit(unit);
    } else if (modalOp.mode === EDIT) {
      result = await updateUnit(unit);
    } else {
      result = await deleteUnit(unit);
    }
    if (result.success) {
      fetchUnits({ search, limit, page: page });
      setModalOp({
        ...modalOp,
        visible: false,
      });
    }
    setLoading(false);
  };

  const columns: any = [
    {
      align: "center",
      title: "Name",
      dataIndex: "name",
      width: "14%",
      key: "name",
      render: (name: String) => name,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Mapped Campus",
      dataIndex: "campusId",
      key: "campusId",
      width: "14%",
      render: (campusId: any) =>
        allCampuses.find((campus: any) => campus.id === campusId)?.name,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Building",
      dataIndex: "building",
      key: "building",
      width: "14%",
      render: (building: any) => building || "-",
      ellipsis: true,
    },
    {
      align: "center",
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
      width: "14%",
      render: (floor: any) => floor || "-",
      ellipsis: true,
    },
    {
      align: "center",
      title: "Procedural Unit",
      dataIndex: "proceduralUnit",
      key: "proceduralUnit",
      width: "14%",
      render: (proceduralUnit: boolean) => (proceduralUnit ? "ON" : "OFF"),
      ellipsis: true,
    },
    {
      align: "center",
      title: "Actions",
      key: "action",
      width: "10%",
      ellipsis: true,
      render: (record: any) => (
        <div className="actionIcons">
          <Button
            size="small"
            className="icon-btn"
            title={VIEW_TEXT}
            onClick={() => {
              onView(record);
            }}
          >
            <EyeOutlined
              style={{ color: BUTTON_GREEN_HEX }}
              className="edit-icon"
            />
          </Button>
          {ability.can("edit", "Unit") && (
            <Button
              size="small"
              className="icon-btn"
              title={EDIT_TEXT}
              onClick={() => {
                onEdit(record);
              }}
            >
              <FormOutlined
                style={{ color: BUTTON_GREEN_HEX }}
                className="edit-icon"
              />
            </Button>
          )}
          <Can I="delete" a="Unit">
            <Button
              size="small"
              className="icon-btn"
              title={DELETE_TEXT}
              onClick={() => {
                onDelete(record);
              }}
            >
              <DeleteOutlined
                style={{ color: BUTTON_GREEN_HEX }}
                className="delete-icon"
              />
            </Button>
          </Can>
        </div>
      ),
      responsive: ["lg", "md"],
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Units | Admin Portal</title>
      </Helmet>
      <Title className={classes.titleText} level={5}>
        Units
      </Title>
      {successMsg ? (
        <Alert
          className={`fadeIn ${classes.alertContainer}`}
          message={successMsg}
          type="success"
          closable
          afterClose={clearMessages}
        />
      ) : null}
      {fetchErrorMsg ? (
        <Alert
          className={`fadeIn ${classes.alertContainer}`}
          message={fetchErrorMsg}
          type="error"
          closable
          afterClose={clearFetchError}
        />
      ) : null}
      <ListComponent
        type={UNIT_PAGE}
        loading={unitsLoading}
        dataSource={units}
        columns={columns}
        handleTableChange={handleTableChange}
        onSearch={onSearch}
        onAdd={onAdd}
        pagination={{
          current: page,
          pageSize: limit,
          total: totalCount,
          showSizeChanger,
          pageSizeOptions,
        }}
        addButtonLabel={"Add New Unit"}
        searchLabel={"Search by Name"}
      />
      {modalOp.mode === VIEW ? (
        <UnitViewModal
          handelClose={handleCancel}
          visible={modalOp.visible}
          unit={modalOp.unit}
          allCampuses={allCampuses}
        />
      ) : null}
      {(modalOp.mode === EDIT || modalOp.mode === CREATE) && modalOp.visible ? (
        <UnitModal
          visible={modalOp.visible}
          unit={modalOp.unit}
          mode={modalOp.mode}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          errorMsg={errorMsg}
          clearMessages={clearMessages}
          loading={loading}
          allCampuses={allCampuses}
        />
      ) : null}
      {modalOp.mode === DELETE && modalOp.visible ? (
        <DeleteModal
          loading={loading}
          entity={modalOp.unit}
          visible={modalOp.visible}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          title={modalOp.title}
          label={modalOp.label}
          errorMsg={errorMsg}
          clearMessages={clearMessages}
          isPassRequired={true}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = ({ unitManagement, campusManagement }: IRootState) => ({
  errorMsg: unitManagement.errorMessage,
  successMsg: unitManagement.successMessage,
  unitsLoading: unitManagement.loading,
  units: unitManagement.units,
  totalCount: unitManagement.totalCount,
  fetchErrorMsg: unitManagement.fetchErrorMessage,
  allCampuses: campusManagement.allCampuses,
});
const mapDispatchToProps = {
  fetchUnits,
  createUnit,
  updateUnit,
  deleteUnit,
  clearMessage,
  fetchAllCampuses,
};
export default connect(mapStateToProps, mapDispatchToProps)(UnitPage);
