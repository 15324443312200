export const FETCH_REQUEST_ALL_CAMPUSES_SUCCESS = "FETCH_ALL_CAMPUSES_SUCCESS";
export const FETCH_REQUEST_ALL_CAMPUSES_FAIL = "FETCH_ALL_CAMPUSES_FAIL";
export const FETCH_CAMPUS_UNITS_SUCCESS = "FETCH_CAMPUS_UNITS_SUCCESS";
export const FETCH_CAMPUS_UNITS_FAIL = "FETCH_CAMPUS_UNITS_FAIL";
export const CLEAR_LOCATION_ERROR_MSG = "CLEAR_LOCATION_ERROR_MSG";
export const FETCH_REQ_SLA_FAIL = "FETCH_REQ_SLA_FAIL";
export const FETCH_REQ_SLA_SUCCESS = "FETCH_REQ_SLA_SUCCESS";
export const FETCH_REQUEST_START = "FETCH_REQUEST_START";
export const FETCH_PATIENTS_SUCCESS = "FETCH_PATIENTS_SUCCESS";
export const FETCH_PATIENTS_FAIL = "FETCH_PATIENTS_FAIL";
