import { Col, Modal, Row, Button, Form, Input } from "antd";
import classes from "./QuickActions.module.scss";
import { adminRootInstance } from "../../../../common/config/axios-config";
import { useEffect, useState } from "react";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
export interface IAssetSummary {
  asset_id: number;
  average_time_diff_in_seconds: number;
  latest_decon_end_time: string;
  latest_decon_start_time: string;
  number_of_rotations: number;
  last_location: string;
}

const QuickActions = (props: any) => {
  const {
    handleClose,
    type,
    asset,
    handleStartDecon,
    visible,
    startDecon,
    setShowQuickFunctions,
    stopDecon,
    setDeconToRepair,
  } = props;
  const [assetSummary, setAssetSummary] = useState<IAssetSummary | null>(null);
  const [timer, setTimer] = useState<string>("");
  const [repairModalVisible, setRepairModalVisible] = useState(false);
  const [repairReason, setRepairReason] = useState("");

  const startTimer = (startTime: string) => {
    // Calculate the initial difference in milliseconds
    let start = new Date(startTime).getTime();

    // Update the timer every second
    setInterval(() => {
      let now = new Date().getTime();
      let elapsed = now - start;

      // Calculate hours, minutes, and seconds
      let hours = Math.floor(
        (elapsed % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((elapsed % (1000 * 60)) / 1000);
      setTimer(
        `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      );
      // Display the result in HH:MM:SS format
    }, 1000);
  };

  useEffect(() => {
    if (visible) {
      adminRootInstance
        .get("/aim/assets/activities/summary/" + asset?.id)
        .then((response) => {
          setAssetSummary(response.data?.data?.summary || null);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [asset]);

  useEffect(() => {
    if (assetSummary) {
      const start = new Date(assetSummary?.latest_decon_start_time).getTime();
      const intervalId = setInterval(() => {
        const now = new Date().getTime();
        const elapsed = now - start;

        const hours = Math.floor(
          (elapsed % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((elapsed % (1000 * 60)) / 1000);

        setTimer(
          `${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
        );
      }, 1000);

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [assetSummary, timer]);

  const formatTime = (seconds: number) => {
    const duration = moment.duration(seconds, 'seconds');
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const secs = duration.seconds();
    let formatedTime = `${secs} seconds`;
    if (minutes > 0) {
      formatedTime = `${minutes} minutes, ${formatedTime}`;
    }
    if (hours > 0) {
      formatedTime = `${hours} hours, ${formatedTime}`;
    }
    if (days > 0) {
      formatedTime = `${days} days, ${formatedTime}`;
    }
    return formatedTime;
  };

  const handleReasonChange = (e: any) => {
    setRepairReason(e.target.value);
  };

  const moveDeconToRepair = () => {
    const repairDeconPayload = {
      note: repairReason,
      status: "REPAIR",
      controlNumber: asset.controlNumber,
      serialNumber: asset.serialNumber,
    };
    setRepairModalVisible(false);
    handleClose();
    setDeconToRepair(repairDeconPayload, asset.id);
  };

  return (
    <>
      <Modal
        open={repairModalVisible}
        onCancel={() => setRepairModalVisible(false)}
        maskClosable={false}
        footer={null}
        width={600}
        confirmLoading={true}
        centered
      >
        <div className={classes.min_padding}>
          <Row justify="center" className={classes.min_pbottom_row}>
            <Col span={24}>
              <p style={{ marginBottom: "0" }}>Enter Repair Reason:</p>
              <TextArea
                placeholder="Enter Repair reason"
                autoSize
                onChange={handleReasonChange}
              />
              {repairReason &&
                repairReason?.length > 0 &&
                repairReason?.length < 3 && (
                  <p className={classes.warning}>
                    Please Enter a valid Reason.
                  </p>
                )}
            </Col>
          </Row>
          <Row justify="end">
            <Col span={4}>
              <Button
                type="primary"
                className={"nextBtn"}
                htmlType="button"
                onClick={moveDeconToRepair}
                disabled={repairReason.length < 3}
              >
                Submit
              </Button>
            </Col>
            <Col span={4}>
              <Button
                type="ghost"
                htmlType="button"
                onClick={() => setRepairModalVisible(false)}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        open={visible}
        onCancel={() => handleClose()}
        maskClosable={false}
        footer={null}
        width={800}
        confirmLoading={true}
      >
        <Row justify="center" className={classes.min_pbottom}>
          <Col span={8} className="text-center m-2">
            <Row justify="center">
              <div className={`${classes.popup}`}>
                {type === "checkin" && (
                  <>
                    <Button
                      type="primary"
                      className={"nextBtn"}
                      htmlType="button"
                      onClick={() => handleStartDecon(asset.controlNumber)}
                    >
                      Start Decon
                    </Button>
                    <br />
                  </>
                )}
                {type === "decon" && (
                  <span className={classes.model_asset_detail_label}>
                    Decon In-Progress: <br />
                    <span className={classes.model_asset_detail_value}>
                      {timer}
                    </span>
                  </span>
                )}
              </div>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={8}>
                <Row justify="center">
                  <div className={`${classes.side_popup} `}>
                    <Row justify="center">
                      <span className={classes.model_asset_detail_title}>
                        <u>Device:</u>
                      </span>
                    </Row>
                    <Row justify="center">
                      <span className={classes.model_asset_detail_label}>
                        <span className={classes.model_asset_detail_value}>
                          {asset?.equipmentName}
                        </span>
                      </span>
                    </Row>
                    <Button
                      type="primary"
                      className={"nextBtn"}
                      htmlType="button"
                      onClick={() =>
                        // handleStopDecon(asset.controlNumber)
                        setRepairModalVisible(true)
                      }
                    >
                      Needs Repair
                    </Button>
                  </div>
                </Row>
              </Col>
              <Col span={8}>
                <Row
                  justify="center"
                  className={classes.modal_control_number_container}
                >
                  <span className={classes.modal_control_number}>
                    {asset?.controlNumber}
                  </span>
                </Row>
              </Col>
              <Col span={8}>
                <Row justify="center">
                  <div className={`${classes.side_popup}`}>
                    <Row justify="center">
                      <span className={classes.model_asset_detail_title}>
                        <u>Quick Details:</u>
                      </span>
                    </Row>
                    <Row justify="center">
                      <span className={classes.model_asset_detail_label}>
                        Average Cleaning Time: <br />{" "}
                        <span className={classes.model_asset_detail_value}>
                          {formatTime(
                            assetSummary?.average_time_diff_in_seconds || 0
                          )}
                        </span>
                      </span>
                    </Row>
                    <hr />
                    <Row justify="center">
                      <span className={classes.model_asset_detail_label}>
                        Latest Decon End Time: <br />
                        <span className={classes.model_asset_detail_value}>
                          {assetSummary?.latest_decon_end_time ? moment(assetSummary?.latest_decon_end_time).format(
                            "DD/MM/YYYY HH:mm:ss"
                          ) : '-'}
                        </span>
                      </span>
                    </Row>
                    <hr />
                    <Row justify="center">
                      <span className={classes.model_asset_detail_label}>
                        Number of Rotations: <br />
                        <span className={classes.model_asset_detail_value}>
                          {assetSummary?.number_of_rotations}
                        </span>
                      </span>
                    </Row>
                    {assetSummary?.last_location && (
                      <>
                        <hr />
                        <Row justify="center">
                          <span className={classes.model_asset_detail_label}>
                            Last Location: <br />
                            <span className={classes.model_asset_detail_value}>
                              {assetSummary?.last_location}
                            </span>
                          </span>
                        </Row>{" "}
                      </>
                    )}
                  </div>
                </Row>
              </Col>
            </Row>
          </Col>
          {type === "decon" && (
            <Col span={8}>
              <Row justify="center">
                <div className={`${classes.popup}`}>
                  <Button
                    type="primary"
                    className={"nextBtn"}
                    htmlType="button"
                    onClick={() => stopDecon(asset.controlNumber)}
                  >
                    Stop Decon
                  </Button>
                </div>
              </Row>
            </Col>
          )}
        </Row>
      </Modal>
    </>
  );
};

export default QuickActions;
