import { checkRequestorPortalTokenValidity } from "../../../common/config/admin-token-check";
import { requestorRootInstance } from "../../../common/config/axios-config";
import { decode } from "../../../common/utils/jwt-decode";
import {
  REQUESTOR_CLEAR_AUTH_MSG,
  REQUESTOR_LOGIN_FAIL,
  REQUESTOR_LOGIN_REQ_START,
  REQUESTOR_LOGIN_SUCCESS,
  REQUESTOR_LOGOUT,
  CONTINUE_WITHOUT_LOGIN,
  EXIT_WITHOUT_LOGIN,
  REQUESTOR_AUTO_LOGIN_FAIL,
  REQUESTOR_AUTO_LOGIN_SUCCESS,
} from "./action-types";

export const login: (body: any) => any = (body) => (dispatch: any) => {
  dispatch({ type: REQUESTOR_LOGIN_REQ_START });
  return requestorRootInstance
    .post(
      process.env.REACT_APP_SSO_ENABLED === "true"
        ? "sso-login"
        : "anonymous-login",
      {
        ...body,
      }
    )
    .then((res: any) => {
      localStorage.setItem("req_access_token", res.data.data.auth.token);
      localStorage.setItem("req_refresh_token", res.data.data.refresh.token);
      const tokenPayload: any = decode(res.data.data.auth.token as string);
      dispatch({
        type: REQUESTOR_LOGIN_SUCCESS,
        payload: { user: tokenPayload.data },
      });
      return { status: "success", user: tokenPayload.data };
    })
    .catch((err: any) => {
      dispatch({
        type: REQUESTOR_LOGIN_FAIL,
        payload: {
          errorMessage: err.response?.data?.message,
        },
      });
      return {
        status: "fail",
        errorMessage: err.response?.data?.message,
      };
    });
};

export const exitWithoutLogin = () => (dispatch: any) => {
  localStorage.removeItem("withoutLogin");
  dispatch({
    type: EXIT_WITHOUT_LOGIN,
  });
};

export const clearAuthError = () => (dispatch: any) => {
  dispatch({
    type: REQUESTOR_CLEAR_AUTH_MSG,
  });
};

export const logoutUser = () => (dispatch: any) => {
  localStorage.removeItem("req_refresh_token");
  localStorage.removeItem("req_access_token");
  localStorage.removeItem("withoutLogin");
  dispatch({
    type: REQUESTOR_LOGOUT,
    payload: { message: "you are logged out successfully" },
  });
};

export const autoLogin = () => async (dispatch: any) => {
  const result: any = await checkRequestorPortalTokenValidity();
  if (result) {
    dispatch({
      type: REQUESTOR_AUTO_LOGIN_SUCCESS,
      payload: { user: result },
    });
  } else {
    localStorage.removeItem("req_access_token");
    localStorage.removeItem("req_refresh_token");
    localStorage.removeItem("withoutLogin");
    dispatch({ type: REQUESTOR_AUTO_LOGIN_FAIL });
  }
};

export const continueWithoutLogin = () => (dispatch: any) => {
  localStorage.removeItem("req_access_token");
  localStorage.removeItem("req_refresh_token");
  localStorage.setItem("withoutLogin", "true");
  dispatch({
    type: CONTINUE_WITHOUT_LOGIN,
  });
};

export const clearRequestorAuthentication =
  (message: any) => (dispatch: any) => {
    localStorage.removeItem("req_access_token");
    localStorage.removeItem("req_refresh_token");
    dispatch({
      type: REQUESTOR_LOGOUT,
      payload: { message: message },
    });
  };
