import React from "react";
import classes from "./my-request.module.scss";
const MyRequest = (props: any) => {
  return (
    <button
      onClick={() => props.onClick(props.data)}
      className={classes.requestMainBtn}
    >
      <div className={classes.mainWrapper}>
        <div className={classes.listContent}>
          <div className={classes.requestDetail}>
            <div>
              <p>
                <span>Request ID:</span>
                {props.data.requestId}
              </p>
              <p>
                <span>Status:</span>
                {props.data.orderExpired ? "EXPIRED" : props.data.status}
              </p>
              <p>
                <span>Request type:</span>
                {props.data.requestType.name}
              </p>
            </div>
          </div>
          <div
            className={`${classes.itemList} ${
              props.data.items.length > 3 ? classes.scroll : classes.noScroll
            }`}
          >
            <div>
              <p>
                <b>Items</b>
              </p>
              {props.data.items.map((item: any, index: any) => {
                return (
                  <p key={index}>
                    {item.item.name} - {item.quantity}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className={classes.slaColor}
          style={{ backgroundColor: props?.data?.sla?.color }}
        ></div>
      </div>
    </button>
  );
};

export default MyRequest;
