import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, RouteProps } from "react-router-dom";
import RequestPortalLayout from "./layout/request-portal-layout";
import { IRootState } from "../../common/redux/reducers";

export interface IPrivateRouteProps extends RouteProps, StateProps {
  authenticatedRoute?: boolean;
}

export const PrivateRouteComponent = ({
  component: Component,
  isAuthenticated,
  sessionHasBeenFetched,
  logout,
  authenticatedRoute = false,
  ...rest
}: IPrivateRouteProps) => {
  const ComponentToRender = Component as React.ElementType;
  const renderRedirect = (props: any) => {
    if (!sessionHasBeenFetched) {
      return <div></div>;
    } else {
      return authenticatedRoute ? (
        isAuthenticated ? (
          <RequestPortalLayout {...props}>
            <ComponentToRender {...props} />
          </RequestPortalLayout>
        ) : (
          <Redirect to="/request" />
        )
      ) : (
        <RequestPortalLayout {...props}>
          <ComponentToRender {...props} />
        </RequestPortalLayout>
      );
    }
  };

  return <Route {...rest} render={renderRedirect} />;
};

const mapStateToProps = ({
  authentication: { isAuthenticated, sessionHasBeenFetched, logout },
}: IRootState) => ({
  isAuthenticated,
  sessionHasBeenFetched,
  logout,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export const PrivateRoute = connect(mapStateToProps, null, null, {
  pure: false,
})(PrivateRouteComponent);

export default PrivateRoute;
