import classes from "../../common-reports.module.scss";
import { itemNameFormatter } from "../../../utils";
import InfoPopover from "../../../../../../common/components/InfoPopover/InfoPopover";
import { title } from "process";
const cellHeight = 70;

export const renderNull = (record: any, forCSV: boolean) => {
  return forCSV ? (
    record || "-"
  ) : (
    <div className={`${classes.cancelReason}`}>{record || "-"}</div>
  );
};

export const getETCCreatedDateTimeCol = () => {
  return [
    {
      title: (
        <span>
          Created Date
          <InfoPopover message="The Date is in MM-DD-YYYY format" />
        </span>
      ),
      dataIndex: "createdDate",
      render: (record: string) => renderNull(record, true),
      width: 150,
      align: "center",
    },
    {
      title: "Created Time",
      dataIndex: "createdTime",
      render: (record: string) => renderNull(record, true),
      width: 150,
      align: "center",
    },
  ];
};

export const deepDiveDurationColumns = () => {
  return [
    {
      title: "Queue Duration",
      dataIndex: "duration",
      render: (record: any) => record?.queue || "-",
      width: 100,
      align: "center",
    },
    {
      title: "Accept Duration",
      dataIndex: "duration",
      render: (record: any) => record?.accept || "-",
      width: 100,
      align: "center",
    },
    {
      title: "Start Duration",
      dataIndex: "duration",
      render: (record: any) => record?.start || "-",
      width: 100,
      align: "center",
    },
    {
      title: "Deliver Duration",
      dataIndex: "duration",
      render: (record: any) => record?.deliver || "-",
      width: 100,
      align: "center",
    },
    {
      title: "Delay Duration",
      dataIndex: "duration",
      render: (record: any) => record?.delay || "-",
      width: 100,
      align: "center",
    },
    {
      title: "Round Trip Duration",
      dataIndex: "duration",
      render: (record: any) => record?.total || "-",
      width: 100,
      align: "center",
    },
  ];
};

interface columnConfigInterface {
  noItemDetails?: any;
  forCSV: boolean;
}
export const columnConfig: any = ({
  noItemDetails = undefined,
  forCSV,
}: columnConfigInterface) => ({
  reqId: {
    title: "Request Id",
    dataIndex: "reqId",
    fixed: "left",
    ellipsis: true,
    render: (record: any) => renderNull(record, forCSV),
    width: 100,
    align: "center",
  },
  sla: {
    title: "SLA",
    dataIndex: "sla",
    ellipsis: true,
    render: (record: any) => renderNull(record, forCSV),
    width: 100,
    align: "center",
  },
  closedBy: {
    title: "Closed By",
    dataIndex: "closedBy",
    render: (record: any) => renderNull(record, forCSV),
    width: 100,
    align: "center",
  },
  reqType: {
    title: "Request Type",
    dataIndex: "reqType",
    render: (record: any) => renderNull(record, forCSV),
    width: 100,
    align: "center",
  },
  items: {
    title: "Items",
    dataIndex: "items",
    width: !noItemDetails ? 100 : 250,
    render: (record: any) => {
      if (!noItemDetails) {
        return (
          <div className={classes.flexItemMiddle}>
            {record?.map((item: any) => {
              return itemNameFormatter(item);
            })}
          </div>
        );
      } else {
        let speciality = false;
        record.forEach((item: any) => {
          if (item.splItem) speciality = true;
        }, 0);
        if (!speciality)
          return (
            <div
              className={`${classes.flexItemMiddle} `}
              style={{
                height: `${record.length * cellHeight}px`,
                borderBottom: "1px solid #c5c5c5",
                margin: "8px -9px -8px -8px",
                paddingBottom: "8px",
              }}
            >
              {record?.map((item: any) => (
                <div style={{ margin: "0px 8px" }}>
                  {itemNameFormatter(item)}
                </div>
              ))}
            </div>
          );
        return (
          <div>
            {record?.map((item: any, ind: number) => {
              if (item.splItem && !item.splItemDetails?.length) {
                return (
                  <div
                    className={classes.flexItemMiddle}
                    style={{
                      ...(speciality ? { height: `${cellHeight}px` } : {}),
                    }}
                  >
                    {itemNameFormatter(item)}
                  </div>
                );
              }
              return (
                <div
                  className={`${classes.flexItemMiddle} `}
                  style={{
                    ...(item.splItem
                      ? {
                          height: `${
                            parseInt(item.splItemDetails?.length) * cellHeight
                          }px`,
                        }
                      : { height: `${cellHeight}px` }),
                    ...(ind !== record.length - 1
                      ? {
                          borderBottom: "1px solid #c5c5c5",
                        }
                      : {
                          borderBottom: "1px solid #c5c5c5",
                          padding: "0px 8px 8px 8px",
                          marginBottom: "-8px",
                        }),
                    marginRight: "-9px",
                    marginLeft: "-8px",
                    padding: "0px 8px",
                  }}
                >
                  {itemNameFormatter(item)}
                </div>
              );
            })}
          </div>
        );
      }
    },
    align: "center",
  },
  itemDetails: {
    title: "Item Details",
    dataIndex: "items",
    align: "center",
    render: (currentField: any) => {
      let dataPresent = false;
      let speciality = false;
      let totalRows = currentField.reduce((acc: any, item: any) => {
        if (item.splItem) {
          speciality = true;
          return acc + item.splItemDetails?.length;
        }
        return acc + 1;
      }, 0);
      let data = (
        <div
          style={{
            ...(speciality
              ? {
                  height: `${cellHeight * totalRows}px`,
                }
              : { height: `${cellHeight}px` }),
            marginBottom: "-8px",
          }}
        >
          {currentField.reduce((acc: any, item: any, ind: any) => {
            const { splItemDetails } = item;
            if (splItemDetails?.length) {
              dataPresent = true;
            }
            const finalResult = acc.concat(
              <div
                style={{
                  ...(ind !== currentField.length - 1
                    ? { borderBottom: "1px solid #c5c5c5" }
                    : {
                        borderBottom: "1px solid #c5c5c5",
                        padding: "0px 8px 8px 8px",
                      }),
                  marginRight: "-8px",
                  marginLeft: "-9px",
                  padding: "0px 8px",

                  paddingBottom: "8px",
                  height: `${cellHeight * (splItemDetails?.length || 1)}px`,
                }}
              >
                {!splItemDetails?.length ? (
                  <div
                    style={{
                      borderBottom: "1px dotted grey",
                      height: `${cellHeight}px`,
                    }}
                    className={classes.flexItemMiddle}
                  >
                    -
                  </div>
                ) : (
                  splItemDetails?.map((details: any, ind: number) => {
                    const {
                      id1Type,
                      id1Value,
                      id2Type,
                      id2Value,
                      patientFirstName,
                      patientLastName,
                    } = details;
                    let patientName = `Patient name - ${
                      !!patientLastName && !!patientFirstName
                        ? `${patientFirstName} ${patientLastName}`
                        : ""
                    }`;
                    return (
                      <div
                        className={classes.flexItemMiddle}
                        style={{
                          ...(ind !== splItemDetails?.length - 1
                            ? {
                                borderBottom: "1px dotted grey",
                              }
                            : {}),
                          height: `${cellHeight}px`,
                        }}
                      >
                        <div
                          title={patientName}
                          className={classes.ellipsisSentence}
                        >
                          {patientName}
                        </div>
                        <div
                          title={`${id1Type} - ${id1Value}`}
                          className={classes.ellipsisSentence}
                        >
                          {id1Type} - {id1Value}
                        </div>
                        <div
                          title={`${id2Type} - ${id2Value}`}
                          className={classes.ellipsisSentence}
                        >
                          {id2Type} - {id2Value}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            );
            return finalResult;
          }, [])}
        </div>
      );
      return dataPresent ? (
        data
      ) : (
        <div
          className={classes.flexItemMiddle}
          style={{
            height: `${currentField.length * cellHeight}px`,
            borderBottom: "1px solid #c5c5c5",
            margin: "8px -8px -8px -9px",
            paddingBottom: "8px",
          }}
        >
          -
        </div>
      );
    },
    width: 300,
  },
  isDelayed: {
    title: "Is Delayed",
    dataIndex: "isDelayed",
    render: (record: any) => (record ? "Yes" : "No"),
    width: 100,
    align: "center",
  },
  isPartial: {
    title: "Is Partial",
    dataIndex: "isPartial",
    render: (record: any) => (record ? "Yes" : "No"),
    width: 100,
    align: "center",
  },
  status: {
    title: "Status",
    dataIndex: "status",
    ellipsis: true,
    render: (status: any, record: any) =>
      renderNull(record.orderExpired ? "EXPIRED" : status, forCSV),
    width: 100,
    align: "center",
  },
  assignedTo: {
    title: "Assigned To",
    dataIndex: "assignedTo",
    key: "assignedTo",
    ellipsis: true,
    render: (record: any) => renderNull(record, forCSV),
    width: 100,
    align: "center",
  },
  unit: {
    title: "Unit Name",
    dataIndex: "unit",
    width: 100,
    ellipsis: true,
    render: (record: any) => renderNull(record, forCSV),
    align: "center",
  },
  room: {
    title: "Room No/Bedspace",
    dataIndex: "room",
    align: "center",
    ellipsis: true,
    render: (record: any) => renderNull(record, forCSV),
    width: 100,
  },
  campus: {
    title: "Campus Name",
    dataIndex: "campus",
    align: "center",
    ellipsis: true,
    render: (record: any) => renderNull(record, forCSV),
    width: 100,
  },
  cancelReason: {
    title: "Cancellation Reason",
    dataIndex: "cancelReason",
    align: "center",
    render: (record: any) => renderNull(record, forCSV),
  },
  createdDate: {
    title: "Created Date",
    dataIndex: "createdDate",
    ellipsis: true,
    render: (record: string) => renderNull(record, true),
    width: 150,
    align: "center",
  },
  createdTime: {
    title: "Created Time",
    dataIndex: "createdTime",
    ellipsis: true,
    render: (record: string) => renderNull(record, true),
    width: 150,
    align: "center",
  },
  duration: deepDiveDurationColumns(),
  requestor: {
    title: "Requestor",
    dataIndex: "requestor",
    ellipsis: true,
    render: (record: string) => renderNull(record, true),
    width: 100,
    align: "center",
  },
  assignedBy: {
    title: "Assigned By",
    dataIndex: "assignedBy",
    ellipsis: true,
    render: (record: string) => renderNull(record, true),
    width: 100,
    align: "center",
  },
  toUnit: {
    title: "To Unit",
    dataIndex: "toUnit",
    ellipsis: true,
    render: (record: string) => renderNull(record, true),
    width: 100,
    align: "center",
  },
  fromUnit: {
    title: "From Unit",
    dataIndex: "fromUnit",
    ellipsis: true,
    render: (record: string) => renderNull(record, true),
    width: 100,
    align: "center",
  },
  patientName: {
    title: "Patient Name",
    dataIndex: "patientName",
    ellipsis: true,
    render: (record: string) => renderNull(record, true),
    width: 100,
    align: "center",
  },
  patientDob: {
    title: "Patient DOB",
    dataIndex: "patientDob",
    ellipsis: true,
    render: (record: string) => renderNull(record, true),
    width: 100,
    align: "center",
  },
});
export const getColumns = (keys: any, noFixed?: boolean) => {
  const columnsConfig = columnConfig({
    noItemDetails: keys.includes("itemDetails"),
    forCSV: false,
  });
  return keys.reduce((acc: any, key: any, index: any) => {
    if (key === "dateTime") return acc.concat(getETCCreatedDateTimeCol());
    if (key === "duration") return acc.concat(deepDiveDurationColumns());
    if (index === 1 && !noFixed)
      return acc.concat({ ...columnsConfig[key], fixed: "left" });
    return acc.concat(columnsConfig[key]);
  }, []);
};
