export const FETCH_REQUEST_SUCCESS = "FETCH_REQUEST_SUCCESS";
export const FETCH_REQUEST_FAIL = "FETCH_REQUEST_FAIL";
export const FETCH_REQUEST_START = "FETCH_REQUEST_START";
export const START_UNIT_FETCH = "START_UNIT_FETCH";
export const FETCH_UNIT_SUCCESS = "FETCH_UNIT_SUCCESS";
export const START_PRIORITY_FETCH = "START_PRIORITY_FETCH";
export const FETCH_PRIORITY_SUCCESS = "FETCH_PRIORITY_SUCCESS";
export const FETCH_NOTES_SUCCESS = "FETCH_NOTES_SUCCESS";
export const FETCH_NOTES_FAIL = "FETCH_NOTES_FAIL";
export const FETCH_NOTES_START = "FETCH_NOTES_START";
export const FETCH_ACTIVITIES_FAIL = "FETCH_ACTIVITIES_FAIL";
export const FETCH_ACTIVITIES_START = "FETCH_ACTIVITIES_START";
export const FETCH_ACTIVITIES_SUCCESS = "FETCH_ACTIVITIES_SUCCESS";
export const CLEAR_REQUEST = "CLEAR_REQUEST";
export const CLEAR_ACTIVITIES = "CLEAR_ACTIVITIES";
export const CLEAR_NOTES = "CLEAR_NOTES";
export const EDIT_REQUEST_START = "EDIT_REQUEST_START";
export const EDIT_REQUEST_SUCCESS = "EDIT_REQUEST_SUCCESS";
export const EDIT_REQUEST_FAIL = "EDIT_REQUEST_FAIL";
export const CLEAR_REQUEST_MESSAGE = "CLEAR_REQUEST_MESSAGE";
export const UPDATED_DETAIL = "UPDATED_DETAIL";
export const UPDATE_NOTES = "UPDATE_NOTES";
export const PUSH_ACTIVITIES = "PUSH_ACTIVITIES";
export const FETCH_PATIENTS_SUCCESS = "FETCH_PATIENTS_SUCCESS";
export const FETCH_PATIENTS_FAIL = "FETCH_PATIENTS_FAIL";
export const FETCH_PATIENTS_START = "FETCH_PATIENTS_START";
