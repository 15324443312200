import {
  FETCH_SLAs_FAIL,
  FETCH_SLAs_SUCCESS,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAIL,
  FETCH_UNITS_SUCCESS,
  FETCH_UNITS_FAIL,
  FETCH_REPORT_START,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_FAIL,
  FETCH_STATUSES_SUCCESS,
  FETCH_STATUSES_FAIL,
  FETCH_ITEMS_SUCCESS,
  FETCH_ITEMS_FAIL,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  FETCH_SPECIALITYITEMS_SUCCESS,
  FETCH_SPECIALITYITEMS_FAIL,
  CLEAR_ALL,
  SET_REPORT_FILTER,
  FETCH_ALL_DISCHARGE_LOCATION_FAIL,
  FETCH_ALL_DISCHARGE_LOCATION_START,
  FETCH_ALL_DISCHARGE_LOCATION_SUCCESS,
} from "./action-types";

const initialState = {
  loading: false,
  errorMessage: null as null | string,
  successMessage: null as null | string,
  allSlas: [] as null | any,
  allOrderTypes: [] as null | any,
  allUsers: [] as null | any,
  allItems: [] as null | any,
  allUnits: [] as null | any,
  allStatuses: [] as null | any,
  filterData: {} as any,
  reportData: {} as any,
  allDischargeLocation: [] as any,
};

export type ReportsDetails = Readonly<typeof initialState>;

const reportsState = (state = initialState, action: any): ReportsDetails => {
  const { type, payload } = action;
  switch (type) {
    case SET_REPORT_FILTER:
      return { ...state, filterData: payload.filterData };
    case FETCH_SLAs_SUCCESS:
      if (payload.page === 1) return { ...state, allSlas: [...payload.slas] }; //if 1st page, replace the previous slas
      return { ...state, allSlas: state.allSlas.concat(payload.slas) };
    case FETCH_ORDERS_SUCCESS:
      return { ...state, allOrderTypes: payload.orderTypes };
    case FETCH_USERS_SUCCESS:
      return { ...state, allUsers: payload.allUsers };
    case FETCH_UNITS_SUCCESS:
      return { ...state, allUnits: payload.units };
    case FETCH_STATUSES_SUCCESS:
      return { ...state, allStatuses: payload.statuses };
    case FETCH_SPECIALITYITEMS_SUCCESS:
      return { ...state, allItems: payload.items };
    case FETCH_ITEMS_SUCCESS:
      return { ...state, allItems: payload.items };
    case FETCH_REPORT_START:
      return {
        ...state,
        reportData: {},
        loading: true,
        errorMessage: null,
      };
    case FETCH_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        reportData: {
          data: payload.reportData,
          totalCount: payload.totalCount,
        },
      };
    case FETCH_REPORT_FAIL:
      return {
        ...state,
        reportData: {},
        loading: false,
        errorMessage: payload.errorMessage,
      };
    case FETCH_ORDERS_FAIL:
    case FETCH_SLAs_FAIL:
    case FETCH_UNITS_FAIL:
    case FETCH_STATUSES_FAIL:
    case FETCH_SPECIALITYITEMS_FAIL:
    case FETCH_ITEMS_FAIL:
    case FETCH_USERS_FAIL:
      return {
        ...state,
        successMessage: null,
        errorMessage: payload.errorMessage,
      };

    case CLEAR_ALL:
      return {
        ...initialState,
      };

    case FETCH_ALL_DISCHARGE_LOCATION_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        allDischargeLocation: payload.allDischargeLocation,
        loading: false,
      };
    case FETCH_ALL_DISCHARGE_LOCATION_FAIL:
      return {
        ...state,
        allDischargeLocation: [],
        loading: false,
      };
    default:
      return state;
  }
};

export default reportsState;
