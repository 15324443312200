/* eslint-disable react-hooks/exhaustive-deps*/
import { useEffect } from "react";
import { connect } from "react-redux";
import { IRootState } from "../../common/redux/reducers";
import { fetchCurrentCampusData } from "../modules/home/action";

function BackgroundCalls({ fetchCurrentCampusData, currentCampusId }: any) {
  useEffect(() => {
    const timer = setInterval(() => {
      if (localStorage.getItem("currentCampusId")) {
        fetchCurrentCampusData({
          id: localStorage.getItem("currentCampusId"),
        });
      }
    }, 15000);
    return () => clearInterval(timer);
  }, [currentCampusId]);
  return <></>;
}
const mapStateToProps = ({
  homeReducer: { currentCampus: { id } = {} },
}: IRootState) => ({
  currentCampusId: id,
});

export default connect(mapStateToProps, {
  fetchCurrentCampusData,
})(BackgroundCalls);
