import Countdown, { zeroPad } from "react-countdown";
import { Card, Col, Row, Typography } from "antd";
import moment from "moment";
import {
  BUTTON_GREEN_HEX,
  DISCHARGE_REQUEST_TYPE_ID,
  GREEN_THEME_HEX,
  RETURN_REQUEST_TYPE_ID,
} from "../../../common/shared-constants";
import { RightCircleOutlined } from "@ant-design/icons";
import classes from "./patient-details.module.scss";
const { Text } = Typography;

function Step3(props: any) {
  const {
    form,
    selectedRequestId,
    selectedRequest,
    allUnits,
    formValues,
    dischargeLocation,
    setTimeLapsed,
  } = props;

  const borderBottom = `1px solid ${GREEN_THEME_HEX}`;

  const getFormValues = () => {
    return form.getFieldsValue();
  };

  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (days > 0) {
      hours = days * 24 + hours;
    }

    if (completed) {
      setTimeLapsed(true);
    } else {
      setTimeLapsed(false);
    }

    return (
      <>
        <div
          style={{
            color: completed ? "red" : BUTTON_GREEN_HEX,
            textAlign: "left",
          }}
        >
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          {completed &&
            " - Not sufficient time for service. Please revise time in step 2"}
        </div>
      </>
    );
  };

  return (
    <Row gutter={[24, 24]} className={classes.container}>
      <Col span={11} className={classes.cardContainer}>
        <Card
          title="Origin"
          bordered
          headStyle={{
            borderColor: GREEN_THEME_HEX,
            borderBottom: borderBottom,
          }}
          className={classes.card}
        >
          <Row className={classes.fieldContainer}>
            <Col span={10} className={classes.labelContainer}>
              <Text>Transport Type</Text>
            </Col>
            <Col span={1} className={classes.labelContainer}>
              :
            </Col>
            <Col span={13} className={classes.labelContainer}>
              <Text>{selectedRequest}</Text>
            </Col>
          </Row>
          <Row className={classes.fieldContainer}>
            <Col span={10} className={classes.labelContainer}>
              <Text>From Unit</Text>
            </Col>
            <Col span={1} className={classes.labelContainer}>
              :
            </Col>
            <Col span={13} className={classes.labelContainer}>
              <Text>
                {allUnits?.find((u: any) => u?.id === getFormValues()?.fromUnit)
                  ?.name ||
                  allUnits?.find((u: any) => u?.id === formValues?.fromUnit)
                    ?.name}
              </Text>
            </Col>
          </Row>
          <Row className={classes.fieldContainer}>
            <Col span={10} className={classes.labelContainer}>
              <Text>From Room / Bedspace</Text>
            </Col>
            <Col span={1} className={classes.labelContainer}>
              :
            </Col>
            <Col span={13} className={classes.labelContainer}>
              <Text>{getFormValues()?.room || formValues?.room}</Text>
            </Col>
          </Row>
          <Row className={classes.fieldContainer}>
            <Col span={10} className={classes.labelContainer}>
              <Text>Patient Name</Text>
            </Col>
            <Col span={1} className={classes.labelContainer}>
              :
            </Col>
            <Col span={13} className={classes.labelContainer}>
              <Text>
                {getFormValues()?.patientName ||
                  formValues?.patientName ||
                  formValues?.toUnit?.split("|")[2]}
              </Text>
            </Col>
          </Row>
          <Row className={classes.fieldContainer}>
            <Col span={10} className={classes.labelContainer}>
              <Text>Nurse Name</Text>
            </Col>
            <Col span={1} className={classes.labelContainer}>
              :
            </Col>
            <Col span={13} className={classes.labelContainer}>
              <Text>{getFormValues()?.nurseName || formValues?.nurseName}</Text>
            </Col>
          </Row>
          <Row className={classes.fieldContainer}>
            <Col span={10} className={classes.labelContainer}>
              <Text>Nurse Contact</Text>
            </Col>
            <Col span={1} className={classes.labelContainer}>
              :
            </Col>
            <Col span={13} className={classes.labelContainer}>
              <Text>
                {getFormValues()?.phoneNumber || formValues?.phoneNumber}
              </Text>
            </Col>
          </Row>
          <Row className={classes.fieldContainer}>
            <Col span={10} className={classes.labelContainer}>
              <Text>Equipment (Yes / No)</Text>
            </Col>
            <Col span={1} className={classes.labelContainer}>
              :
            </Col>
            <Col span={13} className={classes.labelContainer}>
              <Text>
                {formValues?.selectedTransportItems?.length > 0 ? "Yes" : "No"}
              </Text>
            </Col>
          </Row>
          <Row className={classes.fieldContainer}>
            <Col span={10} className={classes.labelContainer}>
              <Text>Request For</Text>
            </Col>
            <Col span={1} className={classes.labelContainer}>
              :
            </Col>
            <Col span={13} className={classes.labelContainer}>
              <Text>{moment().format("MM-DD-YYYY")}</Text>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={2} className={classes.rightArrowContainer}>
        <RightCircleOutlined className={classes.rightArrow} />
      </Col>
      <Col span={11} className={classes.cardContainer}>
        <Card
          title="Destination"
          bordered
          headStyle={{
            borderColor: GREEN_THEME_HEX,
            borderBottom: borderBottom,
          }}
          className={classes.card}
        >
          <Row className={classes.fieldContainer}>
            <Col span={10} className={classes.labelContainer}>
              <Text>Transport Type</Text>
            </Col>
            <Col span={1} className={classes.labelContainer}>
              :
            </Col>
            <Col span={13} className={classes.labelContainer}>
              <Text>{selectedRequest}</Text>
            </Col>
          </Row>
          <Row className={classes.fieldContainer}>
            <Col span={10} className={classes.labelContainer}>
              <Text>To Unit</Text>
            </Col>
            <Col span={1} className={classes.labelContainer}>
              :
            </Col>
            <Col span={13} className={classes.labelContainer}>
              <Text>
                {selectedRequestId === RETURN_REQUEST_TYPE_ID
                  ? allUnits?.find(
                      (u: any) =>
                        u?.id === Number(getFormValues()?.toUnit?.split("|")[1])
                    )?.name ||
                    allUnits?.find(
                      (u: any) =>
                        u?.id === Number(formValues?.toUnit?.split("|")[1])
                    )?.name
                  : selectedRequestId === DISCHARGE_REQUEST_TYPE_ID
                  ? dischargeLocation?.find(
                      (u: any) => u?.id === getFormValues()?.toUnit
                    )?.name ||
                    dischargeLocation?.find(
                      (u: any) => u?.id === formValues?.toUnit
                    )?.name
                  : allUnits?.find(
                      (u: any) => u?.id === getFormValues()?.toUnit
                    )?.name ||
                    allUnits?.find((u: any) => u?.id === formValues?.toUnit)
                      ?.name}
              </Text>
            </Col>
          </Row>
          <Row className={classes.fieldContainer}>
            <Col span={10} className={classes.labelContainer}>
              <Text>To Room / Bedspace</Text>
            </Col>
            <Col span={1} className={classes.labelContainer}>
              :
            </Col>
            <Col span={13} className={classes.labelContainer}>
              <Text>
                {selectedRequestId === RETURN_REQUEST_TYPE_ID
                  ? getFormValues()?.toUnit?.split("|")[4] ||
                    formValues?.toUnit?.split("|")[4]
                  : getFormValues()?.toRoom || formValues?.toRoom || "-"}
              </Text>
            </Col>
          </Row>
          <Row className={classes.fieldContainer}>
            <Col span={10} className={classes.labelContainer}>
              <Text>Patient Name</Text>
            </Col>
            <Col span={1} className={classes.labelContainer}>
              :
            </Col>
            <Col span={13} className={classes.labelContainer}>
              <Text>
                {getFormValues()?.patientName ||
                  formValues?.patientName ||
                  formValues?.toUnit?.split("|")[2]}
              </Text>
            </Col>
          </Row>
          <Row className={classes.fieldContainer}>
            <Col span={10} className={classes.labelContainer}>
              <Text>Nurse Name</Text>
            </Col>
            <Col span={1} className={classes.labelContainer}>
              :
            </Col>
            <Col span={13} className={classes.labelContainer}>
              <Text>{getFormValues()?.nurseName || formValues?.nurseName}</Text>
            </Col>
          </Row>
          <Row className={classes.fieldContainer}>
            <Col span={10} className={classes.labelContainer}>
              <Text>Nurse Contact</Text>
            </Col>
            <Col span={1} className={classes.labelContainer}>
              :
            </Col>
            <Col span={13} className={classes.labelContainer}>
              <Text>
                {getFormValues()?.phoneNumber || formValues?.phoneNumber}
              </Text>
            </Col>
          </Row>
          <Row className={classes.fieldContainer}>
            <Col span={10} className={classes.labelContainer}>
              <Text>Name of person to contact</Text>
            </Col>
            <Col span={1} className={classes.labelContainer}>
              :
            </Col>
            <Col span={13} className={classes.labelContainer}>
              <Text>
                {getFormValues()?.contactPersonName ||
                  formValues?.contactPersonName ||
                  "-"}
              </Text>
            </Col>
          </Row>
          <Row className={classes.fieldContainer}>
            <Col span={10} className={classes.labelContainer}>
              <Text>Equipment (Yes / No)</Text>
            </Col>
            <Col span={1} className={classes.labelContainer}>
              :
            </Col>
            <Col span={13} className={classes.labelContainer}>
              <Text>
                {formValues?.selectedTransportItems?.length > 0 ? "Yes" : "No"}
              </Text>
            </Col>
          </Row>
          <Row className={classes.fieldContainer}>
            <Col span={10} className={classes.labelContainer}>
              <Text>Time Until Service</Text>
            </Col>
            <Col span={1} className={classes.labelContainer}>
              :
            </Col>
            <Col span={13} className={classes.labelContainer}>
              <Text>
                {
                  <Countdown
                    daysInHours={true}
                    date={moment(getFormValues()?.time || formValues?.time)
                      .seconds(0)
                      .format("MM-DD-YYYY HH:mm:ss")}
                    renderer={renderer}
                  />
                }
              </Text>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}

export default Step3;
