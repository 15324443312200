import React from "react";
import { connect } from "react-redux";
import { fetchItemBasedReport } from "../../actions";
import { IRootState } from "../../../../../common/redux/reducers";
import { SpecialityBadge } from "../../../../../common/components/specialityBadge/typeBadges";
import ReportsTable from "../commons/report-table";
const rowDataCreator = (rawData: any) =>
  rawData &&
  rawData.map((dateObj: any) => {
    const { date, total, itemData } = dateObj;
    return Object.keys(itemData).reduce(
      (acc: any, itemId: any) => {
        const itemObj = itemData[itemId];
        return {
          ...acc,
          ...itemObj.data.reduce((a: any, unitData: any) => {
            const { id: unitId, count } = unitData;
            return { ...a, [`${itemId}-${unitId}`]: count };
          }, {}),
        };
      },
      { date, totalOrders: total || "-" }
    );
  });

const columnCreator: any = (rawData: any) => {
  if (!rawData) return null;
  const finalObj = [
    {
      title: "Date",
      dataIndex: "date",
      fixed: "left",
      width: 100,
      align: "center",
    },
    {
      title: "Total Orders",
      dataIndex: "totalOrders",
      key: "totalOrders",
      width: 100,
      align: "center",
    },
  ];
  let topTempObj: any = {};
  rawData.map((dateData: any) => {
    const { itemData } = dateData;
    Object.keys(itemData).map((itemId: any) => {
      const {
        name: itemName,
        data: unitWiseData,
        specialityItem,
      } = itemData[itemId];
      topTempObj = {
        ...topTempObj,
        [itemId]: {
          ...topTempObj[itemId],
          title: () =>
            specialityItem ? <SpecialityBadge itemName={itemName} /> : itemName,
          ...unitWiseData.reduce((acc: any, curr: any) => {
            const { id: unitId, unit: unitName } = curr;
            return {
              ...acc,
              [unitId]: {
                title: unitName,
                width: 100,
                dataIndex: `${itemId}-${unitId}`,
                render: (text: any) => text || "-",
                align: "center",
              },
            };
          }, {}),
        },
      };
      return itemData;
    });
    return dateData;
  });
  const dateKeys: any = Object.keys(topTempObj);
  return finalObj.concat(
    dateKeys.map((itemId: any) => {
      let itemObj = topTempObj[itemId];
      let final: any = { title: itemObj.title, align: "center" };
      delete itemObj.title;
      final = { ...final, children: Object.values(itemObj) };
      return final;
    })
  );
};
const ReportSummaryTable = ({
  fetchItemBasedReport,
  reportData,
  reportLoading,
  filterData,
}: any) => {
  const columns = columnCreator(reportData.data);
  const rowData = rowDataCreator(reportData.data);
  return (
    <ReportsTable
      loading={reportLoading}
      rowData={rowData && rowData}
      columnData={columns}
      totalCount={reportData?.totalCount}
      fetchData={(pagination: any) => {
        fetchItemBasedReport({
          ...pagination,
          ...filterData,
        });
      }}
    />
  );
};
const mapStateToProps = ({ reportsState }: IRootState) => ({
  dataLoading: reportsState.loading,
  allSla: reportsState.allSlas,
  allOrderTypes: reportsState.allOrderTypes,
  successMsg: reportsState.successMessage,
  reportData: reportsState.reportData,
  reportLoading: reportsState.loading,
  filterData: reportsState.filterData,
});
const mapDispatchToProps = {
  fetchItemBasedReport,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportSummaryTable);
