/* eslint-disable react-hooks/exhaustive-deps*/
import { useEffect } from "react";
import { connect } from "react-redux";
import { IRootState } from "../../../../../common/redux/reducers";
import RequestViewNotes from "../notesComponent/RequestViewNotes";
import { clearActivities, fetchActivitiesDetails } from "./actions";

const Activities = ({
  dataLoading,
  activityDetails,
  fetchActivitiesDetails,
  clearActivities,
  reqId,
  selectedRequest
}: any) => {
  //to clear the redux state while unmounting
  useEffect(() => {
    return () => {
      clearActivities();
    };
  }, []);
  useEffect(() => {
    fetchActivitiesDetails({ reqId });
  }, [reqId]);
  return (
    <RequestViewNotes
      type="Activities"
      loading={dataLoading}
      data={activityDetails}
      orderExpired={selectedRequest.orderExpired}
    />
  );
};

const mapStateToProps = ({ activityDetails, requestState }: IRootState) => ({
  dataLoading: activityDetails.loading,
  errorMsg: activityDetails.errorMessage,
  activityDetails: activityDetails.activitiesList,
  selectedRequest: requestState.selectedRequest,
});
const mapDispatchToProps = {
  fetchActivitiesDetails,
  clearActivities,
};
export default connect(mapStateToProps, mapDispatchToProps)(Activities);
