import { Card, Col, Row, Tooltip, Typography } from "antd";
import classes from "./ImageButton.module.scss";
// import Pointer from "../../../../images/pointer.png";
import { useHistory } from "react-router-dom";

interface ImageButtonInterface {
  url: string;
  linkText: string;
  image: any;
}
function ImageButton({ url, linkText, image }: ImageButtonInterface) {
  const history = useHistory();
  return (
    <Row
      justify="center"
      className={classes.cardContainer}
      onClick={() => history.push(url)}
    >
      <Tooltip title={linkText}>
        <Card
          bordered={false}
          className={classes.pointerCard}
          bodyStyle={{ padding: "0px" }}
        >
          <Col style={{ width: "100%" }}>
            <Row justify="center" style={{ height: "80%" }}>
              <img alt="" src={image} className={classes.pointer}></img>
            </Row>
            <Row justify="center">
              <Typography className={classes.pointerText}>
                {linkText}
              </Typography>
            </Row>
          </Col>
        </Card>
      </Tooltip>
    </Row>
  );
}

export default ImageButton;
