import React from "react";
import { merge } from "lodash";
import { SpecialityBadge } from "../../../common/components/specialityBadge/typeBadges";
// import { SpecialityBadge } from "../../../common/components/specialityBadge/typeBadges";
export function isObject(item: any) {
  return item && typeof item === "object" && !Array.isArray(item);
}
export function mergeDeep(sources: any): any {
  if (!sources.length) return {};
  return sources.reduce((acc: any, curr: any) => {
    return merge(acc, curr);
  }, {});
}

export const itemNameFormatter = (item: any) => {
  return (
    <div title={item.splItem ? "Speciality Item" : ""}>
      {item.splItem ? (
        <SpecialityBadge
          itemName={
            (item.name || item.item?.name) + " - " + item.quantity + " "
          }
          minimal
        />
      ) : (
        <div style={{ width: "max-content" }}>
          {(item.name || item.item?.name) + " - " + item.quantity}
        </div>
      )}
    </div>
  );
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const roundToTwoDecimals = (num: number) =>
  num ? Math.round((num + Number.EPSILON) * 100) / 100 : num;
