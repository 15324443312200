import moment from "moment";
import { decode } from "../utils/jwt-decode";
import axios from "axios";
export const checkTokenValidity = async () => {
  const accessToken = localStorage.getItem("access_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!accessToken || !refreshToken) {
    return null;
  }
  const accessTokenData: any = decode(accessToken as string);
  const refreshTokenData: any = decode(refreshToken as string);
  if (!moment.unix(accessTokenData.exp).isAfter(moment().add(5, "minutes"))) {
    if (moment.unix(refreshTokenData.exp).isAfter(moment())) {
      return await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}admin/token`, {
          params: {
            refresh: refreshToken,
          },
        })
        .then((res: any) => {
          localStorage.setItem("access_token", res.data.data.auth.token);
          localStorage.setItem("refresh_token", res.data.data.refresh.token);
          const tokenInfo: any = decode(res.data.data.auth.token);
          return tokenInfo.data;
        })
        .catch((err: any) => {
          return null;
        });
    } else {
      return null;
    }
  }
  return accessTokenData.data;
};

export const checkRequestorPortalTokenValidity = async () => {
  const accessToken = localStorage.getItem("req_access_token");
  const refreshToken = localStorage.getItem("req_refresh_token");
  if (!accessToken || !refreshToken) {
    return null;
  }
  const accessTokenData: any = decode(accessToken as string);
  const refreshTokenData: any = decode(refreshToken as string);
  if (!moment.unix(accessTokenData.exp).isAfter(moment().add(5, "minutes"))) {
    if (moment.unix(refreshTokenData.exp).isAfter(moment())) {
      return await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}request/token`, {
          params: {
            refresh: refreshToken,
          },
        })
        .then((res: any) => {
          localStorage.setItem("req_access_token", res.data.data.auth.token);
          localStorage.setItem(
            "req_refresh_token",
            res.data.data.refresh.token
          );
          const tokenInfo: any = decode(res.data.data.auth.token);
          return tokenInfo.data;
        })
        .catch((err: any) => {
          return null;
        });
    } else {
      return null;
    }
  }
  return accessTokenData.data;
};
