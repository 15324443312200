import { adminRootInstance } from "../../../common/config/axios-config";
import {
	FETCH_USERS_SUCCESS,
	FETCH_USERS_FAIL,
	CREATE_USER_SUCCESS,
	CREATE_USER_FAIL,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAIL,
	DELETE_USER_FAIL,
	DELETE_USER_SUCCESS,
	FETCH_USERS_START,
} from "./action-types";

export const fetchUsers = ({ search, limit, page }: any) => async (
	dispatch: any
) => {
	dispatch({
		type: FETCH_USERS_START,
	});
	await adminRootInstance
		.get("users", {
			params: {
				searchKeyword: search,
				pageSize: limit,
				page,
			},
		})
		.then((res: any) => {
			dispatch({
				type: FETCH_USERS_SUCCESS,
				payload: {
					users: res.data.data.users,
					totalCount: res.data.data.pagination.totalCount,
				},
			});
		})
		.catch((err) => {
			dispatch({
				type: FETCH_USERS_FAIL,
				payload: { fetchErrorMessage: err.response?.data?.message },
			});
		});
};

export const createUser = (user: any) => async (dispatch: any) => {
	return await adminRootInstance
		.post("/user", user)
		.then((res) => {
			dispatch({
				type: CREATE_USER_SUCCESS,
				payload: { successMessage: "User Created Successfully" },
			});
			return { success: true };
		})
		.catch((err) => {
			dispatch({
				type: CREATE_USER_FAIL,
				payload: { errorMessage: err.response?.data?.message },
			});
			return { success: false };
		});
};

export const updateUser = (user: any) => async (dispatch: any) => {
	return await adminRootInstance
		.put(`/user/${user.id}`, user)
		.then((res) => {
			dispatch({
				type: UPDATE_USER_SUCCESS,
				payload: { successMessage: "User Updated Successfully" },
			});
			return { success: true };
		})
		.catch((err) => {
			dispatch({
				type: UPDATE_USER_FAIL,
				payload: { errorMessage: err.response?.data?.message },
			});
			return { success: false };
		});
};

export const deleteUser = ({ id }: any) => async (dispatch: any) => {
	return await adminRootInstance
		.delete(`/user/${id}`)
		.then((res) => {
			dispatch({
				type: DELETE_USER_SUCCESS,
				payload: { successMessage: "User Deleted Successfully" },
			});
			return { success: true };
		})
		.catch((err) => {
			dispatch({
				type: DELETE_USER_FAIL,
				payload: { errorMessage: err.response?.data?.message },
			});
			return { success: false };
		});
};
