import {
  FETCH_SLAS_SUCCESS,
  FETCH_SLAS_FAIL,
  CREATE_SLA_SUCCESS,
  CREATE_SLA_FAIL,
  UPDATE_SLA_SUCCESS,
  UPDATE_SLA_FAIL,
  DELETE_SLA_FAIL,
  DELETE_SLA_SUCCESS,
  CLEAR_SLA_MSG,
  CLEAR_SLA_FETCH_ERROR_MSG,
  FETCH_SLAS_START,
  FETCH_REQUEST_TYPES,
  FETCH_REQUEST_TYPES_FAIL,
} from "./action-types";

const initialState = {
  loading: false,
  slas: [] as any,
  totalCount: 0,
  errorMessage: null as null | string,
  successMessage: null as null | string,
  fetchErrorMessage: null as null | string,
  requestTypes: [] as any,
};

export type SLAManagementState = Readonly<typeof initialState>;

const slaManagement = (
  state = initialState,
  action: any
): SLAManagementState => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SLAS_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SLAS_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchErrorMessage: null,
        slas: payload.slas,
        totalCount: payload.totalCount,
      };
    case FETCH_SLAS_FAIL:
      return {
        ...state,
        loading: false,
        fetchErrorMessage: payload.fetchErrorMessage,
        slas: null,
      };
    case CREATE_SLA_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        successMessage: payload.successMessage,
      };
    case CREATE_SLA_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        successMessage: null,
      };
    case UPDATE_SLA_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        loading: false,
        successMessage: payload.successMessage,
      };
    case UPDATE_SLA_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        successMessage: null,
      };

    case DELETE_SLA_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        successMessage: payload.successMessage,
      };
    case DELETE_SLA_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        successMessage: null,
      };
    case CLEAR_SLA_MSG:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      };
    case CLEAR_SLA_FETCH_ERROR_MSG:
      return {
        ...state,
        fetchErrorMessage: null,
      };
    case FETCH_REQUEST_TYPES:
      return {
        ...state,
        requestTypes: payload.requests.data,
      };
    case FETCH_REQUEST_TYPES_FAIL:
      return {
        ...state,
        requestTypes: [],
      };
    default:
      return state;
  }
};
export default slaManagement;
