import { adminRootInstance } from "../../../common/config/axios-config";
import {
  UPDATE_TRANSPORT_SUCCESS,
  UPDATE_TRANSPORT_FAIL,
  FETCH_TRANSPORTS_FAIL,
  FETCH_TRANSPORTS_SUCCESS,
  FETCH_TRANSPORTS_START,
} from "./action-types";

export const fetchTransports =
  ({ search, limit, page }: any) =>
  async (dispatch: any) => {
    dispatch({
      type: FETCH_TRANSPORTS_START,
    });
    await adminRootInstance
      .get("transport", {
        params: {
          searchKeyword: search,
          pageSize: limit,
          page,
        },
      })
      .then((res: any) => {
        const transports = res.data.data.transports?.filter(
          (transport: any) => transport.id !== 1
        );
        dispatch({
          type: FETCH_TRANSPORTS_SUCCESS,
          payload: {
            transports: transports,
            totalCount: res.data.data.pagination.totalCount,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_TRANSPORTS_FAIL,
          payload: { fetchErrorMessage: err.response?.data?.message },
        });
      });
  };

export const updateTransport = (transport: any) => async (dispatch: any) => {
  return await adminRootInstance
    .put(`/transport/${transport.id}`, transport)
    .then((res) => {
      dispatch({
        type: UPDATE_TRANSPORT_SUCCESS,
        payload: { successMessage: "Transport Updated Successfully" },
      });
      return { success: true };
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_TRANSPORT_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
      return { success: false };
    });
};
