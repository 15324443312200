/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import ItemPickup from "../../shared/components/item-pickup";
import { Row, Col } from "antd";
import classes from "./equipment-pickup.module.scss";
import { useHistory } from "react-router-dom";
import LocationSelection from "../../shared/components/location-selection";
import RequestDetails from "../../shared/components/request-details";
import { IRootState } from "../../../common/redux/reducers";
import { connect } from "react-redux";
import {
  fetchCampuses,
  fetchUnits,
  fetchReqSlas,
} from "../../shared/reducers/actions";
import {
  fetchPickupItems,
  placePickUpOrder,
  clearErrorMessage,
} from "../equipment-pickup/action";
import { setSuccessMessage } from "../home/action";
import { PICKUP_REQUEST_TYPE_ID } from "../../../common/shared-constants";
import { login } from "../login/actions";
import msalInstance from "../../../common/utils/sso-config";
import AnonymousModal from "../../../common/utils/anonymous-popup";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import CustomAlert from "../../../common/utils/custom-alert";
import { sendEmail } from "../../../common/utils/send-email";

const EquipmentPickup = ({
  campuses,
  units,
  fetchUnits,
  pickupItems,
  slas,
  fetchPickupItems,
  fetchReqSlas,
  placePickUpOrder,
  errorMessage,
  clearErrorMessage,
  login,
  account,
  campusData,
}: any) => {
  const history = useHistory();
  const [cartItems, setCartItems] = useState<any>([]);
  const [locationValues, setLocationValues] = useState<any>();
  const [loginError, setLoginError] = useState<any>();
  const [view, setView] = useState("form");
  const [anonymousMoodal, setAnonymousModal] = useState(false);
  const [ssoError, setSsoError] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);

  useEffect(() => {
    campusData.id && fetchReqSlas(PICKUP_REQUEST_TYPE_ID, campusData.id);
  }, [campusData]);

  useEffect(() => {
    const allowedRequestTypesIds = campusData?.requestTypes?.map(
      (requestType: any) => requestType.id
    );
    if (!allowedRequestTypesIds?.includes(PICKUP_REQUEST_TYPE_ID)) {
      history.push("/");
    }
  }, [campusData]);

  useEffect(() => {
    const timeout = loginError && setTimeout(() => setLoginError(""), 5000);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [loginError]);

  const onItemsSelected = (items: any) => {
    setCartItems(items);
  };

  useEffect(() => {
    const timeout = loginError && setTimeout(() => setLoginError(""), 5000);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [loginError]);

  const quantityEdit = (itemId: any, action: any, newQuantity?: number) => {
    const items = cartItems.map((cartItem: any) => {
      if (cartItem.item.id === itemId) {
        if (action === "INCREASE") {
          cartItem.quantity += 1;
        } else if (action === "EDIT") {
          cartItem.quantity = newQuantity;
        } else {
          cartItem.quantity -= 1;
        }
      }
      return cartItem;
    });
    setCartItems(items);
  };

  const onItemRemove = (removedId: any) => {
    let itemRemoving = [...cartItems];
    itemRemoving = itemRemoving.filter(
      (cartItem) => cartItem.item.id !== removedId
    );
    setCartItems(itemRemoving);
  };

  const removeItems = (removeIds: any) => {
    let itemRemoving = [...cartItems];
    itemRemoving = itemRemoving.filter(
      (cartItem) => !removeIds.includes(cartItem.item.id)
    );
    setCartItems(itemRemoving);
  };

  const onNext = async (data: any) => {
    setLocationValues(data);
    await fetchPickupItems(data.unitId, data.campusId);
    if (data !== false) {
      setView("itemselection");
    }
  };

  const backHome = () => {
    history.push("/");
  };

  const fetchUnitsByCampusId = (campusId: any) => {
    fetchUnits(campusId);
  };

  const goBack = (viewVal: any) => {
    setView(viewVal);
  };

  const onItemAdd = (added: any) => {
    const found = cartItems.find(
      (cartItem: any) => cartItem.item.id === added.id
    );
    if (found === undefined) {
      setCartItems([...cartItems, { item: added, quantity: 1 }]);
    }
  };

  const loginRequest = async () => {
    setLoginLoading(true);
    await msalInstance
      .loginPopup()
      .then(async (ssoResponse: any) => {
        if (ssoResponse.idTokenClaims) {
          localStorage.setItem(
            "logoutHint",
            ssoResponse.idTokenClaims.login_hint
          );
          let loginPayload = {
            name: ssoResponse.idTokenClaims.name.split(",")[1]
              ? `${ssoResponse.idTokenClaims.name.split(",")[1]} ${
                  ssoResponse.idTokenClaims.name.split(",")[0]
                }`
              : ssoResponse.idTokenClaims.name.split(",")[0],
            email: ssoResponse.idTokenClaims.preferred_username,
            employeeId: ssoResponse.idTokenClaims.employeeid,
          };
          const loginResponse = await login(loginPayload);
          if (loginResponse.status === "success") {
            setLoginError("");
          } else {
            setLoginError(loginResponse.errorMessage);
          }
        } else {
          setAnonymousModal(true);
          setSsoError("SSO Authentication failed!");
        }
      })
      .catch((err: any) => {
        sendEmail(err);
        setAnonymousModal(true);
        setSsoError(err.message);
      });
    setLoginLoading(false);
  };

  const onFinalSubmit = async (orderData: any) => {
    if (process.env.REACT_APP_SSO_ENABLED === "true") {
      if (account.phone === orderData.phoneNumber) {
        delete orderData.phoneNumber;
      }
      const res = await placePickUpOrder({
        ...orderData,
        externalUserId: account.id,
      });
      if (res.status === "success") {
        history.push("/request");
      }
    } else {
      if (!account) {
        const loginResponse = await login({
          name: orderData.name,
          phone: orderData.phoneNumber,
          email: orderData.email,
        });
        if (loginResponse.status === "success") {
          setLoginError("");
          delete orderData.phoneNumber;
          const res = await placePickUpOrder({
            ...orderData,
            externalUserId: loginResponse.user.id,
          });
          if (res.status === "success") {
            history.push("/request");
          }
        } else {
          setLoginError(loginResponse.errormessage);
        }
      } else {
        delete orderData.phoneNumber;
        const res = await placePickUpOrder({
          ...orderData,
          externalUserId: account.id,
        });
        if (res.status === "success") {
          history.push("/request");
        }
      }
    }
  };

  const onItemAllClear = (e: any) => {
    setCartItems(e);
  };

  const onCartCheckout = (cart: any) => {
    setView("requestdetail");
  };

  const viewSelection = (view: any) => {
    switch (view) {
      case "form":
        return (
          <LocationSelection
            onNext={(e: any) => {
              onNext(e);
            }}
            backHome={backHome}
            campusId={campusData.id}
            units={units}
            fetchUnits={fetchUnitsByCampusId}
            locationValues={locationValues}
            cartItems={cartItems}
            removeItems={removeItems}
          />
        );
      case "itemselection":
        return (
          <ItemPickup
            cartItems={cartItems}
            items={pickupItems}
            onItemsSelected={onItemsSelected}
            quantityEdit={quantityEdit}
            goBack={goBack}
            onItemRemove={onItemRemove}
            clear={onItemAllClear}
            checkout={onCartCheckout}
          />
        );
      case "requestdetail":
        return (
          <RequestDetails
            setLoginError={setLoginError}
            quantityEdit={quantityEdit}
            goBack={goBack}
            locationValues={locationValues}
            campus={campusData}
            units={units}
            cartItems={cartItems}
            slas={slas}
            onItemAdd={onItemAdd}
            onItemRemove={onItemRemove}
            onSubmit={onFinalSubmit}
            login={loginRequest}
            loginLoading={loginLoading}
            requestTypeId={PICKUP_REQUEST_TYPE_ID}
            errorMessage={errorMessage}
            clearErrorMessage={clearErrorMessage}
            account={account}
          />
        );
      default:
        return (
          <LocationSelection
            onNext={(e: any) => {
              onNext(e);
            }}
            backHome={backHome}
            campusId={campusData.id}
            units={units}
            fetchUnits={fetchUnitsByCampusId}
            locationValues={locationValues}
            cartItems={cartItems}
            removeItems={removeItems}
          />
        );
    }
  };

  return (
    <div className={classes.equipmentContainer}>
      <Row>
        <Col span={24}>
          <p className={classes.titleText}>Equipment Pickup</p>
        </Col>
        {!!loginError && (
          <Col span={24}>
            <CustomAlert
              message={loginError}
              onCancel={() => setLoginError("")}
            />
          </Col>
        )}
      </Row>
      {viewSelection(view)}
      <AnonymousModal
        key="info-modal"
        handleCancel={() => {
          setLoginError(ssoError);
          setAnonymousModal(false);
        }}
        handleOk={() => {
          setAnonymousModal(false);
        }}
        isModalVisible={anonymousMoodal}
        icon={<ExclamationCircleTwoTone className={classes.exclamationIcon} />}
        text={"The sso server is currently down. Please try after some time"}
        okayText="Ok"
      />
    </div>
  );
};

const mapStateToProps = ({
  authentication,
  requestData,
  pickUpManagement,
  homeReducer,
}: IRootState) => ({
  campuses: requestData.campuses,
  units: requestData.units,
  errorMsg: requestData.errorMessage,
  slas: requestData.slas,
  pickupItems: pickUpManagement.pickupItems,
  errorMessage: pickUpManagement.errorMessage,
  successMessage: pickUpManagement.successMessage,
  account: authentication.account,
  campusData: homeReducer.currentCampus,
});
const mapDispatchToProps = {
  fetchCampuses,
  fetchUnits,
  fetchPickupItems,
  fetchReqSlas,
  placePickUpOrder,
  setSuccessMessage,
  clearErrorMessage,
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentPickup);
