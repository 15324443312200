import { CANCELLED } from "../../../common/shared-constants";
import { FETCH_CATEGORIESITEMS_START } from "../equipment-delivery/action-types";
import {
  CLEAR_REQUESTS_FETCH_ERROR_MSG,
  FETCH_MYREQUEST_LIST_FAIL,
  FETCH_MYREQUEST_LIST_SUCCESS,
  FETCH_ALL_ITEMS_FAIL,
  FETCH_ALL_ITEMS_SUCCESS,
  EDIT_REQ_SUCCESS,
  CLEAR_MESSAGES,
  EDIT_REQ_FAIL,
  CANCEL_REQ_SUCCESS,
  CANCEL_REQ_FAIL,
  UPDATE_REQUEST,
  CLEAR_DATA,
  FETCH_MYREQUEST_LIST_START,
} from "./action-types";

const initialState = {
  loading: false,
  ordersLoading: false,
  orders: [] as any,
  totalCount: 0,
  items: [] as any,
  fetchErrorMessage: null as null | string,
  successMessage: null as null | string,
  errorMessage: null as null | string,
};

export type MyRequestsState = Readonly<typeof initialState>;

const myRequests = (state = initialState, action: any): MyRequestsState => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_MYREQUEST_LIST_START:
      return {
        ...state,
        ordersLoading: true,
      };
    case FETCH_MYREQUEST_LIST_SUCCESS:
      return {
        ...state,
        orders: payload.orders,
        totalCount: payload.totalCount,
        fetchErrorMessage: null,
        ordersLoading: false,
      };
    case FETCH_MYREQUEST_LIST_FAIL:
      return {
        ...state,
        fetchErrorMessage: payload.errorMessage,
        orders: [],
        ordersLoading: false,
      };
    case CLEAR_REQUESTS_FETCH_ERROR_MSG:
      return {
        ...state,
        fetchErrorMessage: null,
      };
    case FETCH_CATEGORIESITEMS_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: payload.items,
        errorMessage: null,
      };
    case FETCH_ALL_ITEMS_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        items: [],
      };
    case EDIT_REQ_SUCCESS:
      const updatedRequest = payload.updatedRequest;
      const orders = state.orders.map((order: any) => {
        if (order.id === updatedRequest.id) {
          return updatedRequest;
        }
        return order;
      });
      return {
        ...state,
        errorMessage: null,
        successMessage: payload.successMessage,
        orders,
      };
    case EDIT_REQ_FAIL:
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    case CANCEL_REQ_SUCCESS:
      const requestId = payload.requestId;
      const updatedOrders = state.orders.map((order: any) => {
        if (order.requestId === requestId) {
          order.status = CANCELLED;
          order.cancelReason = payload.cancelReason;
          return order;
        }
        return order;
      });
      return {
        ...state,
        errorMessage: null,
        successMessage: payload.successMessage,
        orders: updatedOrders,
      };
    case CANCEL_REQ_FAIL:
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };

    case CLEAR_MESSAGES:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      };
    case UPDATE_REQUEST:
      const updatedOrder = payload.updatedRequest;
      const allOrders = state.orders.map((order: any) =>
        order.id === updatedOrder.id ? updatedOrder : order
      );
      return {
        ...state,
        orders: allOrders,
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default myRequests;
