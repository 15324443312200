import { useEffect, useRef, useState } from "react";

export default function useScanner() {
  const [readCode, setReadCode] = useState<string>("");
  const code = useRef("");
  const reading: any = useRef(false);
  useEffect(() => {
    let timeOut: number;
    document.addEventListener("keypress", (e) => {
      //usually scanners throw an 'Enter' key at the end of read
      if (e.key === "Enter") {
        if (code.current.length > 1) {
          setReadCode(code.current);
          code.current = "";
        }
      } else {
        code.current += e.key; //while this is not an 'enter' it stores the every key
      }

      //run a timeout of 200ms at the first read and clear everything
      if (!reading.current) {
        reading.current = true;
        timeOut = window.setTimeout(() => {
          setReadCode("");
          code.current = "";
          reading.current = false;
        }, 200); //200 works fine for me but you can adjust it
      }
    });
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  return readCode.trim();
}
